import styled from "styled-components";

export const MainBar = styled.div`
  height: 8px;
  background-color: ${({ theme }) => theme.colors.denim};
  border-radius: 16px 16px 16px 16px;
`;

export const UsedBar = styled.div<{ width: number }>`
  height: 8px;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ theme }) => theme.colors.linkWaterDim};
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 0px 0px 16px;
`;

export const Wrapper = styled.div`
  position: relative;
`;
