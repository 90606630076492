import React, { BaseSyntheticEvent } from "react";
import {
  ControlsPanel,
  PlayIcon,
  PauseIcon,
  FullscreenIcon,
  SeekProgress,
  VolumeBar,
  VolumeIcon,
  MutedIcon,
} from "./styles";
import { ControlsProps } from "./types";

const Controls = ({
  currentSeek,
  playing,
  volume,
  handlePlay,
  handleSeekChange,
  handleSeekMouseDown,
  handleSeekMouseUp,
  handleMute,
  handleVolumeChange,
  handleFullscreen,
  totalVideoDuration,
}: ControlsProps) => {
  return (
    <ControlsPanel>
      {playing ? (
        <PauseIcon onClick={handlePlay} />
      ) : (
        <PlayIcon onClick={handlePlay} />
      )}
      <SeekProgress
        type="range"
        step="any"
        min={0}
        max={totalVideoDuration}
        value={currentSeek}
        onChange={(e: BaseSyntheticEvent) =>
          handleSeekChange(Number(e.target.value))
        }
        onMouseDown={handleSeekMouseDown}
        onMouseUp={(e: BaseSyntheticEvent) =>
          handleSeekMouseUp(Number(e.target.value))
        }
      />
      {!volume ? (
        <MutedIcon onClick={handleMute} />
      ) : (
        <VolumeIcon onClick={handleMute} />
      )}
      <VolumeBar
        type="range"
        step="any"
        min={0}
        max={1}
        value={volume}
        onChange={(e: BaseSyntheticEvent) => {
          handleVolumeChange(Number(e.target.value));
        }}
      />
      <FullscreenIcon onClick={handleFullscreen} />
    </ControlsPanel>
  );
};

export default Controls;
