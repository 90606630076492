import Button from "components/atoms/Button";
import media from "helpers/media";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roboto";
  position: relative;
`;

export const InfoWrapper = styled.div`
  margin-right: 1.6rem;
  cursor: pointer;

  ${media.SM`
    display: none;
  `}
`;

export const NameText = styled.div`
  font-size: 1.6rem;

  ${({ theme }) => css`
    ${media.SM`
    font-weight: 500; font-size: 1.5rem; color:
      ${theme.colors.white};
      margin-bottom: 0.7rem;
  `}
  `}
`;

export const EmailText = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.stormGray};
  ${({ theme }) => css`
    ${media.SM`
    font-size: 1.3rem;
     color: ${theme.colors.white};
  `}
  `}
`;

export const Circle = styled.div`
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 2px solid ${({ theme }) => theme.colors.lavenderGray};
  border-radius: 100%;
  cursor: pointer;
`;

export const CircleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colors.blackSqueeze};
  color: ${({ theme }) => theme.colors.denim};
  border-radius: 100%;
`;

export const MobileContainerBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(16px);
  background: linear-gradient(
    115.63deg,
    rgba(255, 255, 255, 0.77) 18.89%,
    rgba(217, 222, 246, 0.566512) 65.78%,
    rgba(178, 188, 236, 0.36) 83.92%
  );
  transition: opacity 0.3s;
  z-index: 99;
  overflow: none;
`;

export const MobileSidebar = styled.div`
  display: none;

  ${media.SM`
    display: block;
  `}
`;

export const MobileContainer = styled.div`
  position: fixed;

  overflow: hidden;
  top: 0;
  left: 0;
  width: 31rem;
  height: 100%;
  z-index: 100;
  border-radius: 0px 40px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

export const SidebarHeader = styled.div`
  height: 9rem;
  background-color: ${({ theme }) => theme.colors.denim};
  position: relative;
  padding-left: 2.4rem;
  padding-right: 3.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarBody = styled.div`
  flex: 1;
`;

export const School = styled.div`
  height: 7rem;
  border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.athensGray};
  display: flex;
  justify-content: left;
  padding-left: 2.5rem;
  align-items: center;
  font-family: "Roboto Slab";
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  text-transform: uppercase;
  div {
    margin-left: 1rem;
    text-align: center;
    width: 17.8rem;
  }
`;

export const Logout = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteLilac};
  color: ${({ theme }) => theme.colors.mandy};
  font-size: 1.4rem;
  line-height: 2.4rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 2rem;
  z-index: 1000;
`;

export const ButtonSection = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 3.2rem;
`;

export const DeleteAccountWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  margin: 0 -4rem;
  padding: 2.4rem 4rem 0;
`;

export const DeleteAccount = styled.button`
  color: ${({ theme }) => theme.colors.mandy};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
