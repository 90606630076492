import { CSSProperties } from "react";
import styled, { css } from "styled-components";

export const StyledButton = styled.button<{
  enabled: boolean;
  enabledStyles: CSSProperties;
}>`
  appearance: none;
  border-radius: 0.4rem;
  border: 1px solid rgba(207, 216, 220, 0.5);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  letter-spacing: 0.04rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  margin: 0 0.2rem;
  white-space: nowrap;
  display: flex;
  gap: 0.6rem;

  ${({ enabled, enabledStyles }) =>
    enabled &&
    css`
      ${{ ...enabledStyles }}
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.manatee};
  }
`;
