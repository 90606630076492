import styled from "styled-components";

import { ReactComponent as Clock } from "assets/images/clock-white.svg";
import Button from "components/atoms/Button";
import media from "helpers/media";

export const ModalWrapper = styled.div`
  ${media.SM`
    display:none  
  `}
`;

export const Heading = styled.h3`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.defaultColor};

  ${media.SM`
  font-size: 1.2rem;
  `}
`;

export const Wrapper = styled.div`
  padding-left: 6.4rem;

  ${media.SM`
    padding: 0;
  `}
`;

export const Content = styled.div`
  font-family: "Roboto";
  font-size: 1.8rem;
  margin-top: 5.2rem;
  margin-bottom: 5.6rem;
  padding-right: 19.5rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.mineShaft};

  ${media.SM`
    margin: 1.6rem 0;
    padding: 0;
    font-size: 1.5rem;
    line-height: 2.4rem;
  `}
`;

export const ActionSectionTitle = styled.h3`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.mineShaft};

  ${media.SM`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}
`;

export const ButtonsSection = styled.div`
  margin-top: 2.4rem;
  display: flex;

  ${media.SM`
    flex-direction: column;
  `}
`;

export const PrimaryBtnWrapper = styled.div`
  margin-right: 2.4rem;

  ${media.SM`
    margin: 0;
  `}
`;

export const Circle = styled.div`
  width: 14.8rem;
  height: 14.8rem;
  border-radius: 100%;
  position: absolute;
  top: -2.5em;
  right: 3.2rem;
  background-color: ${({ theme }) => theme.colors.defaultColor};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${media.SM`
    width: 9.8rem;
    height: 9.8rem;
  `}
`;

export const CircleText = styled.div`
  font-family: "Roboto";
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 1px;

  ${media.SM`
    font-size: 1.3rem;
  `}
`;

export const StyledButton = styled(Button)`
  width: min-content;
  margin-bottom: 1.6rem;

  ${media.SM`
    width: 100%;
  `}
`;

export const StyledClock = styled(Clock)`
  ${media.SM`
    width: 2.6rem;
    height: 2.6rem;
  `}
`;

export const CircleContent = styled.div`
  ${media.SM`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  `}
`;
