import React, { FC } from "react";

import Label from "../Label";

import { OuterBorder, ButtonWrapper, NameText } from "./styles";
import { Props } from "./types";

const RadioButton: FC<Props> = ({
  isSelected,
  label,
  name,
  value,
  onClick,
}) => {
  const handleClick = (id: string) => {
    onClick(id);
  };

  return (
    <div>
      {label && <Label>{label}</Label>}
      <ButtonWrapper onClick={() => handleClick(value)}>
        <OuterBorder type="button" isSelected={isSelected} />
        <NameText>{name}</NameText>
      </ButtonWrapper>
    </div>
  );
};

export default RadioButton;
