import paths from "routes/paths";
import { UserRoles } from "types/user";

const commonRoutes = [
  paths.root,
  paths.signIn,
  paths.decider,
  paths.setupPassword,
  paths.verifyData,
  paths.notFound,
  paths.forgotPassword,
  paths.feedback,
];

const studentRoutes = [
  ...commonRoutes,
  paths.studentDashboard,
  paths.tests,
  paths.selfRegistrationPayment,
  paths.selfRegistrationPaymentSuccess,
  paths.selfRegistrationPaymentFailed,
  paths.paymentSuccess,
  paths.paymentFailed,
  paths.testInstruction,
  paths.training,
  paths.trainingFinished,
  paths.academyDashboard,
  paths.videoPlayer,
  paths.tutorialPlayer,
];
const teacherRoutes = [
  ...commonRoutes,
  paths.faq,
  paths.students,
  paths.studentsAdd,
  paths.folders,
  paths.school,
  paths.paymentSuccess,
  paths.paymentFailed,
];

export const routesByRoles = {
  [UserRoles.student]: studentRoutes,
  [UserRoles.teacher]: teacherRoutes,
};
