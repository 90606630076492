import styled from "styled-components";

export const LabelWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  font-size: 1.3rem;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.4px;
  cursor: pointer;
  width: 13rem;
  margin-left: 0.8rem;
  &:hover {
    border-color: ${({ theme }) => theme.colors.manatee};
  }
`;

export const Label = styled.div`
  display: flex;
  gap: 8px;
`;

export const DropdownContent = styled.div<{ visible: boolean }>`
  position: relative;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 4px 4px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  right: 0;
  top: 4rem;
  width: 18rem;

  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  position: relative;
  user-select: none;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.geyser};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;

  background: ${({ theme }) => theme.colors.aquaHaze};
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.denim};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  display: flex;
  z-index: 9999;
  justify-content: center;
  padding: 1.2rem;
  flex-direction: column;
  gap: 1.2rem;
`;

export const FolderItem = styled.div`
  cursor: pointer;
  padding: 0.8rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.denim};
  }
  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  }
`;
