/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, FlattenSimpleInterpolation } from "styled-components";

import { XXS, XS, SM, MD, LG, XL, XXL } from "constants/devices";

const breakpoints: { [key: string]: number } = {
  XXS,
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(literals, ...placeholders)}
    }
  `;
  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => FlattenSimpleInterpolation>);

export default media;
