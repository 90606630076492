import styled from "styled-components";

export const ConfirmWrapper = styled.div`
  margin-top: 2rem;
  font-size: 1.8rem;
  letter-spacing: 0.3px;
  font-weight: 400;
`;

export const ButtonSection = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 3.2rem;
`;
