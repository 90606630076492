import { ReactiveVar, makeVar } from "@apollo/client";

import { GetAllSchoolsQuery } from "generated/graphql";

export const allSchoolsVar: ReactiveVar<GetAllSchoolsQuery> = makeVar<GetAllSchoolsQuery>(
  {}
);

const currentSchoolIdFromLS = localStorage.getItem("EFC_currentSchool");

export const currentSchoolIdVar: ReactiveVar<string> = makeVar<string>(
  currentSchoolIdFromLS || ""
);
