import React, { FC, useEffect, useRef, ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseIcon } from "assets/images/circle-close.svg";
import Chip from "components/atoms/Chip";
import { SelectableStudent } from "types/student";

import { Props } from "./types";
import {
  Container,
  DataCard,
  DataItem,
  ChipsWrapper,
  CardSection,
  CloseButton,
  CardWrapper,
  OpenChipsButton,
  OpenChipsText,
  Dot,
  TextInputWrapper,
} from "./styles";

const INITIAL_NUMBER_OF_CHIPS = 3;

const EmailChipsGroup: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { inputValue, onInputSearch, data, handleToggle, selectedData } = props;

  const inputElement = useRef<HTMLInputElement | null>(null);
  const [isComposingActive, setComposingActive] = useState(false);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isComposingActive]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    onInputSearch(e.target.value);
  };

  const renderChips = () => {
    const chips = [];
    const length =
      !isComposingActive && selectedData.length > 3
        ? INITIAL_NUMBER_OF_CHIPS
        : selectedData.length;

    if (selectedData.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        chips.push(
          <Chip
            key={selectedData[i].user.id}
            title={selectedData[i]?.user.firstName || ""}
            icon={<Dot />}
            onClose={(e) => {
              e.stopPropagation();
              handleToggle(selectedData[i]);
            }}
            styles={{
              marginRight: "1rem",
              marginBottom: "0.5rem",
            }}
          />
        );
      }
    }

    return chips;
  };

  const handleCardItemClick = (student: SelectableStudent) => {
    if (!student) return;
    handleToggle(student);
    setComposingActive(false);
    onInputSearch("");
  };

  const handleChipsSectionClick = () => {
    setComposingActive(true);
  };

  const isChipsSectionOpen =
    !isComposingActive && selectedData.length > INITIAL_NUMBER_OF_CHIPS;

  return (
    <Container>
      <ChipsWrapper
        style={{
          width: isChipsSectionOpen ? "auto" : "100%",
        }}
        onClick={handleChipsSectionClick}
      >
        {renderChips()}
        {isComposingActive && (
          <TextInputWrapper>
            <input
              type="text"
              ref={inputElement}
              value={inputValue}
              onChange={handleInput}
              onFocus={() => setComposingActive(true)}
            />
          </TextInputWrapper>
        )}
      </ChipsWrapper>
      {isComposingActive && inputValue.length >= 1 && (
        <DataCard>
          <CardSection>
            <CloseButton
              type="button"
              onClick={() => setComposingActive(false)}
            >
              <CloseIcon />
            </CloseButton>
            <CardWrapper>
              {data &&
                data.map((student) => {
                  const isSelected = selectedData.some(
                    (el) => el.user.id === student?.user?.id
                  );

                  return (
                    <DataItem
                      key={student?.user?.id}
                      onClick={() =>
                        student?.user &&
                        handleCardItemClick(student as SelectableStudent)
                      }
                      isSelected={isSelected}
                    >
                      {student?.user?.firstName}
                    </DataItem>
                  );
                })}
            </CardWrapper>
          </CardSection>
        </DataCard>
      )}
      {isChipsSectionOpen && (
        <OpenChipsButton type="button" onClick={handleChipsSectionClick}>
          <OpenChipsText>
            + {selectedData.length - INITIAL_NUMBER_OF_CHIPS}{" "}
            {t("teacher.more")}
          </OpenChipsText>
        </OpenChipsButton>
      )}
    </Container>
  );
};

export default EmailChipsGroup;
