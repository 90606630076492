import styled from "styled-components";

export const DropdownWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.denim};
  border-radius: 0.4rem;
  border: 1px solid rgba(207, 216, 220, 0.5);
  padding: 0.8rem;
  font-weight: 500;
  font-size: 1.3rem;
  width: 190px;
  cursor: pointer;
  margin-left: 10rem;
`;

export const Caption = styled.div`
  flex: 1;
`;

export const DropdownElement = styled.div`
  position: relative;
`;

export const Dropdown = styled.div`
  z-index: 9999;
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  padding-bottom: 1.8rem;
  overflow: auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
`;

export const FolderItem = styled.div`
  padding: 0.8rem;
  cursor: pointer;
  font-size: 1.3rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.geyser};
  &:hover {
    background-color: ${({ theme }) => theme.colors.denim};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.4rem;
  }
  &:last-child {
    border: none;
  }
`;

export const FolderList = styled.div`
  max-height: 150px;
  overflow: auto;
`;

export const SearchInputWrapper = styled.div`
  padding: 0.8rem;
  width: 100%;
`;
