import styled from "styled-components";

import CircleSvg from "assets/images/circle.svg";
import CircleCheckedSvg from "assets/images/circle-checked.svg";

export const Circle = styled.div<{ isset: boolean }>`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${CircleSvg});
  ${({ isset }) => isset && `background-image: url(${CircleCheckedSvg});`}
  margin-right: 0.4rem;
`;

export const Checklist = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.01rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.stormGray};
  margin-top: 0.8rem;
`;

export const ChecklistItem = styled.div`
  display: flex;
  letter-spacing: 0.01rem;
  align-items: center;
  margin-left: 1.6rem;
`;
