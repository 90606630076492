import React, { FC, useState, useEffect } from "react";
import { FormikProps, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Input from "components/molecules/Input";
import Button from "components/atoms/Button";
import { useUpdateSchoolMutation } from "generated/graphql";
import Label from "components/atoms/Label";
import Checkbox from "components/atoms/Checkbox";
import { useToast } from "hooks/Toast";

import { Props } from "./types";
import { CheckboxWrapper, CheckboxText } from "./styles";
import { ModalContentWrapper, ButtonsSection } from "../AddTeacherModal/styles";

const EditSchoolModal: FC<Props> = ({
  isOpen,
  onClose,
  currentSchool,
  onSuccessSchoolEdit,
}) => {
  const { t } = useTranslation();
  const [isAutoReportPublish, setAutoReportPublish] = useState<boolean>(false);
  const [isAutoRemindActive, setAutoRemindActive] = useState<boolean>(false);
  const [includeSponsors, setIncludeSponsors] = useState<boolean>(false);
  const [editSchool] = useUpdateSchoolMutation();
  const Toast = useToast();

  useEffect(() => {
    const autoRemind = currentSchool?.school?.hasRemindersOn;
    setAutoRemindActive(Boolean(autoRemind));
  }, [currentSchool]);

  const editShoolInitialValues = {
    principalName: currentSchool?.school?.principalName || "",
    email: currentSchool?.school?.email || "",
    phone: currentSchool?.school?.phone || "",
    address: currentSchool?.school?.address || "",
    city: currentSchool?.school?.city || "",
    state: currentSchool?.school?.state || "",
    zip: currentSchool?.school?.zip || "",
  };

  const toggleAutoPublishReport = () => {
    setAutoReportPublish((prevState) => !prevState);
  };

  const editSchoolForm: FormikProps<typeof editShoolInitialValues> = useFormik({
    initialValues: editShoolInitialValues,

    // TODO: Add InviteSchoolAdmin when ready on BE
    onSubmit: (values) => {
      editSchool({
        variables: {
          id: currentSchool?.school?.id || "",
          principalName: values.principalName,
          email: values.email.toLowerCase(),
          phone: values.phone,
          address: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
          hasAutoPublishedReports: isAutoReportPublish,
          hasRemindersOn: isAutoRemindActive,
        },
      })
        .then(() => {
          onSuccessSchoolEdit();
        })
        .catch((err) => {
          Toast("error", err.message || t("errorAction.generalError"));
        });
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("teacher.editSchool")}
      size="narrow"
    >
      <ModalContentWrapper>
        <form onSubmit={editSchoolForm.handleSubmit} autoComplete="off">
          <Input
            name="principalName"
            label={t("global.principal")}
            placeholder={t("global.firstNamePlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.principalName}
            error={
              editSchoolForm.touched.principalName &&
              editSchoolForm.errors.principalName
            }
          />
          <Input
            name="email"
            label={t("global.emailAddressLabel")}
            placeholder={t("global.emailAddressPlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.email}
            error={editSchoolForm.touched.email && editSchoolForm.errors.email}
          />

          <Input
            name="phone"
            label={t("global.phoneNumber")}
            placeholder={t("global.phoneNumberPlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.phone}
            error={editSchoolForm.touched.phone && editSchoolForm.errors.phone}
          />
          <Input
            name="address"
            label={t("global.address")}
            placeholder={t("global.addressPlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.address}
            error={
              editSchoolForm.touched.address && editSchoolForm.errors.address
            }
          />
          <Input
            name="city"
            label={t("global.city")}
            placeholder={t("global.cityPlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.city}
            error={editSchoolForm.touched.city && editSchoolForm.errors.city}
          />
          <Input
            name="state"
            label={t("global.state")}
            placeholder={t("global.statePlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.state}
            error={editSchoolForm.touched.state && editSchoolForm.errors.state}
          />
          <Input
            name="zip"
            label={t("global.zip")}
            placeholder={t("global.zipPlaceholder")}
            handleChange={editSchoolForm.handleChange}
            handleBlur={editSchoolForm.handleBlur}
            value={editSchoolForm.values.zip}
            error={editSchoolForm.touched.zip && editSchoolForm.errors.zip}
          />
          <CheckboxWrapper>
            <Label>{t("teacher.autoPublish")}</Label>
            <Checkbox
              isChecked={isAutoReportPublish}
              onClick={toggleAutoPublishReport}
            >
              <CheckboxText>{t("teacher.autoPublishDecide")}</CheckboxText>
            </Checkbox>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Label>{t("teacher.autoRemind")}</Label>
            <Checkbox
              isChecked={isAutoRemindActive}
              onClick={() => setAutoRemindActive((prevState) => !prevState)}
            >
              <CheckboxText>{t("teacher.autoRemindDecide")}</CheckboxText>
            </Checkbox>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Label>{t("teacher.includeSponsors")}</Label>
            <Checkbox
              isChecked={includeSponsors}
              onClick={() => setIncludeSponsors((prevState) => !prevState)}
            >
              <CheckboxText>{t("teacher.includeSponsorsDecide")}</CheckboxText>
            </Checkbox>
          </CheckboxWrapper>
          <ButtonsSection>
            <Button
              variant="primary"
              type="submit"
              style={{ width: "auto" }}
              disabled={false}
            >
              {t("actions.save")}
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={onClose}
              style={{ width: "auto" }}
            >
              {t("actions.cancel")}
            </Button>
          </ButtonsSection>
        </form>
      </ModalContentWrapper>
    </Modal>
  );
};

export default EditSchoolModal;
