import styled from "styled-components";

import { ReactComponent as Confetti } from "assets/images/confetti.svg";
import Button from "components/atoms/Button";
import media from "helpers/media";

export const ModalWrapper = styled.div`
  ${media.SM`
    display:none;
  `}
`;

export const Wrapper = styled.div`
  padding-right: 12rem;

  ${media.SM`
    padding: 0;
    margin-top: 3rem;
  `}
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.mineShaft};

  ${media.SM`
    font-size: 1.5rem;
  `}
`;

export const AdditionalInfo = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 4rem;
`;

export const StyledButton = styled(Button)`
  width: min-content;
  margin-bottom: 1.6rem;
`;

export const SmallCircle = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.defaultColor};

  ${media.XXS`
    width: 5rem;
    height: 5rem; 
    right: -30px !important;
    top: -40px !important;
  `}
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
`;

export const ConfettiIcon = styled(Confetti)`
  ${media.SM`
    width: 3.6rem;
    height: 3.6rem;
  `}
`;

export const IconWrapper = styled.div`
  ${media.SM`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  `}
`;

export const ButtonSection = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;

  ${media.SM`
    display: flex;
    width: 70%;
    margin: 2rem auto 0 auto;
    flex-direction: column;
  `};
`;

export const FinishButton = styled(Button)`
  width: 100%;
`;

export const RightIconWrapper = styled.div`
  width: 100%;
  position: relative;
`;
