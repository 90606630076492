import media from "helpers/media";
import styled from "styled-components";

export const StyledMain = styled.main`
  padding: 4rem;
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.whiteLilac};

  ${media.SM`
    padding: 0;
    flex-direction: column;
  `};
`;
