import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 0 0.6rem 1.6rem;
  border-radius: 23px;

  border: ${({ theme }) => `1px solid ${theme.colors.mystic}`};
`;

export const CloseButton = styled.button`
  padding: 0 1.6rem;
`;
