import styled from "styled-components";

import media from "helpers/media";
import Button from "../Button";

export const Container = styled.div`
  display: none;
  height: 5.5rem;
  width: 100%;
  margin-bottom: 1.6rem;
  background: ${({ theme }) => theme.colors.white};

  ${media.SM`
    display: flex;
    align-items: center;
  `};
`;

export const MobileHeaderButton = styled(Button)`
  width: fit-content;
  min-width: fit-content;
`;
