/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from "react";
import { FormikProps, useFormik } from "formik";
import { useReactiveVar } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { useToast } from "hooks/Toast";
import { required } from "helpers/formValidation";
import Title from "components/atoms/Title";
import StudentList from "components/molecules/StudentList";
import Colors from "constants/colors";

import useDebounce from "hooks/Debounce";

import useClickOutside from "hooks/clickOutSide";

import { currentSchoolIdVar } from "store/school";
import { authenticatedUserVar } from "store/authenticatedUser";

import {
  useGetAllEmailTemplatesQuery,
  useGetAllStudentsQuery,
  useGetAllStudentsSortedQuery,
  useSendEmailMutation,
  useGetAllStudentsMetaQuery,
  StudentStatusType,
  useRevokeInvitationMutation,
  useArchiveStudentsMutation,
  useGetAllStudentsMetaStatusQuery,
  useGetSchoolQuery,
} from "generated/graphql";
import { DropdownElement } from "types";
import { SelectableStudent } from "types/student";

import RawScoresSection from "components/molecules/RawScoresSection";
import SchoolDropdown from "components/molecules/SchoolDropdown";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCreditsModal from "components/molecules/CreditsDropdown/Modal";
import { SearchInput } from "components/molecules/StudentList/styles";
import { useHistory, useParams } from "react-router-dom";
import paths from "routes/paths";
import { TemplatesType } from "pages/Teacher/Students/types";
import StudentMessageModal from "./Modals/StudentMessageModal";
import InviteStudentModal from "./Modals/InviteStudentModal";
import AddStudentsModal from "./Modals/AddStudentsModal";
import StudentActionDrawer from "./Components/StudentActionDrawer";

import {
  TopLabelWrapper,
  Container,
  TableSection,
  StudentsListWrapper,
  StyledCard,
  ActionDrawerWrapper,
  TabHeaderWrapper,
  Circle,
  LegendWrapper,
  LegendItem,
  AddMore,
  TitledPanelWrapper,
  SchoolWrapper,
} from "./styles";

import StudentsListActions from "./Components/StudentsList";
import ResetTestsModal from "./Modals/ResetTestsModal";
import { SchoolIcon } from "./Components/Sidepanel/styles";
import TitledPanel from "./Components/TitledPanel";
import ProgressBar from "./Components/ProgressBar";

const Students: FC = () => {
  const { t } = useTranslation();
  const Toast = useToast();
  const history = useHistory();

  const { folderIds } = useParams<{ folderIds: string }>();
  const LSFolderIds = localStorage.getItem("EFC_folderFilter");

  if (LSFolderIds !== null && LSFolderIds !== "" && folderIds !== LSFolderIds) {
    history.replace(`/students/${LSFolderIds}`);
  }

  const stripeLoader = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY || "");

  const { data: emailTemplates } = useGetAllEmailTemplatesQuery();

  const [studentSearchTerm, setStudentSearchTerm] = useState("");
  const [isCreditModalOpen, setCreditModalOpen] = useState(false);

  const {
    visible: schoolDropdownVisible,
    setVisible: setSchoolDropdownVisible,
    ref: schoolDropdownRef,
  } = useClickOutside();

  const toggleSchoolDropdownVisible = () =>
    setSchoolDropdownVisible(!schoolDropdownVisible);

  const debouncedSearchTerm = useDebounce(studentSearchTerm);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [lastStudentsPage, setLastStudentsPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [revokeInvitation] = useRevokeInvitationMutation();

  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const [selectedStatusId, setSelectedStatusId] = useState<
    StudentStatusType | undefined
  >(undefined);
  const [isSelectedNotSent, setIsSelectedNotSent] = useState<
    boolean | undefined
  >(false);

  const [archiveStudents] = useArchiveStudentsMutation();

  const {
    data: notStartedStudents,
    refetch: notStartedRefetch,
  } = useGetAllStudentsQuery({
    variables: {
      searchTerm: "",
      schoolId: currentSchoolId,
      status: StudentStatusType.NotStarted,
      isArchived: false,
    },
  });

  const { data: inProgressStudents } = useGetAllStudentsQuery({
    variables: {
      searchTerm: "",
      schoolId: currentSchoolId,
      status: StudentStatusType.InProgress,
      isArchived: false,
    },
  });

  const {
    data: studentStatusesCount,
    refetch: studentsStatusRefetch,
  } = useGetAllStudentsMetaStatusQuery({
    variables: {
      whereCompleted: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { isArchived: { equals: false } },
          {
            OR: [
              {
                folder: {
                  id: {
                    in: folderIds?.split(","),
                  },
                },
              },
              {
                folder:
                  folderIds?.includes("-1") || !folderIds
                    ? null
                    : { id: { equals: "-1" } },
              },
            ],
          },
          { status: { equals: "completed" } },
        ],
      },
      whereInProgress: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { isArchived: { equals: false } },
          {
            OR: [
              {
                folder: {
                  id: {
                    in: folderIds?.split(","),
                  },
                },
              },
              {
                folder:
                  folderIds?.includes("-1") || !folderIds
                    ? null
                    : { id: { equals: "-1" } },
              },
            ],
          },
          { status: { equals: "inProgress" } },
        ],
      },
      whereNotStarted: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { isArchived: { equals: false } },
          {
            OR: [
              {
                folder: {
                  id: {
                    in: folderIds?.split(","),
                  },
                },
              },
              {
                folder:
                  folderIds?.includes("-1") || !folderIds
                    ? null
                    : { id: { equals: "-1" } },
              },
            ],
          },
          { status: { equals: "notStarted" } },
        ],
      },
      schoolId: currentSchoolId,
    },
  });

  const headerButtonsData = [
    {
      id: StudentStatusType.Completed,
      title: `${t("teacher.completed")} (${
        studentStatusesCount?.completed || 0
      })`,
      circleColor: Colors.oceanGreen,
    },
    {
      id: StudentStatusType.InProgress,
      title: `${t("teacher.inProgress")} (${
        studentStatusesCount?.inProgress || 0
      })`,
      circleColor: Colors.tulipTree,
    },
    {
      id: StudentStatusType.NotStarted,
      title: `${t("teacher.notStarted")} (${
        studentStatusesCount?.notStarted || 0
      })`,
      circleColor: Colors.mandy,
    },
  ];

  const { data: allStudents } = useGetAllStudentsQuery({
    variables: {
      searchTerm: "",
      schoolId: currentSchoolId,
      isArchived: false,
    },
  });

  const { data: currentSchool, refetch: refetchSchool } = useGetSchoolQuery({
    variables: {
      id: currentSchoolId,
    },
  });

  const {
    data: visibleStudentsData,
    loading: studentsLoading,
    refetch: studentsRefetch,
  } = useGetAllStudentsSortedQuery({
    variables: {
      searchTerm: debouncedSearchTerm,
      schoolId: Number(currentSchoolId),
      skip: (currentPage - 1) * studentsPerPage,
      first: studentsPerPage,
      status: selectedStatusId,
      isArchived: false,
      isNotSentReport: isSelectedNotSent,
      folderIds: folderIds?.split(",").map((folder) => Number(folder)) || null,
      isUnassignedToFolder: folderIds ? folderIds?.includes("-1") : true,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: studentsMeta,
    refetch: studentsMetaRefetch,
  } = useGetAllStudentsMetaQuery({
    variables: {
      where: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { isArchived: { equals: false } },
          {
            OR: [
              {
                folder: {
                  id: {
                    in: folderIds?.split(","),
                  },
                },
              },
              {
                folder:
                  folderIds?.includes("-1") || !folderIds
                    ? null
                    : { id: { equals: "-1" } },
              },
            ],
          },
        ],
      },
    },
  });

  const [sendEmail] = useSendEmailMutation();

  const [selectedTemplateId, setSelectedTemplateId] = useState<TemplatesType>(
    "custom"
  );

  const [studentActionModalOpen, setStudentActionModalOpen] = useState(false);
  const [studentTestsResetModalOpen, setStudentTestsResetModalOpen] = useState(
    false
  );
  const [studentAddModalOpen, setStudentAddModalOpen] = useState(false);
  const [addCSVModalOpen, setAddCSVModalOpen] = useState(false);
  const [studentsAttached, setStudentsAttached] = useState(false);
  const [searchStudent, setSearchStudent] = useState("");
  const [selectedStudents, setSelectedStudents] = useState<SelectableStudent[]>(
    []
  );
  const [studentId, setStudentId] = useState("");

  const [pageOptionsDropdownVisible, setPageOptionsDropdownVisible] = useState(
    false
  );

  const allStudentsNumber = studentsMeta?.studentsCount || 0;

  const getFilteredStudentsCount = () => {
    if (studentStatusesCount && selectedStatusId) {
      return studentStatusesCount[selectedStatusId] || 0;
    }
    if (isSelectedNotSent) {
      return studentStatusesCount?.reportNotSent?.count || 0;
    }

    return allStudentsNumber || 0;
  };

  const filteredStudentsCount = getFilteredStudentsCount();

  useEffect(() => {
    studentsStatusRefetch();
    studentsMetaRefetch();
    const lastPage = Math.ceil(filteredStudentsCount / studentsPerPage);
    setLastStudentsPage(lastPage);
  }, [
    filteredStudentsCount,
    studentsPerPage,
    studentsStatusRefetch,
    studentsMetaRefetch,
  ]);

  useEffect(() => {
    studentsStatusRefetch();
    studentsMetaRefetch();
  }, [
    studentsMeta,
    studentStatusesCount,
    visibleStudentsData,
    studentsMetaRefetch,
    studentsStatusRefetch,
  ]);

  useEffect(() => {
    refetchSchool();
  }, [refetchSchool, currentSchool, allStudentsNumber]);

  const handleStudentPageChange = (pageItemsNum: number) => {
    setStudentsPerPage(pageItemsNum);
    setCurrentPage(1);
  };

  const emailForm: FormikProps<{ title: string; content: string }> = useFormik({
    initialValues: { title: "", content: "" },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: required,
      content: required,
    }),

    onSubmit: ({ title, content }, { resetForm }) => {
      const userIds = selectedStudents.map((student) =>
        Number(student.user.id)
      );

      if (userIds.length > 0) {
        sendEmail({
          variables: {
            emailTitle: title,
            emailContent: `<p>${content.replaceAll("\n", "<br />")}</p>`,
            userIds,
            schoolId: Number(currentSchoolId),
            addReport: studentsAttached,
          },
        })
          .then(() => {
            Toast("success", t("successAction.emailSent"));
          })
          .catch((e) => {
            Toast("error", e.message || t("errorAction.generalError"));
          })
          .finally(() => {
            resetForm();
            setStudentActionModalOpen(false);
          });
      }
    },
  });

  const selectTemplateText = () => {
    if (emailTemplates && emailTemplates.emailTemplates) {
      const selectedTemplateText = emailTemplates.emailTemplates.find(
        (el) => el && el.type === selectedTemplateId
      );
      emailForm.setValues({
        title: selectedTemplateText?.title || "",
        content: selectedTemplateText?.content || "",
      });
    }
  };

  useEffect(() => {
    studentsRefetch();
  }, [currentPage, studentsRefetch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [folderIds]);

  // INFO: Select email content based on selected template
  useEffect(() => {
    selectTemplateText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId, emailTemplates]);

  const authenticatedUser = useReactiveVar(authenticatedUserVar);

  const isAdmin =
    authenticatedUser?.authenticatedItem?.adminOfSchools?.find(
      (school) => school.id === currentSchoolId
    ) || false;

  const onTemplateButtonPress = (id: TemplatesType) => {
    setSelectedTemplateId(id);
  };

  const openPageOptionsDropdown = () => setPageOptionsDropdownVisible(true);

  const closePageOptionsDropdown = () => {
    setPageOptionsDropdownVisible(false);
  };

  const {
    visible: addStudentDropdownVisible,
    setVisible: setAddStudentDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const redirectToAddStudent = () => history.push(paths.studentsAdd);
  const closeStudentOptionsDropdown = () => {
    setAddStudentDropdownVisible(false);
  };

  const closeAllModals = () => {
    setStudentActionModalOpen(false);
    setStudentAddModalOpen(false);
    setSelectedTemplateId("custom");
    selectTemplateText();
    closeStudentOptionsDropdown();
    setAddCSVModalOpen(false);
    studentsRefetch();
    studentsMetaRefetch();
    setStudentsAttached(false);
    setSelectedStudents([]);
    setStudentTestsResetModalOpen(false);
  };

  const closeDropdowns = () => {
    closeStudentOptionsDropdown();
    closePageOptionsDropdown();
  };

  const toggleAttachStudents = () => {
    setStudentsAttached((prevState) => !prevState);
  };

  const handleStudentSearch = (text: string) => {
    setSearchStudent(text);
  };

  const handleDeselectStudents = () => {
    setSelectedStudents([]);
  };

  const selectAllStudents = (students: SelectableStudent[]) => {
    if (selectedStudents.length === students.length) {
      handleDeselectStudents();
    } else {
      setSelectedStudents(students);
    }
  };

  const openInviteModal = () => {
    setStudentAddModalOpen(true);
    setStudentId("");
  };

  const openEditStudentModal = (sId: string) => {
    setStudentId(sId);
    setStudentAddModalOpen(true);
  };

  const handleToggleStudent = (student: SelectableStudent) => {
    const alreadyAdded = selectedStudents.some(
      (el) => el.user.id === student.user.id
    );

    if (!alreadyAdded) {
      setSelectedStudents((prevState) => [...prevState, student]);
    } else {
      setSelectedStudents((prevState) =>
        prevState.filter((el) => el.user.id !== student.user.id)
      );
    }
  };

  const handleSelectStudents = (
    variant: "all" | StudentStatusType.NotStarted | StudentStatusType.InProgress
  ) => {
    selectAllStudents([]);

    if (variant === "all" && allStudents && allStudents.students) {
      const users = allStudents.students.map(
        (student) => student
      ) as SelectableStudent[];
      if (users) setSelectedStudents(users);
    }

    if (
      variant === StudentStatusType.NotStarted &&
      notStartedStudents &&
      notStartedStudents.students
    ) {
      const users = notStartedStudents.students.map(
        (student) => student
      ) as SelectableStudent[];
      if (users) setSelectedStudents(users);
    }

    if (
      variant === StudentStatusType.InProgress &&
      inProgressStudents &&
      inProgressStudents.students
    ) {
      const users = inProgressStudents.students.map(
        (student) => student
      ) as SelectableStudent[];
      if (users) setSelectedStudents(users);
    }
  };

  const findStudentByName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setStudentSearchTerm(e.currentTarget.value);
  };

  const addStudentOptions: DropdownElement[] = [
    {
      link: {
        to: "assets/template.csv",
        download: true,
        target: "_blank",
        children: t("teacher.downloadTemplate"),
      },
    },
    {
      name: t("teacher.addMany"),
      onClick: () => setAddCSVModalOpen(true),
    },
    {
      name: t("teacher.addManually"),
      onClick: () => openInviteModal(),
    },
  ];

  const handlePagePress = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onSelectStatusFilter = (id: StudentStatusType) => {
    if (id === selectedStatusId) {
      setSelectedStatusId(undefined);
    } else {
      setSelectedStatusId(id);
    }
    setIsSelectedNotSent(undefined);
    setCurrentPage(1);
  };

  const handleSelectNotSent = () => {
    if (isSelectedNotSent) {
      setIsSelectedNotSent(undefined);
    } else {
      setIsSelectedNotSent(true);
    }
    setSelectedStatusId(undefined);
    setCurrentPage(1);
  };

  const setCurrentSchoolId = (id: string) => {
    currentSchoolIdVar(id);
    localStorage.setItem("EFC_currentSchool", id);
  };

  const handleMessageStudent = (student: SelectableStudent) => {
    setSelectedStudents([student]);
    setStudentActionModalOpen(true);
  };

  const handleRemoveStudent = (id: string) => {
    revokeInvitation({
      variables: { userIds: [Number(id)], schoolId: Number(currentSchoolId) },
    })
      .then(() => {
        Toast("success", t("successAction.studentRemoved"));
      })
      .catch((error) => {
        Toast("error", error.message || t("errorAction.generalError"));
      })
      .finally(() => {
        if (
          currentPage !== 1 &&
          visibleStudentsData?.allStudentsSorted?.length === 1
        ) {
          setCurrentPage((page) => page - 1);
        }
        studentsRefetch();
        studentsMetaRefetch();
        notStartedRefetch();
      });
  };

  const handleArchiveStudents = (studentIds: string[]) => {
    archiveStudents({
      variables: {
        studentIds: studentIds.map((item) => Number(item)),
        schoolId: Number(currentSchoolId),
      },
    })
      .then(() => {
        Toast("success", t("successAction.studentsArchived"));
      })
      .catch((error) => {
        Toast("error", error.message || t("errorAction.generalError"));
      })
      .finally(() => {
        if (
          currentPage !== 1 &&
          visibleStudentsData?.allStudentsSorted?.length === 1
        ) {
          setCurrentPage((page) => page - 1);
        }
        studentsRefetch();
        studentsMetaRefetch();
        setSelectedStudents([]);
      });
  };

  return (
    <>
      {visibleStudentsData && (
        <StudentMessageModal
          isOpen={studentActionModalOpen}
          onClose={closeAllModals}
          title={t("teacher.sendMessage")}
          selectedTemplateId={selectedTemplateId}
          onTemplateClick={onTemplateButtonPress}
          studentsAttached={studentsAttached}
          setStudentsAttached={toggleAttachStudents}
          searchStudentValue={searchStudent}
          setSearchStudent={handleStudentSearch}
          selectedStudents={selectedStudents}
          handleToggleStudent={handleToggleStudent}
          data={visibleStudentsData.allStudentsSorted}
          form={emailForm}
        />
      )}
      {studentAddModalOpen && (
        <InviteStudentModal
          isOpen={studentAddModalOpen}
          title={studentId ? t("teacher.editStudent") : t("teacher.addStudent")}
          onClose={closeAllModals}
          studentId={studentId}
        />
      )}
      {addCSVModalOpen && (
        <AddStudentsModal
          isOpen={addCSVModalOpen}
          title={t("teacher.addManyStudents")}
          onClose={closeAllModals}
        />
      )}
      {studentTestsResetModalOpen && (
        <ResetTestsModal
          isOpen={studentTestsResetModalOpen}
          title={t("teacher.resetTests")}
          onClose={closeAllModals}
          students={selectedStudents}
        />
      )}
      {isCreditModalOpen && (
        <Elements stripe={stripeLoader}>
          <AddCreditsModal
            isOpen={isCreditModalOpen}
            onClose={() => setCreditModalOpen(false)}
          />
        </Elements>
      )}
      <Container onClick={closeDropdowns} ref={containerRef}>
        <TableSection>
          <TopLabelWrapper>
            <Title>
              {t("teacher.welcomeBack")}
              {authenticatedUser?.authenticatedItem?.firstName}{" "}
              {authenticatedUser?.authenticatedItem?.lastName}
            </Title>
            <SearchInput
              placeholder={t("teacher.searchForStudents")}
              onChange={findStudentByName}
              value={studentSearchTerm}
            />
          </TopLabelWrapper>
          <TitledPanelWrapper>
            <TitledPanel title={t("global.school")}>
              <SchoolWrapper
                onClick={toggleSchoolDropdownVisible}
                ref={schoolDropdownRef}
              >
                <SchoolIcon />
                <SchoolDropdown
                  visible={schoolDropdownVisible}
                  onSelect={setCurrentSchoolId}
                  offsetTop="5.5rem"
                  context="teacher"
                />
              </SchoolWrapper>
            </TitledPanel>
            <TitledPanel title={t("teacher.creditUsage")}>
              <TabHeaderWrapper>
                <div>
                  {currentSchool?.school?.credit?.balance || 0}{" "}
                  {t("teacher.creditAvailable")}
                </div>
                {isAdmin && (
                  <AddMore onClick={() => setCreditModalOpen(true)}>
                    {t("teacher.creditAddMore")}
                  </AddMore>
                )}
              </TabHeaderWrapper>
              <ProgressBar
                allStudentsNumber={allStudentsNumber}
                creditsLeft={currentSchool?.school?.credit?.balance || 0}
              />
              <LegendWrapper>
                <LegendItem>
                  <Circle color={Colors.denim} />
                  {t("teacher.creditRemaning")}
                </LegendItem>
                <LegendItem>
                  <Circle color={Colors.linkWaterDim} />
                  {t("teacher.creditUsed")}
                </LegendItem>
              </LegendWrapper>
            </TitledPanel>
          </TitledPanelWrapper>

          <StyledCard>
            <StudentsListWrapper>
              <StudentList
                handleToggleStudent={handleToggleStudent}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                studentsData={visibleStudentsData}
                studentsLoading={studentsLoading}
                editModal={openEditStudentModal}
                headerButtonsData={headerButtonsData}
                selectedStatusId={selectedStatusId}
                onSelectStatusFilter={onSelectStatusFilter}
                handleMessageStudent={handleMessageStudent}
                handleRemoveStudent={handleRemoveStudent}
                isSelectedNotSent={isSelectedNotSent}
                allStudentsAmount={allStudents?.students?.length || 0}
                setIsSelectedNotSent={handleSelectNotSent}
                onDrawerClose={handleDeselectStudents}
                studentsRefetch={studentsRefetch}
                handleArchiveStudents={handleArchiveStudents}
                openMessageModal={() => setStudentActionModalOpen(true)}
              />
            </StudentsListWrapper>
            <StudentsListActions
              redirectToAddStudent={redirectToAddStudent}
              isAddStudentButtonEnabled={
                (currentSchool?.school?.credit?.balance || 0) > 0
              }
              addStudentOptions={addStudentOptions}
              addStudentDropdownVisible={addStudentDropdownVisible}
              currentPage={currentPage}
              handlePagePress={handlePagePress}
              lastPage={lastStudentsPage}
              openPageOptionsDropdown={openPageOptionsDropdown}
              studentsPerPage={studentsPerPage}
              handleStudentPageChange={handleStudentPageChange}
              pageOptionsDropdownVisible={pageOptionsDropdownVisible}
              studentsNumber={filteredStudentsCount}
            />
          </StyledCard>
        </TableSection>
      </Container>
    </>
  );
};

export default Students;
