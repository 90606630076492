import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchInput } from "components/molecules/StudentList/styles";
import { currentSchoolIdVar } from "store/school";
import { useReactiveVar } from "@apollo/client";
import {
  Folder,
  OrderDirection,
  useGetAllFoldersCountQuery,
  useGetAllFoldersPaginatedQuery,
} from "generated/graphql";
import Button from "components/atoms/Button";
import moment from "moment";
import { ReactComponent as Pencil } from "assets/images/pencil.svg";
import { ReactComponent as Eye } from "assets/images/password-eye-blue.svg";
import { ReactComponent as SortIcon } from "assets/images/sorting.svg";
import { ReactComponent as ChevronDown } from "assets/images/chevron-down-new.svg";
import { ReactComponent as CheckCircle } from "assets/images/check-circle-blue.svg";
import PageNumbers from "components/atoms/PageNumbers";
import DropdownMenu from "components/molecules/DropdownMenu";
import useDebounce from "hooks/Debounce";
import Loader from "components/atoms/Loader";
import AddFolderModal from "./Modals/AddFolderModal";
import {
  Wrapper,
  Header,
  StyledTable,
  StyledTr,
  StyledTd,
  StyledTh,
  StyledTBody,
  ActionsCell,
  EditAction,
  DeleteAction,
  StudentsCount,
  SortWrapper,
  SortDropdown,
  SortDropdownItem,
  SortLabel,
  HeaderWrapper,
  StyledFooter,
} from "./styles";
import EditFolderModal from "./Modals/EditFolderModal";
import DeleteFolderModal from "./Modals/DeleteFolderModal";
import {
  PageNumberWrapper,
  PageOptionsDropdown,
  StyledButton,
} from "../Students/Components/StudentsList/styles";

const Folders: FC = () => {
  const [isAddFolderModalOpen, setAddFolderModalOpen] = useState(false);
  const [isEditFolderModalOpen, setEditFolderModalOpen] = useState(false);
  const [isDeleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { t } = useTranslation();
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);

  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [filterFoldersValue, setFilterFoldersValue] = useState("");

  const [sorting, setSorting] = useState<any>({ name: OrderDirection.Asc });
  const [sortingName, setSortingName] = useState<"az" | "za" | "nos" | "cl">(
    "az"
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageOptionsDropdownVisible, setPageOptionsDropdownVisible] = useState(
    false
  );
  const [foldersPerPage, setFoldersPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const debouncedFilterFoldersValue = useDebounce(filterFoldersValue);

  const {
    data: allFolders,
    refetch: refetchFolders,
    loading: foldersLoading,
  } = useGetAllFoldersPaginatedQuery({
    variables: {
      where: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { name: { contains: debouncedFilterFoldersValue } },
        ],
      },
      orderBy: [sorting],
      take: foldersPerPage,
      skip: (currentPage - 1) * foldersPerPage,
    },
  });

  const {
    data: allFoldersCount,
    refetch: refetchFoldersCount,
  } = useGetAllFoldersCountQuery({
    variables: {
      where: {
        AND: [
          { school: { id: { equals: currentSchoolId } } },
          { name: { contains: debouncedFilterFoldersValue } },
        ],
      },
    },
  });

  const updateSorting = (sort: "az" | "za" | "nos" | "cl") => {
    let newSort;
    switch (sort) {
      case "za":
        newSort = { name: OrderDirection.Desc };
        break;
      case "nos":
        newSort = { numberOfStudents: OrderDirection.Desc };
        break;
      case "cl":
        newSort = { createdAt_utc: OrderDirection.Desc };
        break;
      case "az":
      default:
        newSort = { name: OrderDirection.Asc };
    }

    setDropdownVisible(false);
    setSortingName(sort);
    setSorting(newSort);
  };

  const generateSortName = () => {
    switch (sortingName) {
      case "za":
        return "Alphabetically (Z-A)";
      case "nos":
        return "Number of students";
      case "cl":
        return "Created last";
      case "az":
      default:
        return "Alphabetically (A-Z)";
    }
  };

  const toggleDropdownVisible = () => setDropdownVisible(!dropdownVisible);

  const onSearchFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterFoldersValue(e.target.value);
  };

  const openEditModal = (folder: Folder) => {
    setSelectedFolder(folder);
    setEditFolderModalOpen(true);
  };

  const openDeleteModal = (folder: Folder) => {
    setSelectedFolder(folder);
    setDeleteFolderModalOpen(true);
  };

  const closeAddFolderModal = () => {
    refetchFolders();
    setAddFolderModalOpen(false);
    setEditFolderModalOpen(false);
    setDeleteFolderModalOpen(false);
    refetchFolders();
    refetchFoldersCount();
  };

  const handlePagePress = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const openPageOptionsDropdown = () => setPageOptionsDropdownVisible(true);

  const closePageOptionsDropdown = () => {
    setPageOptionsDropdownVisible(false);
  };

  const handleFolderPageChange = (pageItemsNum: number) => {
    setFoldersPerPage(pageItemsNum);
    setCurrentPage(1);
    closePageOptionsDropdown();
  };

  const FOLDERS_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50];

  useEffect(() => {
    setLastPage(
      Math.ceil((allFoldersCount?.foldersCount || 0) / foldersPerPage)
    );
  }, [allFoldersCount, foldersPerPage]);

  return (
    <>
      <AddFolderModal
        isOpen={isAddFolderModalOpen}
        title={t("global.createNewFolder")}
        onClose={closeAddFolderModal}
      />
      <EditFolderModal
        isOpen={isEditFolderModalOpen}
        title={t("global.editFolder")}
        onClose={closeAddFolderModal}
        selectedFolder={selectedFolder}
      />

      <DeleteFolderModal
        isOpen={isDeleteFolderModalOpen}
        title={t("global.deleteFolder")}
        onClose={closeAddFolderModal}
        selectedFolder={selectedFolder}
      />
      <Wrapper>
        <Header>{t("global.folders")}</Header>
        <HeaderWrapper>
          <SearchInput
            placeholder="Search for folders"
            onChange={onSearchFolderChange}
            //  value={searchStudentValue}
          />
          <SortWrapper>
            <SortLabel onClick={toggleDropdownVisible}>
              <SortIcon />
              <div style={{ flex: 1 }}>Sort: {generateSortName()}</div>
              <ChevronDown
                style={{
                  transform: dropdownVisible ? "rotate(180deg)" : "",
                  transition: "all 0.3s ease-out",
                }}
              />
            </SortLabel>
            {dropdownVisible && (
              <SortDropdown>
                <SortDropdownItem onClick={() => updateSorting("az")}>
                  <div style={{ flex: 1 }}>Alphabetically (A-Z)</div>
                  {sortingName === "az" && (
                    <CheckCircle style={{ width: "16px" }} />
                  )}
                </SortDropdownItem>
                <SortDropdownItem onClick={() => updateSorting("za")}>
                  <div style={{ flex: 1 }}>Alphabetically (Z-A)</div>
                  {sortingName === "za" && (
                    <CheckCircle style={{ width: "16px" }} />
                  )}
                </SortDropdownItem>
                <SortDropdownItem onClick={() => updateSorting("nos")}>
                  <div style={{ flex: 1 }}>Number of students</div>
                  {sortingName === "nos" && (
                    <CheckCircle style={{ width: "16px" }} />
                  )}
                </SortDropdownItem>
                <SortDropdownItem onClick={() => updateSorting("cl")}>
                  <div style={{ flex: 1 }}>Created last</div>
                  {sortingName === "cl" && (
                    <CheckCircle style={{ width: "16px" }} />
                  )}
                </SortDropdownItem>
              </SortDropdown>
            )}
          </SortWrapper>
        </HeaderWrapper>
        <StyledTable>
          <thead>
            <tr>
              <StyledTh>Folder Name</StyledTh>
              <StyledTh>Students</StyledTh>
              <StyledTh>Date of creation</StyledTh>
              <StyledTh>Actions</StyledTh>
            </tr>
          </thead>
          {foldersLoading && (
            <tr>
              <td colSpan={5}>
                <Loader />
              </td>
            </tr>
          )}
          <StyledTBody>
            {allFolders &&
              allFolders?.folders?.map((folder) => (
                <StyledTr key={folder.id}>
                  <StyledTd>
                    {folder.countryModel?.flag} {folder.name}
                  </StyledTd>
                  <StyledTd>
                    <StudentsCount
                      clickable={
                        folder?.studentsCount ? folder.studentsCount > 0 : false
                      }
                      href={`/students/${folder.id}`}
                    >
                      <div>{folder.studentsCount}</div>
                      {folder?.studentsCount ? <Eye stroke="red" /> : null}
                    </StudentsCount>
                  </StyledTd>
                  <StyledTd>
                    {moment(folder.createdAt_utc).format("DD.MM.YYYY")}{" "}
                  </StyledTd>
                  <StyledTd>
                    <ActionsCell>
                      <EditAction onClick={() => openEditModal(folder)}>
                        <Pencil />
                        Edit
                      </EditAction>
                      <DeleteAction onClick={() => openDeleteModal(folder)}>
                        Delete
                      </DeleteAction>
                    </ActionsCell>
                  </StyledTd>
                </StyledTr>
              ))}
          </StyledTBody>
        </StyledTable>
        <StyledFooter>
          <Button
            onClick={() => setAddFolderModalOpen(true)}
            type="button"
            variant="primary"
            width="20%"
          >
            Create New Folder
          </Button>
          {!foldersLoading && allFolders?.folders?.length !== 0 && (
            <>
              <PageNumberWrapper>
                <Button
                  variant="text"
                  disabled={currentPage === 1}
                  onClick={() => handlePagePress(currentPage - 1)}
                >
                  {"<"} {t("global.prev")}
                </Button>
                <PageNumbers
                  currentPage={currentPage}
                  lastPage={lastPage}
                  handlePagePress={handlePagePress}
                />
                <Button
                  variant="text"
                  disabled={currentPage === lastPage}
                  onClick={() => handlePagePress(currentPage + 1)}
                >
                  {t("global.next")} {">"}
                </Button>
              </PageNumberWrapper>

              <PageOptionsDropdown>
                <StyledButton
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    openPageOptionsDropdown();
                  }}
                >
                  Folders per page: {foldersPerPage} <ChevronDown />
                </StyledButton>
                <DropdownMenu
                  position="right"
                  elements={FOLDERS_PER_PAGE_OPTIONS.map((pageItemsNum) => ({
                    name: String(pageItemsNum),
                    onClick: () => handleFolderPageChange(pageItemsNum),
                  }))}
                  offsetTop="5rem"
                  visible={pageOptionsDropdownVisible}
                />
              </PageOptionsDropdown>
            </>
          )}
        </StyledFooter>
      </Wrapper>
    </>
  );
};

export default Folders;
