import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import Input from "components/molecules/Input";
import Select from "components/molecules/Select";
import { SelectValueType } from "components/molecules/Select/Select";

import ConfirmationModal from "components/molecules/ConfirmationModal";

import { firstName, lastName } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import {
  UserGenderType,
  useGetAuthenticatedUserQuery,
  useRemoveAccountMutation,
  useUpdateAuthenticatedUserMutation,
} from "generated/graphql";

import {
  CustomLabel,
  Optional,
  OptionalWrapper,
} from "pages/SetupPassword/styles";
import {
  ButtonSection,
  DeleteAccount,
  DeleteAccountWrapper,
  StyledButton,
} from "./styles";

type EditUserProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  title: string;
  onUserDataChange: VoidFunction;
};

const EditUserModal: FC<EditUserProps> = ({
  isOpen,
  onClose,
  title,
  onUserDataChange,
}) => {
  const { t } = useTranslation();
  const [editUser] = useUpdateAuthenticatedUserMutation();
  const { data: userData } = useGetAuthenticatedUserQuery();

  const editUserInitialValues = {
    firstName: userData?.authenticatedItem?.firstName || "",
    lastName: userData?.authenticatedItem?.lastName || "",
    gender:
      (userData?.authenticatedItem?.gender as UserGenderType) || undefined,
    dateOfBirth: userData?.authenticatedItem?.dateOfBirth || "",
  };

  const Toast = useToast();

  const editUserForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: editUserInitialValues,
    validationSchema: Yup.object({
      firstName,
      lastName,
    }),

    onSubmit: async (values) => {
      if (userData && userData.authenticatedItem) {
        editUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            gender:
              values.gender === "male" || values.gender === "female"
                ? values.gender
                : null,
            dateOfBirth: values.dateOfBirth || null,
          },
        })
          .then(() => {
            onClose();
            Toast("success", t("successAction.userEdited"));
            onUserDataChange();
          })
          .catch((e) => {
            Toast("error", e.message || t("errorAction.generalError"));
          })
          .finally(() => {
            onClose();
          });
      }
    },
  });

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const history = useHistory();
  const [removeAccount] = useRemoveAccountMutation({
    variables: { id: Number(userData?.authenticatedItem?.id) || -1 },
  });
  const deleteAccount = () => {
    removeAccount().then(() => {
      localStorage.removeItem("EFC_token");

      history.push("/login");
    });
  };

  const openConfirmModal = () => setIsConfirmModalOpened(true);
  const closeConfirmModal = () => setIsConfirmModalOpened(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        size="narrow"
        headerMargin="0 0 4.8rem 0"
      >
        <form
          onSubmit={editUserForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="firstName"
            label={t("global.firstNameLabel")}
            placeholder={t("global.firstNamePlaceholder")}
            handleChange={editUserForm.handleChange}
            handleBlur={editUserForm.handleBlur}
            value={editUserForm.values.firstName}
            error={
              editUserForm.touched.firstName && editUserForm.errors.firstName
            }
          />
          <Input
            name="lastName"
            label={t("global.lastNameLabel")}
            placeholder={t("global.lastNamePlaceholder")}
            handleChange={editUserForm.handleChange}
            handleBlur={editUserForm.handleBlur}
            value={editUserForm.values.lastName}
            error={
              editUserForm.touched.lastName && editUserForm.errors.lastName
            }
          />

          <Input
            name="dateOfBirth"
            maxDate={new Date()}
            label={
              <CustomLabel>
                {t("user.dateOfBirth")}{" "}
                <Optional>{t("user.optional")}</Optional>
              </CustomLabel>
            }
            handleChange={editUserForm.handleChange}
            handleBlur={editUserForm.handleBlur}
            setFieldValue={editUserForm.setFieldValue}
            value={editUserForm.values.dateOfBirth || ""}
            date
          />
          <Select
            name="gender"
            label={
              <CustomLabel>
                {t("user.gender")} <Optional>{t("user.optional")}</Optional>
              </CustomLabel>
            }
            handleChange={(name: string, val: SelectValueType) =>
              editUserForm.setFieldValue(name, val?.value)
            }
            handleBlur={editUserForm.handleBlur}
            value={{
              label: editUserForm.values.gender || "",
              value: editUserForm.values.gender || "",
            }}
            options={[
              { label: t("user.male"), value: "male" },
              { label: t("user.female"), value: "female" },
              { label: t("user.other"), value: "other" },
            ]}
          />
          <OptionalWrapper>
            <Optional>{t("user.optionalDisclaimer")}</Optional>
          </OptionalWrapper>

          <ButtonSection>
            <Button
              variant="primary"
              type="button"
              onClick={() => editUserForm.submitForm()}
            >
              {t("actions.save")}
            </Button>
            <Button variant="link" type="button" onClick={onClose}>
              {t("actions.cancel")}
            </Button>
          </ButtonSection>
        </form>
        <DeleteAccountWrapper>
          <DeleteAccount onClick={openConfirmModal}>
            {t("user.deleteAccount")}
          </DeleteAccount>
        </DeleteAccountWrapper>
      </Modal>
      <ConfirmationModal
        title={t("teacher.deleteAccount")}
        isOpen={isConfirmModalOpened}
        onClose={closeConfirmModal}
        contentText={t("user.sureAboutRemoval")}
        confirmButton={
          <div>
            <StyledButton variant="danger" onClick={deleteAccount}>
              {t("user.confirmRemoval")}
            </StyledButton>
          </div>
        }
        cancelButton={
          <Button variant="link" onClick={closeConfirmModal}>
            {t("actions.cancel")}
          </Button>
        }
      />
    </>
  );
};

export default EditUserModal;
