import styled from "styled-components";

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.manatee};
  font-weight: 600;
`;

export const Card = styled.div`
  padding: 1.6rem 2.4rem 2rem 2.4rem;
  border-radius: 8px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white}; ;
`;
