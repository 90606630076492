import styled from "styled-components";

import Button from "components/atoms/Button";

export const Container = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 4.8rem;
`;

export const ConfirmMessage = styled.div<{ wide?: boolean }>`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.3px;
  width: ${({ wide }) => (wide ? "32rem" : "26rem")};
`;
