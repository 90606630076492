import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  padding: 3.2rem;
  width: 1170px;
  min-width: 25rem;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3.3rem 0;
`;

export const Header = styled.div`
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem; /* 100% */
  letter-spacing: 0.3px;
`;

export const StyledTable = styled.table`
  width: 100%;

  border-collapse: collapse;
  margin-bottom: 3.2rem;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

export const StyledTr = styled.tr`
  padding: 1rem 2.4rem;
  background-color: white;

  &:nth-child(odd) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.7) 100%
      ),
      ${({ theme }) => theme.colors.linkWaterDim};
  }
`;

export const StyledTd = styled.td`
  padding: 1rem 2.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
`;

export const StyledTh = styled.th`
  padding: 1rem 2.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  text-align: left;
`;

export const StyledTBody = styled.tbody`
  border: 1px solid red;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.mystic};
`;

export const ActionsCell = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const EditAction = styled.div`
  color: ${({ theme }) => theme.colors.denim};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const DeleteAction = styled.div`
  color: ${({ theme }) => theme.colors.mandy};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const StudentsCount = styled.a<{ clickable: boolean }>`
  width: 7rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.mystic};
  border-radius: 4px;
  ${({ clickable }) => (clickable ? "cursor: pointer" : "cursor: default")};
  ${({ clickable }) => !clickable && "pointer-events: none"};

  &:hover {
    ${({ clickable, theme }) =>
      clickable && `border-color: ${theme.colors.mineShaft};`};
    text-decoration: none;
  }
`;

export const SortLabel = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.4px;
  cursor: pointer;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 232px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.mineShaft};
  }
`;

export const SortWrapper = styled.div`
  position: relative;
`;

export const SortDropdown = styled.div`
  display: flex;
  padding-bottom: 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 232px;
  flex: 1 0 0;
  position: absolute;
  top: calc(100% + 1.2rem);
  left: 0;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 400;
  line-height: 1.6rem;
`;

export const SortDropdownItem = styled.div`
  border-top: 1px solid #e7ecee;
  width: 100%;
  padding: 1.2rem 1.6rem 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  &:hover {
    background-color: #e8f4fa;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.2rem 0;
`;
