import React from "react";
import { useHistory } from "react-router-dom";
import {
  VideoWrapper,
  VideoCard,
  Title,
  ArrowRight,
  VideoImage,
  ImageContainer,
  ContentContainer,
  Play,
  WatchAgain,
} from "./styles";
import { Props } from "./types";

const ACADEMY_PATH = "/academy/watch";

const Video = ({ video }: Props) => {
  const history = useHistory();

  const isVideoCompleted = video?.views[0]?.isCompleted;

  const handleNavigate = () => {
    history.push(`${ACADEMY_PATH}/${video.id}`);
  };

  return (
    <VideoWrapper onClick={handleNavigate}>
      <VideoCard className={isVideoCompleted ? "completed" : undefined}>
        <ImageContainer>
          <VideoImage className={isVideoCompleted ? "completed" : undefined} />
        </ImageContainer>
        <ContentContainer>
          <Title>{video?.title}</Title>
          {isVideoCompleted ? (
            <WatchAgain />
          ) : (
            <>
              <Play>
                PLAY
                <ArrowRight />
              </Play>
            </>
          )}
        </ContentContainer>
      </VideoCard>
    </VideoWrapper>
  );
};

export default Video;
