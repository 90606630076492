import styled from "styled-components";
import media from "helpers/media";
import Colors from "constants/colors";

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 1.3rem 14rem;
  color: ${Colors.shuttleGray};

  ${media.SM`
flex-direction: column;
align-items: center;
  `};
`;

export const FooterItem = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 1rem;

  &:first-child {
    flex: 1;
  }
  a {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.denim};
  }
`;

export const StyledUl = styled.ul`
  display: flex;
  gap: 3rem;
  align-items: center;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.denim};
`;
