import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import NavigationBar from "components/molecules/NavigationBar";
import Footer from "components/atoms/Footer";

import paths from "routes/paths";
import { STATIC_LINKS } from "constants/staticLinks";
import { StyledMain, StyledHeader, StyledFooter } from "./styles";

const Main: FC = ({ children }) => {
  const { t } = useTranslation();

  const teacherNav = [
    { to: paths.studentsEmptyFolder, title: t("global.students") },
    { to: paths.school, title: t("global.school") },
    { to: `${STATIC_LINKS.FAQ}`, title: t("global.faq") },
    { to: paths.folders, title: t("global.folders") },
  ];

  return (
    <>
      <StyledHeader>
        <NavigationBar navigationItems={teacherNav} />
      </StyledHeader>
      <StyledMain>{children}</StyledMain>
      <StyledFooter>
        <Footer />
      </StyledFooter>
    </>
  );
};

export default Main;
