import React from "react";
import { Route, Redirect } from "react-router-dom";

import paths from "routes/paths";

import { Props } from "./types";

const RouteWrapper: React.FC<Props> = ({ isPrivate, ...rest }) => {
  // INFO: temporarily mocked of isSigned value
  const isSigned = false;

  if (isPrivate && !isSigned) {
    return <Redirect to={paths.root} />;
  }

  if (!isPrivate && isSigned) {
    return <Redirect to="/404" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} />;
};

export default RouteWrapper;

RouteWrapper.defaultProps = {
  isPrivate: false,
};
