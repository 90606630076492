import Colors from "constants/colors";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLoader = styled.div<{ color?: Colors }>`
  display: inline-block;
  width: 80px;
  height: 80px;
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: ${({ theme, color }) => `6px solid ${color || theme.colors.denim}`};
    border-color: ${({ theme, color }) =>
      `${color || theme.colors.denim} transparent ${
        color || theme.colors.denim
      } transparent `};
    animation: ${rotate} 1.2s linear infinite;
  }
`;
