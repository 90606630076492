import React from "react";

import { ReactComponent as ExternalLink } from "assets/images/external-link.svg";

import Header from "components/atoms/Header";

import LoginTheme from "themes/LoginTheme";

import { useToast } from "hooks/Toast";
import {
  useGetAuthenticatedUserQuery,
  useGetSchoolQuery,
  useCreatePaymentSessionMutation,
} from "generated/graphql";

import { loadStripe } from "@stripe/stripe-js";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import {
  ButtonsWrapper,
  HeaderWrapper,
  PriceLine,
  AdditionalInfo,
  StyledButton,
} from "./styles";

const PaymentContent = () => {
  const stripe = useStripe();
  const Toast = useToast();
  const [createPaymentSession] = useCreatePaymentSessionMutation();

  const { data: authenticatedUser } = useGetAuthenticatedUserQuery();

  const { data: currentSchool } = useGetSchoolQuery({
    variables: {
      id: authenticatedUser?.authenticatedItem?.teachers![0].school?.id || "-1",
    },
  });

  const handlePay = () => {
    if (currentSchool && currentSchool.school && currentSchool.school.credit) {
      const creditId = Number(currentSchool.school.credit.id);

      createPaymentSession({
        variables: {
          creditId,
          quantity: 1,
        },
      })
        .then((sessionIdData) => {
          if (stripe && sessionIdData.data) {
            const sessionId = sessionIdData.data.createPaymentSession;

            if (sessionId) {
              stripe.redirectToCheckout({
                sessionId,
              });
            }
          }
        })
        .catch((error) => {
          Toast("error", error.message || "errorAction.generalError");
        });
    }
  };

  return (
    <LoginTheme selfRegistration narrow>
      <HeaderWrapper>
        <Header>Finalise your account</Header>
      </HeaderWrapper>
      <PriceLine>
        <div>Individual Assessment</div>
        <div>60 €</div>
      </PriceLine>
      <AdditionalInfo>
        To begin the assessments and get your personalised report, please
        finalise your account via Stripe using the button below.
      </AdditionalInfo>
      <ButtonsWrapper style={{ width: "20rem" }}>
        <StyledButton variant="primary" onClick={handlePay}>
          Pay with Stripe <ExternalLink />
        </StyledButton>
      </ButtonsWrapper>
    </LoginTheme>
  );
};

const Payment = () => {
  const stripeLoader = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY || "");

  return (
    <Elements stripe={stripeLoader}>
      <PaymentContent />
    </Elements>
  );
};

export default Payment;
