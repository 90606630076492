import React, { FC } from "react";
import ReactTooltip from "react-tooltip";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { useToast } from "hooks/Toast";
import Colors from "constants/colors";
import {
  StudentStatusType,
  useGetAuthenticatedUserQuery,
} from "generated/graphql";
import { downloadStudentReport } from "api/downloadReport";

import {
  DrawerSection,
  DrawerLeftText,
  StyledButton,
  StyledCloseIcon,
  StyledPaperPlaneIcon,
  SendMsgBtnContent,
  DownloadBtnWrapper,
  ActionDrawerContainer,
  UnarchiveButtonWrapper,
  UnarchiveButtonWrapperContent,
  StyledUnarchive,
  StyledArchive,
} from "./styles";
import { Props } from "./types";
import AssignToFolder from "../AssignToFolder";

const StudentActionDrawer: FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    selectedStudents,
    openMessageModal,
    onDrawerClose,
    isArchivedStudents = false,
    handleUnarchiveStudents,
    handleArchiveStudents,
    onFolderSelect,
  } = props;

  const Toast = useToast();

  const isStatusComplete = selectedStudents.some(
    (student) => student.status === StudentStatusType.Completed
  );

  const downloadStudentReports = () => {
    const reports = selectedStudents
      .filter(
        (selectedStudent) =>
          selectedStudent.status !== StudentStatusType.NotStarted &&
          selectedStudent.user.isConfirmed &&
          selectedStudent.testAttempt.reportName
      )
      .map((selectedStudent) => ({
        id: selectedStudent.id,
        reportName: selectedStudent.testAttempt.reportName,
      }));

    reports.forEach((report) => {
      downloadStudentReport(
        report.id,
        (res) => {
          fileDownload(res.data, report.reportName);
          Toast("success", t("successAction.reportDownloading"));
        },
        () => {
          Toast("error", t("errorAction.reportDoesntExist"));
        }
      );
    });
  };

  return (
    <ActionDrawerContainer>
      <DrawerSection>
        <DrawerLeftText>
          {selectedStudents.length === 1 && (
            <>
              {selectedStudents.length} {t("teacher.studentSelected")}
            </>
          )}
          {selectedStudents.length > 1 && (
            <>
              {selectedStudents.length} {t("teacher.studentsSelected")}
            </>
          )}
        </DrawerLeftText>
        <div style={{ display: "flex" }}>
          <AssignToFolder onFolderSelect={onFolderSelect} />
        </div>
      </DrawerSection>
      <DrawerSection>
        {isArchivedStudents && handleUnarchiveStudents && (
          <UnarchiveButtonWrapper>
            <StyledButton
              variant="link"
              onClick={() =>
                handleUnarchiveStudents(selectedStudents.map(({ id }) => id))
              }
            >
              <UnarchiveButtonWrapperContent>
                <StyledUnarchive />
                {t("actions.unarchive")}
              </UnarchiveButtonWrapperContent>
            </StyledButton>
          </UnarchiveButtonWrapper>
        )}
        {!isArchivedStudents && handleArchiveStudents && (
          <SendMsgBtnContent>
            <StyledButton
              variant="link"
              onClick={() =>
                handleArchiveStudents(selectedStudents.map(({ id }) => id))
              }
            >
              <UnarchiveButtonWrapperContent>
                <StyledArchive />
                {t("actions.archive")}
              </UnarchiveButtonWrapperContent>
            </StyledButton>
          </SendMsgBtnContent>
        )}

        <StyledButton variant="link" onClick={openMessageModal}>
          <SendMsgBtnContent>
            <StyledPaperPlaneIcon />
            {t("teacher.sendMessages")} ({selectedStudents.length})
          </SendMsgBtnContent>
        </StyledButton>
        <ReactTooltip
          place="top"
          type="light"
          effect="float"
          border
          borderColor={Colors.denim}
          id="downloadStudentReports"
          disable={isStatusComplete}
        >
          <span>
            {t("teacher.completedStudentsInfo")} <br />
            {t("teacher.with")} <i>{t("teacher.completed")}</i>{" "}
            {t("teacher.status").toLowerCase()}
          </span>
        </ReactTooltip>
        <DownloadBtnWrapper>
          <StyledPaperPlaneIcon />
          <span data-tip data-for="downloadStudentReports">
            <StyledButton
              variant="link"
              disabled={!isStatusComplete}
              onClick={downloadStudentReports}
            >
              {t("teacher.downloadStudentReports")}
            </StyledButton>
          </span>
        </DownloadBtnWrapper>
        <StyledButton variant="link" onClick={onDrawerClose}>
          <StyledCloseIcon />
        </StyledButton>
      </DrawerSection>
    </ActionDrawerContainer>
  );
};

export default StudentActionDrawer;
