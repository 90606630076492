import { Assessment, Student } from "types";
import i18n from "utils/i18n/i18n";

export const assessments: Assessment[] = [
  {
    name: i18n.t("student.interestInventory"),
    status: "available",
    time: "",
    type: "interest",
    apiName: "Interest",
  },
];

export const student: Student = {
  name: "Elin",
  assessments,
  assessments_finished: false,
};

export const studentFinished: Student = {
  name: "Elin",
  assessments,
  assessments_finished: true,
};
