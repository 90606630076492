import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetAuthenticatedUserQuery,
  useGetVideosWithViewsQuery,
} from "generated/graphql";
import paths from "routes/paths";
import Loader from "components/atoms/Loader";
import Video from "components/molecules/Video";
import { useToast } from "hooks/Toast";
import { VideoModel } from "./types";
import { Container, Title, VideoListContainer } from "./styles";

const AcademyDashboard = (): JSX.Element => {
  const Toast = useToast();
  const { t } = useTranslation();

  const { data: userData } = useGetAuthenticatedUserQuery();

  const {
    data: videoData,
    loading: videoLoading,
    error: videoError,
  } = useGetVideosWithViewsQuery({
    variables: { isTutorial: false },
    fetchPolicy: "cache-and-network",
  });

  const [videos, setVideos] = useState<VideoModel[]>([]);

  useEffect(() => {
    const data = videoData?.findVideosWithViews as VideoModel[];
    if (data?.length) {
      setVideos(data);
    }
  }, [videoData]);

  const redirectToDashboard = () => {
    return <Redirect to={paths.studentDashboard} />;
  };

  if (!userData?.authenticatedItem?.students![0].testAttempt?.isCompleted) {
    return redirectToDashboard();
  }

  if (videoLoading) {
    return <Loader />;
  }

  if (!videoLoading && videoError) {
    Toast("error", t("errorAction.noAccess"));
    return redirectToDashboard();
  }

  return (
    <>
      {videos?.length ? (
        <Container>
          <Title>{t("academy.dashboardHeader")}</Title>
          <VideoListContainer>
            {videos.length &&
              videos.map((video, index) => <Video key={index} video={video} />)}
          </VideoListContainer>
        </Container>
      ) : (
        <div>There are no videos</div>
      )}
    </>
  );
};

export default AcademyDashboard;
