import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import Card from "components/atoms/Card";
import SideMenu from "components/molecules/SideMenu";

import MobileHeader from "components/atoms/MobileHeader";
import {
  Container,
  CheckIconWrapper,
  StyledIcon,
  ContentRow,
  ContentText,
  SubHeader,
  Header,
  HeaderSection,
  Content,
  CardWrapper,
  SideMenuWrapper,
  CalculatorStyledIcon,
  ContentBanner,
  BannerText,
  DictionaryStyledIcon,
} from "./styles";
import { Props } from "./types";

const TestInformation: FC<Props> = ({
  contentType,
  assessmentType,
  sideMenuValues,
  subHeader,
  buttonsSection,
}) => {
  const history = useHistory();

  const renderContent = () => {
    return contentType.contentData.map((el, i) => (
      <ContentRow key={i}>
        {el.isBanner ? (
          <ContentBanner style={el.styles && { ...el.styles }}>
            <CalculatorStyledIcon type={assessmentType} />
            <DictionaryStyledIcon type={assessmentType} />
            <BannerText>{el.content}</BannerText>
          </ContentBanner>
        ) : (
          <>
            <CheckIconWrapper>
              <StyledIcon />
            </CheckIconWrapper>
            <ContentText style={el.styles && { ...el.styles }}>
              {el.content}
            </ContentText>
          </>
        )}
      </ContentRow>
    ));
  };

  return (
    <Container>
      <MobileHeader
        title={sideMenuValues[sideMenuValues.length - 1].name}
        onClick={() =>
          history.push(sideMenuValues[sideMenuValues.length - 1].path)
        }
      />
      <SideMenuWrapper>
        <SideMenu values={sideMenuValues} />
      </SideMenuWrapper>
      <CardWrapper>
        <Card>
          <HeaderSection>
            <Header>{contentType.header}</Header>
            <SubHeader>{subHeader}</SubHeader>
          </HeaderSection>
          <Content>{renderContent()}</Content>
          {buttonsSection}
        </Card>
      </CardWrapper>
    </Container>
  );
};

export default TestInformation;
