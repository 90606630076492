import styled from "styled-components";

import Colors from "constants/colors";

export const Container = styled.div`
  margin-right: 1.6rem;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: -0.5rem;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  min-height: 4rem;
  max-height: 10rem;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DataCard = styled.div`
  position: absolute;
  width: 50%;
  top: 100%;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
  border-radius: 4px;
  z-index: 9;
`;

export const DataItem = styled.button<{ isSelected: boolean }>`
  text-align: start;
  padding: 1rem 2rem;
  background: ${(props) => (props.isSelected ? Colors.nepal : Colors.white)};

  &:hover {
    background: ${({ theme }) => theme.colors.nepal};
    cursor: pointer;
  }
`;

export const CardSection = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OpenChipsButton = styled.button`
  padding-left: 2rem;
  white-space: nowrap;
`;

export const OpenChipsText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.denim};
`;

export const Dot = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  background: ${({ theme }) => theme.colors.oceanGreen};
  margin-right: 0.75rem;
  border-radius: 100%;
`;

export const TextInputWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;
