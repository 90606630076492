import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";
import MobileModal from "components/molecules/MobileModal";

import data from "./data";
import {
  SmallCircle,
  Text,
  AdditionalInfo,
  Wrapper,
  ModalWrapper,
  ConfettiIcon,
  IconWrapper,
} from "./styles";
import { Props } from "./types";

const FinishedTestModal: FC<Props> = ({ isOpen, onClose, testType }) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalWrapper>
        <Modal
          isOpen={isOpen}
          size="narrow"
          onClose={onClose}
          title={t("student.congratulations")}
          closeIcon={false}
          rightHeaderContent={
            <SmallCircle>
              <ConfettiIcon />
            </SmallCircle>
          }
        >
          <Wrapper>
            <Text>
              {t("student.justFinished")}{" "}
              {testType && <strong>{testType}</strong>}.
              <AdditionalInfo
                dangerouslySetInnerHTML={{ __html: data.assessmentInfo }}
              />
            </Text>
            <Button variant="primary" onClick={onClose}>
              {t("student.returnToDashboard")}
            </Button>
          </Wrapper>
        </Modal>
      </ModalWrapper>

      {/* Displayed only on mobile */}
      <MobileModal
        onClose={onClose}
        isOpen={isOpen}
        title={t("student.congratulations")}
        headerIcon={
          <IconWrapper>
            <SmallCircle>
              <ConfettiIcon />
            </SmallCircle>
          </IconWrapper>
        }
      >
        <Wrapper>
          <Text>
            {t("student.justFinished")}{" "}
            {testType && <strong>{testType}</strong>}.
            <AdditionalInfo
              dangerouslySetInnerHTML={{ __html: data.assessmentInfo }}
            />
          </Text>
          <Button variant="primary" onClick={onClose}>
            {t("student.returnToDashboard")}
          </Button>
        </Wrapper>
      </MobileModal>
    </>
  );
};

export default FinishedTestModal;
