import styled from "styled-components";

export const Container = styled.div`
  width: 26.6rem;
`;

export const Label = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.manatee};
`;

export const CompletedBar = styled.div<{ width: number }>`
  height: 1rem;
  width: ${({ width }) => `${width}%`};
  transition: all ease-in-out 300ms;
  ${({ width }) =>
    width < 100
      ? `border-radius: 1rem 0.2rem 0.2rem 1rem`
      : `border-radius: 1rem`};

  background: ${({ theme }) => theme.colors.defaultColor};
  position: relative;
  &::before {
    position: absolute;
    content: ${({ width }) => (width > 100 ? "" : 0)};
    width: 0.6rem;
    height: 1.1rem;
    background: ${({ theme }) => theme.colors.white};
    right: -0.25rem;
    transform: skew(-25deg, 0deg);
  }
`;

export const InProgressBar = styled.div<{ width: number }>`
  height: 1rem;
  border-radius: 0.2rem 1rem 1rem 0.2rem;
  width: ${({ width }) => `${width}%`};
  background: ${({ theme }) => theme.colors.secondaryColor};
  transition: all ease-in-out 300ms;
`;

export const ChartInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.defaultColor};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`;

export const ChartInfoText = styled.h3`
  color: ${({ theme }) => theme.colors.defaultColor};
  font-family: "Roboto Slab";
  font-size: 1.6rem;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1.6rem;
`;

export const CompletedInfoText = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
  text-align: end;
  color: ${({ theme }) => theme.colors.mineShaft};
`;
