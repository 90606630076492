import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import { useToast } from "hooks/Toast";

import { useResetInterestInventoriesMutation } from "generated/graphql";
import { CaretDown } from "components/molecules/SchoolDropdown/styles";
import Select from "components/molecules/Select";
import { SelectValueType } from "components/molecules/Select/Select";
import {
  Container,
  ButtonsSection,
  ButtonGroup,
  TextSection,
  ResultWrapper,
  ResultElement,
  ExpandedResultDetails,
  ExpandedResultDetailsItem,
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  ResultText,
} from "./styles";
import { ResetTestsProps } from "./types";
import { CustomLabel } from "../../../SetupPassword/styles";

type ResetTestsResponse = {
  successReset: { success: boolean; message: string }[];
  warnings: { isWarning: boolean; message: string }[];
  errors: { isError: boolean; message: string }[];
};

type ResultDetailsExpand = {
  success: boolean;
  warnings: boolean;
  errors: boolean;
};

const INTEREST_TEST_VALUE = "Interest";

const TEST_CATEGORIES_OPTIONS = [
  { label: "Abstract", value: "Abstract" },
  { label: "Numerical", value: "Numerical" },
  { label: "Verbal Vocabulary", value: "Verbal_Vocabulary" },
  { label: "Verbal Reasoning", value: "Verbal_Reasoning" },
  { label: "Verbal Comprehension", value: "Verbal_Comprehension" },
  { label: "Interest Inventory", value: INTEREST_TEST_VALUE },
];

const ResetTestsModal: FC<ResetTestsProps> = ({
  isOpen,
  onClose,
  title,
  students,
}) => {
  const { t } = useTranslation();
  const [resetInterestInventories] = useResetInterestInventoriesMutation();
  const [
    selectedTestCategory,
    setSelectedTestCategory,
  ] = useState<SelectValueType>(null);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const [resetResultData, setResetResultData] = useState<ResetTestsResponse>({
    successReset: [],
    warnings: [],
    errors: [],
  });
  const [expandedDetails, setExpandedDetails] = useState<ResultDetailsExpand>({
    success: false,
    warnings: false,
    errors: false,
  });

  const Toast = useToast();

  const closeModal = () => {
    onClose();
  };

  const showResultModal = (data: ResetTestsResponse) => {
    setResetResultData(data);
    setResultModalIsOpen(true);
  };

  const handleExpandResults = (type: keyof ResultDetailsExpand) => {
    setExpandedDetails((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const resetTests = async () => {
    if (selectedTestCategory) {
      if (selectedTestCategory.value === INTEREST_TEST_VALUE) {
        try {
          const { data } = await resetInterestInventories({
            variables: {
              testAttemptsIds: students.map((student) =>
                Number(student.testAttempt.id)
              ),
            },
          });
          showResultModal(
            data?.resetManyInterestInventories as ResetTestsResponse
          );
        } catch (err: any) {
          Toast("error", err?.message || t("errorAction.generalError"));
        }
      }
    } else {
      Toast("error", t("errorAction.testCategoryRequired"));
    }
  };

  return (
    <>
      <Modal
        isOpen={resultModalIsOpen}
        onClose={closeModal}
        size="medium"
        title="Reset tests result"
        zIndex={2000}
      >
        <Container>
          <ResultWrapper>
            <ResultElement>
              <ResultText>
                <SuccessIcon />
                {`Success (${resetResultData.successReset.length})`}
              </ResultText>
              <Button
                variant="text"
                onClick={() => handleExpandResults("success")}
                width="auto"
              >
                <CaretDown />
              </Button>
            </ResultElement>
            {expandedDetails.success && (
              <ExpandedResultDetails>
                {resetResultData.successReset.map((success, index) => (
                  <ExpandedResultDetailsItem key={index}>
                    {success.message}
                  </ExpandedResultDetailsItem>
                ))}
              </ExpandedResultDetails>
            )}
            <ResultElement>
              <ResultText>
                <WarningIcon />
                {`Warnings (${resetResultData.warnings.length})`}
              </ResultText>
              <Button
                variant="text"
                onClick={() => handleExpandResults("warnings")}
                width="auto"
              >
                <CaretDown />
              </Button>
            </ResultElement>
            {expandedDetails.warnings && (
              <ExpandedResultDetails>
                {resetResultData.warnings.map((warning, index) => (
                  <ExpandedResultDetailsItem key={index}>
                    {warning.message}
                  </ExpandedResultDetailsItem>
                ))}
              </ExpandedResultDetails>
            )}
            <ResultElement>
              <ResultText>
                <ErrorIcon />
                {`Errors (${resetResultData.errors.length})`}
              </ResultText>
              <Button
                variant="text"
                onClick={() => handleExpandResults("errors")}
                width="auto"
              >
                <CaretDown />
              </Button>
            </ResultElement>
            {expandedDetails.errors && (
              <ExpandedResultDetails>
                {resetResultData.errors.map((error, index) => (
                  <ExpandedResultDetailsItem key={index}>
                    {error.message}
                  </ExpandedResultDetailsItem>
                ))}
              </ExpandedResultDetails>
            )}
            <ButtonsSection fullWidth>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  width="auto"
                  variant="primary"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </Button>
              </div>
            </ButtonsSection>
          </ResultWrapper>
        </Container>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        size="medium"
        headerMargin="0 0 4.8rem 0"
      >
        <Container>
          <Select
            name="testCategory"
            label={<CustomLabel>{t("teacher.testCategory")}</CustomLabel>}
            handleChange={(name: string, value: SelectValueType) =>
              setSelectedTestCategory(value)
            }
            value={{
              label: selectedTestCategory?.label || "",
              value: selectedTestCategory?.value || "",
            }}
            options={TEST_CATEGORIES_OPTIONS}
          />
          <TextSection>
            <div style={{ color: "red" }}>
              {t("teacher.resetTestsText", {
                testCategory: selectedTestCategory?.label,
              })}
            </div>
            <ul>
              {students.map((student) => (
                <li key={student.id} style={{ listStyle: "square" }}>
                  {student.user.firstName} {student.user.lastName}
                </li>
              ))}
            </ul>
          </TextSection>
          <ButtonsSection fullWidth>
            <div>
              <Button
                variant="primary"
                type="button"
                onClick={() => resetTests()}
              >
                {t("actions.reset")}
              </Button>
            </div>
            <ButtonGroup>
              <>
                <Button
                  width="auto"
                  variant="link"
                  type="button"
                  onClick={closeModal}
                >
                  {t("actions.cancel")}
                </Button>
              </>
            </ButtonGroup>
          </ButtonsSection>
        </Container>
      </Modal>
    </>
  );
};

export default ResetTestsModal;
