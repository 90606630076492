import * as Yup from "yup";
import i18n from "utils/i18n/i18n";

export const required = Yup.string().required(i18n.t("errors.fieldRequired"));

export const name = Yup.string()
  .required(i18n.t("errors.nameRequired"))
  .min(3, i18n.t("errors.provide3chars"));

export const email = Yup.string()
  .email(i18n.t("errors.invalidEmail"))
  .required(i18n.t("errors.emailRequired"));

export const password = Yup.string()
  .required(i18n.t("errors.noPass"))
  .min(8, i18n.t("errors.passMin8Chars"))
  .matches(/\d/, i18n.t("errors.numberInPass"))
  .matches(/[A-Z]/, i18n.t("errors.upperLetterInPass"))
  .matches(/\W+/, i18n.t("errors.specialCharsInPass"));

export const passwordConfirmation = Yup.string()
  .required(i18n.t("errors.noPassConfirmation"))
  .oneOf([Yup.ref("password")], i18n.t("errors.passMustMatch"));

export const firstName = Yup.string().required(
  i18n.t("errors.firstNameRequired")
);
export const lastName = Yup.string().required(
  i18n.t("errors.lastNameRequired")
);

export const country = Yup.string().required(i18n.t("errors.countryRequired"));
