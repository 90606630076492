import styled, { css } from "styled-components";

import Magnifier from "assets/images/magnifier.svg";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableBody = styled.tbody``;

export const TableHeader = styled.th`
  font-weight: 500;
  font-size: 1.3rem;
  text-align: left;
  padding: 1.8rem 0;

  &:first-child {
    padding-left: 2.4rem;
  }
`;

export const ActionItem = styled.div<{
  clickable?: boolean;
  disabled?: boolean;
  danger?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  color: ${({ theme }) => theme.colors.denim};
  font-size: 1.2rem;

  &:nth-child(2) {
    margin-left: 2.4rem;
  }

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.mandy};
    `}
`;

export const TableCell = styled.td`
  padding: 1.8rem 0;

  &:first-child {
    padding-left: 2.4rem;
    border-left: 1px solid ${({ theme }) => theme.colors.mystic};
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.colors.mystic};
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 2.6rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.2rem;
`;

export const SearchInput = styled.input`
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.mystic};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  letter-spacing: 0.04rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  margin: 0 0.2rem;
  background-image: url(${Magnifier});
  background-repeat: no-repeat;
  background-position: 1.6rem;
  padding-left: 3.5rem;
  cursor: text;
  width: 30rem;
  border-radius: 8px;
`;

export const NoStudentsInfo = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.nepal};
  font-weight: 400;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.manatee};
  text-align: center;
  padding: 4rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
