import styled, { css } from "styled-components";

import Card from "../Card";

export const Background = styled.div<{ isOpen: boolean; zIndex?: number }>`
  position: fixed;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(16px);
  background: linear-gradient(
    115.63deg,
    rgba(255, 255, 255, 0.77) 18.89%,
    rgba(217, 222, 246, 0.566512) 65.78%,
    rgba(178, 188, 236, 0.36) 83.92%
  );
  transition: opacity 0.3s;

  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex || 1000};
`;

export const Container = styled.div<{ size: "wide" | "narrow" | "medium" }>`
  display: flex;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  ${({ size }) =>
    size === "narrow" &&
    css`
      width: 48.2rem;
    `}
  ${({ size }) =>
    size === "medium" &&
    css`
      width: 68.4rem;
    `}
  ${({ size }) =>
    size === "wide" &&
    css`
      width: 88.6rem;
    `}
  margin: auto;
  overflow: visible;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div<{ headerMargin?: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${({ headerMargin }) => headerMargin && headerMargin};
`;

export const HeaderTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  font-family: "Roboto Slab";
  letter-spacing: 0.3px;
`;

export const SubTitle = styled.h3`
  margin-top: 1.2rem;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 1.8rem;
`;

export const CloseButton = styled.button`
  align-self: flex-start;
`;

export const StyledCard = styled(Card)`
  position: relative;
  overflow: visible;
`;
