import React, { FC } from "react";

import { StyledCard } from "./styles";
import { Props } from "./types";

const Card: FC<Props> = ({ className, children, styles, active }) => (
  <StyledCard className={className} active={active} style={{ ...styles }}>
    {children}
  </StyledCard>
);

export default Card;
