import Button from "components/atoms/Button";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteLilac};
  height: 100%;
`;

export const ButtonsWrapper = styled.div<{ row?: boolean }>`
  margin-top: 4rem;
  display: flex;

  ${({ row }) =>
    row &&
    css`
      flex-direction: column;
      gap: 2.4rem;
    `}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 2.4rem;
`;

export const CustomLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Optional = styled.span`
  color: ${({ theme }) => theme.colors.santasGray};
  font-style: italic;
  font-size: 1.4rem;
`;

export const OptionalWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: -1rem;
`;

export const CheckEmail = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  line-height: 2.4rem;
  letter-spacing: 0.2px;
`;

export const PriceLine = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  padding-top: 1.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  justify-content: space-between;
  font-weight: 500;

  div:last-child {
    font-size: 2.4rem;
    color: ${({ theme }) => theme.colors.denim};
    font-family: "Roboto Slab";
    font-weight: 700;
  }
`;

export const AdditionalInfo = styled.div`
  margin-top: 3rem;
  font-size: 1.5rem;
  margin-bottom: 5.6rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: center;

  svg * {
    fill: #fff;
  }
`;

export const ExpiredInvitationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`;

export const ExpiredInvitationLabel = styled.div`
  margin-top: 4rem;
`;
