import styled from "styled-components";

import { ReactComponent as School } from "assets/images/school.svg";
import Button from "components/atoms/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
`;

export const SchoolDataWrapper = styled.div`
  display: flex;
  height: 30rem;
  padding-bottom: 2rem;
`;

export const TableSection = styled.div`
  margin-right: 2rem;
  flex: 1;
`;

export const SchoolDetails = styled.div`
  width: 47rem;
  min-width: 25rem;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Roboto Slab";
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.denim};
  width: 14rem;
  min-width: 14rem;
`;
export const Value = styled.span`
  font-size: 1.6rem;
`;

export const Inforow = styled.div<{ align?: "flex-start" }>`
  display: flex;
  margin-bottom: 1rem;
  align-items: ${({ align }) => align || "center"};
`;

export const SchoolInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 1.6rem;
`;

export const SchoolIcon = styled(School)`
  margin-right: 1.2rem;
  min-width: 3rem;
`;

export const PencilButton = styled(Button)`
  padding: 0.2rem 0 1.6rem 1.6rem;
  width: fit-content;
  min-width: auto;
  height: auto;
`;
