import styled from "styled-components";
import Magnifier from "assets/images/magnifier.svg";

export const LabelWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  font-size: 1.3rem;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.4px;
  cursor: pointer;
  width: 13rem;
  &:hover {
    border-color: ${({ theme }) => theme.colors.manatee};
  }
`;

export const Label = styled.div`
  display: flex;
  gap: 8px;
`;

export const DropdownContent = styled.div<{ visible: boolean }>`
  position: relative;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 4px 4px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  width: 26rem;
  right: 0;
  top: 4rem;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  position: relative;
  user-select: none;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.geyser};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;

  background: ${({ theme }) => theme.colors.aquaHaze};
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.denim};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  flex-direction: column;
  gap: 1.2rem;
  z-index: 9999;
`;

export const FolderItem = styled.div<{ isActive: boolean }>`
  padding: 0.8rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;

  ${({ isActive }) => !isActive && `opacity: 0.4; pointer-events: none`}
  ${({ isActive }) => isActive && `cursor: pointer;`}

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.athensGray};
  }

  &:hover {
    ${({ isActive, theme }) => isActive && `color: ${theme.colors.denim};`}
  }
`;

export const SearchInput = styled.input`
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.mystic};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  letter-spacing: 0.04rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  margin: 0 0.2rem;
  background-image: url(${Magnifier});
  background-repeat: no-repeat;
  background-position: 0.8rem;
  padding-left: 2.5rem;
  cursor: text;
  border-radius: 4px;
`;

export const Pill = styled.div`
  padding: 0px 0.7rem;
  height: 1.6rem;
  background-color: ${({ theme }) => theme.colors.denim};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  font-size: 1.1rem;
`;
