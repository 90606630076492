import styled from "styled-components";

import { ReactComponent as CaretDown } from "assets/images/caret-down.svg";

export const Container = styled.div`
  position: relative;
  margin: 0 2rem 0 auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.4rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCaretDown = styled(CaretDown)`
  width: 1.2rem;
  height: 0.6rem;
  margin-right: 0.6rem;
`;
