import styled from "styled-components";
import Close from "assets/images/close.svg";

export const ToastWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.8rem;
  padding: 1.2rem 2.4rem 1.2rem 1.2rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 0.02rem;
  color: ${({ theme }) => theme.colors.shuttleGray};
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

export const ToastText = styled.div`
  max-width: 17rem;
  margin-left: 0.8rem;
`;

export const CloseIcon = styled.div`
  margin-left: 3rem;
  cursor: pointer;

  width: 11px;
  height: 11px;
  background-image: url(${Close});
  background-repeat: no-repeat;
  background-attachment: cover;
`;
