import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/Toast";
import {
  useGetVideoWithViewsQuery,
  useCompleteVideoViewMutation,
  useStartVideoWatchingMutation,
  VideoView,
  useGetAuthenticatedUserQuery,
} from "generated/graphql";
import VideoPlayer from "components/molecules/VideoPlayer";
import MobileHeader from "components/atoms/MobileHeader";
import SideMenu from "components/molecules/SideMenu";
import paths from "routes/paths";
import { Container, SideMenuWrapper } from "./styles";
import { VideoModel } from "../AcademyDashboard/types";

const FINISH_PERCENT = 0.95;

const Player = ({ isTutorial }: { isTutorial: boolean }) => {
  const Toast = useToast();
  const { t } = useTranslation();
  const history = useHistory();
  const { videoId } = useParams<{ videoId: string }>();

  const { data: userData } = useGetAuthenticatedUserQuery();

  const {
    data: videoData,
    loading: videoLoading,
    refetch: refetchVideoData,
    error: videoError,
  } = useGetVideoWithViewsQuery({
    variables: { videoId: Number(videoId) },
  });

  const [startWatchingVideo] = useStartVideoWatchingMutation();
  const [completeVideoView] = useCompleteVideoViewMutation();

  const [video, setVideo] = useState<VideoModel | null>(null);
  const [videoView, setVideoView] = useState<VideoView | null>(null);

  const redirectToDashboard = () => {
    return <Redirect to={paths.studentDashboard} />;
  };

  useEffect(() => {
    const data = videoData?.findVideoWithViews as VideoModel;
    if (data) {
      setVideo(data);
      setVideoView(data?.views[0]);
    }
  }, [videoData]);

  if (
    !userData?.authenticatedItem?.students![0].testAttempt?.isCompleted &&
    video &&
    !video.isTutorial
  ) {
    return redirectToDashboard();
  }

  if (video && video.isTutorial !== isTutorial) {
    return redirectToDashboard();
  }

  const sideMenuValues = isTutorial
    ? [{ name: t("student.backToAssessments"), path: paths.studentDashboard }]
    : [{ name: t("academy.allVideos"), path: paths.academyDashboard }];

  if (!videoLoading && videoError) {
    Toast("error", t("errorAction.noAccess"));
    return redirectToDashboard();
  }

  const handleOnStart = async () => {
    if (video && !videoView) {
      startWatchingVideo({
        variables: {
          videoId: Number(video.id),
        },
      })
        .then(() => {
          refetchVideoData();
        })
        .catch(() => {
          Toast("error", t("errorAction.generalError"));
        });
    }
  };

  const handleOnEnded = () => {
    if (video && videoView && !videoView.isCompleted) {
      completeVideoView({
        variables: {
          videoViewId: Number(videoView.id),
        },
      })
        .then(() => {
          refetchVideoData();
        })
        .catch(() => {
          Toast("error", t("errorAction.generalError"));
        });
    }
  };

  const handleOnProgress = (played: number) => {
    if (played > FINISH_PERCENT) handleOnEnded();
  };

  return (
    <>
      {video && (
        <Container>
          <MobileHeader
            title={sideMenuValues[sideMenuValues.length - 1].name}
            onClick={() =>
              history.push(sideMenuValues[sideMenuValues.length - 1].path)
            }
          />
          <SideMenuWrapper>
            <SideMenu values={sideMenuValues} />
          </SideMenuWrapper>
          <VideoPlayer
            video={video}
            handleOnEnded={handleOnEnded}
            handleOnProgress={handleOnProgress}
            handleOnStart={handleOnStart}
            maxWidth={844}
            maxHeight={576}
            isTutorial={isTutorial}
          />
        </Container>
      )}
    </>
  );
};

export default Player;
