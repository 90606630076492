import styled, { keyframes } from "styled-components";

import media from "helpers/media";

const slideUp = keyframes`
  0% {
    visibility: hidden;
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
    visibility: visible;
  }
`;

export const Background = styled.div`
  display: none;
  position: fixed;
  background: linear-gradient(
    115.63deg,
    rgba(255, 255, 255, 0.77) 18.89%,
    rgba(217, 222, 246, 0.566512) 65.78%,
    rgba(178, 188, 236, 0.36) 83.92%
  );
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 9999;

  ${media.SM`
    display: block;  
    bottom: 0;
  `}
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4.6rem 4rem;
  width: 100%;
  max-height: 85%;
  height: fit-content;
  overflow: auto;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  animation: 0.5s ease-out ${slideUp} 1;
  animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
`;

export const Title = styled.h3`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  margin-bottom: 1.2rem;
`;

export const Heading = styled.div`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 1.2rem;
`;
