import styled from "styled-components";

import { ReactComponent as ExternalLink } from "assets/images/external-link.svg";
import { ReactComponent as Faq } from "assets/images/faq.svg";
import { ReactComponent as Tutorial } from "assets/images/tutorial.svg";
import media from "helpers/media";
import { ZIndex } from "constants/theme";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;

  ${media.SM`
    padding: 3.2rem 3rem 0 1rem;
    font-size: 2.4rem;
  `}
`;

export const AvailableAssessments = styled.h2`
  color: ${({ theme }) => theme.colors.mineShaft};
  font-family: "Roboto Slab";
  font-size: 2.2rem;
  font-weight: 500;

  ${media.SM`
    line-height: 2.8rem;
    font-size: 2.4rem;
  `}
`;

export const AssessmentInfo = styled.h3`
  font-family: "Roboto";
  color: ${({ theme }) => theme.colors.shuttleGray};
  font-size: 1.6rem;
  font-weight: 400;
  margin: 4.8rem 0 3.2rem;
  white-space: pre-line;

  ${media.SM`
    line-height: 1.8rem;
    font-size: 1.3rem;
    font-weight: 400;
  `}
`;

export const ButtonContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.mineShaft};
  display: flex;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 1.8rem;
  max-width: 26rem;
  width: 100%;

  ${media.SM`
    line-height: 1.5rem;
    font-size: 1.2rem;
    max-width: 20rem;
  `}
`;

export const RoundedButton = styled.button<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.linkWaterDark : theme.colors.white};
  border-radius: 16px;
  margin-right: 1.6rem;
  height: 6.4rem;
  min-width: 6.4rem;

  ${media.SM`
    height: 4.8rem;
    min-width: 4.8rem;
  `}

  border: ${({ isActive, theme }) =>
    isActive ? "" : `1px solid ${theme.colors.athensGrayLight}`};
`;

export const ReportContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.denim};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  margin-bottom: 3rem;
  position: relative;
  z-index: ${ZIndex.Default};

  ${media.SM`
    display: none;
  `}

  &::after {
    background-color: ${({ theme }) => theme.colors.lightPurple};
    border: 2px solid ${({ theme }) => theme.colors.denim};
    border-radius: 8px;
    content: "";
    height: 100%;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100%;
    z-index: -${ZIndex.Default};
  }
`;

export const ReportTop = styled.div`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.mineShaft};
  font-family: Roboto Slab;
  font-weight: 500;
  justify-content: space-between;
  font-size: 2.4rem;
`;

export const ReportBadge = styled.span`
  align-items: center;
  background: #f0f7fc;
  border-radius: 100%;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  height: 6.4rem;
  min-width: 6.4rem;
  margin-left: 0.4rem;
`;

export const ReportDownloadText = styled.p`
  color: ${({ theme }) => theme.colors.shuttleGray};
  padding: 0;
  margin: 4.8rem 0 6.6rem;
  font-size: 1.8rem;
`;

export const FaqIcon = styled(Faq)<{ $isActive: boolean }>`
  fill: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.denim : theme.colors.manatee};
`;
export const TutorialIcon = styled(Tutorial)``;

export const ExternalLinkIcon = styled(ExternalLink)`
  margin-left: 0.6rem;
  margin-bottom: -0.2rem;
`;

export const MobileOnly = styled.div`
  display: none;

  ${media.SM`
    display: block;
    margin-bottom: 5rem;
  `}
`;

export const ReportWrapper = styled.div`
  background-color: #e7ecee;
  padding: 4rem 4rem 5rem 4rem;
`;
