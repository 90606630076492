import React, { FC } from "react";

import { StyledTextarea, Container } from "./styles";
import { Props } from "./types";

const Textarea: FC<Props> = (props) => {
  const { value, onChange, styles, name, onBlur, reference } = props;

  return (
    <Container>
      <StyledTextarea
        name={name}
        style={styles}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={reference}
      />
    </Container>
  );
};

export default Textarea;
