import styled from "styled-components";
import media from "helpers/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0px 8%;
  min-height: calc(100vh - 20.4rem);

  ${media.SM`
    align-items: center;
  `};

  ${media.XS`
    margin: 0px;
  `};
`;

export const VideoListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px 16px;

  ${media.SM`
    justify-content: center;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.mineShaft};
  font-family: "Roboto Slab";
  weight: 400;
  font-size: 3.2rem;
  width: 100%;
  margin-bottom: 5.4rem;

  ${media.SM`
    text-align: center;
  `};
`;
