import i18n from "utils/i18n/i18n";

import { AssessmentType } from "types";

export const getTestName = (type: AssessmentType) => {
  let testName = "";

  switch (type) {
    case "interest":
      testName = i18n.t("test.interest.interestInventory");
      break;

    default:
      testName = "";
  }
  return testName;
};
