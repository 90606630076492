import React, { FC } from "react";

import { Props } from "./types";
import { StyledInput, Wrapper } from "./styles";

const Checkbox: FC<Props> = (props) => {
  const { isChecked, onClick, children } = props;

  return (
    <Wrapper type="button" onClick={onClick}>
      <StyledInput isChecked={isChecked} type="checkbox" />
      {children}
    </Wrapper>
  );
};

export default Checkbox;
