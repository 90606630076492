import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "components/atoms/Header";
import { User, useGetAuthenticatedUserQuery } from "generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { currentSchoolIdVar } from "store/school";
import { Table, TableBody, TableHeader } from "../StudentList/styles";

import { TableRow, TableCell, ActionItem } from "../StudentRow/styles";

import {
  TableWrapper,
  HeaderWrapper,
  TeacherActionButton,
  DetailsIconStyled,
} from "./styles";
import { Props, TeacherInfo } from "./types";

const TeachersList: FC<Props> = ({
  handleAddTeacher,
  currentSchool,
  teachersData,
  openDetailsModal,
}) => {
  const { t } = useTranslation();

  const [allTeachers, setAllTeachers] = useState<TeacherInfo[]>([]);

  const { data: authenticatedUser } = useGetAuthenticatedUserQuery();
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const isAdmin =
    authenticatedUser?.authenticatedItem?.adminOfSchools?.find(
      (school) => school.id === currentSchoolId
    ) || false;

  useEffect(() => {
    const localTeachers: TeacherInfo[] = [];

    teachersData?.teachers?.forEach(
      (teacher) =>
        teacher?.user &&
        localTeachers.push({ ...teacher?.user, teacherId: teacher?.id })
    );
    teachersData?.school?.schoolAdmins
      ?.filter(
        (admin) => !localTeachers.find((teacher) => teacher.id === admin.id)
      )
      .forEach((teacher) => teacher && localTeachers.push(teacher));

    setAllTeachers(localTeachers);
  }, [teachersData]);

  return (
    <>
      <TableWrapper>
        <HeaderWrapper>
          <Header>{t("teacher.activeTeachers")}</Header>
          {isAdmin && (
            <TeacherActionButton variant="primary" onClick={handleAddTeacher}>
              {t("teacher.addTeacher")}
            </TeacherActionButton>
          )}
        </HeaderWrapper>
        <Table>
          <thead>
            <tr>
              <TableHeader style={{ width: "40%" }}>
                {t("teacher.teachersName")}
              </TableHeader>
              <TableHeader style={{ width: "40%" }}>
                {t("global.role")}
              </TableHeader>
              <TableHeader style={{ width: "20%" }}>
                {t("global.details")}
              </TableHeader>
              <TableHeader />
            </tr>
          </thead>
          <TableBody>
            {allTeachers &&
              allTeachers.map((teacher, id) => {
                if (teacher) {
                  const { firstName, lastName, id: userId } = teacher as User;

                  const isSchoolAdmin = currentSchool?.school?.schoolAdmins?.some(
                    (admin) => admin.id === userId
                  );
                  const isLoggedUserTeacher =
                    userId === currentSchool?.authenticatedItem?.id;

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        {lastName}, {firstName}
                        {isLoggedUserTeacher && <> ({t("teacher.you")})</>}
                      </TableCell>
                      <TableCell>
                        {isSchoolAdmin
                          ? t("global.schoolAdmin")
                          : t("global.teacher")}
                      </TableCell>
                      <TableCell>
                        <ActionItem
                          onClick={() => {
                            openDetailsModal(teacher);
                          }}
                          style={{ paddingLeft: "1rem" }}
                        >
                          <DetailsIconStyled />
                        </ActionItem>
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default TeachersList;
