import React, { FC, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import Checkbox from "components/atoms/Checkbox";
import Header from "components/atoms/Header";
import ButtonGroup from "components/molecules/ButtonGroup";
import Button from "components/atoms/Button";
import ConfirmationModal from "components/molecules/ConfirmationModal";
import StudentRow from "components/molecules/StudentRow";

import Loader from "components/atoms/Loader";
import { SelectableStudent } from "types/student";

import {
  GetAllStudentsSortedQuery,
  GetArchivedStudentsQuery,
  useAssignStudentsToFolderMutation,
} from "generated/graphql";
import { ActionDrawerWrapper } from "pages/Teacher/Students/styles";
import StudentActionDrawer from "pages/Teacher/Students/Components/StudentActionDrawer";
import { useReactiveVar } from "@apollo/client";
import { currentSchoolIdVar } from "store/school";
import {
  Table,
  TableBody,
  TableHeader,
  TableWrapper,
  HeaderWrapper,
  NoStudentsInfo,
  ContentWrapper,
} from "./styles";
import { Props } from "./types";
import { StudentDetails } from "../StudentRow/types";
import ArchiveYearsDropdown from "../ArchiveYearsDropdown";
import FolderDropdown from "../FolderDropdown";
import ReportDropdown from "../ReportDropdown";

const matchStudentAndSelectedStudent = (
  selectedStudent: SelectableStudent,
  student: StudentDetails
) => selectedStudent.user.id === student?.user?.id;

const StudentList: FC<Props> = (props) => {
  const {
    handleToggleStudent,
    selectedStudents,
    setSelectedStudents,
    studentsData,
    studentsLoading,
    editModal,
    headerButtonsData,
    selectedStatusId,
    onSelectStatusFilter,
    handleMessageStudent,
    handleRemoveStudent,
    handleUnarchiveStudent,
    isArchivedStudentsList = false,
    setIsSelectedNotSent,
    isSelectedNotSent,
    archiveYears,
    setSelectedYear,
    onDrawerClose,
    studentsRefetch,
    openMessageModal,
    handleArchiveStudents,
  } = props;
  const { t } = useTranslation();

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [studentRemoveId, setStudentRemoveId] = useState<null | string>(null);
  const [studentUnarchiveId, setStudentUnarchiveId] = useState<null | string>(
    null
  );
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);

  const openConfirmModal = () => setIsConfirmModalOpened(true);
  const closeConfirmModal = () => {
    setIsConfirmModalOpened(false);
    setStudentRemoveId(null);
    setStudentUnarchiveId(null);
  };

  const students =
    (isArchivedStudentsList
      ? (studentsData as GetArchivedStudentsQuery)?.allArchivedStudents
      : (studentsData as GetAllStudentsSortedQuery)?.allStudentsSorted) || [];

  const areAllSelected = students.every((student) =>
    selectedStudents.some((el) => matchStudentAndSelectedStudent(el, student))
  );

  const handleToggleAllStudents = () => {
    const selectStudentsFromOtherFilter = selectedStudents.filter(
      (selectedStudent) =>
        !students.some((student) =>
          matchStudentAndSelectedStudent(selectedStudent, student)
        )
    );

    if (areAllSelected) {
      return setSelectedStudents(selectStudentsFromOtherFilter);
    }
    return setSelectedStudents(
      selectStudentsFromOtherFilter.concat(students as SelectableStudent[])
    );
  };

  const [assignStudentMutation] = useAssignStudentsToFolderMutation();

  const assignToFolder = async (folderId: string) => {
    await assignStudentMutation({
      variables: {
        folderId: Number(folderId),
        schoolId: Number(currentSchoolId),
        studentIds: selectedStudents.map((student) => Number(student.id)),
      },
    }).then(() => {
      setSelectedStudents([]);
      studentsRefetch();
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const renderTableBody = () => {
    if (students.length > 0) {
      return (
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <Checkbox
                  isChecked={areAllSelected}
                  onClick={handleToggleAllStudents}
                />
              </TableHeader>
              <TableHeader>{t("teacher.studentsName")}</TableHeader>
              {!isArchivedStudentsList && (
                <TableHeader>{t("teacher.folder")}</TableHeader>
              )}
              <TableHeader>{t("teacher.status")}</TableHeader>
              <TableHeader>{t("teacher.actions")}</TableHeader>
              <TableHeader>{t("teacher.report")}</TableHeader>
            </tr>
          </thead>
          <TableBody>
            {students.map((student) => (
              <StudentRow
                key={student?.id}
                student={student}
                setSelectedStudents={setSelectedStudents}
                isSelected={selectedStudents.some(
                  (el) => el.user.id === student?.user?.id
                )}
                isArchived={isArchivedStudentsList}
                onRemove={(item) => {
                  setStudentRemoveId(item?.user?.id as string);
                  openConfirmModal();
                }}
                onEdit={(item) =>
                  item?.user && editModal && editModal(item.user.id)
                }
                onUnarchive={(item) => {
                  setStudentUnarchiveId(item?.id as string);
                  openConfirmModal();
                }}
                onToggle={(item) =>
                  item?.user && handleToggleStudent(item as SelectableStudent)
                }
                onSendMessage={(item) => {
                  handleMessageStudent(item as SelectableStudent);
                }}
                studentsRefetch={studentsRefetch}
              />
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <NoStudentsInfo>
        {isArchivedStudentsList
          ? t("teacher.noArchivedStudents")
          : t("teacher.noStudents")}
        <br />
      </NoStudentsInfo>
    );
  };

  return (
    <>
      {studentRemoveId && (
        <ConfirmationModal
          title={t("teacher.deleteAccount")}
          isOpen={isConfirmModalOpened}
          onClose={closeConfirmModal}
          contentText={t("teacher.sureAboutStudentRemoval")}
          confirmButton={
            <Button
              variant="danger"
              onClick={() => {
                handleRemoveStudent(studentRemoveId);
                closeConfirmModal();
              }}
            >
              {t("teacher.removeStudentConfirm")}
            </Button>
          }
          cancelButton={
            <Button variant="link" onClick={closeConfirmModal}>
              {t("actions.cancel")}
            </Button>
          }
        />
      )}
      {studentUnarchiveId && handleUnarchiveStudent && (
        <ConfirmationModal
          title={t("teacher.unarchiveStudent")}
          isOpen={isConfirmModalOpened}
          onClose={closeConfirmModal}
          contentText={t("teacher.sureAboutStudentUnarchive")}
          confirmButton={
            <Button
              variant="danger"
              onClick={() => {
                handleUnarchiveStudent([studentUnarchiveId]);
                closeConfirmModal();
              }}
            >
              {t("teacher.unarchiveStudentConfirm")}
            </Button>
          }
          cancelButton={
            <Button variant="link" onClick={closeConfirmModal}>
              {t("actions.cancel")}
            </Button>
          }
        />
      )}
      <TableWrapper>
        {students.length > 0 && (
          <>
            <ReactTooltip
              id="onlyNotStarted"
              place="bottom"
              type="dark"
              effect="solid"
            >
              {isArchivedStudentsList ? null : (
                <span>
                  {t("teacher.editStudentInfo")}
                  <br /> {t("teacher.editStudentInfo2")} <br />
                  {t("teacher.editStudentInfo3")}
                </span>
              )}
            </ReactTooltip>
            <ReactTooltip
              id="results"
              place="bottom"
              type="dark"
              effect="solid"
              getContent={(content) => {
                const {
                  verbalScore,
                  numericalScore,
                  abstractScore,
                } = JSON.parse(content || "{}");
                return (
                  <span>
                    <strong>{t("teacher.abstractScoreInfo")}: </strong>
                    {abstractScore}
                    <br />
                    <strong>{t("teacher.numericalScoreInfo")}: </strong>
                    {numericalScore}
                    <br />
                    <strong>{t("teacher.verbalScoreInfo")}: </strong>
                    {verbalScore}
                  </span>
                );
              }}
            />
          </>
        )}
        <HeaderWrapper>
          <Header>
            {t(
              isArchivedStudentsList
                ? "teacher.previousYearsHeader"
                : "teacher.listOfStudents"
            )}
          </Header>

          {!isArchivedStudentsList &&
            headerButtonsData &&
            onSelectStatusFilter && (
              <ButtonGroup
                buttonsData={headerButtonsData}
                selectedButtonId={selectedStatusId}
                onButtonClick={onSelectStatusFilter}
                selectNotSent={setIsSelectedNotSent}
                isSelectedNotSent={isSelectedNotSent}
              />
            )}
          {isArchivedStudentsList &&
            !!archiveYears?.length &&
            setSelectedYear && (
              <ArchiveYearsDropdown
                archiveYears={archiveYears}
                setSelectedYear={setSelectedYear}
              />
            )}
          {!isArchivedStudentsList && <FolderDropdown />}
          <ReportDropdown
            setIsSelectedNotSent={setIsSelectedNotSent}
            isSelectedNotSent={isSelectedNotSent}
          />
        </HeaderWrapper>
        {selectedStudents.length > 0 && studentsData && (
          <ActionDrawerWrapper>
            <StudentActionDrawer
              selectedStudents={selectedStudents}
              openMessageModal={openMessageModal}
              onDrawerClose={onDrawerClose}
              onFolderSelect={assignToFolder}
              handleArchiveStudents={handleArchiveStudents}
            />
          </ActionDrawerWrapper>
        )}

        <ContentWrapper>
          {studentsLoading ? <Loader /> : renderTableBody()}
        </ContentWrapper>
      </TableWrapper>
    </>
  );
};

export default StudentList;
