import styled from "styled-components";

import Button from "../Button";

export const CurrentPageBtn = styled(Button).attrs({ variant: "text" })`
  font-weight: 500;
  padding: 0.4rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.lavenderGray};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const NextPagesWrapper = styled.div`
  display: flex;
`;
