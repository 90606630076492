import Card from "components/atoms/Card";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 1170px;
  height: 846px;
`;

export const TopLabelWrapper = styled.div`
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
`;

export const TableSection = styled.div`
  width: 100%;
  margin-right: 2rem;
  margin-top: 0.8rem;
`;

export const CreditsWrapper = styled.div`
  border: 1.5px solid ${({ theme }) => theme.colors.mystic};

  width: 22rem;
  height: 22rem;
  border-radius: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreditsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.linkWater};
  width: 18rem;
  height: 18rem;
  border-radius: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Slab";
  font-weight: 700;
  letter-spacing: 0.028rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  flex-direction: column;
`;

export const Credits = styled.h3`
  font-weight: 700;
  font-size: 5.7rem;
  letter-spacing: 0, 028rem;
  color: ${({ theme }) => theme.colors.denim};
`;

export const StudentsListWrapper = styled.div`
  height: 82%;
  overflow-y: auto;
`;

export const StyledCard = styled(Card)`
  position: relative;
  height: calc(100% - 18rem);
`;

export const SchoolWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  margin-top: 1.8rem;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.geyser};

  background: ${({ theme }) => theme.colors.white};
`;

export const ActionDrawerWrapper = styled.div<{ width?: number }>`
  margin: auto;
`;

export const TabHeaderWrapper = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.6rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.mineShaft};
  padding: 1.2rem 0;
`;

export const AddMore = styled.div`
  color: ${({ theme }) => theme.colors.denim};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.treePoppy};
  }
`;

export const Circle = styled.div<{ color: string }>`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${({ color }) => color};
  border-radius: 0.8rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-top: 1.2rem;
`;

export const LegendItem = styled.div`
  display: flex;
  font-size: 1.2rem;
  gap: 0.8rem;
  align-items: center;
`;

export const TitledPanelWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;
