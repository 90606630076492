import React, { FC } from "react";

import {
  StyledInput,
  Container,
  ArrowWrapper,
  ArrowUp,
  ArrowDown,
} from "./styles";
import { Props } from "./types";

const NumberInput: FC<Props> = ({ name, placeholder, value, handleChange }) => {
  const increaseValue = () => {
    const increasedValue = Number(value) + 1;
    handleChange(increasedValue.toString());
  };

  const decreaseValue = () => {
    const decreasedValue = Number(value) - 1;
    const correctValue = decreasedValue < 0 ? 0 : decreasedValue;
    handleChange(correctValue.toString());
  };

  const numbersOnlyFilter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-" || e.key === ".") e.preventDefault();
  };

  const handleSetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
  };

  return (
    <Container>
      <StyledInput
        type="number"
        name={name}
        placeholder={placeholder}
        value={value || ""}
        onChange={handleSetValue}
        min={1}
        step={1}
        onKeyDown={numbersOnlyFilter}
      />
      <ArrowWrapper>
        <button type="button" onClick={increaseValue}>
          <ArrowUp />
        </button>
        <button type="button" onClick={decreaseValue}>
          <ArrowDown />
        </button>
      </ArrowWrapper>
    </Container>
  );
};

export default NumberInput;
