import styled from "styled-components";

import { ReactComponent as School } from "assets/images/school.svg";

export const SchoolIcon = styled(School)`
  margin-right: 1.2rem;
  min-width: 3rem;
`;

export const SchoolInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.2rem;
  text-transform: uppercase;
`;

export const ChartInfo = styled.div`
  font-family: "Roboto Slab";
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ChartInfoTitle = styled.div`
  font-size: 5.6rem;
`;
export const ChartInfoSubTitle = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
`;

export const BulkActionText = styled.div`
  font-family: "Roboto Slab";
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.4rem;
`;

export const BulkActionTextMuted = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.stormGray};
`;

export const CreditsWrapper = styled.div`
  border: 1.5px solid ${({ theme }) => theme.colors.mystic};
  width: 22rem;
  height: 22rem;
  border-radius: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreditsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.linkWater};
  width: 18rem;
  height: 18rem;
  border-radius: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Slab";
  font-weight: 700;
  letter-spacing: 0.028rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  flex-direction: column;
`;

export const Credits = styled.h3`
  font-weight: 700;
  font-size: 5.7rem;
  letter-spacing: 0, 028rem;
  color: ${({ theme }) => theme.colors.denim};
`;

export const CreditBalance = styled.p`
  color: ${({ theme }) => theme.colors.mineShaft};
  font-family: "Roboto Slab";
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1.6rem;
`;

export const CreditsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const SearchStudentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2.2rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  &:first-of-type {
    margin-top: 0;
  }
`;
