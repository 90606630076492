import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  CalendarDay: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Career = {
  __typename?: 'Career';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  careerId?: Maybe<Scalars['String']>;
  jobCategory?: Maybe<JobCategory>;
  name?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type CareerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CareerWhereInput = {
  AND?: Maybe<Array<CareerWhereInput>>;
  OR?: Maybe<Array<CareerWhereInput>>;
  NOT?: Maybe<Array<CareerWhereInput>>;
  id?: Maybe<IdFilter>;
  careerId?: Maybe<StringFilter>;
  jobCategory?: Maybe<JobCategoryWhereInput>;
  name?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type IdFilter = {
  equals?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  not?: Maybe<IdFilter>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<DateTimeNullableFilter>;
};

export type CareerOrderByInput = {
  id?: Maybe<OrderDirection>;
  careerId?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type CareerUpdateInput = {
  careerId?: Maybe<Scalars['String']>;
  jobCategory?: Maybe<JobCategoryRelateToOneForUpdateInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type JobCategoryRelateToOneForUpdateInput = {
  create?: Maybe<JobCategoryCreateInput>;
  connect?: Maybe<JobCategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type CareerUpdateArgs = {
  where: CareerWhereUniqueInput;
  data: CareerUpdateInput;
};

export type CareerCreateInput = {
  careerId?: Maybe<Scalars['String']>;
  jobCategory?: Maybe<JobCategoryRelateToOneForCreateInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type JobCategoryRelateToOneForCreateInput = {
  create?: Maybe<JobCategoryCreateInput>;
  connect?: Maybe<JobCategoryWhereUniqueInput>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CountryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryWhereInput = {
  AND?: Maybe<Array<CountryWhereInput>>;
  OR?: Maybe<Array<CountryWhereInput>>;
  NOT?: Maybe<Array<CountryWhereInput>>;
  id?: Maybe<IdFilter>;
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  flag?: Maybe<StringFilter>;
  websites?: Maybe<StringNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type CountryOrderByInput = {
  id?: Maybe<OrderDirection>;
  code?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  flag?: Maybe<OrderDirection>;
  websites?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type CountryUpdateInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CountryUpdateArgs = {
  where: CountryWhereUniqueInput;
  data: CountryUpdateInput;
};

export type CountryCreateInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type EmailTemplateWhereInput = {
  AND?: Maybe<Array<EmailTemplateWhereInput>>;
  OR?: Maybe<Array<EmailTemplateWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateWhereInput>>;
  id?: Maybe<IdFilter>;
  title?: Maybe<StringNullableFilter>;
  content?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type EmailTemplateOrderByInput = {
  id?: Maybe<OrderDirection>;
  title?: Maybe<OrderDirection>;
  content?: Maybe<OrderDirection>;
  type?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type EmailTemplateUpdateInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateArgs = {
  where: EmailTemplateWhereUniqueInput;
  data: EmailTemplateUpdateInput;
};

export type EmailTemplateCreateInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestAnswer = {
  __typename?: 'InterestAnswer';
  id: Scalars['ID'];
  answer?: Maybe<Scalars['Int']>;
  testAttempt?: Maybe<TestAttempt>;
  interestQuestion?: Maybe<InterestQuestion>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestAnswerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type InterestAnswerWhereInput = {
  AND?: Maybe<Array<InterestAnswerWhereInput>>;
  OR?: Maybe<Array<InterestAnswerWhereInput>>;
  NOT?: Maybe<Array<InterestAnswerWhereInput>>;
  id?: Maybe<IdFilter>;
  answer?: Maybe<IntNullableFilter>;
  testAttempt?: Maybe<TestAttemptWhereInput>;
  interestQuestion?: Maybe<InterestQuestionWhereInput>;
  timestamp?: Maybe<DateTimeNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<IntNullableFilter>;
};

export type InterestAnswerOrderByInput = {
  id?: Maybe<OrderDirection>;
  answer?: Maybe<OrderDirection>;
  timestamp?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type InterestAnswerUpdateInput = {
  answer?: Maybe<Scalars['Int']>;
  testAttempt?: Maybe<TestAttemptRelateToOneForUpdateInput>;
  interestQuestion?: Maybe<InterestQuestionRelateToOneForUpdateInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TestAttemptRelateToOneForUpdateInput = {
  create?: Maybe<TestAttemptCreateInput>;
  connect?: Maybe<TestAttemptWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type InterestQuestionRelateToOneForUpdateInput = {
  create?: Maybe<InterestQuestionCreateInput>;
  connect?: Maybe<InterestQuestionWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type InterestAnswerUpdateArgs = {
  where: InterestAnswerWhereUniqueInput;
  data: InterestAnswerUpdateInput;
};

export type InterestAnswerCreateInput = {
  answer?: Maybe<Scalars['Int']>;
  testAttempt?: Maybe<TestAttemptRelateToOneForCreateInput>;
  interestQuestion?: Maybe<InterestQuestionRelateToOneForCreateInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TestAttemptRelateToOneForCreateInput = {
  create?: Maybe<TestAttemptCreateInput>;
  connect?: Maybe<TestAttemptWhereUniqueInput>;
};

export type InterestQuestionRelateToOneForCreateInput = {
  create?: Maybe<InterestQuestionCreateInput>;
  connect?: Maybe<InterestQuestionWhereUniqueInput>;
};

export type InterestQuestion = {
  __typename?: 'InterestQuestion';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  interestId?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<InterestQuestionVariant>>;
  variantsCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<InterestQuestion>;
  jobCategory?: Maybe<JobCategory>;
  subquestions?: Maybe<Array<InterestQuestion>>;
  subquestionsCount?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<InterestAnswer>>;
  answersCount?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type InterestQuestionVariantsArgs = {
  where?: InterestQuestionVariantWhereInput;
  orderBy?: Array<InterestQuestionVariantOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestQuestionVariantWhereUniqueInput>;
};


export type InterestQuestionVariantsCountArgs = {
  where?: InterestQuestionVariantWhereInput;
};


export type InterestQuestionSubquestionsArgs = {
  where?: InterestQuestionWhereInput;
  orderBy?: Array<InterestQuestionOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestQuestionWhereUniqueInput>;
};


export type InterestQuestionSubquestionsCountArgs = {
  where?: InterestQuestionWhereInput;
};


export type InterestQuestionAnswersArgs = {
  where?: InterestAnswerWhereInput;
  orderBy?: Array<InterestAnswerOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestAnswerWhereUniqueInput>;
};


export type InterestQuestionAnswersCountArgs = {
  where?: InterestAnswerWhereInput;
};

export type InterestQuestionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  interestId?: Maybe<Scalars['String']>;
};

export type InterestQuestionWhereInput = {
  AND?: Maybe<Array<InterestQuestionWhereInput>>;
  OR?: Maybe<Array<InterestQuestionWhereInput>>;
  NOT?: Maybe<Array<InterestQuestionWhereInput>>;
  id?: Maybe<IdFilter>;
  interestId?: Maybe<StringFilter>;
  variants?: Maybe<InterestQuestionVariantManyRelationFilter>;
  name?: Maybe<StringFilter>;
  parent?: Maybe<InterestQuestionWhereInput>;
  jobCategory?: Maybe<JobCategoryWhereInput>;
  subquestions?: Maybe<InterestQuestionManyRelationFilter>;
  answers?: Maybe<InterestAnswerManyRelationFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type InterestQuestionVariantManyRelationFilter = {
  every?: Maybe<InterestQuestionVariantWhereInput>;
  some?: Maybe<InterestQuestionVariantWhereInput>;
  none?: Maybe<InterestQuestionVariantWhereInput>;
};

export type InterestQuestionManyRelationFilter = {
  every?: Maybe<InterestQuestionWhereInput>;
  some?: Maybe<InterestQuestionWhereInput>;
  none?: Maybe<InterestQuestionWhereInput>;
};

export type InterestAnswerManyRelationFilter = {
  every?: Maybe<InterestAnswerWhereInput>;
  some?: Maybe<InterestAnswerWhereInput>;
  none?: Maybe<InterestAnswerWhereInput>;
};

export type InterestQuestionOrderByInput = {
  id?: Maybe<OrderDirection>;
  interestId?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type InterestQuestionUpdateInput = {
  interestId?: Maybe<Scalars['String']>;
  variants?: Maybe<InterestQuestionVariantRelateToManyForUpdateInput>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<InterestQuestionRelateToOneForUpdateInput>;
  jobCategory?: Maybe<JobCategoryRelateToOneForUpdateInput>;
  subquestions?: Maybe<InterestQuestionRelateToManyForUpdateInput>;
  answers?: Maybe<InterestAnswerRelateToManyForUpdateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestQuestionVariantRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<InterestQuestionVariantWhereUniqueInput>>;
  set?: Maybe<Array<InterestQuestionVariantWhereUniqueInput>>;
  create?: Maybe<Array<InterestQuestionVariantCreateInput>>;
  connect?: Maybe<Array<InterestQuestionVariantWhereUniqueInput>>;
};

export type InterestQuestionRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<InterestQuestionWhereUniqueInput>>;
  set?: Maybe<Array<InterestQuestionWhereUniqueInput>>;
  create?: Maybe<Array<InterestQuestionCreateInput>>;
  connect?: Maybe<Array<InterestQuestionWhereUniqueInput>>;
};

export type InterestAnswerRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<InterestAnswerWhereUniqueInput>>;
  set?: Maybe<Array<InterestAnswerWhereUniqueInput>>;
  create?: Maybe<Array<InterestAnswerCreateInput>>;
  connect?: Maybe<Array<InterestAnswerWhereUniqueInput>>;
};

export type InterestQuestionUpdateArgs = {
  where: InterestQuestionWhereUniqueInput;
  data: InterestQuestionUpdateInput;
};

export type InterestQuestionCreateInput = {
  interestId?: Maybe<Scalars['String']>;
  variants?: Maybe<InterestQuestionVariantRelateToManyForCreateInput>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<InterestQuestionRelateToOneForCreateInput>;
  jobCategory?: Maybe<JobCategoryRelateToOneForCreateInput>;
  subquestions?: Maybe<InterestQuestionRelateToManyForCreateInput>;
  answers?: Maybe<InterestAnswerRelateToManyForCreateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestQuestionVariantRelateToManyForCreateInput = {
  create?: Maybe<Array<InterestQuestionVariantCreateInput>>;
  connect?: Maybe<Array<InterestQuestionVariantWhereUniqueInput>>;
};

export type InterestQuestionRelateToManyForCreateInput = {
  create?: Maybe<Array<InterestQuestionCreateInput>>;
  connect?: Maybe<Array<InterestQuestionWhereUniqueInput>>;
};

export type InterestAnswerRelateToManyForCreateInput = {
  create?: Maybe<Array<InterestAnswerCreateInput>>;
  connect?: Maybe<Array<InterestAnswerWhereUniqueInput>>;
};

export type InterestQuestionVariant = {
  __typename?: 'InterestQuestionVariant';
  id: Scalars['ID'];
  interestQuestion?: Maybe<InterestQuestion>;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestQuestionVariantWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type InterestQuestionVariantWhereInput = {
  AND?: Maybe<Array<InterestQuestionVariantWhereInput>>;
  OR?: Maybe<Array<InterestQuestionVariantWhereInput>>;
  NOT?: Maybe<Array<InterestQuestionVariantWhereInput>>;
  id?: Maybe<IdFilter>;
  interestQuestion?: Maybe<InterestQuestionWhereInput>;
  name?: Maybe<StringFilter>;
  language?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type InterestQuestionVariantOrderByInput = {
  id?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  language?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type InterestQuestionVariantUpdateInput = {
  interestQuestion?: Maybe<InterestQuestionRelateToOneForUpdateInput>;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type InterestQuestionVariantUpdateArgs = {
  where: InterestQuestionVariantWhereUniqueInput;
  data: InterestQuestionVariantUpdateInput;
};

export type InterestQuestionVariantCreateInput = {
  interestQuestion?: Maybe<InterestQuestionRelateToOneForCreateInput>;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type JobCategory = {
  __typename?: 'JobCategory';
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  interestQuestions?: Maybe<Array<InterestQuestion>>;
  interestQuestionsCount?: Maybe<Scalars['Int']>;
  careers?: Maybe<Array<Career>>;
  careersCount?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type JobCategoryInterestQuestionsArgs = {
  where?: InterestQuestionWhereInput;
  orderBy?: Array<InterestQuestionOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestQuestionWhereUniqueInput>;
};


export type JobCategoryInterestQuestionsCountArgs = {
  where?: InterestQuestionWhereInput;
};


export type JobCategoryCareersArgs = {
  where?: CareerWhereInput;
  orderBy?: Array<CareerOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CareerWhereUniqueInput>;
};


export type JobCategoryCareersCountArgs = {
  where?: CareerWhereInput;
};

export type JobCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type JobCategoryWhereInput = {
  AND?: Maybe<Array<JobCategoryWhereInput>>;
  OR?: Maybe<Array<JobCategoryWhereInput>>;
  NOT?: Maybe<Array<JobCategoryWhereInput>>;
  id?: Maybe<IdFilter>;
  number?: Maybe<IntFilter>;
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  interestQuestions?: Maybe<InterestQuestionManyRelationFilter>;
  careers?: Maybe<CareerManyRelationFilter>;
  shortDescription?: Maybe<StringFilter>;
  fullDescription?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<IntFilter>;
};

export type CareerManyRelationFilter = {
  every?: Maybe<CareerWhereInput>;
  some?: Maybe<CareerWhereInput>;
  none?: Maybe<CareerWhereInput>;
};

export type JobCategoryOrderByInput = {
  id?: Maybe<OrderDirection>;
  number?: Maybe<OrderDirection>;
  code?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  shortDescription?: Maybe<OrderDirection>;
  fullDescription?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type JobCategoryUpdateInput = {
  number?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  interestQuestions?: Maybe<InterestQuestionRelateToManyForUpdateInput>;
  careers?: Maybe<CareerRelateToManyForUpdateInput>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CareerRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<CareerWhereUniqueInput>>;
  set?: Maybe<Array<CareerWhereUniqueInput>>;
  create?: Maybe<Array<CareerCreateInput>>;
  connect?: Maybe<Array<CareerWhereUniqueInput>>;
};

export type JobCategoryUpdateArgs = {
  where: JobCategoryWhereUniqueInput;
  data: JobCategoryUpdateInput;
};

export type JobCategoryCreateInput = {
  number?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  interestQuestions?: Maybe<InterestQuestionRelateToManyForCreateInput>;
  careers?: Maybe<CareerRelateToManyForCreateInput>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CareerRelateToManyForCreateInput = {
  create?: Maybe<Array<CareerCreateInput>>;
  connect?: Maybe<Array<CareerWhereUniqueInput>>;
};

export type School = {
  __typename?: 'School';
  id: Scalars['ID'];
  actions?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  testPrice?: Maybe<Scalars['Float']>;
  principalName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldOutput>;
  country?: Maybe<Country>;
  language?: Maybe<Scalars['String']>;
  hasRemindersOn?: Maybe<Scalars['Boolean']>;
  schoolAdmins?: Maybe<Array<User>>;
  schoolAdminsCount?: Maybe<Scalars['Int']>;
  teachers?: Maybe<Array<Teacher>>;
  teachersCount?: Maybe<Scalars['Int']>;
  students?: Maybe<Array<Student>>;
  studentsCount?: Maybe<Scalars['Int']>;
  folders?: Maybe<Array<Folder>>;
  foldersCount?: Maybe<Scalars['Int']>;
  credit?: Maybe<Credit>;
  isIndividual?: Maybe<Scalars['Boolean']>;
  crmContacts?: Maybe<Array<CrmContact>>;
  crmContactsCount?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type SchoolSchoolAdminsArgs = {
  where?: UserWhereInput;
  orderBy?: Array<UserOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<UserWhereUniqueInput>;
};


export type SchoolSchoolAdminsCountArgs = {
  where?: UserWhereInput;
};


export type SchoolTeachersArgs = {
  where?: TeacherWhereInput;
  orderBy?: Array<TeacherOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<TeacherWhereUniqueInput>;
};


export type SchoolTeachersCountArgs = {
  where?: TeacherWhereInput;
};


export type SchoolStudentsArgs = {
  where?: StudentWhereInput;
  orderBy?: Array<StudentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentWhereUniqueInput>;
};


export type SchoolStudentsCountArgs = {
  where?: StudentWhereInput;
};


export type SchoolFoldersArgs = {
  where?: FolderWhereInput;
  orderBy?: Array<FolderOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FolderWhereUniqueInput>;
};


export type SchoolFoldersCountArgs = {
  where?: FolderWhereInput;
};


export type SchoolCrmContactsArgs = {
  where?: CrmContactWhereInput;
  orderBy?: Array<CrmContactOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CrmContactWhereUniqueInput>;
};


export type SchoolCrmContactsCountArgs = {
  where?: CrmContactWhereInput;
};

export type FileFieldOutput = {
  __typename?: 'FileFieldOutput';
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  url: Scalars['String'];
};

export type SchoolWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type SchoolWhereInput = {
  AND?: Maybe<Array<SchoolWhereInput>>;
  OR?: Maybe<Array<SchoolWhereInput>>;
  NOT?: Maybe<Array<SchoolWhereInput>>;
  id?: Maybe<IdFilter>;
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  testPrice?: Maybe<FloatNullableFilter>;
  principalName?: Maybe<StringNullableFilter>;
  address?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  website?: Maybe<StringNullableFilter>;
  zip?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  country?: Maybe<CountryWhereInput>;
  language?: Maybe<StringFilter>;
  hasRemindersOn?: Maybe<BooleanFilter>;
  schoolAdmins?: Maybe<UserManyRelationFilter>;
  teachers?: Maybe<TeacherManyRelationFilter>;
  students?: Maybe<StudentManyRelationFilter>;
  folders?: Maybe<FolderManyRelationFilter>;
  credit?: Maybe<CreditWhereInput>;
  isIndividual?: Maybe<BooleanFilter>;
  crmContacts?: Maybe<CrmContactManyRelationFilter>;
  category?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<FloatNullableFilter>;
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<BooleanFilter>;
};

export type UserManyRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type TeacherManyRelationFilter = {
  every?: Maybe<TeacherWhereInput>;
  some?: Maybe<TeacherWhereInput>;
  none?: Maybe<TeacherWhereInput>;
};

export type StudentManyRelationFilter = {
  every?: Maybe<StudentWhereInput>;
  some?: Maybe<StudentWhereInput>;
  none?: Maybe<StudentWhereInput>;
};

export type FolderManyRelationFilter = {
  every?: Maybe<FolderWhereInput>;
  some?: Maybe<FolderWhereInput>;
  none?: Maybe<FolderWhereInput>;
};

export type CrmContactManyRelationFilter = {
  every?: Maybe<CrmContactWhereInput>;
  some?: Maybe<CrmContactWhereInput>;
  none?: Maybe<CrmContactWhereInput>;
};

export type SchoolOrderByInput = {
  id?: Maybe<OrderDirection>;
  code?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  email?: Maybe<OrderDirection>;
  testPrice?: Maybe<OrderDirection>;
  principalName?: Maybe<OrderDirection>;
  address?: Maybe<OrderDirection>;
  phone?: Maybe<OrderDirection>;
  website?: Maybe<OrderDirection>;
  zip?: Maybe<OrderDirection>;
  city?: Maybe<OrderDirection>;
  state?: Maybe<OrderDirection>;
  language?: Maybe<OrderDirection>;
  hasRemindersOn?: Maybe<OrderDirection>;
  isIndividual?: Maybe<OrderDirection>;
  category?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type SchoolUpdateInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  testPrice?: Maybe<Scalars['Float']>;
  principalName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldInput>;
  country?: Maybe<CountryRelateToOneForUpdateInput>;
  language?: Maybe<Scalars['String']>;
  hasRemindersOn?: Maybe<Scalars['Boolean']>;
  schoolAdmins?: Maybe<UserRelateToManyForUpdateInput>;
  teachers?: Maybe<TeacherRelateToManyForUpdateInput>;
  students?: Maybe<StudentRelateToManyForUpdateInput>;
  folders?: Maybe<FolderRelateToManyForUpdateInput>;
  credit?: Maybe<CreditRelateToOneForUpdateInput>;
  isIndividual?: Maybe<Scalars['Boolean']>;
  crmContacts?: Maybe<CrmContactRelateToManyForUpdateInput>;
  category?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FileFieldInput = {
  upload: Scalars['Upload'];
};


export type CountryRelateToOneForUpdateInput = {
  create?: Maybe<CountryCreateInput>;
  connect?: Maybe<CountryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UserRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  create?: Maybe<Array<UserCreateInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type TeacherRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<TeacherWhereUniqueInput>>;
  set?: Maybe<Array<TeacherWhereUniqueInput>>;
  create?: Maybe<Array<TeacherCreateInput>>;
  connect?: Maybe<Array<TeacherWhereUniqueInput>>;
};

export type StudentRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<StudentWhereUniqueInput>>;
  set?: Maybe<Array<StudentWhereUniqueInput>>;
  create?: Maybe<Array<StudentCreateInput>>;
  connect?: Maybe<Array<StudentWhereUniqueInput>>;
};

export type FolderRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<FolderWhereUniqueInput>>;
  set?: Maybe<Array<FolderWhereUniqueInput>>;
  create?: Maybe<Array<FolderCreateInput>>;
  connect?: Maybe<Array<FolderWhereUniqueInput>>;
};

export type CreditRelateToOneForUpdateInput = {
  create?: Maybe<CreditCreateInput>;
  connect?: Maybe<CreditWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type CrmContactRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<CrmContactWhereUniqueInput>>;
  set?: Maybe<Array<CrmContactWhereUniqueInput>>;
  create?: Maybe<Array<CrmContactCreateInput>>;
  connect?: Maybe<Array<CrmContactWhereUniqueInput>>;
};

export type SchoolUpdateArgs = {
  where: SchoolWhereUniqueInput;
  data: SchoolUpdateInput;
};

export type SchoolCreateInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  testPrice?: Maybe<Scalars['Float']>;
  principalName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldInput>;
  country?: Maybe<CountryRelateToOneForCreateInput>;
  language?: Maybe<Scalars['String']>;
  hasRemindersOn?: Maybe<Scalars['Boolean']>;
  schoolAdmins?: Maybe<UserRelateToManyForCreateInput>;
  teachers?: Maybe<TeacherRelateToManyForCreateInput>;
  students?: Maybe<StudentRelateToManyForCreateInput>;
  folders?: Maybe<FolderRelateToManyForCreateInput>;
  credit?: Maybe<CreditRelateToOneForCreateInput>;
  isIndividual?: Maybe<Scalars['Boolean']>;
  crmContacts?: Maybe<CrmContactRelateToManyForCreateInput>;
  category?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CountryRelateToOneForCreateInput = {
  create?: Maybe<CountryCreateInput>;
  connect?: Maybe<CountryWhereUniqueInput>;
};

export type UserRelateToManyForCreateInput = {
  create?: Maybe<Array<UserCreateInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type TeacherRelateToManyForCreateInput = {
  create?: Maybe<Array<TeacherCreateInput>>;
  connect?: Maybe<Array<TeacherWhereUniqueInput>>;
};

export type StudentRelateToManyForCreateInput = {
  create?: Maybe<Array<StudentCreateInput>>;
  connect?: Maybe<Array<StudentWhereUniqueInput>>;
};

export type FolderRelateToManyForCreateInput = {
  create?: Maybe<Array<FolderCreateInput>>;
  connect?: Maybe<Array<FolderWhereUniqueInput>>;
};

export type CreditRelateToOneForCreateInput = {
  create?: Maybe<CreditCreateInput>;
  connect?: Maybe<CreditWhereUniqueInput>;
};

export type CrmContactRelateToManyForCreateInput = {
  create?: Maybe<Array<CrmContactCreateInput>>;
  connect?: Maybe<Array<CrmContactWhereUniqueInput>>;
};

export type Sponsor = {
  __typename?: 'Sponsor';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldOutput>;
  country?: Maybe<Country>;
  link?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type SponsorWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type SponsorWhereInput = {
  AND?: Maybe<Array<SponsorWhereInput>>;
  OR?: Maybe<Array<SponsorWhereInput>>;
  NOT?: Maybe<Array<SponsorWhereInput>>;
  id?: Maybe<IdFilter>;
  name?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  country?: Maybe<CountryWhereInput>;
  link?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  from?: Maybe<DateTimeFilter>;
  to?: Maybe<DateTimeFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<DateTimeFilter>;
};

export type SponsorOrderByInput = {
  id?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  email?: Maybe<OrderDirection>;
  link?: Maybe<OrderDirection>;
  description?: Maybe<OrderDirection>;
  type?: Maybe<OrderDirection>;
  from?: Maybe<OrderDirection>;
  to?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type SponsorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldInput>;
  country?: Maybe<CountryRelateToOneForUpdateInput>;
  link?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type SponsorUpdateArgs = {
  where: SponsorWhereUniqueInput;
  data: SponsorUpdateInput;
};

export type SponsorCreateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<FileFieldInput>;
  country?: Maybe<CountryRelateToOneForCreateInput>;
  link?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type Student = {
  __typename?: 'Student';
  id: Scalars['ID'];
  user?: Maybe<User>;
  school?: Maybe<School>;
  folder?: Maybe<Folder>;
  countryModel?: Maybe<Country>;
  testAttempt?: Maybe<TestAttempt>;
  status?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  isArchived?: Maybe<Scalars['Boolean']>;
  videoViews?: Maybe<Array<VideoView>>;
  videoViewsCount?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type StudentVideoViewsArgs = {
  where?: VideoViewWhereInput;
  orderBy?: Array<VideoViewOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<VideoViewWhereUniqueInput>;
};


export type StudentVideoViewsCountArgs = {
  where?: VideoViewWhereInput;
};

export type StudentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type StudentWhereInput = {
  AND?: Maybe<Array<StudentWhereInput>>;
  OR?: Maybe<Array<StudentWhereInput>>;
  NOT?: Maybe<Array<StudentWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  school?: Maybe<SchoolWhereInput>;
  folder?: Maybe<FolderWhereInput>;
  countryModel?: Maybe<CountryWhereInput>;
  testAttempt?: Maybe<TestAttemptWhereInput>;
  status?: Maybe<StringNullableFilter>;
  payment?: Maybe<PaymentWhereInput>;
  isArchived?: Maybe<BooleanFilter>;
  videoViews?: Maybe<VideoViewManyRelationFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type VideoViewManyRelationFilter = {
  every?: Maybe<VideoViewWhereInput>;
  some?: Maybe<VideoViewWhereInput>;
  none?: Maybe<VideoViewWhereInput>;
};

export type StudentOrderByInput = {
  id?: Maybe<OrderDirection>;
  status?: Maybe<OrderDirection>;
  isArchived?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type StudentUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  school?: Maybe<SchoolRelateToOneForUpdateInput>;
  folder?: Maybe<FolderRelateToOneForUpdateInput>;
  countryModel?: Maybe<CountryRelateToOneForUpdateInput>;
  testAttempt?: Maybe<TestAttemptRelateToOneForUpdateInput>;
  status?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentRelateToOneForUpdateInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  videoViews?: Maybe<VideoViewRelateToManyForUpdateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type UserRelateToOneForUpdateInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type SchoolRelateToOneForUpdateInput = {
  create?: Maybe<SchoolCreateInput>;
  connect?: Maybe<SchoolWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type FolderRelateToOneForUpdateInput = {
  create?: Maybe<FolderCreateInput>;
  connect?: Maybe<FolderWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type PaymentRelateToOneForUpdateInput = {
  create?: Maybe<PaymentCreateInput>;
  connect?: Maybe<PaymentWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type VideoViewRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<VideoViewWhereUniqueInput>>;
  set?: Maybe<Array<VideoViewWhereUniqueInput>>;
  create?: Maybe<Array<VideoViewCreateInput>>;
  connect?: Maybe<Array<VideoViewWhereUniqueInput>>;
};

export type StudentUpdateArgs = {
  where: StudentWhereUniqueInput;
  data: StudentUpdateInput;
};

export type StudentCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  school?: Maybe<SchoolRelateToOneForCreateInput>;
  folder?: Maybe<FolderRelateToOneForCreateInput>;
  countryModel?: Maybe<CountryRelateToOneForCreateInput>;
  testAttempt?: Maybe<TestAttemptRelateToOneForCreateInput>;
  status?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentRelateToOneForCreateInput>;
  isArchived?: Maybe<Scalars['Boolean']>;
  videoViews?: Maybe<VideoViewRelateToManyForCreateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type UserRelateToOneForCreateInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type SchoolRelateToOneForCreateInput = {
  create?: Maybe<SchoolCreateInput>;
  connect?: Maybe<SchoolWhereUniqueInput>;
};

export type FolderRelateToOneForCreateInput = {
  create?: Maybe<FolderCreateInput>;
  connect?: Maybe<FolderWhereUniqueInput>;
};

export type PaymentRelateToOneForCreateInput = {
  create?: Maybe<PaymentCreateInput>;
  connect?: Maybe<PaymentWhereUniqueInput>;
};

export type VideoViewRelateToManyForCreateInput = {
  create?: Maybe<Array<VideoViewCreateInput>>;
  connect?: Maybe<Array<VideoViewWhereUniqueInput>>;
};

export type Teacher = {
  __typename?: 'Teacher';
  id: Scalars['ID'];
  user?: Maybe<User>;
  school?: Maybe<School>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TeacherWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TeacherWhereInput = {
  AND?: Maybe<Array<TeacherWhereInput>>;
  OR?: Maybe<Array<TeacherWhereInput>>;
  NOT?: Maybe<Array<TeacherWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  school?: Maybe<SchoolWhereInput>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type TeacherOrderByInput = {
  id?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type TeacherUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  school?: Maybe<SchoolRelateToOneForUpdateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TeacherUpdateArgs = {
  where: TeacherWhereUniqueInput;
  data: TeacherUpdateInput;
};

export type TeacherCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  school?: Maybe<SchoolRelateToOneForCreateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TestAttempt = {
  __typename?: 'TestAttempt';
  id: Scalars['ID'];
  actions?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  results?: Maybe<Scalars['JSON']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  reportName?: Maybe<Scalars['String']>;
  isReportPublished?: Maybe<Scalars['Boolean']>;
  isReportSent?: Maybe<Scalars['Boolean']>;
  interestInventoryStartTime?: Maybe<Scalars['DateTime']>;
  students?: Maybe<Array<Student>>;
  studentsCount?: Maybe<Scalars['Int']>;
  interestAnswers?: Maybe<Array<InterestAnswer>>;
  interestAnswersCount?: Maybe<Scalars['Int']>;
  feedbackResponse?: Maybe<Array<FeedbackResponse>>;
  feedbackResponseCount?: Maybe<Scalars['Int']>;
  reportPublishedAt?: Maybe<Scalars['DateTime']>;
  testCompletedAt?: Maybe<Scalars['DateTime']>;
  reportSentToQA?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type TestAttemptStudentsArgs = {
  where?: StudentWhereInput;
  orderBy?: Array<StudentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentWhereUniqueInput>;
};


export type TestAttemptStudentsCountArgs = {
  where?: StudentWhereInput;
};


export type TestAttemptInterestAnswersArgs = {
  where?: InterestAnswerWhereInput;
  orderBy?: Array<InterestAnswerOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestAnswerWhereUniqueInput>;
};


export type TestAttemptInterestAnswersCountArgs = {
  where?: InterestAnswerWhereInput;
};


export type TestAttemptFeedbackResponseArgs = {
  where?: FeedbackResponseWhereInput;
  orderBy?: Array<FeedbackResponseOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackResponseWhereUniqueInput>;
};


export type TestAttemptFeedbackResponseCountArgs = {
  where?: FeedbackResponseWhereInput;
};

export type TestAttemptWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TestAttemptWhereInput = {
  AND?: Maybe<Array<TestAttemptWhereInput>>;
  OR?: Maybe<Array<TestAttemptWhereInput>>;
  NOT?: Maybe<Array<TestAttemptWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  isCompleted?: Maybe<BooleanFilter>;
  reportName?: Maybe<StringNullableFilter>;
  isReportPublished?: Maybe<BooleanFilter>;
  isReportSent?: Maybe<BooleanFilter>;
  interestInventoryStartTime?: Maybe<DateTimeNullableFilter>;
  students?: Maybe<StudentManyRelationFilter>;
  interestAnswers?: Maybe<InterestAnswerManyRelationFilter>;
  feedbackResponse?: Maybe<FeedbackResponseManyRelationFilter>;
  reportPublishedAt?: Maybe<DateTimeNullableFilter>;
  testCompletedAt?: Maybe<DateTimeNullableFilter>;
  reportSentToQA?: Maybe<BooleanFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FeedbackResponseManyRelationFilter = {
  every?: Maybe<FeedbackResponseWhereInput>;
  some?: Maybe<FeedbackResponseWhereInput>;
  none?: Maybe<FeedbackResponseWhereInput>;
};

export type TestAttemptOrderByInput = {
  id?: Maybe<OrderDirection>;
  isCompleted?: Maybe<OrderDirection>;
  reportName?: Maybe<OrderDirection>;
  isReportPublished?: Maybe<OrderDirection>;
  isReportSent?: Maybe<OrderDirection>;
  interestInventoryStartTime?: Maybe<OrderDirection>;
  reportPublishedAt?: Maybe<OrderDirection>;
  testCompletedAt?: Maybe<OrderDirection>;
  reportSentToQA?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type TestAttemptUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  results?: Maybe<Scalars['JSON']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  reportName?: Maybe<Scalars['String']>;
  isReportPublished?: Maybe<Scalars['Boolean']>;
  isReportSent?: Maybe<Scalars['Boolean']>;
  interestInventoryStartTime?: Maybe<Scalars['DateTime']>;
  students?: Maybe<StudentRelateToManyForUpdateInput>;
  interestAnswers?: Maybe<InterestAnswerRelateToManyForUpdateInput>;
  feedbackResponse?: Maybe<FeedbackResponseRelateToManyForUpdateInput>;
  reportPublishedAt?: Maybe<Scalars['DateTime']>;
  testCompletedAt?: Maybe<Scalars['DateTime']>;
  reportSentToQA?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackResponseRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<FeedbackResponseWhereUniqueInput>>;
  set?: Maybe<Array<FeedbackResponseWhereUniqueInput>>;
  create?: Maybe<Array<FeedbackResponseCreateInput>>;
  connect?: Maybe<Array<FeedbackResponseWhereUniqueInput>>;
};

export type TestAttemptUpdateArgs = {
  where: TestAttemptWhereUniqueInput;
  data: TestAttemptUpdateInput;
};

export type TestAttemptCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  results?: Maybe<Scalars['JSON']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  reportName?: Maybe<Scalars['String']>;
  isReportPublished?: Maybe<Scalars['Boolean']>;
  isReportSent?: Maybe<Scalars['Boolean']>;
  interestInventoryStartTime?: Maybe<Scalars['DateTime']>;
  students?: Maybe<StudentRelateToManyForCreateInput>;
  interestAnswers?: Maybe<InterestAnswerRelateToManyForCreateInput>;
  feedbackResponse?: Maybe<FeedbackResponseRelateToManyForCreateInput>;
  reportPublishedAt?: Maybe<Scalars['DateTime']>;
  testCompletedAt?: Maybe<Scalars['DateTime']>;
  reportSentToQA?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackResponseRelateToManyForCreateInput = {
  create?: Maybe<Array<FeedbackResponseCreateInput>>;
  connect?: Maybe<Array<FeedbackResponseWhereUniqueInput>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['CalendarDay']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  password?: Maybe<PasswordState>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Student>>;
  studentsCount?: Maybe<Scalars['Int']>;
  teachers?: Maybe<Array<Teacher>>;
  teachersCount?: Maybe<Scalars['Int']>;
  testAttempts?: Maybe<Array<TestAttempt>>;
  testAttemptsCount?: Maybe<Scalars['Int']>;
  adminOfSchools?: Maybe<Array<School>>;
  adminOfSchoolsCount?: Maybe<Scalars['Int']>;
  crmContacts?: Maybe<Array<CrmContact>>;
  crmContactsCount?: Maybe<Scalars['Int']>;
  feedbackResponse?: Maybe<Array<FeedbackResponse>>;
  feedbackResponseCount?: Maybe<Scalars['Int']>;
  scheduledInvitation?: Maybe<Array<StudentScheduledInvitation>>;
  scheduledInvitationCount?: Maybe<Scalars['Int']>;
  firstReminder?: Maybe<Scalars['DateTime']>;
  secondReminder?: Maybe<Scalars['DateTime']>;
  thirdReminder?: Maybe<Scalars['DateTime']>;
  fourthReminder?: Maybe<Scalars['DateTime']>;
  fifthReminder?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type UserStudentsArgs = {
  where?: StudentWhereInput;
  orderBy?: Array<StudentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentWhereUniqueInput>;
};


export type UserStudentsCountArgs = {
  where?: StudentWhereInput;
};


export type UserTeachersArgs = {
  where?: TeacherWhereInput;
  orderBy?: Array<TeacherOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<TeacherWhereUniqueInput>;
};


export type UserTeachersCountArgs = {
  where?: TeacherWhereInput;
};


export type UserTestAttemptsArgs = {
  where?: TestAttemptWhereInput;
  orderBy?: Array<TestAttemptOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<TestAttemptWhereUniqueInput>;
};


export type UserTestAttemptsCountArgs = {
  where?: TestAttemptWhereInput;
};


export type UserAdminOfSchoolsArgs = {
  where?: SchoolWhereInput;
  orderBy?: Array<SchoolOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<SchoolWhereUniqueInput>;
};


export type UserAdminOfSchoolsCountArgs = {
  where?: SchoolWhereInput;
};


export type UserCrmContactsArgs = {
  where?: CrmContactWhereInput;
  orderBy?: Array<CrmContactOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CrmContactWhereUniqueInput>;
};


export type UserCrmContactsCountArgs = {
  where?: CrmContactWhereInput;
};


export type UserFeedbackResponseArgs = {
  where?: FeedbackResponseWhereInput;
  orderBy?: Array<FeedbackResponseOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackResponseWhereUniqueInput>;
};


export type UserFeedbackResponseCountArgs = {
  where?: FeedbackResponseWhereInput;
};


export type UserScheduledInvitationArgs = {
  where?: StudentScheduledInvitationWhereInput;
  orderBy?: Array<StudentScheduledInvitationOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentScheduledInvitationWhereUniqueInput>;
};


export type UserScheduledInvitationCountArgs = {
  where?: StudentScheduledInvitationWhereInput;
};


export type PasswordState = {
  __typename?: 'PasswordState';
  isSet: Scalars['Boolean'];
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<IdFilter>;
  email?: Maybe<StringFilter>;
  language?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  dateOfBirth?: Maybe<CalendarDayNullableFilter>;
  isAdmin?: Maybe<BooleanFilter>;
  isDeleted?: Maybe<BooleanFilter>;
  isConfirmed?: Maybe<BooleanFilter>;
  password?: Maybe<PasswordFilter>;
  resetPasswordToken?: Maybe<StringNullableFilter>;
  students?: Maybe<StudentManyRelationFilter>;
  teachers?: Maybe<TeacherManyRelationFilter>;
  testAttempts?: Maybe<TestAttemptManyRelationFilter>;
  adminOfSchools?: Maybe<SchoolManyRelationFilter>;
  crmContacts?: Maybe<CrmContactManyRelationFilter>;
  feedbackResponse?: Maybe<FeedbackResponseManyRelationFilter>;
  scheduledInvitation?: Maybe<StudentScheduledInvitationManyRelationFilter>;
  firstReminder?: Maybe<DateTimeNullableFilter>;
  secondReminder?: Maybe<DateTimeNullableFilter>;
  thirdReminder?: Maybe<DateTimeNullableFilter>;
  fourthReminder?: Maybe<DateTimeNullableFilter>;
  fifthReminder?: Maybe<DateTimeNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type CalendarDayNullableFilter = {
  equals?: Maybe<Scalars['CalendarDay']>;
  in?: Maybe<Array<Scalars['CalendarDay']>>;
  notIn?: Maybe<Array<Scalars['CalendarDay']>>;
  lt?: Maybe<Scalars['CalendarDay']>;
  lte?: Maybe<Scalars['CalendarDay']>;
  gt?: Maybe<Scalars['CalendarDay']>;
  gte?: Maybe<Scalars['CalendarDay']>;
  not?: Maybe<CalendarDayNullableFilter>;
};

export type PasswordFilter = {
  isSet: Scalars['Boolean'];
};

export type TestAttemptManyRelationFilter = {
  every?: Maybe<TestAttemptWhereInput>;
  some?: Maybe<TestAttemptWhereInput>;
  none?: Maybe<TestAttemptWhereInput>;
};

export type SchoolManyRelationFilter = {
  every?: Maybe<SchoolWhereInput>;
  some?: Maybe<SchoolWhereInput>;
  none?: Maybe<SchoolWhereInput>;
};

export type StudentScheduledInvitationManyRelationFilter = {
  every?: Maybe<StudentScheduledInvitationWhereInput>;
  some?: Maybe<StudentScheduledInvitationWhereInput>;
  none?: Maybe<StudentScheduledInvitationWhereInput>;
};

export type UserOrderByInput = {
  id?: Maybe<OrderDirection>;
  email?: Maybe<OrderDirection>;
  language?: Maybe<OrderDirection>;
  firstName?: Maybe<OrderDirection>;
  lastName?: Maybe<OrderDirection>;
  gender?: Maybe<OrderDirection>;
  dateOfBirth?: Maybe<OrderDirection>;
  isAdmin?: Maybe<OrderDirection>;
  isDeleted?: Maybe<OrderDirection>;
  isConfirmed?: Maybe<OrderDirection>;
  resetPasswordToken?: Maybe<OrderDirection>;
  firstReminder?: Maybe<OrderDirection>;
  secondReminder?: Maybe<OrderDirection>;
  thirdReminder?: Maybe<OrderDirection>;
  fourthReminder?: Maybe<OrderDirection>;
  fifthReminder?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['CalendarDay']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  students?: Maybe<StudentRelateToManyForUpdateInput>;
  teachers?: Maybe<TeacherRelateToManyForUpdateInput>;
  testAttempts?: Maybe<TestAttemptRelateToManyForUpdateInput>;
  adminOfSchools?: Maybe<SchoolRelateToManyForUpdateInput>;
  crmContacts?: Maybe<CrmContactRelateToManyForUpdateInput>;
  feedbackResponse?: Maybe<FeedbackResponseRelateToManyForUpdateInput>;
  scheduledInvitation?: Maybe<StudentScheduledInvitationRelateToManyForUpdateInput>;
  firstReminder?: Maybe<Scalars['DateTime']>;
  secondReminder?: Maybe<Scalars['DateTime']>;
  thirdReminder?: Maybe<Scalars['DateTime']>;
  fourthReminder?: Maybe<Scalars['DateTime']>;
  fifthReminder?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TestAttemptRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<TestAttemptWhereUniqueInput>>;
  set?: Maybe<Array<TestAttemptWhereUniqueInput>>;
  create?: Maybe<Array<TestAttemptCreateInput>>;
  connect?: Maybe<Array<TestAttemptWhereUniqueInput>>;
};

export type SchoolRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<SchoolWhereUniqueInput>>;
  set?: Maybe<Array<SchoolWhereUniqueInput>>;
  create?: Maybe<Array<SchoolCreateInput>>;
  connect?: Maybe<Array<SchoolWhereUniqueInput>>;
};

export type StudentScheduledInvitationRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<StudentScheduledInvitationWhereUniqueInput>>;
  set?: Maybe<Array<StudentScheduledInvitationWhereUniqueInput>>;
  create?: Maybe<Array<StudentScheduledInvitationCreateInput>>;
  connect?: Maybe<Array<StudentScheduledInvitationWhereUniqueInput>>;
};

export type UserUpdateArgs = {
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
};

export type UserCreateInput = {
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['CalendarDay']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  students?: Maybe<StudentRelateToManyForCreateInput>;
  teachers?: Maybe<TeacherRelateToManyForCreateInput>;
  testAttempts?: Maybe<TestAttemptRelateToManyForCreateInput>;
  adminOfSchools?: Maybe<SchoolRelateToManyForCreateInput>;
  crmContacts?: Maybe<CrmContactRelateToManyForCreateInput>;
  feedbackResponse?: Maybe<FeedbackResponseRelateToManyForCreateInput>;
  scheduledInvitation?: Maybe<StudentScheduledInvitationRelateToManyForCreateInput>;
  firstReminder?: Maybe<Scalars['DateTime']>;
  secondReminder?: Maybe<Scalars['DateTime']>;
  thirdReminder?: Maybe<Scalars['DateTime']>;
  fourthReminder?: Maybe<Scalars['DateTime']>;
  fifthReminder?: Maybe<Scalars['DateTime']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type TestAttemptRelateToManyForCreateInput = {
  create?: Maybe<Array<TestAttemptCreateInput>>;
  connect?: Maybe<Array<TestAttemptWhereUniqueInput>>;
};

export type SchoolRelateToManyForCreateInput = {
  create?: Maybe<Array<SchoolCreateInput>>;
  connect?: Maybe<Array<SchoolWhereUniqueInput>>;
};

export type StudentScheduledInvitationRelateToManyForCreateInput = {
  create?: Maybe<Array<StudentScheduledInvitationCreateInput>>;
  connect?: Maybe<Array<StudentScheduledInvitationWhereUniqueInput>>;
};

export type Credit = {
  __typename?: 'Credit';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
  payments?: Maybe<Array<Payment>>;
  paymentsCount?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type CreditPaymentsArgs = {
  where?: PaymentWhereInput;
  orderBy?: Array<PaymentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<PaymentWhereUniqueInput>;
};


export type CreditPaymentsCountArgs = {
  where?: PaymentWhereInput;
};

export type CreditWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CreditWhereInput = {
  AND?: Maybe<Array<CreditWhereInput>>;
  OR?: Maybe<Array<CreditWhereInput>>;
  NOT?: Maybe<Array<CreditWhereInput>>;
  id?: Maybe<IdFilter>;
  school?: Maybe<SchoolWhereInput>;
  payments?: Maybe<PaymentManyRelationFilter>;
  balance?: Maybe<IntNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type PaymentManyRelationFilter = {
  every?: Maybe<PaymentWhereInput>;
  some?: Maybe<PaymentWhereInput>;
  none?: Maybe<PaymentWhereInput>;
};

export type CreditOrderByInput = {
  id?: Maybe<OrderDirection>;
  balance?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type CreditUpdateInput = {
  school?: Maybe<SchoolRelateToOneForUpdateInput>;
  payments?: Maybe<PaymentRelateToManyForUpdateInput>;
  balance?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type PaymentRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  create?: Maybe<Array<PaymentCreateInput>>;
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
};

export type CreditUpdateArgs = {
  where: CreditWhereUniqueInput;
  data: CreditUpdateInput;
};

export type CreditCreateInput = {
  school?: Maybe<SchoolRelateToOneForCreateInput>;
  payments?: Maybe<PaymentRelateToManyForCreateInput>;
  balance?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type PaymentRelateToManyForCreateInput = {
  create?: Maybe<Array<PaymentCreateInput>>;
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  credit?: Maybe<Credit>;
  student?: Maybe<Student>;
  cost?: Maybe<Scalars['Int']>;
  testPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  isFreeCredit?: Maybe<Scalars['Boolean']>;
  isSponsorCredit?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PaymentWhereInput = {
  AND?: Maybe<Array<PaymentWhereInput>>;
  OR?: Maybe<Array<PaymentWhereInput>>;
  NOT?: Maybe<Array<PaymentWhereInput>>;
  id?: Maybe<IdFilter>;
  credit?: Maybe<CreditWhereInput>;
  student?: Maybe<StudentWhereInput>;
  cost?: Maybe<IntFilter>;
  testPrice?: Maybe<FloatNullableFilter>;
  status?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  isFreeCredit?: Maybe<BooleanFilter>;
  isSponsorCredit?: Maybe<BooleanFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type PaymentOrderByInput = {
  id?: Maybe<OrderDirection>;
  cost?: Maybe<OrderDirection>;
  testPrice?: Maybe<OrderDirection>;
  status?: Maybe<OrderDirection>;
  stripeId?: Maybe<OrderDirection>;
  isFreeCredit?: Maybe<OrderDirection>;
  isSponsorCredit?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type PaymentUpdateInput = {
  credit?: Maybe<CreditRelateToOneForUpdateInput>;
  student?: Maybe<StudentRelateToOneForUpdateInput>;
  cost?: Maybe<Scalars['Int']>;
  testPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  isFreeCredit?: Maybe<Scalars['Boolean']>;
  isSponsorCredit?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type StudentRelateToOneForUpdateInput = {
  create?: Maybe<StudentCreateInput>;
  connect?: Maybe<StudentWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type PaymentUpdateArgs = {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateInput;
};

export type PaymentCreateInput = {
  credit?: Maybe<CreditRelateToOneForCreateInput>;
  student?: Maybe<StudentRelateToOneForCreateInput>;
  cost?: Maybe<Scalars['Int']>;
  testPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  isFreeCredit?: Maybe<Scalars['Boolean']>;
  isSponsorCredit?: Maybe<Scalars['Boolean']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type StudentRelateToOneForCreateInput = {
  create?: Maybe<StudentCreateInput>;
  connect?: Maybe<StudentWhereUniqueInput>;
};

export type CrmContact = {
  __typename?: 'CrmContact';
  id: Scalars['ID'];
  user?: Maybe<User>;
  school?: Maybe<School>;
  crmContactId?: Maybe<Scalars['String']>;
  crmOrganizationId?: Maybe<Scalars['String']>;
  crmRole?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CrmContactWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CrmContactWhereInput = {
  AND?: Maybe<Array<CrmContactWhereInput>>;
  OR?: Maybe<Array<CrmContactWhereInput>>;
  NOT?: Maybe<Array<CrmContactWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  school?: Maybe<SchoolWhereInput>;
  crmContactId?: Maybe<StringFilter>;
  crmOrganizationId?: Maybe<StringFilter>;
  crmRole?: Maybe<StringFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type CrmContactOrderByInput = {
  id?: Maybe<OrderDirection>;
  crmContactId?: Maybe<OrderDirection>;
  crmOrganizationId?: Maybe<OrderDirection>;
  crmRole?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type CrmContactUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  school?: Maybe<SchoolRelateToOneForUpdateInput>;
  crmContactId?: Maybe<Scalars['String']>;
  crmOrganizationId?: Maybe<Scalars['String']>;
  crmRole?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type CrmContactUpdateArgs = {
  where: CrmContactWhereUniqueInput;
  data: CrmContactUpdateInput;
};

export type CrmContactCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  school?: Maybe<SchoolRelateToOneForCreateInput>;
  crmContactId?: Maybe<Scalars['String']>;
  crmOrganizationId?: Maybe<Scalars['String']>;
  crmRole?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isTutorial?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Country>;
  views?: Maybe<Array<VideoView>>;
  viewsCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};


export type VideoViewsArgs = {
  where?: VideoViewWhereInput;
  orderBy?: Array<VideoViewOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<VideoViewWhereUniqueInput>;
};


export type VideoViewsCountArgs = {
  where?: VideoViewWhereInput;
};

export type VideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type VideoWhereInput = {
  AND?: Maybe<Array<VideoWhereInput>>;
  OR?: Maybe<Array<VideoWhereInput>>;
  NOT?: Maybe<Array<VideoWhereInput>>;
  id?: Maybe<IdFilter>;
  title?: Maybe<StringFilter>;
  url?: Maybe<StringFilter>;
  isTutorial?: Maybe<BooleanFilter>;
  country?: Maybe<CountryWhereInput>;
  views?: Maybe<VideoViewManyRelationFilter>;
};

export type VideoOrderByInput = {
  id?: Maybe<OrderDirection>;
  title?: Maybe<OrderDirection>;
  url?: Maybe<OrderDirection>;
  isTutorial?: Maybe<OrderDirection>;
  description?: Maybe<OrderDirection>;
};

export type VideoUpdateInput = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isTutorial?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryRelateToOneForUpdateInput>;
  views?: Maybe<VideoViewRelateToManyForUpdateInput>;
  description?: Maybe<Scalars['String']>;
};

export type VideoUpdateArgs = {
  where: VideoWhereUniqueInput;
  data: VideoUpdateInput;
};

export type VideoCreateInput = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  isTutorial?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryRelateToOneForCreateInput>;
  views?: Maybe<VideoViewRelateToManyForCreateInput>;
  description?: Maybe<Scalars['String']>;
};

export type VideoView = {
  __typename?: 'VideoView';
  id: Scalars['ID'];
  student?: Maybe<Student>;
  video?: Maybe<Video>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type VideoViewWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type VideoViewWhereInput = {
  AND?: Maybe<Array<VideoViewWhereInput>>;
  OR?: Maybe<Array<VideoViewWhereInput>>;
  NOT?: Maybe<Array<VideoViewWhereInput>>;
  id?: Maybe<IdFilter>;
  student?: Maybe<StudentWhereInput>;
  video?: Maybe<VideoWhereInput>;
  isCompleted?: Maybe<BooleanFilter>;
};

export type VideoViewOrderByInput = {
  id?: Maybe<OrderDirection>;
  isCompleted?: Maybe<OrderDirection>;
};

export type VideoViewUpdateInput = {
  student?: Maybe<StudentRelateToOneForUpdateInput>;
  video?: Maybe<VideoRelateToOneForUpdateInput>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type VideoRelateToOneForUpdateInput = {
  create?: Maybe<VideoCreateInput>;
  connect?: Maybe<VideoWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type VideoViewUpdateArgs = {
  where: VideoViewWhereUniqueInput;
  data: VideoViewUpdateInput;
};

export type VideoViewCreateInput = {
  student?: Maybe<StudentRelateToOneForCreateInput>;
  video?: Maybe<VideoRelateToOneForCreateInput>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type VideoRelateToOneForCreateInput = {
  create?: Maybe<VideoCreateInput>;
  connect?: Maybe<VideoWhereUniqueInput>;
};

export type ReportLink = {
  __typename?: 'ReportLink';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryOrder?: Maybe<Scalars['Int']>;
  isInternational?: Maybe<Scalars['Boolean']>;
  internationalOrder?: Maybe<Scalars['Int']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  onlineOrder?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type ReportLinkWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
};

export type ReportLinkWhereInput = {
  AND?: Maybe<Array<ReportLinkWhereInput>>;
  OR?: Maybe<Array<ReportLinkWhereInput>>;
  NOT?: Maybe<Array<ReportLinkWhereInput>>;
  id?: Maybe<IdFilter>;
  label?: Maybe<StringFilter>;
  url?: Maybe<StringFilter>;
  country?: Maybe<CountryWhereInput>;
  countryOrder?: Maybe<IntNullableFilter>;
  isInternational?: Maybe<BooleanFilter>;
  internationalOrder?: Maybe<IntNullableFilter>;
  isOnline?: Maybe<BooleanFilter>;
  onlineOrder?: Maybe<IntNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type ReportLinkOrderByInput = {
  id?: Maybe<OrderDirection>;
  label?: Maybe<OrderDirection>;
  url?: Maybe<OrderDirection>;
  countryOrder?: Maybe<OrderDirection>;
  isInternational?: Maybe<OrderDirection>;
  internationalOrder?: Maybe<OrderDirection>;
  isOnline?: Maybe<OrderDirection>;
  onlineOrder?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type ReportLinkUpdateInput = {
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  country?: Maybe<CountryRelateToOneForUpdateInput>;
  countryOrder?: Maybe<Scalars['Int']>;
  isInternational?: Maybe<Scalars['Boolean']>;
  internationalOrder?: Maybe<Scalars['Int']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  onlineOrder?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type ReportLinkUpdateArgs = {
  where: ReportLinkWhereUniqueInput;
  data: ReportLinkUpdateInput;
};

export type ReportLinkCreateInput = {
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  country?: Maybe<CountryRelateToOneForCreateInput>;
  countryOrder?: Maybe<Scalars['Int']>;
  isInternational?: Maybe<Scalars['Boolean']>;
  internationalOrder?: Maybe<Scalars['Int']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  onlineOrder?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackForm = {
  __typename?: 'FeedbackForm';
  id: Scalars['ID'];
  formId?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  modal?: Maybe<FeedbackFormModal>;
  responses?: Maybe<Array<FeedbackResponse>>;
  responsesCount?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type FeedbackFormResponsesArgs = {
  where?: FeedbackResponseWhereInput;
  orderBy?: Array<FeedbackResponseOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackResponseWhereUniqueInput>;
};


export type FeedbackFormResponsesCountArgs = {
  where?: FeedbackResponseWhereInput;
};

export type FeedbackFormWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  formId?: Maybe<Scalars['String']>;
};

export type FeedbackFormWhereInput = {
  AND?: Maybe<Array<FeedbackFormWhereInput>>;
  OR?: Maybe<Array<FeedbackFormWhereInput>>;
  NOT?: Maybe<Array<FeedbackFormWhereInput>>;
  id?: Maybe<IdFilter>;
  formId?: Maybe<StringFilter>;
  formType?: Maybe<StringFilter>;
  country?: Maybe<CountryWhereInput>;
  modal?: Maybe<FeedbackFormModalWhereInput>;
  responses?: Maybe<FeedbackResponseManyRelationFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FeedbackFormOrderByInput = {
  id?: Maybe<OrderDirection>;
  formId?: Maybe<OrderDirection>;
  formType?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type FeedbackFormUpdateInput = {
  formId?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  country?: Maybe<CountryRelateToOneForUpdateInput>;
  modal?: Maybe<FeedbackFormModalRelateToOneForUpdateInput>;
  responses?: Maybe<FeedbackResponseRelateToManyForUpdateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormModalRelateToOneForUpdateInput = {
  create?: Maybe<FeedbackFormModalCreateInput>;
  connect?: Maybe<FeedbackFormModalWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type FeedbackFormUpdateArgs = {
  where: FeedbackFormWhereUniqueInput;
  data: FeedbackFormUpdateInput;
};

export type FeedbackFormCreateInput = {
  formId?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  country?: Maybe<CountryRelateToOneForCreateInput>;
  modal?: Maybe<FeedbackFormModalRelateToOneForCreateInput>;
  responses?: Maybe<FeedbackResponseRelateToManyForCreateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormModalRelateToOneForCreateInput = {
  create?: Maybe<FeedbackFormModalCreateInput>;
  connect?: Maybe<FeedbackFormModalWhereUniqueInput>;
};

export type FeedbackResponse = {
  __typename?: 'FeedbackResponse';
  id: Scalars['ID'];
  user?: Maybe<User>;
  testAttempt?: Maybe<TestAttempt>;
  form?: Maybe<FeedbackForm>;
  responseId?: Maybe<Scalars['String']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  remindersSent?: Maybe<Scalars['Int']>;
  lastReminderSentAt?: Maybe<Scalars['DateTime']>;
  token?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackResponseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type FeedbackResponseWhereInput = {
  AND?: Maybe<Array<FeedbackResponseWhereInput>>;
  OR?: Maybe<Array<FeedbackResponseWhereInput>>;
  NOT?: Maybe<Array<FeedbackResponseWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  testAttempt?: Maybe<TestAttemptWhereInput>;
  form?: Maybe<FeedbackFormWhereInput>;
  responseId?: Maybe<StringNullableFilter>;
  isSubmitted?: Maybe<BooleanFilter>;
  remindersSent?: Maybe<IntNullableFilter>;
  lastReminderSentAt?: Maybe<DateTimeNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FeedbackResponseOrderByInput = {
  id?: Maybe<OrderDirection>;
  responseId?: Maybe<OrderDirection>;
  isSubmitted?: Maybe<OrderDirection>;
  remindersSent?: Maybe<OrderDirection>;
  lastReminderSentAt?: Maybe<OrderDirection>;
  token?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type FeedbackResponseUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  testAttempt?: Maybe<TestAttemptRelateToOneForUpdateInput>;
  form?: Maybe<FeedbackFormRelateToOneForUpdateInput>;
  responseId?: Maybe<Scalars['String']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  remindersSent?: Maybe<Scalars['Int']>;
  lastReminderSentAt?: Maybe<Scalars['DateTime']>;
  token?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormRelateToOneForUpdateInput = {
  create?: Maybe<FeedbackFormCreateInput>;
  connect?: Maybe<FeedbackFormWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type FeedbackResponseUpdateArgs = {
  where: FeedbackResponseWhereUniqueInput;
  data: FeedbackResponseUpdateInput;
};

export type FeedbackResponseCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  testAttempt?: Maybe<TestAttemptRelateToOneForCreateInput>;
  form?: Maybe<FeedbackFormRelateToOneForCreateInput>;
  responseId?: Maybe<Scalars['String']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  remindersSent?: Maybe<Scalars['Int']>;
  lastReminderSentAt?: Maybe<Scalars['DateTime']>;
  token?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormRelateToOneForCreateInput = {
  create?: Maybe<FeedbackFormCreateInput>;
  connect?: Maybe<FeedbackFormWhereUniqueInput>;
};

export type FeedbackFormModal = {
  __typename?: 'FeedbackFormModal';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalContent?: Maybe<Scalars['String']>;
  feedbackForms?: Maybe<Array<FeedbackForm>>;
  feedbackFormsCount?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type FeedbackFormModalFeedbackFormsArgs = {
  where?: FeedbackFormWhereInput;
  orderBy?: Array<FeedbackFormOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackFormWhereUniqueInput>;
};


export type FeedbackFormModalFeedbackFormsCountArgs = {
  where?: FeedbackFormWhereInput;
};

export type FeedbackFormModalWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type FeedbackFormModalWhereInput = {
  AND?: Maybe<Array<FeedbackFormModalWhereInput>>;
  OR?: Maybe<Array<FeedbackFormModalWhereInput>>;
  NOT?: Maybe<Array<FeedbackFormModalWhereInput>>;
  id?: Maybe<IdFilter>;
  label?: Maybe<StringFilter>;
  modalTitle?: Maybe<StringFilter>;
  modalContent?: Maybe<StringFilter>;
  feedbackForms?: Maybe<FeedbackFormManyRelationFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FeedbackFormManyRelationFilter = {
  every?: Maybe<FeedbackFormWhereInput>;
  some?: Maybe<FeedbackFormWhereInput>;
  none?: Maybe<FeedbackFormWhereInput>;
};

export type FeedbackFormModalOrderByInput = {
  id?: Maybe<OrderDirection>;
  label?: Maybe<OrderDirection>;
  modalTitle?: Maybe<OrderDirection>;
  modalContent?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type FeedbackFormModalUpdateInput = {
  label?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalContent?: Maybe<Scalars['String']>;
  feedbackForms?: Maybe<FeedbackFormRelateToManyForUpdateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormRelateToManyForUpdateInput = {
  disconnect?: Maybe<Array<FeedbackFormWhereUniqueInput>>;
  set?: Maybe<Array<FeedbackFormWhereUniqueInput>>;
  create?: Maybe<Array<FeedbackFormCreateInput>>;
  connect?: Maybe<Array<FeedbackFormWhereUniqueInput>>;
};

export type FeedbackFormModalUpdateArgs = {
  where: FeedbackFormModalWhereUniqueInput;
  data: FeedbackFormModalUpdateInput;
};

export type FeedbackFormModalCreateInput = {
  label?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalContent?: Maybe<Scalars['String']>;
  feedbackForms?: Maybe<FeedbackFormRelateToManyForCreateInput>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FeedbackFormRelateToManyForCreateInput = {
  create?: Maybe<Array<FeedbackFormCreateInput>>;
  connect?: Maybe<Array<FeedbackFormWhereUniqueInput>>;
};

export type ReportSender = {
  __typename?: 'ReportSender';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  reportsSent?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type ReportSenderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ReportSenderWhereInput = {
  AND?: Maybe<Array<ReportSenderWhereInput>>;
  OR?: Maybe<Array<ReportSenderWhereInput>>;
  NOT?: Maybe<Array<ReportSenderWhereInput>>;
  id?: Maybe<IdFilter>;
  email?: Maybe<StringFilter>;
  frequency?: Maybe<IntFilter>;
  reportsSent?: Maybe<IntNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type ReportSenderOrderByInput = {
  id?: Maybe<OrderDirection>;
  email?: Maybe<OrderDirection>;
  frequency?: Maybe<OrderDirection>;
  reportsSent?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type ReportSenderUpdateInput = {
  email?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  reportsSent?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type ReportSenderUpdateArgs = {
  where: ReportSenderWhereUniqueInput;
  data: ReportSenderUpdateInput;
};

export type ReportSenderCreateInput = {
  email?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['Int']>;
  reportsSent?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type StudentScheduledInvitation = {
  __typename?: 'StudentScheduledInvitation';
  id: Scalars['ID'];
  user?: Maybe<User>;
  invitationDate?: Maybe<Scalars['DateTime']>;
  isSent?: Maybe<Scalars['Boolean']>;
  userExists?: Maybe<Scalars['Boolean']>;
  inviterEmail?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type StudentScheduledInvitationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type StudentScheduledInvitationWhereInput = {
  AND?: Maybe<Array<StudentScheduledInvitationWhereInput>>;
  OR?: Maybe<Array<StudentScheduledInvitationWhereInput>>;
  NOT?: Maybe<Array<StudentScheduledInvitationWhereInput>>;
  id?: Maybe<IdFilter>;
  user?: Maybe<UserWhereInput>;
  invitationDate?: Maybe<DateTimeFilter>;
  isSent?: Maybe<BooleanFilter>;
  userExists?: Maybe<BooleanFilter>;
  inviterEmail?: Maybe<StringNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type StudentScheduledInvitationOrderByInput = {
  id?: Maybe<OrderDirection>;
  invitationDate?: Maybe<OrderDirection>;
  isSent?: Maybe<OrderDirection>;
  userExists?: Maybe<OrderDirection>;
  inviterEmail?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type StudentScheduledInvitationUpdateInput = {
  user?: Maybe<UserRelateToOneForUpdateInput>;
  invitationDate?: Maybe<Scalars['DateTime']>;
  isSent?: Maybe<Scalars['Boolean']>;
  userExists?: Maybe<Scalars['Boolean']>;
  inviterEmail?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type StudentScheduledInvitationUpdateArgs = {
  where: StudentScheduledInvitationWhereUniqueInput;
  data: StudentScheduledInvitationUpdateInput;
};

export type StudentScheduledInvitationCreateInput = {
  user?: Maybe<UserRelateToOneForCreateInput>;
  invitationDate?: Maybe<Scalars['DateTime']>;
  isSent?: Maybe<Scalars['Boolean']>;
  userExists?: Maybe<Scalars['Boolean']>;
  inviterEmail?: Maybe<Scalars['String']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Student>>;
  studentsCount?: Maybe<Scalars['Int']>;
  school?: Maybe<School>;
  countryModel?: Maybe<Country>;
  numberOfStudents?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type FolderStudentsArgs = {
  where?: StudentWhereInput;
  orderBy?: Array<StudentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentWhereUniqueInput>;
};


export type FolderStudentsCountArgs = {
  where?: StudentWhereInput;
};

export type FolderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type FolderWhereInput = {
  AND?: Maybe<Array<FolderWhereInput>>;
  OR?: Maybe<Array<FolderWhereInput>>;
  NOT?: Maybe<Array<FolderWhereInput>>;
  id?: Maybe<IdFilter>;
  name?: Maybe<StringFilter>;
  students?: Maybe<StudentManyRelationFilter>;
  school?: Maybe<SchoolWhereInput>;
  countryModel?: Maybe<CountryWhereInput>;
  numberOfStudents?: Maybe<IntNullableFilter>;
  updatedAt_utc?: Maybe<DateTimeNullableFilter>;
  createdAt_utc?: Maybe<DateTimeNullableFilter>;
};

export type FolderOrderByInput = {
  id?: Maybe<OrderDirection>;
  name?: Maybe<OrderDirection>;
  numberOfStudents?: Maybe<OrderDirection>;
  updatedAt_utc?: Maybe<OrderDirection>;
  createdAt_utc?: Maybe<OrderDirection>;
};

export type FolderUpdateInput = {
  name?: Maybe<Scalars['String']>;
  students?: Maybe<StudentRelateToManyForUpdateInput>;
  school?: Maybe<SchoolRelateToOneForUpdateInput>;
  countryModel?: Maybe<CountryRelateToOneForUpdateInput>;
  numberOfStudents?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};

export type FolderUpdateArgs = {
  where: FolderWhereUniqueInput;
  data: FolderUpdateInput;
};

export type FolderCreateInput = {
  name?: Maybe<Scalars['String']>;
  students?: Maybe<StudentRelateToManyForCreateInput>;
  school?: Maybe<SchoolRelateToOneForCreateInput>;
  countryModel?: Maybe<CountryRelateToOneForCreateInput>;
  numberOfStudents?: Maybe<Scalars['Int']>;
  updatedAt_utc?: Maybe<Scalars['DateTime']>;
  createdAt_utc?: Maybe<Scalars['DateTime']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  createCareer?: Maybe<Career>;
  createCareers?: Maybe<Array<Maybe<Career>>>;
  updateCareer?: Maybe<Career>;
  updateCareers?: Maybe<Array<Maybe<Career>>>;
  deleteCareer?: Maybe<Career>;
  deleteCareers?: Maybe<Array<Maybe<Career>>>;
  createCountry?: Maybe<Country>;
  createCountries?: Maybe<Array<Maybe<Country>>>;
  updateCountry?: Maybe<Country>;
  updateCountries?: Maybe<Array<Maybe<Country>>>;
  deleteCountry?: Maybe<Country>;
  deleteCountries?: Maybe<Array<Maybe<Country>>>;
  createEmailTemplate?: Maybe<EmailTemplate>;
  createEmailTemplates?: Maybe<Array<Maybe<EmailTemplate>>>;
  updateEmailTemplate?: Maybe<EmailTemplate>;
  updateEmailTemplates?: Maybe<Array<Maybe<EmailTemplate>>>;
  deleteEmailTemplate?: Maybe<EmailTemplate>;
  deleteEmailTemplates?: Maybe<Array<Maybe<EmailTemplate>>>;
  createInterestAnswer?: Maybe<InterestAnswer>;
  createInterestAnswers?: Maybe<Array<Maybe<InterestAnswer>>>;
  updateInterestAnswer?: Maybe<InterestAnswer>;
  updateInterestAnswers?: Maybe<Array<Maybe<InterestAnswer>>>;
  deleteInterestAnswer?: Maybe<InterestAnswer>;
  deleteInterestAnswers?: Maybe<Array<Maybe<InterestAnswer>>>;
  createInterestQuestion?: Maybe<InterestQuestion>;
  createInterestQuestions?: Maybe<Array<Maybe<InterestQuestion>>>;
  updateInterestQuestion?: Maybe<InterestQuestion>;
  updateInterestQuestions?: Maybe<Array<Maybe<InterestQuestion>>>;
  deleteInterestQuestion?: Maybe<InterestQuestion>;
  deleteInterestQuestions?: Maybe<Array<Maybe<InterestQuestion>>>;
  createInterestQuestionVariant?: Maybe<InterestQuestionVariant>;
  createInterestQuestionVariants?: Maybe<Array<Maybe<InterestQuestionVariant>>>;
  updateInterestQuestionVariant?: Maybe<InterestQuestionVariant>;
  updateInterestQuestionVariants?: Maybe<Array<Maybe<InterestQuestionVariant>>>;
  deleteInterestQuestionVariant?: Maybe<InterestQuestionVariant>;
  deleteInterestQuestionVariants?: Maybe<Array<Maybe<InterestQuestionVariant>>>;
  createJobCategory?: Maybe<JobCategory>;
  createJobCategories?: Maybe<Array<Maybe<JobCategory>>>;
  updateJobCategory?: Maybe<JobCategory>;
  updateJobCategories?: Maybe<Array<Maybe<JobCategory>>>;
  deleteJobCategory?: Maybe<JobCategory>;
  deleteJobCategories?: Maybe<Array<Maybe<JobCategory>>>;
  createSchool?: Maybe<School>;
  createSchools?: Maybe<Array<Maybe<School>>>;
  updateSchool?: Maybe<School>;
  updateSchools?: Maybe<Array<Maybe<School>>>;
  deleteSchool?: Maybe<School>;
  deleteSchools?: Maybe<Array<Maybe<School>>>;
  createSponsor?: Maybe<Sponsor>;
  createSponsors?: Maybe<Array<Maybe<Sponsor>>>;
  updateSponsor?: Maybe<Sponsor>;
  updateSponsors?: Maybe<Array<Maybe<Sponsor>>>;
  deleteSponsor?: Maybe<Sponsor>;
  deleteSponsors?: Maybe<Array<Maybe<Sponsor>>>;
  createStudent?: Maybe<Student>;
  createStudents?: Maybe<Array<Maybe<Student>>>;
  updateStudent?: Maybe<Student>;
  updateStudents?: Maybe<Array<Maybe<Student>>>;
  deleteStudent?: Maybe<Student>;
  deleteStudents?: Maybe<Array<Maybe<Student>>>;
  createTeacher?: Maybe<Teacher>;
  createTeachers?: Maybe<Array<Maybe<Teacher>>>;
  updateTeacher?: Maybe<Teacher>;
  updateTeachers?: Maybe<Array<Maybe<Teacher>>>;
  deleteTeacher?: Maybe<Teacher>;
  deleteTeachers?: Maybe<Array<Maybe<Teacher>>>;
  createTestAttempt?: Maybe<TestAttempt>;
  createTestAttempts?: Maybe<Array<Maybe<TestAttempt>>>;
  updateTestAttempt?: Maybe<TestAttempt>;
  updateTestAttempts?: Maybe<Array<Maybe<TestAttempt>>>;
  deleteTestAttempt?: Maybe<TestAttempt>;
  deleteTestAttempts?: Maybe<Array<Maybe<TestAttempt>>>;
  createUser?: Maybe<User>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  updateUser?: Maybe<User>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
  deleteUser?: Maybe<User>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  createCredit?: Maybe<Credit>;
  createCredits?: Maybe<Array<Maybe<Credit>>>;
  updateCredit?: Maybe<Credit>;
  updateCredits?: Maybe<Array<Maybe<Credit>>>;
  deleteCredit?: Maybe<Credit>;
  deleteCredits?: Maybe<Array<Maybe<Credit>>>;
  createPayment?: Maybe<Payment>;
  createPayments?: Maybe<Array<Maybe<Payment>>>;
  updatePayment?: Maybe<Payment>;
  updatePayments?: Maybe<Array<Maybe<Payment>>>;
  deletePayment?: Maybe<Payment>;
  deletePayments?: Maybe<Array<Maybe<Payment>>>;
  createCrmContact?: Maybe<CrmContact>;
  createCrmContacts?: Maybe<Array<Maybe<CrmContact>>>;
  updateCrmContact?: Maybe<CrmContact>;
  updateCrmContacts?: Maybe<Array<Maybe<CrmContact>>>;
  deleteCrmContact?: Maybe<CrmContact>;
  deleteCrmContacts?: Maybe<Array<Maybe<CrmContact>>>;
  createVideo?: Maybe<Video>;
  createVideos?: Maybe<Array<Maybe<Video>>>;
  updateVideo?: Maybe<Video>;
  updateVideos?: Maybe<Array<Maybe<Video>>>;
  deleteVideo?: Maybe<Video>;
  deleteVideos?: Maybe<Array<Maybe<Video>>>;
  createVideoView?: Maybe<VideoView>;
  createVideoViews?: Maybe<Array<Maybe<VideoView>>>;
  updateVideoView?: Maybe<VideoView>;
  updateVideoViews?: Maybe<Array<Maybe<VideoView>>>;
  deleteVideoView?: Maybe<VideoView>;
  deleteVideoViews?: Maybe<Array<Maybe<VideoView>>>;
  createReportLink?: Maybe<ReportLink>;
  createReportLinks?: Maybe<Array<Maybe<ReportLink>>>;
  updateReportLink?: Maybe<ReportLink>;
  updateReportLinks?: Maybe<Array<Maybe<ReportLink>>>;
  deleteReportLink?: Maybe<ReportLink>;
  deleteReportLinks?: Maybe<Array<Maybe<ReportLink>>>;
  createFeedbackForm?: Maybe<FeedbackForm>;
  createFeedbackForms?: Maybe<Array<Maybe<FeedbackForm>>>;
  updateFeedbackForm?: Maybe<FeedbackForm>;
  updateFeedbackForms?: Maybe<Array<Maybe<FeedbackForm>>>;
  deleteFeedbackForm?: Maybe<FeedbackForm>;
  deleteFeedbackForms?: Maybe<Array<Maybe<FeedbackForm>>>;
  createFeedbackResponse?: Maybe<FeedbackResponse>;
  createFeedbackResponses?: Maybe<Array<Maybe<FeedbackResponse>>>;
  updateFeedbackResponse?: Maybe<FeedbackResponse>;
  updateFeedbackResponses?: Maybe<Array<Maybe<FeedbackResponse>>>;
  deleteFeedbackResponse?: Maybe<FeedbackResponse>;
  deleteFeedbackResponses?: Maybe<Array<Maybe<FeedbackResponse>>>;
  createFeedbackFormModal?: Maybe<FeedbackFormModal>;
  createFeedbackFormModals?: Maybe<Array<Maybe<FeedbackFormModal>>>;
  updateFeedbackFormModal?: Maybe<FeedbackFormModal>;
  updateFeedbackFormModals?: Maybe<Array<Maybe<FeedbackFormModal>>>;
  deleteFeedbackFormModal?: Maybe<FeedbackFormModal>;
  deleteFeedbackFormModals?: Maybe<Array<Maybe<FeedbackFormModal>>>;
  createReportSender?: Maybe<ReportSender>;
  createReportSenders?: Maybe<Array<Maybe<ReportSender>>>;
  updateReportSender?: Maybe<ReportSender>;
  updateReportSenders?: Maybe<Array<Maybe<ReportSender>>>;
  deleteReportSender?: Maybe<ReportSender>;
  deleteReportSenders?: Maybe<Array<Maybe<ReportSender>>>;
  createStudentScheduledInvitation?: Maybe<StudentScheduledInvitation>;
  createStudentScheduledInvitations?: Maybe<Array<Maybe<StudentScheduledInvitation>>>;
  updateStudentScheduledInvitation?: Maybe<StudentScheduledInvitation>;
  updateStudentScheduledInvitations?: Maybe<Array<Maybe<StudentScheduledInvitation>>>;
  deleteStudentScheduledInvitation?: Maybe<StudentScheduledInvitation>;
  deleteStudentScheduledInvitations?: Maybe<Array<Maybe<StudentScheduledInvitation>>>;
  createFolder?: Maybe<Folder>;
  createFolders?: Maybe<Array<Maybe<Folder>>>;
  updateFolder?: Maybe<Folder>;
  updateFolders?: Maybe<Array<Maybe<Folder>>>;
  deleteFolder?: Maybe<Folder>;
  deleteFolders?: Maybe<Array<Maybe<Folder>>>;
  endSession: Scalars['Boolean'];
  authenticateUserWithPassword?: Maybe<UserAuthenticationWithPasswordResult>;
  createInitialUser: UserAuthenticationWithPasswordSuccess;
  importCountries?: Maybe<VoidOutput>;
  importCareers?: Maybe<VoidOutput>;
  startInterestInventoryTest?: Maybe<VoidOutput>;
  storeInterestAnswer?: Maybe<VoidOutput>;
  completeInterestInventoryTest?: Maybe<VoidOutput>;
  resetInterestInventory?: Maybe<VoidOutput>;
  resetManyInterestInventories?: Maybe<ResetManyTestsResponse>;
  createSubtractPayment?: Maybe<VoidOutput>;
  registerIndividualSchool?: Maybe<VoidOutput>;
  enableIndividualTest?: Maybe<VoidOutput>;
  inviteStudent?: Maybe<InvitedStudents>;
  inviteStudents?: Maybe<InvitedStudents>;
  inviteTeacher?: Maybe<InvitedTeachers>;
  inviteTeachers?: Maybe<InvitedTeachers>;
  resendTeacherOrSchoolAdminInvitation?: Maybe<VoidOutput>;
  inviteSchoolAdmin?: Maybe<InvitedSchoolAdmins>;
  inviteSchoolAdmins?: Maybe<InvitedSchoolAdmins>;
  resendStudentInvitation?: Maybe<VoidOutput>;
  resetPassword?: Maybe<VoidOutput>;
  revokeInvitation?: Maybe<Array<Maybe<Scalars['Int']>>>;
  remindPassword?: Maybe<VoidOutput>;
  createPaymentSession?: Maybe<Scalars['String']>;
  sendEmailToStudents?: Maybe<VoidOutput>;
  sendInvoiceRequest?: Maybe<VoidOutput>;
  removeAccount?: Maybe<VoidOutput>;
  removeSchoolAdmin?: Maybe<VoidOutput>;
  generateReport?: Maybe<VoidOutput>;
  syncContactsWithOnePageCRM?: Maybe<SyncContactsWithOnePageCrmOutput>;
  unarchiveStudents?: Maybe<Array<Student>>;
  archiveStudents?: Maybe<Array<Student>>;
  startVideoWatching?: Maybe<VoidOutput>;
  completeVideoView?: Maybe<VoidOutput>;
  closeTutorialVideo?: Maybe<VoidOutput>;
  regenerateNotExistingReports?: Maybe<RegeneratedOutput>;
  generateDemoSchool?: Maybe<VoidOutput>;
  reorderReportLinks?: Maybe<VoidOutput>;
  formSubmitByAuthedUser?: Maybe<VoidOutput>;
  formSubmitByToken?: Maybe<VoidOutput>;
  regenerateInvitationLink?: Maybe<RegenerateLinkResponse>;
  updateAuthenticatedUser?: Maybe<User>;
  updateScheduledInvitation?: Maybe<VoidOutput>;
  createFolderInSchool?: Maybe<VoidOutput>;
  updateFolderInSchool?: Maybe<VoidOutput>;
  removeFolderInSchool?: Maybe<VoidOutput>;
  assignStudentsToFolder?: Maybe<VoidOutput>;
  removeStudentsFromFolder?: Maybe<VoidOutput>;
};


export type MutationCreateCareerArgs = {
  data: CareerCreateInput;
};


export type MutationCreateCareersArgs = {
  data: Array<CareerCreateInput>;
};


export type MutationUpdateCareerArgs = {
  where: CareerWhereUniqueInput;
  data: CareerUpdateInput;
};


export type MutationUpdateCareersArgs = {
  data: Array<CareerUpdateArgs>;
};


export type MutationDeleteCareerArgs = {
  where: CareerWhereUniqueInput;
};


export type MutationDeleteCareersArgs = {
  where: Array<CareerWhereUniqueInput>;
};


export type MutationCreateCountryArgs = {
  data: CountryCreateInput;
};


export type MutationCreateCountriesArgs = {
  data: Array<CountryCreateInput>;
};


export type MutationUpdateCountryArgs = {
  where: CountryWhereUniqueInput;
  data: CountryUpdateInput;
};


export type MutationUpdateCountriesArgs = {
  data: Array<CountryUpdateArgs>;
};


export type MutationDeleteCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type MutationDeleteCountriesArgs = {
  where: Array<CountryWhereUniqueInput>;
};


export type MutationCreateEmailTemplateArgs = {
  data: EmailTemplateCreateInput;
};


export type MutationCreateEmailTemplatesArgs = {
  data: Array<EmailTemplateCreateInput>;
};


export type MutationUpdateEmailTemplateArgs = {
  where: EmailTemplateWhereUniqueInput;
  data: EmailTemplateUpdateInput;
};


export type MutationUpdateEmailTemplatesArgs = {
  data: Array<EmailTemplateUpdateArgs>;
};


export type MutationDeleteEmailTemplateArgs = {
  where: EmailTemplateWhereUniqueInput;
};


export type MutationDeleteEmailTemplatesArgs = {
  where: Array<EmailTemplateWhereUniqueInput>;
};


export type MutationCreateInterestAnswerArgs = {
  data: InterestAnswerCreateInput;
};


export type MutationCreateInterestAnswersArgs = {
  data: Array<InterestAnswerCreateInput>;
};


export type MutationUpdateInterestAnswerArgs = {
  where: InterestAnswerWhereUniqueInput;
  data: InterestAnswerUpdateInput;
};


export type MutationUpdateInterestAnswersArgs = {
  data: Array<InterestAnswerUpdateArgs>;
};


export type MutationDeleteInterestAnswerArgs = {
  where: InterestAnswerWhereUniqueInput;
};


export type MutationDeleteInterestAnswersArgs = {
  where: Array<InterestAnswerWhereUniqueInput>;
};


export type MutationCreateInterestQuestionArgs = {
  data: InterestQuestionCreateInput;
};


export type MutationCreateInterestQuestionsArgs = {
  data: Array<InterestQuestionCreateInput>;
};


export type MutationUpdateInterestQuestionArgs = {
  where: InterestQuestionWhereUniqueInput;
  data: InterestQuestionUpdateInput;
};


export type MutationUpdateInterestQuestionsArgs = {
  data: Array<InterestQuestionUpdateArgs>;
};


export type MutationDeleteInterestQuestionArgs = {
  where: InterestQuestionWhereUniqueInput;
};


export type MutationDeleteInterestQuestionsArgs = {
  where: Array<InterestQuestionWhereUniqueInput>;
};


export type MutationCreateInterestQuestionVariantArgs = {
  data: InterestQuestionVariantCreateInput;
};


export type MutationCreateInterestQuestionVariantsArgs = {
  data: Array<InterestQuestionVariantCreateInput>;
};


export type MutationUpdateInterestQuestionVariantArgs = {
  where: InterestQuestionVariantWhereUniqueInput;
  data: InterestQuestionVariantUpdateInput;
};


export type MutationUpdateInterestQuestionVariantsArgs = {
  data: Array<InterestQuestionVariantUpdateArgs>;
};


export type MutationDeleteInterestQuestionVariantArgs = {
  where: InterestQuestionVariantWhereUniqueInput;
};


export type MutationDeleteInterestQuestionVariantsArgs = {
  where: Array<InterestQuestionVariantWhereUniqueInput>;
};


export type MutationCreateJobCategoryArgs = {
  data: JobCategoryCreateInput;
};


export type MutationCreateJobCategoriesArgs = {
  data: Array<JobCategoryCreateInput>;
};


export type MutationUpdateJobCategoryArgs = {
  where: JobCategoryWhereUniqueInput;
  data: JobCategoryUpdateInput;
};


export type MutationUpdateJobCategoriesArgs = {
  data: Array<JobCategoryUpdateArgs>;
};


export type MutationDeleteJobCategoryArgs = {
  where: JobCategoryWhereUniqueInput;
};


export type MutationDeleteJobCategoriesArgs = {
  where: Array<JobCategoryWhereUniqueInput>;
};


export type MutationCreateSchoolArgs = {
  data: SchoolCreateInput;
};


export type MutationCreateSchoolsArgs = {
  data: Array<SchoolCreateInput>;
};


export type MutationUpdateSchoolArgs = {
  where: SchoolWhereUniqueInput;
  data: SchoolUpdateInput;
};


export type MutationUpdateSchoolsArgs = {
  data: Array<SchoolUpdateArgs>;
};


export type MutationDeleteSchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type MutationDeleteSchoolsArgs = {
  where: Array<SchoolWhereUniqueInput>;
};


export type MutationCreateSponsorArgs = {
  data: SponsorCreateInput;
};


export type MutationCreateSponsorsArgs = {
  data: Array<SponsorCreateInput>;
};


export type MutationUpdateSponsorArgs = {
  where: SponsorWhereUniqueInput;
  data: SponsorUpdateInput;
};


export type MutationUpdateSponsorsArgs = {
  data: Array<SponsorUpdateArgs>;
};


export type MutationDeleteSponsorArgs = {
  where: SponsorWhereUniqueInput;
};


export type MutationDeleteSponsorsArgs = {
  where: Array<SponsorWhereUniqueInput>;
};


export type MutationCreateStudentArgs = {
  data: StudentCreateInput;
};


export type MutationCreateStudentsArgs = {
  data: Array<StudentCreateInput>;
};


export type MutationUpdateStudentArgs = {
  where: StudentWhereUniqueInput;
  data: StudentUpdateInput;
};


export type MutationUpdateStudentsArgs = {
  data: Array<StudentUpdateArgs>;
};


export type MutationDeleteStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type MutationDeleteStudentsArgs = {
  where: Array<StudentWhereUniqueInput>;
};


export type MutationCreateTeacherArgs = {
  data: TeacherCreateInput;
};


export type MutationCreateTeachersArgs = {
  data: Array<TeacherCreateInput>;
};


export type MutationUpdateTeacherArgs = {
  where: TeacherWhereUniqueInput;
  data: TeacherUpdateInput;
};


export type MutationUpdateTeachersArgs = {
  data: Array<TeacherUpdateArgs>;
};


export type MutationDeleteTeacherArgs = {
  where: TeacherWhereUniqueInput;
};


export type MutationDeleteTeachersArgs = {
  where: Array<TeacherWhereUniqueInput>;
};


export type MutationCreateTestAttemptArgs = {
  data: TestAttemptCreateInput;
};


export type MutationCreateTestAttemptsArgs = {
  data: Array<TestAttemptCreateInput>;
};


export type MutationUpdateTestAttemptArgs = {
  where: TestAttemptWhereUniqueInput;
  data: TestAttemptUpdateInput;
};


export type MutationUpdateTestAttemptsArgs = {
  data: Array<TestAttemptUpdateArgs>;
};


export type MutationDeleteTestAttemptArgs = {
  where: TestAttemptWhereUniqueInput;
};


export type MutationDeleteTestAttemptsArgs = {
  where: Array<TestAttemptWhereUniqueInput>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUsersArgs = {
  data: Array<UserCreateInput>;
};


export type MutationUpdateUserArgs = {
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
};


export type MutationUpdateUsersArgs = {
  data: Array<UserUpdateArgs>;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUsersArgs = {
  where: Array<UserWhereUniqueInput>;
};


export type MutationCreateCreditArgs = {
  data: CreditCreateInput;
};


export type MutationCreateCreditsArgs = {
  data: Array<CreditCreateInput>;
};


export type MutationUpdateCreditArgs = {
  where: CreditWhereUniqueInput;
  data: CreditUpdateInput;
};


export type MutationUpdateCreditsArgs = {
  data: Array<CreditUpdateArgs>;
};


export type MutationDeleteCreditArgs = {
  where: CreditWhereUniqueInput;
};


export type MutationDeleteCreditsArgs = {
  where: Array<CreditWhereUniqueInput>;
};


export type MutationCreatePaymentArgs = {
  data: PaymentCreateInput;
};


export type MutationCreatePaymentsArgs = {
  data: Array<PaymentCreateInput>;
};


export type MutationUpdatePaymentArgs = {
  where: PaymentWhereUniqueInput;
  data: PaymentUpdateInput;
};


export type MutationUpdatePaymentsArgs = {
  data: Array<PaymentUpdateArgs>;
};


export type MutationDeletePaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type MutationDeletePaymentsArgs = {
  where: Array<PaymentWhereUniqueInput>;
};


export type MutationCreateCrmContactArgs = {
  data: CrmContactCreateInput;
};


export type MutationCreateCrmContactsArgs = {
  data: Array<CrmContactCreateInput>;
};


export type MutationUpdateCrmContactArgs = {
  where: CrmContactWhereUniqueInput;
  data: CrmContactUpdateInput;
};


export type MutationUpdateCrmContactsArgs = {
  data: Array<CrmContactUpdateArgs>;
};


export type MutationDeleteCrmContactArgs = {
  where: CrmContactWhereUniqueInput;
};


export type MutationDeleteCrmContactsArgs = {
  where: Array<CrmContactWhereUniqueInput>;
};


export type MutationCreateVideoArgs = {
  data: VideoCreateInput;
};


export type MutationCreateVideosArgs = {
  data: Array<VideoCreateInput>;
};


export type MutationUpdateVideoArgs = {
  where: VideoWhereUniqueInput;
  data: VideoUpdateInput;
};


export type MutationUpdateVideosArgs = {
  data: Array<VideoUpdateArgs>;
};


export type MutationDeleteVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type MutationDeleteVideosArgs = {
  where: Array<VideoWhereUniqueInput>;
};


export type MutationCreateVideoViewArgs = {
  data: VideoViewCreateInput;
};


export type MutationCreateVideoViewsArgs = {
  data: Array<VideoViewCreateInput>;
};


export type MutationUpdateVideoViewArgs = {
  where: VideoViewWhereUniqueInput;
  data: VideoViewUpdateInput;
};


export type MutationUpdateVideoViewsArgs = {
  data: Array<VideoViewUpdateArgs>;
};


export type MutationDeleteVideoViewArgs = {
  where: VideoViewWhereUniqueInput;
};


export type MutationDeleteVideoViewsArgs = {
  where: Array<VideoViewWhereUniqueInput>;
};


export type MutationCreateReportLinkArgs = {
  data: ReportLinkCreateInput;
};


export type MutationCreateReportLinksArgs = {
  data: Array<ReportLinkCreateInput>;
};


export type MutationUpdateReportLinkArgs = {
  where: ReportLinkWhereUniqueInput;
  data: ReportLinkUpdateInput;
};


export type MutationUpdateReportLinksArgs = {
  data: Array<ReportLinkUpdateArgs>;
};


export type MutationDeleteReportLinkArgs = {
  where: ReportLinkWhereUniqueInput;
};


export type MutationDeleteReportLinksArgs = {
  where: Array<ReportLinkWhereUniqueInput>;
};


export type MutationCreateFeedbackFormArgs = {
  data: FeedbackFormCreateInput;
};


export type MutationCreateFeedbackFormsArgs = {
  data: Array<FeedbackFormCreateInput>;
};


export type MutationUpdateFeedbackFormArgs = {
  where: FeedbackFormWhereUniqueInput;
  data: FeedbackFormUpdateInput;
};


export type MutationUpdateFeedbackFormsArgs = {
  data: Array<FeedbackFormUpdateArgs>;
};


export type MutationDeleteFeedbackFormArgs = {
  where: FeedbackFormWhereUniqueInput;
};


export type MutationDeleteFeedbackFormsArgs = {
  where: Array<FeedbackFormWhereUniqueInput>;
};


export type MutationCreateFeedbackResponseArgs = {
  data: FeedbackResponseCreateInput;
};


export type MutationCreateFeedbackResponsesArgs = {
  data: Array<FeedbackResponseCreateInput>;
};


export type MutationUpdateFeedbackResponseArgs = {
  where: FeedbackResponseWhereUniqueInput;
  data: FeedbackResponseUpdateInput;
};


export type MutationUpdateFeedbackResponsesArgs = {
  data: Array<FeedbackResponseUpdateArgs>;
};


export type MutationDeleteFeedbackResponseArgs = {
  where: FeedbackResponseWhereUniqueInput;
};


export type MutationDeleteFeedbackResponsesArgs = {
  where: Array<FeedbackResponseWhereUniqueInput>;
};


export type MutationCreateFeedbackFormModalArgs = {
  data: FeedbackFormModalCreateInput;
};


export type MutationCreateFeedbackFormModalsArgs = {
  data: Array<FeedbackFormModalCreateInput>;
};


export type MutationUpdateFeedbackFormModalArgs = {
  where: FeedbackFormModalWhereUniqueInput;
  data: FeedbackFormModalUpdateInput;
};


export type MutationUpdateFeedbackFormModalsArgs = {
  data: Array<FeedbackFormModalUpdateArgs>;
};


export type MutationDeleteFeedbackFormModalArgs = {
  where: FeedbackFormModalWhereUniqueInput;
};


export type MutationDeleteFeedbackFormModalsArgs = {
  where: Array<FeedbackFormModalWhereUniqueInput>;
};


export type MutationCreateReportSenderArgs = {
  data: ReportSenderCreateInput;
};


export type MutationCreateReportSendersArgs = {
  data: Array<ReportSenderCreateInput>;
};


export type MutationUpdateReportSenderArgs = {
  where: ReportSenderWhereUniqueInput;
  data: ReportSenderUpdateInput;
};


export type MutationUpdateReportSendersArgs = {
  data: Array<ReportSenderUpdateArgs>;
};


export type MutationDeleteReportSenderArgs = {
  where: ReportSenderWhereUniqueInput;
};


export type MutationDeleteReportSendersArgs = {
  where: Array<ReportSenderWhereUniqueInput>;
};


export type MutationCreateStudentScheduledInvitationArgs = {
  data: StudentScheduledInvitationCreateInput;
};


export type MutationCreateStudentScheduledInvitationsArgs = {
  data: Array<StudentScheduledInvitationCreateInput>;
};


export type MutationUpdateStudentScheduledInvitationArgs = {
  where: StudentScheduledInvitationWhereUniqueInput;
  data: StudentScheduledInvitationUpdateInput;
};


export type MutationUpdateStudentScheduledInvitationsArgs = {
  data: Array<StudentScheduledInvitationUpdateArgs>;
};


export type MutationDeleteStudentScheduledInvitationArgs = {
  where: StudentScheduledInvitationWhereUniqueInput;
};


export type MutationDeleteStudentScheduledInvitationsArgs = {
  where: Array<StudentScheduledInvitationWhereUniqueInput>;
};


export type MutationCreateFolderArgs = {
  data: FolderCreateInput;
};


export type MutationCreateFoldersArgs = {
  data: Array<FolderCreateInput>;
};


export type MutationUpdateFolderArgs = {
  where: FolderWhereUniqueInput;
  data: FolderUpdateInput;
};


export type MutationUpdateFoldersArgs = {
  data: Array<FolderUpdateArgs>;
};


export type MutationDeleteFolderArgs = {
  where: FolderWhereUniqueInput;
};


export type MutationDeleteFoldersArgs = {
  where: Array<FolderWhereUniqueInput>;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateInitialUserArgs = {
  data: CreateInitialUserInput;
};


export type MutationImportCountriesArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationImportCareersArgs = {
  data?: Maybe<Array<Maybe<ImportCareersInput>>>;
};


export type MutationStartInterestInventoryTestArgs = {
  testId: Scalars['Int'];
};


export type MutationStoreInterestAnswerArgs = {
  testId: Scalars['Int'];
  interestQuestionId: Scalars['Int'];
  answer: Scalars['Int'];
};


export type MutationCompleteInterestInventoryTestArgs = {
  testId: Scalars['Int'];
};


export type MutationResetInterestInventoryArgs = {
  testId: Scalars['Int'];
};


export type MutationResetManyInterestInventoriesArgs = {
  testAttemptsIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationCreateSubtractPaymentArgs = {
  data?: Maybe<CreateSubtractPaymentInput>;
};


export type MutationRegisterIndividualSchoolArgs = {
  data: IndividualSchoolInviteInput;
};


export type MutationEnableIndividualTestArgs = {
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
};


export type MutationInviteStudentArgs = {
  data: UserInviteInput;
  schoolId: Scalars['Int'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  invitationDate?: Maybe<Scalars['Float']>;
};


export type MutationInviteStudentsArgs = {
  data?: Maybe<Array<UserInviteInput>>;
  schoolId: Scalars['Int'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  invitationDate?: Maybe<Scalars['Float']>;
};


export type MutationInviteTeacherArgs = {
  data: UserInviteInput;
  schoolId: Scalars['Int'];
};


export type MutationInviteTeachersArgs = {
  data?: Maybe<Array<UserInviteInput>>;
  schoolId: Scalars['Int'];
};


export type MutationResendTeacherOrSchoolAdminInvitationArgs = {
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
};


export type MutationInviteSchoolAdminArgs = {
  data: UserInviteInput;
  schoolId: Scalars['Int'];
};


export type MutationInviteSchoolAdminsArgs = {
  data?: Maybe<Array<UserInviteInput>>;
  schoolId: Scalars['Int'];
};


export type MutationResendStudentInvitationArgs = {
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationRevokeInvitationArgs = {
  userIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
};


export type MutationRemindPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePaymentSessionArgs = {
  creditId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type MutationSendEmailToStudentsArgs = {
  addReport?: Maybe<Scalars['Boolean']>;
  emailTitle: Scalars['String'];
  emailContent: Scalars['String'];
  schoolId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type MutationSendInvoiceRequestArgs = {
  schoolId: Scalars['Int'];
  userId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type MutationRemoveAccountArgs = {
  userId: Scalars['Int'];
};


export type MutationRemoveSchoolAdminArgs = {
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
};


export type MutationGenerateReportArgs = {
  testId: Scalars['Int'];
};


export type MutationUnarchiveStudentsArgs = {
  studentIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
};


export type MutationArchiveStudentsArgs = {
  studentIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
};


export type MutationStartVideoWatchingArgs = {
  videoId: Scalars['Int'];
};


export type MutationCompleteVideoViewArgs = {
  videoViewId: Scalars['Int'];
};


export type MutationCloseTutorialVideoArgs = {
  videoId: Scalars['Int'];
};


export type MutationReorderReportLinksArgs = {
  orderType: Scalars['String'];
  links?: Maybe<Array<Maybe<LinkToReorder>>>;
};


export type MutationFormSubmitByAuthedUserArgs = {
  formId: Scalars['String'];
  responseId: Scalars['String'];
  testAttemptId?: Maybe<Scalars['Int']>;
};


export type MutationFormSubmitByTokenArgs = {
  token: Scalars['String'];
  responseId: Scalars['String'];
};


export type MutationRegenerateInvitationLinkArgs = {
  token: Scalars['String'];
};


export type MutationUpdateAuthenticatedUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderType>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateScheduledInvitationArgs = {
  invitationId: Scalars['Int'];
  schoolId: Scalars['Int'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  invitationDate?: Maybe<Scalars['Float']>;
};


export type MutationCreateFolderInSchoolArgs = {
  schoolId: Scalars['Int'];
  name: Scalars['String'];
  studentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  countryId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateFolderInSchoolArgs = {
  id: Scalars['Int'];
  schoolId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
};


export type MutationRemoveFolderInSchoolArgs = {
  id: Scalars['Int'];
  schoolId: Scalars['Int'];
};


export type MutationAssignStudentsToFolderArgs = {
  folderId: Scalars['Int'];
  schoolId: Scalars['Int'];
  studentIds: Array<Scalars['Int']>;
};


export type MutationRemoveStudentsFromFolderArgs = {
  folderId: Scalars['Int'];
  schoolId: Scalars['Int'];
  studentIds: Array<Scalars['Int']>;
};

export type UserAuthenticationWithPasswordResult = UserAuthenticationWithPasswordSuccess | UserAuthenticationWithPasswordFailure;

export type UserAuthenticationWithPasswordSuccess = {
  __typename?: 'UserAuthenticationWithPasswordSuccess';
  sessionToken: Scalars['String'];
  item: User;
};

export type UserAuthenticationWithPasswordFailure = {
  __typename?: 'UserAuthenticationWithPasswordFailure';
  message: Scalars['String'];
};

export type CreateInitialUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  careers?: Maybe<Array<Career>>;
  career?: Maybe<Career>;
  careersCount?: Maybe<Scalars['Int']>;
  countries?: Maybe<Array<Country>>;
  country?: Maybe<Country>;
  countriesCount?: Maybe<Scalars['Int']>;
  emailTemplates?: Maybe<Array<EmailTemplate>>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplatesCount?: Maybe<Scalars['Int']>;
  interestAnswers?: Maybe<Array<InterestAnswer>>;
  interestAnswer?: Maybe<InterestAnswer>;
  interestAnswersCount?: Maybe<Scalars['Int']>;
  interestQuestions?: Maybe<Array<InterestQuestion>>;
  interestQuestion?: Maybe<InterestQuestion>;
  interestQuestionsCount?: Maybe<Scalars['Int']>;
  interestQuestionVariants?: Maybe<Array<InterestQuestionVariant>>;
  interestQuestionVariant?: Maybe<InterestQuestionVariant>;
  interestQuestionVariantsCount?: Maybe<Scalars['Int']>;
  jobCategories?: Maybe<Array<JobCategory>>;
  jobCategory?: Maybe<JobCategory>;
  jobCategoriesCount?: Maybe<Scalars['Int']>;
  schools?: Maybe<Array<School>>;
  school?: Maybe<School>;
  schoolsCount?: Maybe<Scalars['Int']>;
  sponsors?: Maybe<Array<Sponsor>>;
  sponsor?: Maybe<Sponsor>;
  sponsorsCount?: Maybe<Scalars['Int']>;
  students?: Maybe<Array<Student>>;
  student?: Maybe<Student>;
  studentsCount?: Maybe<Scalars['Int']>;
  teachers?: Maybe<Array<Teacher>>;
  teacher?: Maybe<Teacher>;
  teachersCount?: Maybe<Scalars['Int']>;
  testAttempts?: Maybe<Array<TestAttempt>>;
  testAttempt?: Maybe<TestAttempt>;
  testAttemptsCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<User>>;
  user?: Maybe<User>;
  usersCount?: Maybe<Scalars['Int']>;
  credits?: Maybe<Array<Credit>>;
  credit?: Maybe<Credit>;
  creditsCount?: Maybe<Scalars['Int']>;
  payments?: Maybe<Array<Payment>>;
  payment?: Maybe<Payment>;
  paymentsCount?: Maybe<Scalars['Int']>;
  crmContacts?: Maybe<Array<CrmContact>>;
  crmContact?: Maybe<CrmContact>;
  crmContactsCount?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Video>>;
  video?: Maybe<Video>;
  videosCount?: Maybe<Scalars['Int']>;
  videoViews?: Maybe<Array<VideoView>>;
  videoView?: Maybe<VideoView>;
  videoViewsCount?: Maybe<Scalars['Int']>;
  reportLinks?: Maybe<Array<ReportLink>>;
  reportLink?: Maybe<ReportLink>;
  reportLinksCount?: Maybe<Scalars['Int']>;
  feedbackForms?: Maybe<Array<FeedbackForm>>;
  feedbackForm?: Maybe<FeedbackForm>;
  feedbackFormsCount?: Maybe<Scalars['Int']>;
  feedbackResponses?: Maybe<Array<FeedbackResponse>>;
  feedbackResponse?: Maybe<FeedbackResponse>;
  feedbackResponsesCount?: Maybe<Scalars['Int']>;
  feedbackFormModals?: Maybe<Array<FeedbackFormModal>>;
  feedbackFormModal?: Maybe<FeedbackFormModal>;
  feedbackFormModalsCount?: Maybe<Scalars['Int']>;
  reportSenders?: Maybe<Array<ReportSender>>;
  reportSender?: Maybe<ReportSender>;
  reportSendersCount?: Maybe<Scalars['Int']>;
  studentScheduledInvitations?: Maybe<Array<StudentScheduledInvitation>>;
  studentScheduledInvitation?: Maybe<StudentScheduledInvitation>;
  studentScheduledInvitationsCount?: Maybe<Scalars['Int']>;
  folders?: Maybe<Array<Folder>>;
  folder?: Maybe<Folder>;
  foldersCount?: Maybe<Scalars['Int']>;
  keystone: KeystoneMeta;
  authenticatedItem?: Maybe<AuthenticatedItem>;
  allStudentsSorted?: Maybe<Array<Student>>;
  allReportNotSentStudentsMeta: StudentsMeta;
  allArchivedStudents?: Maybe<Array<Student>>;
  allArchivedStudentsMeta: StudentsMeta;
  allArchivedStudentsYears?: Maybe<Array<Scalars['Int']>>;
  findVideosWithViews?: Maybe<Array<Video>>;
  findVideoWithViews: Video;
  findFeedbackFormWithResponse?: Maybe<FeedbackFormResponse>;
  findFeedbackResponseByToken?: Maybe<FindByTokenResponse>;
};


export type QueryCareersArgs = {
  where?: CareerWhereInput;
  orderBy?: Array<CareerOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CareerWhereUniqueInput>;
};


export type QueryCareerArgs = {
  where: CareerWhereUniqueInput;
};


export type QueryCareersCountArgs = {
  where?: CareerWhereInput;
};


export type QueryCountriesArgs = {
  where?: CountryWhereInput;
  orderBy?: Array<CountryOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CountryWhereUniqueInput>;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCountriesCountArgs = {
  where?: CountryWhereInput;
};


export type QueryEmailTemplatesArgs = {
  where?: EmailTemplateWhereInput;
  orderBy?: Array<EmailTemplateOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<EmailTemplateWhereUniqueInput>;
};


export type QueryEmailTemplateArgs = {
  where: EmailTemplateWhereUniqueInput;
};


export type QueryEmailTemplatesCountArgs = {
  where?: EmailTemplateWhereInput;
};


export type QueryInterestAnswersArgs = {
  where?: InterestAnswerWhereInput;
  orderBy?: Array<InterestAnswerOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestAnswerWhereUniqueInput>;
};


export type QueryInterestAnswerArgs = {
  where: InterestAnswerWhereUniqueInput;
};


export type QueryInterestAnswersCountArgs = {
  where?: InterestAnswerWhereInput;
};


export type QueryInterestQuestionsArgs = {
  where?: InterestQuestionWhereInput;
  orderBy?: Array<InterestQuestionOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestQuestionWhereUniqueInput>;
};


export type QueryInterestQuestionArgs = {
  where: InterestQuestionWhereUniqueInput;
};


export type QueryInterestQuestionsCountArgs = {
  where?: InterestQuestionWhereInput;
};


export type QueryInterestQuestionVariantsArgs = {
  where?: InterestQuestionVariantWhereInput;
  orderBy?: Array<InterestQuestionVariantOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<InterestQuestionVariantWhereUniqueInput>;
};


export type QueryInterestQuestionVariantArgs = {
  where: InterestQuestionVariantWhereUniqueInput;
};


export type QueryInterestQuestionVariantsCountArgs = {
  where?: InterestQuestionVariantWhereInput;
};


export type QueryJobCategoriesArgs = {
  where?: JobCategoryWhereInput;
  orderBy?: Array<JobCategoryOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<JobCategoryWhereUniqueInput>;
};


export type QueryJobCategoryArgs = {
  where: JobCategoryWhereUniqueInput;
};


export type QueryJobCategoriesCountArgs = {
  where?: JobCategoryWhereInput;
};


export type QuerySchoolsArgs = {
  where?: SchoolWhereInput;
  orderBy?: Array<SchoolOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<SchoolWhereUniqueInput>;
};


export type QuerySchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type QuerySchoolsCountArgs = {
  where?: SchoolWhereInput;
};


export type QuerySponsorsArgs = {
  where?: SponsorWhereInput;
  orderBy?: Array<SponsorOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<SponsorWhereUniqueInput>;
};


export type QuerySponsorArgs = {
  where: SponsorWhereUniqueInput;
};


export type QuerySponsorsCountArgs = {
  where?: SponsorWhereInput;
};


export type QueryStudentsArgs = {
  where?: StudentWhereInput;
  orderBy?: Array<StudentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentWhereUniqueInput>;
};


export type QueryStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type QueryStudentsCountArgs = {
  where?: StudentWhereInput;
};


export type QueryTeachersArgs = {
  where?: TeacherWhereInput;
  orderBy?: Array<TeacherOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<TeacherWhereUniqueInput>;
};


export type QueryTeacherArgs = {
  where: TeacherWhereUniqueInput;
};


export type QueryTeachersCountArgs = {
  where?: TeacherWhereInput;
};


export type QueryTestAttemptsArgs = {
  where?: TestAttemptWhereInput;
  orderBy?: Array<TestAttemptOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<TestAttemptWhereUniqueInput>;
};


export type QueryTestAttemptArgs = {
  where: TestAttemptWhereUniqueInput;
};


export type QueryTestAttemptsCountArgs = {
  where?: TestAttemptWhereInput;
};


export type QueryUsersArgs = {
  where?: UserWhereInput;
  orderBy?: Array<UserOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<UserWhereUniqueInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersCountArgs = {
  where?: UserWhereInput;
};


export type QueryCreditsArgs = {
  where?: CreditWhereInput;
  orderBy?: Array<CreditOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CreditWhereUniqueInput>;
};


export type QueryCreditArgs = {
  where: CreditWhereUniqueInput;
};


export type QueryCreditsCountArgs = {
  where?: CreditWhereInput;
};


export type QueryPaymentsArgs = {
  where?: PaymentWhereInput;
  orderBy?: Array<PaymentOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<PaymentWhereUniqueInput>;
};


export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type QueryPaymentsCountArgs = {
  where?: PaymentWhereInput;
};


export type QueryCrmContactsArgs = {
  where?: CrmContactWhereInput;
  orderBy?: Array<CrmContactOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<CrmContactWhereUniqueInput>;
};


export type QueryCrmContactArgs = {
  where: CrmContactWhereUniqueInput;
};


export type QueryCrmContactsCountArgs = {
  where?: CrmContactWhereInput;
};


export type QueryVideosArgs = {
  where?: VideoWhereInput;
  orderBy?: Array<VideoOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<VideoWhereUniqueInput>;
};


export type QueryVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type QueryVideosCountArgs = {
  where?: VideoWhereInput;
};


export type QueryVideoViewsArgs = {
  where?: VideoViewWhereInput;
  orderBy?: Array<VideoViewOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<VideoViewWhereUniqueInput>;
};


export type QueryVideoViewArgs = {
  where: VideoViewWhereUniqueInput;
};


export type QueryVideoViewsCountArgs = {
  where?: VideoViewWhereInput;
};


export type QueryReportLinksArgs = {
  where?: ReportLinkWhereInput;
  orderBy?: Array<ReportLinkOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<ReportLinkWhereUniqueInput>;
};


export type QueryReportLinkArgs = {
  where: ReportLinkWhereUniqueInput;
};


export type QueryReportLinksCountArgs = {
  where?: ReportLinkWhereInput;
};


export type QueryFeedbackFormsArgs = {
  where?: FeedbackFormWhereInput;
  orderBy?: Array<FeedbackFormOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackFormWhereUniqueInput>;
};


export type QueryFeedbackFormArgs = {
  where: FeedbackFormWhereUniqueInput;
};


export type QueryFeedbackFormsCountArgs = {
  where?: FeedbackFormWhereInput;
};


export type QueryFeedbackResponsesArgs = {
  where?: FeedbackResponseWhereInput;
  orderBy?: Array<FeedbackResponseOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackResponseWhereUniqueInput>;
};


export type QueryFeedbackResponseArgs = {
  where: FeedbackResponseWhereUniqueInput;
};


export type QueryFeedbackResponsesCountArgs = {
  where?: FeedbackResponseWhereInput;
};


export type QueryFeedbackFormModalsArgs = {
  where?: FeedbackFormModalWhereInput;
  orderBy?: Array<FeedbackFormModalOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FeedbackFormModalWhereUniqueInput>;
};


export type QueryFeedbackFormModalArgs = {
  where: FeedbackFormModalWhereUniqueInput;
};


export type QueryFeedbackFormModalsCountArgs = {
  where?: FeedbackFormModalWhereInput;
};


export type QueryReportSendersArgs = {
  where?: ReportSenderWhereInput;
  orderBy?: Array<ReportSenderOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<ReportSenderWhereUniqueInput>;
};


export type QueryReportSenderArgs = {
  where: ReportSenderWhereUniqueInput;
};


export type QueryReportSendersCountArgs = {
  where?: ReportSenderWhereInput;
};


export type QueryStudentScheduledInvitationsArgs = {
  where?: StudentScheduledInvitationWhereInput;
  orderBy?: Array<StudentScheduledInvitationOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<StudentScheduledInvitationWhereUniqueInput>;
};


export type QueryStudentScheduledInvitationArgs = {
  where: StudentScheduledInvitationWhereUniqueInput;
};


export type QueryStudentScheduledInvitationsCountArgs = {
  where?: StudentScheduledInvitationWhereInput;
};


export type QueryFoldersArgs = {
  where?: FolderWhereInput;
  orderBy?: Array<FolderOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Scalars['Int'];
  cursor?: Maybe<FolderWhereUniqueInput>;
};


export type QueryFolderArgs = {
  where: FolderWhereUniqueInput;
};


export type QueryFoldersCountArgs = {
  where?: FolderWhereInput;
};


export type QueryAllStudentsSortedArgs = {
  schoolId: Scalars['Int'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<StudentStatusType>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isNotSentReport?: Maybe<Scalars['Boolean']>;
  folderIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isUnassignedToFolder?: Maybe<Scalars['Boolean']>;
};


export type QueryAllReportNotSentStudentsMetaArgs = {
  schoolId: Scalars['ID'];
};


export type QueryAllArchivedStudentsArgs = {
  schoolId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
};


export type QueryAllArchivedStudentsMetaArgs = {
  schoolId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Scalars['Int']>;
};


export type QueryAllArchivedStudentsYearsArgs = {
  schoolId: Scalars['Int'];
};


export type QueryFindVideosWithViewsArgs = {
  isTutorial: Scalars['Boolean'];
};


export type QueryFindVideoWithViewsArgs = {
  videoId: Scalars['Int'];
};


export type QueryFindFeedbackFormWithResponseArgs = {
  formType: Scalars['String'];
};


export type QueryFindFeedbackResponseByTokenArgs = {
  token: Scalars['String'];
};

export type AuthenticatedItem = User;

export type KeystoneMeta = {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
};

export type KeystoneAdminMeta = {
  __typename?: 'KeystoneAdminMeta';
  lists: Array<KeystoneAdminUiListMeta>;
  list?: Maybe<KeystoneAdminUiListMeta>;
};


export type KeystoneAdminMetaListArgs = {
  key: Scalars['String'];
};

export type KeystoneAdminUiListMeta = {
  __typename?: 'KeystoneAdminUIListMeta';
  key: Scalars['String'];
  itemQueryName: Scalars['String'];
  listQueryName: Scalars['String'];
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  path: Scalars['String'];
  label: Scalars['String'];
  singular: Scalars['String'];
  plural: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  initialColumns: Array<Scalars['String']>;
  pageSize: Scalars['Int'];
  labelField: Scalars['String'];
  fields: Array<KeystoneAdminUiFieldMeta>;
  groups: Array<KeystoneAdminUiFieldGroupMeta>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  isSingleton: Scalars['Boolean'];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: 'KeystoneAdminUIFieldMeta';
  path: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isOrderable: Scalars['Boolean'];
  isFilterable: Scalars['Boolean'];
  isNonNull?: Maybe<Array<KeystoneAdminUiFieldMetaIsNonNull>>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  viewsIndex: Scalars['Int'];
  customViewsIndex?: Maybe<Scalars['Int']>;
  createView: KeystoneAdminUiFieldMetaCreateView;
  listView: KeystoneAdminUiFieldMetaListView;
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  search?: Maybe<QueryMode>;
};


export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: Maybe<Scalars['ID']>;
};

export enum KeystoneAdminUiFieldMetaIsNonNull {
  Read = 'read',
  Create = 'create',
  Update = 'update'
}

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden'
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Read = 'read',
  Hidden = 'hidden'
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
  fieldPosition?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldPosition>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = 'edit',
  Read = 'read',
  Hidden = 'hidden'
}

export enum KeystoneAdminUiFieldMetaItemViewFieldPosition {
  Form = 'form',
  Sidebar = 'sidebar'
}

export type KeystoneAdminUiFieldGroupMeta = {
  __typename?: 'KeystoneAdminUIFieldGroupMeta';
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
};

export type KeystoneAdminUiSort = {
  __typename?: 'KeystoneAdminUISort';
  field: Scalars['String'];
  direction: KeystoneAdminUiSortDirection;
};

export enum KeystoneAdminUiSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum UserGenderType {
  Female = 'female',
  Male = 'male'
}

export enum UserLanguageType {
  En = 'EN'
}

export enum StudentStatusType {
  Completed = 'completed',
  InProgress = 'inProgress',
  NotStarted = 'notStarted'
}

export type VoidOutput = {
  __typename?: 'VoidOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type RegeneratedResult = {
  __typename?: 'RegeneratedResult';
  regenerated?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type RegeneratedOutput = {
  __typename?: 'RegeneratedOutput';
  regenerated?: Maybe<Array<Maybe<RegeneratedResult>>>;
  failed?: Maybe<Array<Maybe<RegeneratedResult>>>;
};

export type UpdateCsvError = {
  __typename?: 'UpdateCSVError';
  success?: Maybe<Scalars['Boolean']>;
  itemId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ResetManyTestsError = {
  __typename?: 'ResetManyTestsError';
  isError?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ResetManyTestsWarning = {
  __typename?: 'ResetManyTestsWarning';
  isWarning?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ResetManyTestsSuccess = {
  __typename?: 'ResetManyTestsSuccess';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ResetManyTestsResponse = {
  __typename?: 'ResetManyTestsResponse';
  successReset?: Maybe<Array<Maybe<ResetManyTestsSuccess>>>;
  warnings?: Maybe<Array<Maybe<ResetManyTestsWarning>>>;
  errors?: Maybe<Array<Maybe<ResetManyTestsError>>>;
};

export type InvitedStudents = {
  __typename?: 'InvitedStudents';
  existingStudents?: Maybe<Array<Maybe<Student>>>;
  studentsForExistingUsers?: Maybe<Array<Maybe<Student>>>;
  studentsForNewUsers?: Maybe<Array<Maybe<Student>>>;
};

export type InvitedTeachers = {
  __typename?: 'InvitedTeachers';
  existingTeachers?: Maybe<Array<Maybe<Teacher>>>;
  teachersForExistingUsers?: Maybe<Array<Maybe<Teacher>>>;
  teachersForNewUsers?: Maybe<Array<Maybe<Teacher>>>;
};

export type InvitedSchoolAdmins = {
  __typename?: 'InvitedSchoolAdmins';
  existingSchoolAdmins?: Maybe<Array<Maybe<User>>>;
  schoolAdminsForExistingUsers?: Maybe<Array<Maybe<User>>>;
  schoolAdminsForNewUsers?: Maybe<Array<Maybe<User>>>;
};

export type SyncContactsWithOnePageCrmOutput = {
  __typename?: 'syncContactsWithOnePageCrmOutput';
  success?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  allSchoolsFromCrmCount?: Maybe<Scalars['Int']>;
  allSchoolsFromCareerFitCount?: Maybe<Scalars['Int']>;
  foundSchoolsCount?: Maybe<Scalars['Int']>;
  foundContactsCount?: Maybe<Scalars['Int']>;
};

export type StudentsMeta = {
  __typename?: 'StudentsMeta';
  count: Scalars['Int'];
};

export type TypeformTokenResponse = {
  __typename?: 'TypeformTokenResponse';
  userId: Scalars['Int'];
  testAttemptId: Scalars['Int'];
};

export type ModalFeedback = {
  __typename?: 'ModalFeedback';
  id: Scalars['Int'];
  modalContent: Scalars['String'];
  modalTitle: Scalars['String'];
};

export type FeedbackFormResponse = {
  __typename?: 'FeedbackFormResponse';
  id: Scalars['Int'];
  formId: Scalars['String'];
  formType: Scalars['String'];
  modal: ModalFeedback;
  responses?: Maybe<Array<Maybe<FeedbackResponse>>>;
};

export type FeedbackResponseType = {
  __typename?: 'FeedbackResponseType';
  isSubmitted: Scalars['Boolean'];
  form: FeedbackFormResponse;
};

export type FindByTokenResponse = {
  __typename?: 'FindByTokenResponse';
  feedbackResponse: FeedbackResponseType;
  isSubmitted: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  anotherFeedbackFormType?: Maybe<Scalars['String']>;
};

export type RegenerateLinkResponse = {
  __typename?: 'RegenerateLinkResponse';
  token?: Maybe<Scalars['String']>;
};

export type UserInviteInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderType>;
  dateOfBirth?: Maybe<Scalars['String']>;
};

export type IndividualSchoolInviteInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderType>;
  dateOfBirth?: Maybe<Scalars['String']>;
  language?: Maybe<UserLanguageType>;
  country: Scalars['String'];
};

export type ImportCareersInput = {
  careerId: Scalars['String'];
  name: Scalars['String'];
  jobCategoryCode: Scalars['String'];
};

export type CreateSubtractPaymentInput = {
  creditId: Scalars['Int'];
  cost: Scalars['Int'];
  isFreeCredit: Scalars['Boolean'];
  isSponsorCredit: Scalars['Boolean'];
};

export type LinkToReorder = {
  id: Scalars['Int'];
};

export type RemoveAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeAccount?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type UpdateAuthenticatedUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderType>;
}>;


export type UpdateAuthenticatedUserMutation = (
  { __typename?: 'Mutation' }
  & { updateAuthenticatedUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type SendEmailMutationVariables = Exact<{
  emailTitle: Scalars['String'];
  emailContent: Scalars['String'];
  userIds: Array<Scalars['Int']>;
  schoolId: Scalars['Int'];
  addReport?: Maybe<Scalars['Boolean']>;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailToStudents?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type FormSubmitByAuthedUserMutationVariables = Exact<{
  formId: Scalars['String'];
  responseId: Scalars['String'];
  testAttemptId?: Maybe<Scalars['Int']>;
}>;


export type FormSubmitByAuthedUserMutation = (
  { __typename?: 'Mutation' }
  & { formSubmitByAuthedUser?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type FormSubmitByTokenMutationVariables = Exact<{
  token: Scalars['String'];
  responseId: Scalars['String'];
}>;


export type FormSubmitByTokenMutation = (
  { __typename?: 'Mutation' }
  & { formSubmitByToken?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type AssignStudentsToFolderMutationVariables = Exact<{
  folderId: Scalars['Int'];
  schoolId: Scalars['Int'];
  studentIds: Array<Scalars['Int']>;
}>;


export type AssignStudentsToFolderMutation = (
  { __typename?: 'Mutation' }
  & { assignStudentsToFolder?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type CreateFolderInSchoolMutationVariables = Exact<{
  schoolId: Scalars['Int'];
  name: Scalars['String'];
  studentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  countryId?: Maybe<Scalars['Int']>;
}>;


export type CreateFolderInSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createFolderInSchool?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type DeleteFolderMutationVariables = Exact<{
  where: FolderWhereUniqueInput;
}>;


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id'>
  )> }
);

export type UpdateFolderInSchoolMutationVariables = Exact<{
  updateFolderInSchoolId: Scalars['Int'];
  schoolId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
}>;


export type UpdateFolderInSchoolMutation = (
  { __typename?: 'Mutation' }
  & { updateFolderInSchool?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type CreatePaymentSessionMutationVariables = Exact<{
  creditId: Scalars['Int'];
  quantity: Scalars['Int'];
}>;


export type CreatePaymentSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPaymentSession'>
);

export type SendInvoiceRequestMutationVariables = Exact<{
  schoolId: Scalars['Int'];
  userId: Scalars['Int'];
  quantity: Scalars['Int'];
}>;


export type SendInvoiceRequestMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoiceRequest?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type UpdateSchoolMutationVariables = Exact<{
  id: Scalars['ID'];
  principalName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  hasAutoPublishedReports: Scalars['Boolean'];
  hasRemindersOn: Scalars['Boolean'];
}>;


export type UpdateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { updateSchool?: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'id'>
  )> }
);

export type InviteSchoolAdminMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  schoolId: Scalars['Int'];
}>;


export type InviteSchoolAdminMutation = (
  { __typename?: 'Mutation' }
  & { inviteSchoolAdmin?: Maybe<(
    { __typename?: 'InvitedSchoolAdmins' }
    & { schoolAdminsForNewUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, existingSchoolAdmins?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, schoolAdminsForExistingUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>> }
  )> }
);

export type RemoveSchoolAdminMutationVariables = Exact<{
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
}>;


export type RemoveSchoolAdminMutation = (
  { __typename?: 'Mutation' }
  & { removeSchoolAdmin?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type EnableIndividualTestMutationVariables = Exact<{
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
}>;


export type EnableIndividualTestMutation = (
  { __typename?: 'Mutation' }
  & { enableIndividualTest?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type RegisterIndividualSchoolMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderType>;
  country: Scalars['String'];
}>;


export type RegisterIndividualSchoolMutation = (
  { __typename?: 'Mutation' }
  & { registerIndividualSchool?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type InviteStudentMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  schoolId: Scalars['Int'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  invitationDate?: Maybe<Scalars['Float']>;
}>;


export type InviteStudentMutation = (
  { __typename?: 'Mutation' }
  & { inviteStudent?: Maybe<(
    { __typename?: 'InvitedStudents' }
    & { studentsForNewUsers?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )>>>, existingStudents?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )>>> }
  )> }
);

export type ResendStudentInvitationMutationVariables = Exact<{
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
}>;


export type ResendStudentInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendStudentInvitation?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type ArchiveStudentsMutationVariables = Exact<{
  studentIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
}>;


export type ArchiveStudentsMutation = (
  { __typename?: 'Mutation' }
  & { archiveStudents?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'isArchived'>
  )>> }
);

export type InviteStudentsMutationVariables = Exact<{
  data?: Maybe<Array<UserInviteInput>>;
  schoolId: Scalars['Int'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  invitationDate?: Maybe<Scalars['Float']>;
}>;


export type InviteStudentsMutation = (
  { __typename?: 'Mutation' }
  & { inviteStudents?: Maybe<(
    { __typename?: 'InvitedStudents' }
    & { existingStudents?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )>>>, studentsForExistingUsers?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )>>>, studentsForNewUsers?: Maybe<Array<Maybe<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
    )>>> }
  )> }
);

export type RevokeInvitationMutationVariables = Exact<{
  userIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
}>;


export type RevokeInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeInvitation'>
);

export type UnarchiveStudentsMutationVariables = Exact<{
  studentIds?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
}>;


export type UnarchiveStudentsMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveStudents?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'isArchived'>
  )>> }
);

export type DeleteTeacherMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTeacherMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeacher?: Maybe<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id'>
  )> }
);

export type InviteTeacherMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  schoolId: Scalars['Int'];
}>;


export type InviteTeacherMutation = (
  { __typename?: 'Mutation' }
  & { inviteTeacher?: Maybe<(
    { __typename?: 'InvitedTeachers' }
    & { teachersForNewUsers?: Maybe<Array<Maybe<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id'>
    )>>>, existingTeachers?: Maybe<Array<Maybe<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id'>
    )>>>, teachersForExistingUsers?: Maybe<Array<Maybe<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id'>
    )>>> }
  )> }
);

export type ResendTeacherOrSchoolAdminInvitationMutationVariables = Exact<{
  userId: Scalars['Int'];
  schoolId: Scalars['Int'];
}>;


export type ResendTeacherOrSchoolAdminInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendTeacherOrSchoolAdminInvitation?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type CompleteInterestInventoryTestMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteInterestInventoryTestMutation = (
  { __typename?: 'Mutation' }
  & { completeInterestInventoryTest?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type ResetInterestInventoriesMutationVariables = Exact<{
  testAttemptsIds: Array<Maybe<Scalars['Int']>>;
}>;


export type ResetInterestInventoriesMutation = (
  { __typename?: 'Mutation' }
  & { resetManyInterestInventories?: Maybe<(
    { __typename?: 'ResetManyTestsResponse' }
    & { successReset?: Maybe<Array<Maybe<(
      { __typename?: 'ResetManyTestsSuccess' }
      & Pick<ResetManyTestsSuccess, 'message'>
    )>>>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ResetManyTestsError' }
      & Pick<ResetManyTestsError, 'message'>
    )>>>, warnings?: Maybe<Array<Maybe<(
      { __typename?: 'ResetManyTestsWarning' }
      & Pick<ResetManyTestsWarning, 'message'>
    )>>> }
  )> }
);

export type StartInterestInventoryTestMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StartInterestInventoryTestMutation = (
  { __typename?: 'Mutation' }
  & { startInterestInventoryTest?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type StoreInterestInventoryAnswerMutationVariables = Exact<{
  testId: Scalars['Int'];
  interestQuestionId: Scalars['Int'];
  answer: Scalars['Int'];
}>;


export type StoreInterestInventoryAnswerMutation = (
  { __typename?: 'Mutation' }
  & { storeInterestAnswer?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type AuthenticateUserWithPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticateUserWithPasswordMutation = (
  { __typename?: 'Mutation' }
  & { authenticateUserWithPassword?: Maybe<(
    { __typename?: 'UserAuthenticationWithPasswordSuccess' }
    & Pick<UserAuthenticationWithPasswordSuccess, 'sessionToken'>
  ) | (
    { __typename?: 'UserAuthenticationWithPasswordFailure' }
    & Pick<UserAuthenticationWithPasswordFailure, 'message'>
  )> }
);

export type RegenerateInvitationLinkMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RegenerateInvitationLinkMutation = (
  { __typename?: 'Mutation' }
  & { regenerateInvitationLink?: Maybe<(
    { __typename?: 'RegenerateLinkResponse' }
    & Pick<RegenerateLinkResponse, 'token'>
  )> }
);

export type RemindPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemindPasswordMutation = (
  { __typename?: 'Mutation' }
  & { remindPassword?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CloseTutorialVideoMutationVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type CloseTutorialVideoMutation = (
  { __typename?: 'Mutation' }
  & { closeTutorialVideo?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type CompleteVideoViewMutationVariables = Exact<{
  videoViewId: Scalars['Int'];
}>;


export type CompleteVideoViewMutation = (
  { __typename?: 'Mutation' }
  & { completeVideoView?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type StartVideoWatchingMutationVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type StartVideoWatchingMutation = (
  { __typename?: 'Mutation' }
  & { startVideoWatching?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);

export type GetAuthenticatedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticatedUserQuery = (
  { __typename?: 'Query' }
  & { authenticatedItem?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'lastName' | 'firstName' | 'email' | 'id' | 'dateOfBirth' | 'isAdmin' | 'gender' | 'isConfirmed'>
    & { teachers?: Maybe<Array<(
      { __typename?: 'Teacher' }
      & { school?: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'id' | 'name' | 'isIndividual'>
        & { credit?: Maybe<(
          { __typename?: 'Credit' }
          & Pick<Credit, 'balance'>
        )> }
      )> }
    )>>, adminOfSchools?: Maybe<Array<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'isIndividual'>
    )>>, students?: Maybe<Array<(
      { __typename?: 'Student' }
      & Pick<Student, 'id'>
      & { testAttempt?: Maybe<(
        { __typename?: 'TestAttempt' }
        & Pick<TestAttempt, 'id' | 'isCompleted' | 'isReportPublished' | 'reportName'>
      )>, school?: Maybe<(
        { __typename?: 'School' }
        & Pick<School, 'id' | 'name'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id'>
        )> }
      )> }
    )>> }
  )> }
);

export type UnauthenticateUserMutationVariables = Exact<{ [key: string]: never; }>;


export type UnauthenticateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'endSession'>
);

export type GetAllCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'code' | 'name'>
  )>> }
);

export type GetAllEmailTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEmailTemplatesQuery = (
  { __typename?: 'Query' }
  & { emailTemplates?: Maybe<Array<(
    { __typename?: 'EmailTemplate' }
    & Pick<EmailTemplate, 'title' | 'content' | 'type'>
  )>> }
);

export type GetFeedbackFormWithResponseQueryVariables = Exact<{
  formType: Scalars['String'];
}>;


export type GetFeedbackFormWithResponseQuery = (
  { __typename?: 'Query' }
  & { findFeedbackFormWithResponse?: Maybe<(
    { __typename?: 'FeedbackFormResponse' }
    & Pick<FeedbackFormResponse, 'id' | 'formId' | 'formType'>
    & { modal: (
      { __typename?: 'ModalFeedback' }
      & Pick<ModalFeedback, 'id' | 'modalTitle' | 'modalContent'>
    ), responses?: Maybe<Array<Maybe<(
      { __typename?: 'FeedbackResponse' }
      & Pick<FeedbackResponse, 'id' | 'isSubmitted'>
    )>>> }
  )> }
);

export type GetFeedbackResponseByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetFeedbackResponseByTokenQuery = (
  { __typename?: 'Query' }
  & { findFeedbackResponseByToken?: Maybe<(
    { __typename?: 'FindByTokenResponse' }
    & Pick<FindByTokenResponse, 'anotherFeedbackFormType' | 'url' | 'isSubmitted'>
    & { feedbackResponse: (
      { __typename?: 'FeedbackResponseType' }
      & Pick<FeedbackResponseType, 'isSubmitted'>
      & { form: (
        { __typename?: 'FeedbackFormResponse' }
        & Pick<FeedbackFormResponse, 'id' | 'formId' | 'formType'>
        & { modal: (
          { __typename?: 'ModalFeedback' }
          & Pick<ModalFeedback, 'id' | 'modalTitle' | 'modalContent'>
        ) }
      ) }
    ) }
  )> }
);

export type GetAllFolderCountriesQueryVariables = Exact<{
  orderBy: Array<CountryOrderByInput>;
}>;


export type GetAllFolderCountriesQuery = (
  { __typename?: 'Query' }
  & { countries?: Maybe<Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'flag' | 'id' | 'name'>
  )>> }
);

export type GetAllFoldersQueryVariables = Exact<{
  where: FolderWhereInput;
  orderBy: Array<FolderOrderByInput>;
}>;


export type GetAllFoldersQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'name' | 'createdAt_utc' | 'studentsCount' | 'id'>
    & { countryModel?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'flag' | 'code'>
    )> }
  )>> }
);

export type GetAllFoldersCountQueryVariables = Exact<{
  where: FolderWhereInput;
}>;


export type GetAllFoldersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'foldersCount'>
);

export type GetAllFoldersPaginatedQueryVariables = Exact<{
  where: FolderWhereInput;
  orderBy: Array<FolderOrderByInput>;
  take?: Maybe<Scalars['Int']>;
  skip: Scalars['Int'];
}>;


export type GetAllFoldersPaginatedQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'name' | 'createdAt_utc' | 'studentsCount' | 'id'>
    & { countryModel?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'flag' | 'code'>
    )> }
  )>> }
);

export type GetAllSchoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSchoolsQuery = (
  { __typename?: 'Query' }
  & { schools?: Maybe<Array<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'address' | 'code' | 'id'>
    & { teachers?: Maybe<Array<(
      { __typename?: 'Teacher' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isAdmin'>
      )> }
    )>>, schoolAdmins?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>>, students?: Maybe<Array<(
      { __typename?: 'Student' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isAdmin'>
      )> }
    )>> }
  )>> }
);

export type GetAllTeachersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAllTeachersQuery = (
  { __typename?: 'Query' }
  & { teachers?: Maybe<Array<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'isAdmin' | 'isDeleted' | 'id' | 'isConfirmed'>
    )> }
  )>>, school?: Maybe<(
    { __typename?: 'School' }
    & { schoolAdmins?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'isAdmin' | 'isDeleted' | 'isConfirmed' | 'id'>
    )>> }
  )> }
);

export type GetSchoolQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSchoolQuery = (
  { __typename?: 'Query' }
  & { authenticatedItem?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, school?: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'name' | 'id' | 'phone' | 'website' | 'address' | 'testPrice' | 'code' | 'principalName' | 'zip' | 'state' | 'city' | 'email' | 'hasRemindersOn'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'name' | 'id'>
    )>, credit?: Maybe<(
      { __typename?: 'Credit' }
      & Pick<Credit, 'id' | 'balance'>
    )>, teachers?: Maybe<Array<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )>>, schoolAdmins?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>> }
  )> }
);

export type GetSponsorsQueryVariables = Exact<{
  date: Scalars['DateTime'];
  countryId: Scalars['ID'];
}>;


export type GetSponsorsQuery = (
  { __typename?: 'Query' }
  & { sponsors?: Maybe<Array<(
    { __typename?: 'Sponsor' }
    & Pick<Sponsor, 'id'>
  )>> }
);

export type GetArchivedStudentsQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  schoolId: Scalars['Int'];
  schoolYear?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetArchivedStudentsQuery = (
  { __typename?: 'Query' }
  & { allArchivedStudents?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'status' | 'id'>
    & { testAttempt?: Maybe<(
      { __typename?: 'TestAttempt' }
      & Pick<TestAttempt, 'id' | 'reportName'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isConfirmed'>
    )> }
  )>> }
);

export type GetAllArchivedStudentsMetaQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  schoolId: Scalars['Int'];
  schoolYear?: Maybe<Scalars['Int']>;
}>;


export type GetAllArchivedStudentsMetaQuery = (
  { __typename?: 'Query' }
  & { allArchivedStudentsMeta: (
    { __typename?: 'StudentsMeta' }
    & Pick<StudentsMeta, 'count'>
  ) }
);

export type GetArchivedStudentsYearsQueryVariables = Exact<{
  schoolId: Scalars['Int'];
}>;


export type GetArchivedStudentsYearsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allArchivedStudentsYears'>
);

export type GetAllStudentsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  schoolId: Scalars['ID'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllStudentsQuery = (
  { __typename?: 'Query' }
  & { students?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'status' | 'id'>
    & { testAttempt?: Maybe<(
      { __typename?: 'TestAttempt' }
      & Pick<TestAttempt, 'id' | 'reportName'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isConfirmed'>
    )> }
  )>> }
);

export type GetAllStudentsMetaQueryVariables = Exact<{
  where: StudentWhereInput;
}>;


export type GetAllStudentsMetaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'studentsCount'>
);

export type GetAllStudentsMetaStatusQueryVariables = Exact<{
  whereCompleted: StudentWhereInput;
  whereNotStarted: StudentWhereInput;
  whereInProgress: StudentWhereInput;
  schoolId: Scalars['ID'];
}>;


export type GetAllStudentsMetaStatusQuery = (
  { __typename?: 'Query' }
  & { completed: Query['studentsCount'], notStarted: Query['studentsCount'], inProgress: Query['studentsCount'] }
  & { reportNotSent: (
    { __typename?: 'StudentsMeta' }
    & Pick<StudentsMeta, 'count'>
  ) }
);

export type GetAllStudentsSortedQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  schoolId: Scalars['Int'];
  first: Scalars['Int'];
  skip: Scalars['Int'];
  status?: Maybe<StudentStatusType>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isNotSentReport?: Maybe<Scalars['Boolean']>;
  folderIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isUnassignedToFolder?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllStudentsSortedQuery = (
  { __typename?: 'Query' }
  & { allStudentsSorted?: Maybe<Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'status' | 'id'>
    & { testAttempt?: Maybe<(
      { __typename?: 'TestAttempt' }
      & Pick<TestAttempt, 'id' | 'reportName'>
    )>, folder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'name' | 'id'>
      & { countryModel?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'flag'>
      )> }
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'isConfirmed'>
    )> }
  )>> }
);

export type GetAnswersForInterestTestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAnswersForInterestTestQuery = (
  { __typename?: 'Query' }
  & { interestAnswers?: Maybe<Array<(
    { __typename?: 'InterestAnswer' }
    & Pick<InterestAnswer, 'answer'>
    & { interestQuestion?: Maybe<(
      { __typename?: 'InterestQuestion' }
      & Pick<InterestQuestion, 'id'>
      & { parent?: Maybe<(
        { __typename?: 'InterestQuestion' }
        & Pick<InterestQuestion, 'id'>
      )> }
    )> }
  )>> }
);

export type GetAllInterestQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInterestQuestionsQuery = (
  { __typename?: 'Query' }
  & { interestQuestions?: Maybe<Array<(
    { __typename?: 'InterestQuestion' }
    & Pick<InterestQuestion, 'id' | 'name' | 'interestId'>
    & { parent?: Maybe<(
      { __typename?: 'InterestQuestion' }
      & Pick<InterestQuestion, 'id'>
    )>, subquestions?: Maybe<Array<(
      { __typename?: 'InterestQuestion' }
      & Pick<InterestQuestion, 'id'>
    )>> }
  )>> }
);

export type FindUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'lastName' | 'firstName' | 'email' | 'id' | 'isConfirmed'>
  )> }
);

export type GetVideoWithViewsQueryVariables = Exact<{
  videoId: Scalars['Int'];
}>;


export type GetVideoWithViewsQuery = (
  { __typename?: 'Query' }
  & { findVideoWithViews: (
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'url' | 'isTutorial' | 'description'>
    & { views?: Maybe<Array<(
      { __typename?: 'VideoView' }
      & Pick<VideoView, 'id' | 'isCompleted'>
    )>> }
  ) }
);

export type GetVideosWithViewsQueryVariables = Exact<{
  isTutorial: Scalars['Boolean'];
}>;


export type GetVideosWithViewsQuery = (
  { __typename?: 'Query' }
  & { findVideosWithViews?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'url' | 'isTutorial'>
    & { views?: Maybe<Array<(
      { __typename?: 'VideoView' }
      & Pick<VideoView, 'id' | 'isCompleted'>
    )>> }
  )>> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  passwordToken: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'VoidOutput' }
    & Pick<VoidOutput, 'success'>
  )> }
);


export const RemoveAccountDocument = gql`
    mutation removeAccount($id: Int!) {
  removeAccount(userId: $id) {
    success
  }
}
    `;
export type RemoveAccountMutationFn = Apollo.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        return Apollo.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, baseOptions);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = Apollo.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = Apollo.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const UpdateAuthenticatedUserDocument = gql`
    mutation updateAuthenticatedUser($firstName: String!, $lastName: String!, $dateOfBirth: String, $gender: UserGenderType) {
  updateAuthenticatedUser(
    firstName: $firstName
    lastName: $lastName
    dateOfBirth: $dateOfBirth
    gender: $gender
    isConfirmed: true
  ) {
    id
  }
}
    `;
export type UpdateAuthenticatedUserMutationFn = Apollo.MutationFunction<UpdateAuthenticatedUserMutation, UpdateAuthenticatedUserMutationVariables>;

/**
 * __useUpdateAuthenticatedUserMutation__
 *
 * To run a mutation, you first call `useUpdateAuthenticatedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthenticatedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthenticatedUserMutation, { data, loading, error }] = useUpdateAuthenticatedUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateAuthenticatedUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthenticatedUserMutation, UpdateAuthenticatedUserMutationVariables>) {
        return Apollo.useMutation<UpdateAuthenticatedUserMutation, UpdateAuthenticatedUserMutationVariables>(UpdateAuthenticatedUserDocument, baseOptions);
      }
export type UpdateAuthenticatedUserMutationHookResult = ReturnType<typeof useUpdateAuthenticatedUserMutation>;
export type UpdateAuthenticatedUserMutationResult = Apollo.MutationResult<UpdateAuthenticatedUserMutation>;
export type UpdateAuthenticatedUserMutationOptions = Apollo.BaseMutationOptions<UpdateAuthenticatedUserMutation, UpdateAuthenticatedUserMutationVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($emailTitle: String!, $emailContent: String!, $userIds: [Int!]!, $schoolId: Int!, $addReport: Boolean) {
  sendEmailToStudents(
    emailTitle: $emailTitle
    emailContent: $emailContent
    schoolId: $schoolId
    userIds: $userIds
    addReport: $addReport
  ) {
    success
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      emailTitle: // value for 'emailTitle'
 *      emailContent: // value for 'emailContent'
 *      userIds: // value for 'userIds'
 *      schoolId: // value for 'schoolId'
 *      addReport: // value for 'addReport'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, baseOptions);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const FormSubmitByAuthedUserDocument = gql`
    mutation formSubmitByAuthedUser($formId: String!, $responseId: String!, $testAttemptId: Int) {
  formSubmitByAuthedUser(
    formId: $formId
    responseId: $responseId
    testAttemptId: $testAttemptId
  ) {
    success
  }
}
    `;
export type FormSubmitByAuthedUserMutationFn = Apollo.MutationFunction<FormSubmitByAuthedUserMutation, FormSubmitByAuthedUserMutationVariables>;

/**
 * __useFormSubmitByAuthedUserMutation__
 *
 * To run a mutation, you first call `useFormSubmitByAuthedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormSubmitByAuthedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formSubmitByAuthedUserMutation, { data, loading, error }] = useFormSubmitByAuthedUserMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      responseId: // value for 'responseId'
 *      testAttemptId: // value for 'testAttemptId'
 *   },
 * });
 */
export function useFormSubmitByAuthedUserMutation(baseOptions?: Apollo.MutationHookOptions<FormSubmitByAuthedUserMutation, FormSubmitByAuthedUserMutationVariables>) {
        return Apollo.useMutation<FormSubmitByAuthedUserMutation, FormSubmitByAuthedUserMutationVariables>(FormSubmitByAuthedUserDocument, baseOptions);
      }
export type FormSubmitByAuthedUserMutationHookResult = ReturnType<typeof useFormSubmitByAuthedUserMutation>;
export type FormSubmitByAuthedUserMutationResult = Apollo.MutationResult<FormSubmitByAuthedUserMutation>;
export type FormSubmitByAuthedUserMutationOptions = Apollo.BaseMutationOptions<FormSubmitByAuthedUserMutation, FormSubmitByAuthedUserMutationVariables>;
export const FormSubmitByTokenDocument = gql`
    mutation formSubmitByToken($token: String!, $responseId: String!) {
  formSubmitByToken(token: $token, responseId: $responseId) {
    success
  }
}
    `;
export type FormSubmitByTokenMutationFn = Apollo.MutationFunction<FormSubmitByTokenMutation, FormSubmitByTokenMutationVariables>;

/**
 * __useFormSubmitByTokenMutation__
 *
 * To run a mutation, you first call `useFormSubmitByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormSubmitByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formSubmitByTokenMutation, { data, loading, error }] = useFormSubmitByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      responseId: // value for 'responseId'
 *   },
 * });
 */
export function useFormSubmitByTokenMutation(baseOptions?: Apollo.MutationHookOptions<FormSubmitByTokenMutation, FormSubmitByTokenMutationVariables>) {
        return Apollo.useMutation<FormSubmitByTokenMutation, FormSubmitByTokenMutationVariables>(FormSubmitByTokenDocument, baseOptions);
      }
export type FormSubmitByTokenMutationHookResult = ReturnType<typeof useFormSubmitByTokenMutation>;
export type FormSubmitByTokenMutationResult = Apollo.MutationResult<FormSubmitByTokenMutation>;
export type FormSubmitByTokenMutationOptions = Apollo.BaseMutationOptions<FormSubmitByTokenMutation, FormSubmitByTokenMutationVariables>;
export const AssignStudentsToFolderDocument = gql`
    mutation AssignStudentsToFolder($folderId: Int!, $schoolId: Int!, $studentIds: [Int!]!) {
  assignStudentsToFolder(
    folderId: $folderId
    schoolId: $schoolId
    studentIds: $studentIds
  ) {
    success
  }
}
    `;
export type AssignStudentsToFolderMutationFn = Apollo.MutationFunction<AssignStudentsToFolderMutation, AssignStudentsToFolderMutationVariables>;

/**
 * __useAssignStudentsToFolderMutation__
 *
 * To run a mutation, you first call `useAssignStudentsToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStudentsToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStudentsToFolderMutation, { data, loading, error }] = useAssignStudentsToFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      schoolId: // value for 'schoolId'
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useAssignStudentsToFolderMutation(baseOptions?: Apollo.MutationHookOptions<AssignStudentsToFolderMutation, AssignStudentsToFolderMutationVariables>) {
        return Apollo.useMutation<AssignStudentsToFolderMutation, AssignStudentsToFolderMutationVariables>(AssignStudentsToFolderDocument, baseOptions);
      }
export type AssignStudentsToFolderMutationHookResult = ReturnType<typeof useAssignStudentsToFolderMutation>;
export type AssignStudentsToFolderMutationResult = Apollo.MutationResult<AssignStudentsToFolderMutation>;
export type AssignStudentsToFolderMutationOptions = Apollo.BaseMutationOptions<AssignStudentsToFolderMutation, AssignStudentsToFolderMutationVariables>;
export const CreateFolderInSchoolDocument = gql`
    mutation CreateFolderInSchool($schoolId: Int!, $name: String!, $studentIds: [Int], $countryId: Int) {
  createFolderInSchool(
    schoolId: $schoolId
    name: $name
    studentIds: $studentIds
    countryId: $countryId
  ) {
    success
  }
}
    `;
export type CreateFolderInSchoolMutationFn = Apollo.MutationFunction<CreateFolderInSchoolMutation, CreateFolderInSchoolMutationVariables>;

/**
 * __useCreateFolderInSchoolMutation__
 *
 * To run a mutation, you first call `useCreateFolderInSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderInSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderInSchoolMutation, { data, loading, error }] = useCreateFolderInSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      name: // value for 'name'
 *      studentIds: // value for 'studentIds'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useCreateFolderInSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderInSchoolMutation, CreateFolderInSchoolMutationVariables>) {
        return Apollo.useMutation<CreateFolderInSchoolMutation, CreateFolderInSchoolMutationVariables>(CreateFolderInSchoolDocument, baseOptions);
      }
export type CreateFolderInSchoolMutationHookResult = ReturnType<typeof useCreateFolderInSchoolMutation>;
export type CreateFolderInSchoolMutationResult = Apollo.MutationResult<CreateFolderInSchoolMutation>;
export type CreateFolderInSchoolMutationOptions = Apollo.BaseMutationOptions<CreateFolderInSchoolMutation, CreateFolderInSchoolMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($where: FolderWhereUniqueInput!) {
  deleteFolder(where: $where) {
    id
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, baseOptions);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const UpdateFolderInSchoolDocument = gql`
    mutation UpdateFolderInSchool($updateFolderInSchoolId: Int!, $schoolId: Int!, $name: String, $countryId: Int) {
  updateFolderInSchool(
    id: $updateFolderInSchoolId
    schoolId: $schoolId
    name: $name
    countryId: $countryId
  ) {
    success
  }
}
    `;
export type UpdateFolderInSchoolMutationFn = Apollo.MutationFunction<UpdateFolderInSchoolMutation, UpdateFolderInSchoolMutationVariables>;

/**
 * __useUpdateFolderInSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateFolderInSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderInSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderInSchoolMutation, { data, loading, error }] = useUpdateFolderInSchoolMutation({
 *   variables: {
 *      updateFolderInSchoolId: // value for 'updateFolderInSchoolId'
 *      schoolId: // value for 'schoolId'
 *      name: // value for 'name'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useUpdateFolderInSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderInSchoolMutation, UpdateFolderInSchoolMutationVariables>) {
        return Apollo.useMutation<UpdateFolderInSchoolMutation, UpdateFolderInSchoolMutationVariables>(UpdateFolderInSchoolDocument, baseOptions);
      }
export type UpdateFolderInSchoolMutationHookResult = ReturnType<typeof useUpdateFolderInSchoolMutation>;
export type UpdateFolderInSchoolMutationResult = Apollo.MutationResult<UpdateFolderInSchoolMutation>;
export type UpdateFolderInSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateFolderInSchoolMutation, UpdateFolderInSchoolMutationVariables>;
export const CreatePaymentSessionDocument = gql`
    mutation createPaymentSession($creditId: Int!, $quantity: Int!) {
  createPaymentSession(creditId: $creditId, quantity: $quantity)
}
    `;
export type CreatePaymentSessionMutationFn = Apollo.MutationFunction<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;

/**
 * __useCreatePaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSessionMutation, { data, loading, error }] = useCreatePaymentSessionMutation({
 *   variables: {
 *      creditId: // value for 'creditId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreatePaymentSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>) {
        return Apollo.useMutation<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>(CreatePaymentSessionDocument, baseOptions);
      }
export type CreatePaymentSessionMutationHookResult = ReturnType<typeof useCreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationResult = Apollo.MutationResult<CreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationOptions = Apollo.BaseMutationOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;
export const SendInvoiceRequestDocument = gql`
    mutation sendInvoiceRequest($schoolId: Int!, $userId: Int!, $quantity: Int!) {
  sendInvoiceRequest(schoolId: $schoolId, userId: $userId, quantity: $quantity) {
    success
  }
}
    `;
export type SendInvoiceRequestMutationFn = Apollo.MutationFunction<SendInvoiceRequestMutation, SendInvoiceRequestMutationVariables>;

/**
 * __useSendInvoiceRequestMutation__
 *
 * To run a mutation, you first call `useSendInvoiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceRequestMutation, { data, loading, error }] = useSendInvoiceRequestMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      userId: // value for 'userId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useSendInvoiceRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceRequestMutation, SendInvoiceRequestMutationVariables>) {
        return Apollo.useMutation<SendInvoiceRequestMutation, SendInvoiceRequestMutationVariables>(SendInvoiceRequestDocument, baseOptions);
      }
export type SendInvoiceRequestMutationHookResult = ReturnType<typeof useSendInvoiceRequestMutation>;
export type SendInvoiceRequestMutationResult = Apollo.MutationResult<SendInvoiceRequestMutation>;
export type SendInvoiceRequestMutationOptions = Apollo.BaseMutationOptions<SendInvoiceRequestMutation, SendInvoiceRequestMutationVariables>;
export const UpdateSchoolDocument = gql`
    mutation updateSchool($id: ID!, $principalName: String!, $email: String!, $phone: String!, $address: String!, $city: String!, $state: String!, $zip: String!, $hasAutoPublishedReports: Boolean!, $hasRemindersOn: Boolean!) {
  updateSchool(
    where: {id: $id}
    data: {principalName: $principalName, email: $email, phone: $phone, address: $address, city: $city, state: $state, zip: $zip, hasRemindersOn: $hasRemindersOn}
  ) {
    id
  }
}
    `;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      principalName: // value for 'principalName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      hasAutoPublishedReports: // value for 'hasAutoPublishedReports'
 *      hasRemindersOn: // value for 'hasRemindersOn'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, baseOptions);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const InviteSchoolAdminDocument = gql`
    mutation inviteSchoolAdmin($email: String!, $firstName: String!, $lastName: String!, $schoolId: Int!) {
  inviteSchoolAdmin(
    data: {email: $email, firstName: $firstName, lastName: $lastName}
    schoolId: $schoolId
  ) {
    schoolAdminsForNewUsers {
      id
    }
    existingSchoolAdmins {
      id
    }
    schoolAdminsForExistingUsers {
      id
    }
  }
}
    `;
export type InviteSchoolAdminMutationFn = Apollo.MutationFunction<InviteSchoolAdminMutation, InviteSchoolAdminMutationVariables>;

/**
 * __useInviteSchoolAdminMutation__
 *
 * To run a mutation, you first call `useInviteSchoolAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSchoolAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSchoolAdminMutation, { data, loading, error }] = useInviteSchoolAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useInviteSchoolAdminMutation(baseOptions?: Apollo.MutationHookOptions<InviteSchoolAdminMutation, InviteSchoolAdminMutationVariables>) {
        return Apollo.useMutation<InviteSchoolAdminMutation, InviteSchoolAdminMutationVariables>(InviteSchoolAdminDocument, baseOptions);
      }
export type InviteSchoolAdminMutationHookResult = ReturnType<typeof useInviteSchoolAdminMutation>;
export type InviteSchoolAdminMutationResult = Apollo.MutationResult<InviteSchoolAdminMutation>;
export type InviteSchoolAdminMutationOptions = Apollo.BaseMutationOptions<InviteSchoolAdminMutation, InviteSchoolAdminMutationVariables>;
export const RemoveSchoolAdminDocument = gql`
    mutation removeSchoolAdmin($userId: Int!, $schoolId: Int!) {
  removeSchoolAdmin(userId: $userId, schoolId: $schoolId) {
    success
  }
}
    `;
export type RemoveSchoolAdminMutationFn = Apollo.MutationFunction<RemoveSchoolAdminMutation, RemoveSchoolAdminMutationVariables>;

/**
 * __useRemoveSchoolAdminMutation__
 *
 * To run a mutation, you first call `useRemoveSchoolAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSchoolAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSchoolAdminMutation, { data, loading, error }] = useRemoveSchoolAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useRemoveSchoolAdminMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSchoolAdminMutation, RemoveSchoolAdminMutationVariables>) {
        return Apollo.useMutation<RemoveSchoolAdminMutation, RemoveSchoolAdminMutationVariables>(RemoveSchoolAdminDocument, baseOptions);
      }
export type RemoveSchoolAdminMutationHookResult = ReturnType<typeof useRemoveSchoolAdminMutation>;
export type RemoveSchoolAdminMutationResult = Apollo.MutationResult<RemoveSchoolAdminMutation>;
export type RemoveSchoolAdminMutationOptions = Apollo.BaseMutationOptions<RemoveSchoolAdminMutation, RemoveSchoolAdminMutationVariables>;
export const EnableIndividualTestDocument = gql`
    mutation enableIndividualTest($userId: Int!, $schoolId: Int!) {
  enableIndividualTest(userId: $userId, schoolId: $schoolId) {
    success
  }
}
    `;
export type EnableIndividualTestMutationFn = Apollo.MutationFunction<EnableIndividualTestMutation, EnableIndividualTestMutationVariables>;

/**
 * __useEnableIndividualTestMutation__
 *
 * To run a mutation, you first call `useEnableIndividualTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableIndividualTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableIndividualTestMutation, { data, loading, error }] = useEnableIndividualTestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useEnableIndividualTestMutation(baseOptions?: Apollo.MutationHookOptions<EnableIndividualTestMutation, EnableIndividualTestMutationVariables>) {
        return Apollo.useMutation<EnableIndividualTestMutation, EnableIndividualTestMutationVariables>(EnableIndividualTestDocument, baseOptions);
      }
export type EnableIndividualTestMutationHookResult = ReturnType<typeof useEnableIndividualTestMutation>;
export type EnableIndividualTestMutationResult = Apollo.MutationResult<EnableIndividualTestMutation>;
export type EnableIndividualTestMutationOptions = Apollo.BaseMutationOptions<EnableIndividualTestMutation, EnableIndividualTestMutationVariables>;
export const RegisterIndividualSchoolDocument = gql`
    mutation registerIndividualSchool($email: String!, $firstName: String!, $lastName: String!, $dateOfBirth: String, $gender: UserGenderType, $country: String!) {
  registerIndividualSchool(
    data: {email: $email, firstName: $firstName, lastName: $lastName, gender: $gender, dateOfBirth: $dateOfBirth, language: EN, country: $country}
  ) {
    success
  }
}
    `;
export type RegisterIndividualSchoolMutationFn = Apollo.MutationFunction<RegisterIndividualSchoolMutation, RegisterIndividualSchoolMutationVariables>;

/**
 * __useRegisterIndividualSchoolMutation__
 *
 * To run a mutation, you first call `useRegisterIndividualSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterIndividualSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerIndividualSchoolMutation, { data, loading, error }] = useRegisterIndividualSchoolMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      gender: // value for 'gender'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useRegisterIndividualSchoolMutation(baseOptions?: Apollo.MutationHookOptions<RegisterIndividualSchoolMutation, RegisterIndividualSchoolMutationVariables>) {
        return Apollo.useMutation<RegisterIndividualSchoolMutation, RegisterIndividualSchoolMutationVariables>(RegisterIndividualSchoolDocument, baseOptions);
      }
export type RegisterIndividualSchoolMutationHookResult = ReturnType<typeof useRegisterIndividualSchoolMutation>;
export type RegisterIndividualSchoolMutationResult = Apollo.MutationResult<RegisterIndividualSchoolMutation>;
export type RegisterIndividualSchoolMutationOptions = Apollo.BaseMutationOptions<RegisterIndividualSchoolMutation, RegisterIndividualSchoolMutationVariables>;
export const InviteStudentDocument = gql`
    mutation inviteStudent($email: String!, $firstName: String!, $lastName: String!, $schoolId: Int!, $isScheduled: Boolean, $invitationDate: Float) {
  inviteStudent(
    data: {email: $email, firstName: $firstName, lastName: $lastName}
    schoolId: $schoolId
    invitationDate: $invitationDate
    isScheduled: $isScheduled
  ) {
    studentsForNewUsers {
      id
    }
    existingStudents {
      id
    }
    studentsForNewUsers {
      id
    }
  }
}
    `;
export type InviteStudentMutationFn = Apollo.MutationFunction<InviteStudentMutation, InviteStudentMutationVariables>;

/**
 * __useInviteStudentMutation__
 *
 * To run a mutation, you first call `useInviteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStudentMutation, { data, loading, error }] = useInviteStudentMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      schoolId: // value for 'schoolId'
 *      isScheduled: // value for 'isScheduled'
 *      invitationDate: // value for 'invitationDate'
 *   },
 * });
 */
export function useInviteStudentMutation(baseOptions?: Apollo.MutationHookOptions<InviteStudentMutation, InviteStudentMutationVariables>) {
        return Apollo.useMutation<InviteStudentMutation, InviteStudentMutationVariables>(InviteStudentDocument, baseOptions);
      }
export type InviteStudentMutationHookResult = ReturnType<typeof useInviteStudentMutation>;
export type InviteStudentMutationResult = Apollo.MutationResult<InviteStudentMutation>;
export type InviteStudentMutationOptions = Apollo.BaseMutationOptions<InviteStudentMutation, InviteStudentMutationVariables>;
export const ResendStudentInvitationDocument = gql`
    mutation resendStudentInvitation($userId: Int!, $schoolId: Int!) {
  resendStudentInvitation(userId: $userId, schoolId: $schoolId) {
    success
  }
}
    `;
export type ResendStudentInvitationMutationFn = Apollo.MutationFunction<ResendStudentInvitationMutation, ResendStudentInvitationMutationVariables>;

/**
 * __useResendStudentInvitationMutation__
 *
 * To run a mutation, you first call `useResendStudentInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendStudentInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendStudentInvitationMutation, { data, loading, error }] = useResendStudentInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useResendStudentInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendStudentInvitationMutation, ResendStudentInvitationMutationVariables>) {
        return Apollo.useMutation<ResendStudentInvitationMutation, ResendStudentInvitationMutationVariables>(ResendStudentInvitationDocument, baseOptions);
      }
export type ResendStudentInvitationMutationHookResult = ReturnType<typeof useResendStudentInvitationMutation>;
export type ResendStudentInvitationMutationResult = Apollo.MutationResult<ResendStudentInvitationMutation>;
export type ResendStudentInvitationMutationOptions = Apollo.BaseMutationOptions<ResendStudentInvitationMutation, ResendStudentInvitationMutationVariables>;
export const ArchiveStudentsDocument = gql`
    mutation archiveStudents($studentIds: [Int!], $schoolId: Int!) {
  archiveStudents(studentIds: $studentIds, schoolId: $schoolId) {
    id
    isArchived
  }
}
    `;
export type ArchiveStudentsMutationFn = Apollo.MutationFunction<ArchiveStudentsMutation, ArchiveStudentsMutationVariables>;

/**
 * __useArchiveStudentsMutation__
 *
 * To run a mutation, you first call `useArchiveStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveStudentsMutation, { data, loading, error }] = useArchiveStudentsMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useArchiveStudentsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveStudentsMutation, ArchiveStudentsMutationVariables>) {
        return Apollo.useMutation<ArchiveStudentsMutation, ArchiveStudentsMutationVariables>(ArchiveStudentsDocument, baseOptions);
      }
export type ArchiveStudentsMutationHookResult = ReturnType<typeof useArchiveStudentsMutation>;
export type ArchiveStudentsMutationResult = Apollo.MutationResult<ArchiveStudentsMutation>;
export type ArchiveStudentsMutationOptions = Apollo.BaseMutationOptions<ArchiveStudentsMutation, ArchiveStudentsMutationVariables>;
export const InviteStudentsDocument = gql`
    mutation inviteStudents($data: [UserInviteInput!], $schoolId: Int!, $isScheduled: Boolean, $invitationDate: Float) {
  inviteStudents(
    data: $data
    schoolId: $schoolId
    invitationDate: $invitationDate
    isScheduled: $isScheduled
  ) {
    existingStudents {
      id
    }
    studentsForExistingUsers {
      id
    }
    studentsForNewUsers {
      id
    }
  }
}
    `;
export type InviteStudentsMutationFn = Apollo.MutationFunction<InviteStudentsMutation, InviteStudentsMutationVariables>;

/**
 * __useInviteStudentsMutation__
 *
 * To run a mutation, you first call `useInviteStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStudentsMutation, { data, loading, error }] = useInviteStudentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      schoolId: // value for 'schoolId'
 *      isScheduled: // value for 'isScheduled'
 *      invitationDate: // value for 'invitationDate'
 *   },
 * });
 */
export function useInviteStudentsMutation(baseOptions?: Apollo.MutationHookOptions<InviteStudentsMutation, InviteStudentsMutationVariables>) {
        return Apollo.useMutation<InviteStudentsMutation, InviteStudentsMutationVariables>(InviteStudentsDocument, baseOptions);
      }
export type InviteStudentsMutationHookResult = ReturnType<typeof useInviteStudentsMutation>;
export type InviteStudentsMutationResult = Apollo.MutationResult<InviteStudentsMutation>;
export type InviteStudentsMutationOptions = Apollo.BaseMutationOptions<InviteStudentsMutation, InviteStudentsMutationVariables>;
export const RevokeInvitationDocument = gql`
    mutation revokeInvitation($userIds: [Int!], $schoolId: Int!) {
  revokeInvitation(userIds: $userIds, schoolId: $schoolId)
}
    `;
export type RevokeInvitationMutationFn = Apollo.MutationFunction<RevokeInvitationMutation, RevokeInvitationMutationVariables>;

/**
 * __useRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationMutation, { data, loading, error }] = useRevokeInvitationMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useRevokeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RevokeInvitationMutation, RevokeInvitationMutationVariables>) {
        return Apollo.useMutation<RevokeInvitationMutation, RevokeInvitationMutationVariables>(RevokeInvitationDocument, baseOptions);
      }
export type RevokeInvitationMutationHookResult = ReturnType<typeof useRevokeInvitationMutation>;
export type RevokeInvitationMutationResult = Apollo.MutationResult<RevokeInvitationMutation>;
export type RevokeInvitationMutationOptions = Apollo.BaseMutationOptions<RevokeInvitationMutation, RevokeInvitationMutationVariables>;
export const UnarchiveStudentsDocument = gql`
    mutation unarchiveStudents($studentIds: [Int!], $schoolId: Int!) {
  unarchiveStudents(studentIds: $studentIds, schoolId: $schoolId) {
    id
    isArchived
  }
}
    `;
export type UnarchiveStudentsMutationFn = Apollo.MutationFunction<UnarchiveStudentsMutation, UnarchiveStudentsMutationVariables>;

/**
 * __useUnarchiveStudentsMutation__
 *
 * To run a mutation, you first call `useUnarchiveStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveStudentsMutation, { data, loading, error }] = useUnarchiveStudentsMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useUnarchiveStudentsMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveStudentsMutation, UnarchiveStudentsMutationVariables>) {
        return Apollo.useMutation<UnarchiveStudentsMutation, UnarchiveStudentsMutationVariables>(UnarchiveStudentsDocument, baseOptions);
      }
export type UnarchiveStudentsMutationHookResult = ReturnType<typeof useUnarchiveStudentsMutation>;
export type UnarchiveStudentsMutationResult = Apollo.MutationResult<UnarchiveStudentsMutation>;
export type UnarchiveStudentsMutationOptions = Apollo.BaseMutationOptions<UnarchiveStudentsMutation, UnarchiveStudentsMutationVariables>;
export const DeleteTeacherDocument = gql`
    mutation deleteTeacher($id: ID!) {
  deleteTeacher(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteTeacherMutationFn = Apollo.MutationFunction<DeleteTeacherMutation, DeleteTeacherMutationVariables>;

/**
 * __useDeleteTeacherMutation__
 *
 * To run a mutation, you first call `useDeleteTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeacherMutation, { data, loading, error }] = useDeleteTeacherMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeacherMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeacherMutation, DeleteTeacherMutationVariables>) {
        return Apollo.useMutation<DeleteTeacherMutation, DeleteTeacherMutationVariables>(DeleteTeacherDocument, baseOptions);
      }
export type DeleteTeacherMutationHookResult = ReturnType<typeof useDeleteTeacherMutation>;
export type DeleteTeacherMutationResult = Apollo.MutationResult<DeleteTeacherMutation>;
export type DeleteTeacherMutationOptions = Apollo.BaseMutationOptions<DeleteTeacherMutation, DeleteTeacherMutationVariables>;
export const InviteTeacherDocument = gql`
    mutation inviteTeacher($email: String!, $firstName: String!, $lastName: String!, $schoolId: Int!) {
  inviteTeacher(
    data: {email: $email, firstName: $firstName, lastName: $lastName}
    schoolId: $schoolId
  ) {
    teachersForNewUsers {
      id
    }
    existingTeachers {
      id
    }
    teachersForExistingUsers {
      id
    }
  }
}
    `;
export type InviteTeacherMutationFn = Apollo.MutationFunction<InviteTeacherMutation, InviteTeacherMutationVariables>;

/**
 * __useInviteTeacherMutation__
 *
 * To run a mutation, you first call `useInviteTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeacherMutation, { data, loading, error }] = useInviteTeacherMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useInviteTeacherMutation(baseOptions?: Apollo.MutationHookOptions<InviteTeacherMutation, InviteTeacherMutationVariables>) {
        return Apollo.useMutation<InviteTeacherMutation, InviteTeacherMutationVariables>(InviteTeacherDocument, baseOptions);
      }
export type InviteTeacherMutationHookResult = ReturnType<typeof useInviteTeacherMutation>;
export type InviteTeacherMutationResult = Apollo.MutationResult<InviteTeacherMutation>;
export type InviteTeacherMutationOptions = Apollo.BaseMutationOptions<InviteTeacherMutation, InviteTeacherMutationVariables>;
export const ResendTeacherOrSchoolAdminInvitationDocument = gql`
    mutation resendTeacherOrSchoolAdminInvitation($userId: Int!, $schoolId: Int!) {
  resendTeacherOrSchoolAdminInvitation(userId: $userId, schoolId: $schoolId) {
    success
  }
}
    `;
export type ResendTeacherOrSchoolAdminInvitationMutationFn = Apollo.MutationFunction<ResendTeacherOrSchoolAdminInvitationMutation, ResendTeacherOrSchoolAdminInvitationMutationVariables>;

/**
 * __useResendTeacherOrSchoolAdminInvitationMutation__
 *
 * To run a mutation, you first call `useResendTeacherOrSchoolAdminInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTeacherOrSchoolAdminInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTeacherOrSchoolAdminInvitationMutation, { data, loading, error }] = useResendTeacherOrSchoolAdminInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useResendTeacherOrSchoolAdminInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendTeacherOrSchoolAdminInvitationMutation, ResendTeacherOrSchoolAdminInvitationMutationVariables>) {
        return Apollo.useMutation<ResendTeacherOrSchoolAdminInvitationMutation, ResendTeacherOrSchoolAdminInvitationMutationVariables>(ResendTeacherOrSchoolAdminInvitationDocument, baseOptions);
      }
export type ResendTeacherOrSchoolAdminInvitationMutationHookResult = ReturnType<typeof useResendTeacherOrSchoolAdminInvitationMutation>;
export type ResendTeacherOrSchoolAdminInvitationMutationResult = Apollo.MutationResult<ResendTeacherOrSchoolAdminInvitationMutation>;
export type ResendTeacherOrSchoolAdminInvitationMutationOptions = Apollo.BaseMutationOptions<ResendTeacherOrSchoolAdminInvitationMutation, ResendTeacherOrSchoolAdminInvitationMutationVariables>;
export const CompleteInterestInventoryTestDocument = gql`
    mutation completeInterestInventoryTest($id: Int!) {
  completeInterestInventoryTest(testId: $id) {
    success
  }
}
    `;
export type CompleteInterestInventoryTestMutationFn = Apollo.MutationFunction<CompleteInterestInventoryTestMutation, CompleteInterestInventoryTestMutationVariables>;

/**
 * __useCompleteInterestInventoryTestMutation__
 *
 * To run a mutation, you first call `useCompleteInterestInventoryTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInterestInventoryTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInterestInventoryTestMutation, { data, loading, error }] = useCompleteInterestInventoryTestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteInterestInventoryTestMutation(baseOptions?: Apollo.MutationHookOptions<CompleteInterestInventoryTestMutation, CompleteInterestInventoryTestMutationVariables>) {
        return Apollo.useMutation<CompleteInterestInventoryTestMutation, CompleteInterestInventoryTestMutationVariables>(CompleteInterestInventoryTestDocument, baseOptions);
      }
export type CompleteInterestInventoryTestMutationHookResult = ReturnType<typeof useCompleteInterestInventoryTestMutation>;
export type CompleteInterestInventoryTestMutationResult = Apollo.MutationResult<CompleteInterestInventoryTestMutation>;
export type CompleteInterestInventoryTestMutationOptions = Apollo.BaseMutationOptions<CompleteInterestInventoryTestMutation, CompleteInterestInventoryTestMutationVariables>;
export const ResetInterestInventoriesDocument = gql`
    mutation resetInterestInventories($testAttemptsIds: [Int]!) {
  resetManyInterestInventories(testAttemptsIds: $testAttemptsIds) {
    successReset {
      message
    }
    errors {
      message
    }
    warnings {
      message
    }
  }
}
    `;
export type ResetInterestInventoriesMutationFn = Apollo.MutationFunction<ResetInterestInventoriesMutation, ResetInterestInventoriesMutationVariables>;

/**
 * __useResetInterestInventoriesMutation__
 *
 * To run a mutation, you first call `useResetInterestInventoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetInterestInventoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetInterestInventoriesMutation, { data, loading, error }] = useResetInterestInventoriesMutation({
 *   variables: {
 *      testAttemptsIds: // value for 'testAttemptsIds'
 *   },
 * });
 */
export function useResetInterestInventoriesMutation(baseOptions?: Apollo.MutationHookOptions<ResetInterestInventoriesMutation, ResetInterestInventoriesMutationVariables>) {
        return Apollo.useMutation<ResetInterestInventoriesMutation, ResetInterestInventoriesMutationVariables>(ResetInterestInventoriesDocument, baseOptions);
      }
export type ResetInterestInventoriesMutationHookResult = ReturnType<typeof useResetInterestInventoriesMutation>;
export type ResetInterestInventoriesMutationResult = Apollo.MutationResult<ResetInterestInventoriesMutation>;
export type ResetInterestInventoriesMutationOptions = Apollo.BaseMutationOptions<ResetInterestInventoriesMutation, ResetInterestInventoriesMutationVariables>;
export const StartInterestInventoryTestDocument = gql`
    mutation startInterestInventoryTest($id: Int!) {
  startInterestInventoryTest(testId: $id) {
    success
  }
}
    `;
export type StartInterestInventoryTestMutationFn = Apollo.MutationFunction<StartInterestInventoryTestMutation, StartInterestInventoryTestMutationVariables>;

/**
 * __useStartInterestInventoryTestMutation__
 *
 * To run a mutation, you first call `useStartInterestInventoryTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartInterestInventoryTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startInterestInventoryTestMutation, { data, loading, error }] = useStartInterestInventoryTestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartInterestInventoryTestMutation(baseOptions?: Apollo.MutationHookOptions<StartInterestInventoryTestMutation, StartInterestInventoryTestMutationVariables>) {
        return Apollo.useMutation<StartInterestInventoryTestMutation, StartInterestInventoryTestMutationVariables>(StartInterestInventoryTestDocument, baseOptions);
      }
export type StartInterestInventoryTestMutationHookResult = ReturnType<typeof useStartInterestInventoryTestMutation>;
export type StartInterestInventoryTestMutationResult = Apollo.MutationResult<StartInterestInventoryTestMutation>;
export type StartInterestInventoryTestMutationOptions = Apollo.BaseMutationOptions<StartInterestInventoryTestMutation, StartInterestInventoryTestMutationVariables>;
export const StoreInterestInventoryAnswerDocument = gql`
    mutation storeInterestInventoryAnswer($testId: Int!, $interestQuestionId: Int!, $answer: Int!) {
  storeInterestAnswer(
    testId: $testId
    interestQuestionId: $interestQuestionId
    answer: $answer
  ) {
    success
  }
}
    `;
export type StoreInterestInventoryAnswerMutationFn = Apollo.MutationFunction<StoreInterestInventoryAnswerMutation, StoreInterestInventoryAnswerMutationVariables>;

/**
 * __useStoreInterestInventoryAnswerMutation__
 *
 * To run a mutation, you first call `useStoreInterestInventoryAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreInterestInventoryAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeInterestInventoryAnswerMutation, { data, loading, error }] = useStoreInterestInventoryAnswerMutation({
 *   variables: {
 *      testId: // value for 'testId'
 *      interestQuestionId: // value for 'interestQuestionId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useStoreInterestInventoryAnswerMutation(baseOptions?: Apollo.MutationHookOptions<StoreInterestInventoryAnswerMutation, StoreInterestInventoryAnswerMutationVariables>) {
        return Apollo.useMutation<StoreInterestInventoryAnswerMutation, StoreInterestInventoryAnswerMutationVariables>(StoreInterestInventoryAnswerDocument, baseOptions);
      }
export type StoreInterestInventoryAnswerMutationHookResult = ReturnType<typeof useStoreInterestInventoryAnswerMutation>;
export type StoreInterestInventoryAnswerMutationResult = Apollo.MutationResult<StoreInterestInventoryAnswerMutation>;
export type StoreInterestInventoryAnswerMutationOptions = Apollo.BaseMutationOptions<StoreInterestInventoryAnswerMutation, StoreInterestInventoryAnswerMutationVariables>;
export const AuthenticateUserWithPasswordDocument = gql`
    mutation authenticateUserWithPassword($email: String!, $password: String!) {
  authenticateUserWithPassword(email: $email, password: $password) {
    ... on UserAuthenticationWithPasswordSuccess {
      sessionToken
    }
    ... on UserAuthenticationWithPasswordFailure {
      message
    }
  }
}
    `;
export type AuthenticateUserWithPasswordMutationFn = Apollo.MutationFunction<AuthenticateUserWithPasswordMutation, AuthenticateUserWithPasswordMutationVariables>;

/**
 * __useAuthenticateUserWithPasswordMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserWithPasswordMutation, { data, loading, error }] = useAuthenticateUserWithPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateUserWithPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserWithPasswordMutation, AuthenticateUserWithPasswordMutationVariables>) {
        return Apollo.useMutation<AuthenticateUserWithPasswordMutation, AuthenticateUserWithPasswordMutationVariables>(AuthenticateUserWithPasswordDocument, baseOptions);
      }
export type AuthenticateUserWithPasswordMutationHookResult = ReturnType<typeof useAuthenticateUserWithPasswordMutation>;
export type AuthenticateUserWithPasswordMutationResult = Apollo.MutationResult<AuthenticateUserWithPasswordMutation>;
export type AuthenticateUserWithPasswordMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserWithPasswordMutation, AuthenticateUserWithPasswordMutationVariables>;
export const RegenerateInvitationLinkDocument = gql`
    mutation regenerateInvitationLink($token: String!) {
  regenerateInvitationLink(token: $token) {
    token
  }
}
    `;
export type RegenerateInvitationLinkMutationFn = Apollo.MutationFunction<RegenerateInvitationLinkMutation, RegenerateInvitationLinkMutationVariables>;

/**
 * __useRegenerateInvitationLinkMutation__
 *
 * To run a mutation, you first call `useRegenerateInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateInvitationLinkMutation, { data, loading, error }] = useRegenerateInvitationLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegenerateInvitationLinkMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateInvitationLinkMutation, RegenerateInvitationLinkMutationVariables>) {
        return Apollo.useMutation<RegenerateInvitationLinkMutation, RegenerateInvitationLinkMutationVariables>(RegenerateInvitationLinkDocument, baseOptions);
      }
export type RegenerateInvitationLinkMutationHookResult = ReturnType<typeof useRegenerateInvitationLinkMutation>;
export type RegenerateInvitationLinkMutationResult = Apollo.MutationResult<RegenerateInvitationLinkMutation>;
export type RegenerateInvitationLinkMutationOptions = Apollo.BaseMutationOptions<RegenerateInvitationLinkMutation, RegenerateInvitationLinkMutationVariables>;
export const RemindPasswordDocument = gql`
    mutation remindPassword($email: String!) {
  remindPassword(email: $email) {
    success
  }
}
    `;
export type RemindPasswordMutationFn = Apollo.MutationFunction<RemindPasswordMutation, RemindPasswordMutationVariables>;

/**
 * __useRemindPasswordMutation__
 *
 * To run a mutation, you first call `useRemindPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemindPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remindPasswordMutation, { data, loading, error }] = useRemindPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemindPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RemindPasswordMutation, RemindPasswordMutationVariables>) {
        return Apollo.useMutation<RemindPasswordMutation, RemindPasswordMutationVariables>(RemindPasswordDocument, baseOptions);
      }
export type RemindPasswordMutationHookResult = ReturnType<typeof useRemindPasswordMutation>;
export type RemindPasswordMutationResult = Apollo.MutationResult<RemindPasswordMutation>;
export type RemindPasswordMutationOptions = Apollo.BaseMutationOptions<RemindPasswordMutation, RemindPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $email: String, $firstName: String, $lastName: String, $password: String) {
  updateUser(
    where: {id: $id}
    data: {firstName: $firstName, lastName: $lastName, password: $password, email: $email}
  ) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CloseTutorialVideoDocument = gql`
    mutation closeTutorialVideo($videoId: Int!) {
  closeTutorialVideo(videoId: $videoId) {
    success
  }
}
    `;
export type CloseTutorialVideoMutationFn = Apollo.MutationFunction<CloseTutorialVideoMutation, CloseTutorialVideoMutationVariables>;

/**
 * __useCloseTutorialVideoMutation__
 *
 * To run a mutation, you first call `useCloseTutorialVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTutorialVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTutorialVideoMutation, { data, loading, error }] = useCloseTutorialVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useCloseTutorialVideoMutation(baseOptions?: Apollo.MutationHookOptions<CloseTutorialVideoMutation, CloseTutorialVideoMutationVariables>) {
        return Apollo.useMutation<CloseTutorialVideoMutation, CloseTutorialVideoMutationVariables>(CloseTutorialVideoDocument, baseOptions);
      }
export type CloseTutorialVideoMutationHookResult = ReturnType<typeof useCloseTutorialVideoMutation>;
export type CloseTutorialVideoMutationResult = Apollo.MutationResult<CloseTutorialVideoMutation>;
export type CloseTutorialVideoMutationOptions = Apollo.BaseMutationOptions<CloseTutorialVideoMutation, CloseTutorialVideoMutationVariables>;
export const CompleteVideoViewDocument = gql`
    mutation completeVideoView($videoViewId: Int!) {
  completeVideoView(videoViewId: $videoViewId) {
    success
  }
}
    `;
export type CompleteVideoViewMutationFn = Apollo.MutationFunction<CompleteVideoViewMutation, CompleteVideoViewMutationVariables>;

/**
 * __useCompleteVideoViewMutation__
 *
 * To run a mutation, you first call `useCompleteVideoViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVideoViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVideoViewMutation, { data, loading, error }] = useCompleteVideoViewMutation({
 *   variables: {
 *      videoViewId: // value for 'videoViewId'
 *   },
 * });
 */
export function useCompleteVideoViewMutation(baseOptions?: Apollo.MutationHookOptions<CompleteVideoViewMutation, CompleteVideoViewMutationVariables>) {
        return Apollo.useMutation<CompleteVideoViewMutation, CompleteVideoViewMutationVariables>(CompleteVideoViewDocument, baseOptions);
      }
export type CompleteVideoViewMutationHookResult = ReturnType<typeof useCompleteVideoViewMutation>;
export type CompleteVideoViewMutationResult = Apollo.MutationResult<CompleteVideoViewMutation>;
export type CompleteVideoViewMutationOptions = Apollo.BaseMutationOptions<CompleteVideoViewMutation, CompleteVideoViewMutationVariables>;
export const StartVideoWatchingDocument = gql`
    mutation startVideoWatching($videoId: Int!) {
  startVideoWatching(videoId: $videoId) {
    success
  }
}
    `;
export type StartVideoWatchingMutationFn = Apollo.MutationFunction<StartVideoWatchingMutation, StartVideoWatchingMutationVariables>;

/**
 * __useStartVideoWatchingMutation__
 *
 * To run a mutation, you first call `useStartVideoWatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartVideoWatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startVideoWatchingMutation, { data, loading, error }] = useStartVideoWatchingMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useStartVideoWatchingMutation(baseOptions?: Apollo.MutationHookOptions<StartVideoWatchingMutation, StartVideoWatchingMutationVariables>) {
        return Apollo.useMutation<StartVideoWatchingMutation, StartVideoWatchingMutationVariables>(StartVideoWatchingDocument, baseOptions);
      }
export type StartVideoWatchingMutationHookResult = ReturnType<typeof useStartVideoWatchingMutation>;
export type StartVideoWatchingMutationResult = Apollo.MutationResult<StartVideoWatchingMutation>;
export type StartVideoWatchingMutationOptions = Apollo.BaseMutationOptions<StartVideoWatchingMutation, StartVideoWatchingMutationVariables>;
export const GetAuthenticatedUserDocument = gql`
    query getAuthenticatedUser {
  authenticatedItem {
    ... on User {
      lastName
      firstName
      email
      id
      dateOfBirth
      isAdmin
      gender
      isConfirmed
      teachers {
        school {
          id
          name
          isIndividual
          credit {
            balance
          }
        }
      }
      adminOfSchools {
        id
        isIndividual
      }
      students {
        id
        testAttempt {
          id
          isCompleted
          isReportPublished
          reportName
        }
        school {
          id
          name
          country {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>) {
        return Apollo.useQuery<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>(GetAuthenticatedUserDocument, baseOptions);
      }
export function useGetAuthenticatedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>) {
          return Apollo.useLazyQuery<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>(GetAuthenticatedUserDocument, baseOptions);
        }
export type GetAuthenticatedUserQueryHookResult = ReturnType<typeof useGetAuthenticatedUserQuery>;
export type GetAuthenticatedUserLazyQueryHookResult = ReturnType<typeof useGetAuthenticatedUserLazyQuery>;
export type GetAuthenticatedUserQueryResult = Apollo.QueryResult<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>;
export const UnauthenticateUserDocument = gql`
    mutation unauthenticateUser {
  endSession
}
    `;
export type UnauthenticateUserMutationFn = Apollo.MutationFunction<UnauthenticateUserMutation, UnauthenticateUserMutationVariables>;

/**
 * __useUnauthenticateUserMutation__
 *
 * To run a mutation, you first call `useUnauthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnauthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unauthenticateUserMutation, { data, loading, error }] = useUnauthenticateUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnauthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<UnauthenticateUserMutation, UnauthenticateUserMutationVariables>) {
        return Apollo.useMutation<UnauthenticateUserMutation, UnauthenticateUserMutationVariables>(UnauthenticateUserDocument, baseOptions);
      }
export type UnauthenticateUserMutationHookResult = ReturnType<typeof useUnauthenticateUserMutation>;
export type UnauthenticateUserMutationResult = Apollo.MutationResult<UnauthenticateUserMutation>;
export type UnauthenticateUserMutationOptions = Apollo.BaseMutationOptions<UnauthenticateUserMutation, UnauthenticateUserMutationVariables>;
export const GetAllCountriesDocument = gql`
    query getAllCountries {
  countries {
    id
    code
    name
  }
}
    `;

/**
 * __useGetAllCountriesQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
        return Apollo.useQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, baseOptions);
      }
export function useGetAllCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, baseOptions);
        }
export type GetAllCountriesQueryHookResult = ReturnType<typeof useGetAllCountriesQuery>;
export type GetAllCountriesLazyQueryHookResult = ReturnType<typeof useGetAllCountriesLazyQuery>;
export type GetAllCountriesQueryResult = Apollo.QueryResult<GetAllCountriesQuery, GetAllCountriesQueryVariables>;
export const GetAllEmailTemplatesDocument = gql`
    query getAllEmailTemplates {
  emailTemplates {
    title
    content
    type
  }
}
    `;

/**
 * __useGetAllEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmailTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEmailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables>) {
        return Apollo.useQuery<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables>(GetAllEmailTemplatesDocument, baseOptions);
      }
export function useGetAllEmailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables>(GetAllEmailTemplatesDocument, baseOptions);
        }
export type GetAllEmailTemplatesQueryHookResult = ReturnType<typeof useGetAllEmailTemplatesQuery>;
export type GetAllEmailTemplatesLazyQueryHookResult = ReturnType<typeof useGetAllEmailTemplatesLazyQuery>;
export type GetAllEmailTemplatesQueryResult = Apollo.QueryResult<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables>;
export const GetFeedbackFormWithResponseDocument = gql`
    query getFeedbackFormWithResponse($formType: String!) {
  findFeedbackFormWithResponse(formType: $formType) {
    id
    formId
    formType
    modal {
      id
      modalTitle
      modalContent
    }
    responses {
      id
      isSubmitted
    }
  }
}
    `;

/**
 * __useGetFeedbackFormWithResponseQuery__
 *
 * To run a query within a React component, call `useGetFeedbackFormWithResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackFormWithResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackFormWithResponseQuery({
 *   variables: {
 *      formType: // value for 'formType'
 *   },
 * });
 */
export function useGetFeedbackFormWithResponseQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackFormWithResponseQuery, GetFeedbackFormWithResponseQueryVariables>) {
        return Apollo.useQuery<GetFeedbackFormWithResponseQuery, GetFeedbackFormWithResponseQueryVariables>(GetFeedbackFormWithResponseDocument, baseOptions);
      }
export function useGetFeedbackFormWithResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackFormWithResponseQuery, GetFeedbackFormWithResponseQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackFormWithResponseQuery, GetFeedbackFormWithResponseQueryVariables>(GetFeedbackFormWithResponseDocument, baseOptions);
        }
export type GetFeedbackFormWithResponseQueryHookResult = ReturnType<typeof useGetFeedbackFormWithResponseQuery>;
export type GetFeedbackFormWithResponseLazyQueryHookResult = ReturnType<typeof useGetFeedbackFormWithResponseLazyQuery>;
export type GetFeedbackFormWithResponseQueryResult = Apollo.QueryResult<GetFeedbackFormWithResponseQuery, GetFeedbackFormWithResponseQueryVariables>;
export const GetFeedbackResponseByTokenDocument = gql`
    query getFeedbackResponseByToken($token: String!) {
  findFeedbackResponseByToken(token: $token) {
    anotherFeedbackFormType
    url
    isSubmitted
    feedbackResponse {
      isSubmitted
      form {
        id
        formId
        formType
        modal {
          id
          modalTitle
          modalContent
        }
      }
    }
  }
}
    `;

/**
 * __useGetFeedbackResponseByTokenQuery__
 *
 * To run a query within a React component, call `useGetFeedbackResponseByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackResponseByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackResponseByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetFeedbackResponseByTokenQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackResponseByTokenQuery, GetFeedbackResponseByTokenQueryVariables>) {
        return Apollo.useQuery<GetFeedbackResponseByTokenQuery, GetFeedbackResponseByTokenQueryVariables>(GetFeedbackResponseByTokenDocument, baseOptions);
      }
export function useGetFeedbackResponseByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackResponseByTokenQuery, GetFeedbackResponseByTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackResponseByTokenQuery, GetFeedbackResponseByTokenQueryVariables>(GetFeedbackResponseByTokenDocument, baseOptions);
        }
export type GetFeedbackResponseByTokenQueryHookResult = ReturnType<typeof useGetFeedbackResponseByTokenQuery>;
export type GetFeedbackResponseByTokenLazyQueryHookResult = ReturnType<typeof useGetFeedbackResponseByTokenLazyQuery>;
export type GetFeedbackResponseByTokenQueryResult = Apollo.QueryResult<GetFeedbackResponseByTokenQuery, GetFeedbackResponseByTokenQueryVariables>;
export const GetAllFolderCountriesDocument = gql`
    query getAllFolderCountries($orderBy: [CountryOrderByInput!]!) {
  countries(orderBy: $orderBy) {
    code
    flag
    id
    name
  }
}
    `;

/**
 * __useGetAllFolderCountriesQuery__
 *
 * To run a query within a React component, call `useGetAllFolderCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFolderCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFolderCountriesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllFolderCountriesQuery(baseOptions: Apollo.QueryHookOptions<GetAllFolderCountriesQuery, GetAllFolderCountriesQueryVariables>) {
        return Apollo.useQuery<GetAllFolderCountriesQuery, GetAllFolderCountriesQueryVariables>(GetAllFolderCountriesDocument, baseOptions);
      }
export function useGetAllFolderCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFolderCountriesQuery, GetAllFolderCountriesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllFolderCountriesQuery, GetAllFolderCountriesQueryVariables>(GetAllFolderCountriesDocument, baseOptions);
        }
export type GetAllFolderCountriesQueryHookResult = ReturnType<typeof useGetAllFolderCountriesQuery>;
export type GetAllFolderCountriesLazyQueryHookResult = ReturnType<typeof useGetAllFolderCountriesLazyQuery>;
export type GetAllFolderCountriesQueryResult = Apollo.QueryResult<GetAllFolderCountriesQuery, GetAllFolderCountriesQueryVariables>;
export const GetAllFoldersDocument = gql`
    query getAllFolders($where: FolderWhereInput!, $orderBy: [FolderOrderByInput!]!) {
  folders(where: $where, orderBy: $orderBy) {
    name
    createdAt_utc
    studentsCount
    id
    countryModel {
      id
      flag
      code
    }
  }
}
    `;

/**
 * __useGetAllFoldersQuery__
 *
 * To run a query within a React component, call `useGetAllFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFoldersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllFoldersQuery(baseOptions: Apollo.QueryHookOptions<GetAllFoldersQuery, GetAllFoldersQueryVariables>) {
        return Apollo.useQuery<GetAllFoldersQuery, GetAllFoldersQueryVariables>(GetAllFoldersDocument, baseOptions);
      }
export function useGetAllFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFoldersQuery, GetAllFoldersQueryVariables>) {
          return Apollo.useLazyQuery<GetAllFoldersQuery, GetAllFoldersQueryVariables>(GetAllFoldersDocument, baseOptions);
        }
export type GetAllFoldersQueryHookResult = ReturnType<typeof useGetAllFoldersQuery>;
export type GetAllFoldersLazyQueryHookResult = ReturnType<typeof useGetAllFoldersLazyQuery>;
export type GetAllFoldersQueryResult = Apollo.QueryResult<GetAllFoldersQuery, GetAllFoldersQueryVariables>;
export const GetAllFoldersCountDocument = gql`
    query getAllFoldersCount($where: FolderWhereInput!) {
  foldersCount(where: $where)
}
    `;

/**
 * __useGetAllFoldersCountQuery__
 *
 * To run a query within a React component, call `useGetAllFoldersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFoldersCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllFoldersCountQuery(baseOptions: Apollo.QueryHookOptions<GetAllFoldersCountQuery, GetAllFoldersCountQueryVariables>) {
        return Apollo.useQuery<GetAllFoldersCountQuery, GetAllFoldersCountQueryVariables>(GetAllFoldersCountDocument, baseOptions);
      }
export function useGetAllFoldersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFoldersCountQuery, GetAllFoldersCountQueryVariables>) {
          return Apollo.useLazyQuery<GetAllFoldersCountQuery, GetAllFoldersCountQueryVariables>(GetAllFoldersCountDocument, baseOptions);
        }
export type GetAllFoldersCountQueryHookResult = ReturnType<typeof useGetAllFoldersCountQuery>;
export type GetAllFoldersCountLazyQueryHookResult = ReturnType<typeof useGetAllFoldersCountLazyQuery>;
export type GetAllFoldersCountQueryResult = Apollo.QueryResult<GetAllFoldersCountQuery, GetAllFoldersCountQueryVariables>;
export const GetAllFoldersPaginatedDocument = gql`
    query getAllFoldersPaginated($where: FolderWhereInput!, $orderBy: [FolderOrderByInput!]!, $take: Int, $skip: Int!) {
  folders(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    name
    createdAt_utc
    studentsCount
    id
    countryModel {
      id
      flag
      code
    }
  }
}
    `;

/**
 * __useGetAllFoldersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetAllFoldersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFoldersPaginatedQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAllFoldersPaginatedQuery(baseOptions: Apollo.QueryHookOptions<GetAllFoldersPaginatedQuery, GetAllFoldersPaginatedQueryVariables>) {
        return Apollo.useQuery<GetAllFoldersPaginatedQuery, GetAllFoldersPaginatedQueryVariables>(GetAllFoldersPaginatedDocument, baseOptions);
      }
export function useGetAllFoldersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFoldersPaginatedQuery, GetAllFoldersPaginatedQueryVariables>) {
          return Apollo.useLazyQuery<GetAllFoldersPaginatedQuery, GetAllFoldersPaginatedQueryVariables>(GetAllFoldersPaginatedDocument, baseOptions);
        }
export type GetAllFoldersPaginatedQueryHookResult = ReturnType<typeof useGetAllFoldersPaginatedQuery>;
export type GetAllFoldersPaginatedLazyQueryHookResult = ReturnType<typeof useGetAllFoldersPaginatedLazyQuery>;
export type GetAllFoldersPaginatedQueryResult = Apollo.QueryResult<GetAllFoldersPaginatedQuery, GetAllFoldersPaginatedQueryVariables>;
export const GetAllSchoolsDocument = gql`
    query getAllSchools {
  schools {
    name
    address
    code
    id
    teachers {
      user {
        id
        firstName
        lastName
        email
        isAdmin
      }
    }
    schoolAdmins {
      id
      firstName
      lastName
      email
    }
    students {
      user {
        id
        firstName
        lastName
        email
        isAdmin
      }
    }
  }
}
    `;

/**
 * __useGetAllSchoolsQuery__
 *
 * To run a query within a React component, call `useGetAllSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>) {
        return Apollo.useQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(GetAllSchoolsDocument, baseOptions);
      }
export function useGetAllSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>) {
          return Apollo.useLazyQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(GetAllSchoolsDocument, baseOptions);
        }
export type GetAllSchoolsQueryHookResult = ReturnType<typeof useGetAllSchoolsQuery>;
export type GetAllSchoolsLazyQueryHookResult = ReturnType<typeof useGetAllSchoolsLazyQuery>;
export type GetAllSchoolsQueryResult = Apollo.QueryResult<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>;
export const GetAllTeachersDocument = gql`
    query getAllTeachers($id: ID!) {
  teachers(
    where: {AND: [{school: {id: {equals: $id}}}, {user: {isDeleted: {equals: false}}}]}
  ) {
    id
    user {
      firstName
      lastName
      email
      isAdmin
      isDeleted
      id
      isConfirmed
    }
  }
  school(where: {id: $id}) {
    schoolAdmins {
      firstName
      lastName
      email
      isAdmin
      isDeleted
      isConfirmed
      id
    }
  }
}
    `;

/**
 * __useGetAllTeachersQuery__
 *
 * To run a query within a React component, call `useGetAllTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetAllTeachersQuery, GetAllTeachersQueryVariables>) {
        return Apollo.useQuery<GetAllTeachersQuery, GetAllTeachersQueryVariables>(GetAllTeachersDocument, baseOptions);
      }
export function useGetAllTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTeachersQuery, GetAllTeachersQueryVariables>) {
          return Apollo.useLazyQuery<GetAllTeachersQuery, GetAllTeachersQueryVariables>(GetAllTeachersDocument, baseOptions);
        }
export type GetAllTeachersQueryHookResult = ReturnType<typeof useGetAllTeachersQuery>;
export type GetAllTeachersLazyQueryHookResult = ReturnType<typeof useGetAllTeachersLazyQuery>;
export type GetAllTeachersQueryResult = Apollo.QueryResult<GetAllTeachersQuery, GetAllTeachersQueryVariables>;
export const GetSchoolDocument = gql`
    query getSchool($id: ID!) {
  authenticatedItem {
    ... on User {
      id
    }
  }
  school(where: {id: $id}) {
    name
    id
    phone
    website
    address
    testPrice
    code
    principalName
    zip
    state
    city
    country {
      name
      id
    }
    email
    hasRemindersOn
    credit {
      id
      balance
    }
    teachers {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
    schoolAdmins {
      id
    }
  }
}
    `;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
        return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
      }
export function useGetSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
          return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
        }
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<typeof useGetSchoolLazyQuery>;
export type GetSchoolQueryResult = Apollo.QueryResult<GetSchoolQuery, GetSchoolQueryVariables>;
export const GetSponsorsDocument = gql`
    query getSponsors($date: DateTime!, $countryId: ID!) {
  sponsors(
    where: {from: {lte: $date}, to: {gte: $date}, country: {id: {equals: $countryId}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetSponsorsQuery__
 *
 * To run a query within a React component, call `useGetSponsorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetSponsorsQuery(baseOptions: Apollo.QueryHookOptions<GetSponsorsQuery, GetSponsorsQueryVariables>) {
        return Apollo.useQuery<GetSponsorsQuery, GetSponsorsQueryVariables>(GetSponsorsDocument, baseOptions);
      }
export function useGetSponsorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSponsorsQuery, GetSponsorsQueryVariables>) {
          return Apollo.useLazyQuery<GetSponsorsQuery, GetSponsorsQueryVariables>(GetSponsorsDocument, baseOptions);
        }
export type GetSponsorsQueryHookResult = ReturnType<typeof useGetSponsorsQuery>;
export type GetSponsorsLazyQueryHookResult = ReturnType<typeof useGetSponsorsLazyQuery>;
export type GetSponsorsQueryResult = Apollo.QueryResult<GetSponsorsQuery, GetSponsorsQueryVariables>;
export const GetArchivedStudentsDocument = gql`
    query getArchivedStudents($searchTerm: String, $schoolId: Int!, $schoolYear: Int, $first: Int!, $skip: Int!) {
  allArchivedStudents(
    name: $searchTerm
    schoolId: $schoolId
    schoolYear: $schoolYear
    first: $first
    skip: $skip
  ) {
    status
    id
    testAttempt {
      id
      reportName
    }
    user {
      id
      firstName
      lastName
      email
      isConfirmed
    }
  }
}
    `;

/**
 * __useGetArchivedStudentsQuery__
 *
 * To run a query within a React component, call `useGetArchivedStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedStudentsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      schoolId: // value for 'schoolId'
 *      schoolYear: // value for 'schoolYear'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArchivedStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetArchivedStudentsQuery, GetArchivedStudentsQueryVariables>) {
        return Apollo.useQuery<GetArchivedStudentsQuery, GetArchivedStudentsQueryVariables>(GetArchivedStudentsDocument, baseOptions);
      }
export function useGetArchivedStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedStudentsQuery, GetArchivedStudentsQueryVariables>) {
          return Apollo.useLazyQuery<GetArchivedStudentsQuery, GetArchivedStudentsQueryVariables>(GetArchivedStudentsDocument, baseOptions);
        }
export type GetArchivedStudentsQueryHookResult = ReturnType<typeof useGetArchivedStudentsQuery>;
export type GetArchivedStudentsLazyQueryHookResult = ReturnType<typeof useGetArchivedStudentsLazyQuery>;
export type GetArchivedStudentsQueryResult = Apollo.QueryResult<GetArchivedStudentsQuery, GetArchivedStudentsQueryVariables>;
export const GetAllArchivedStudentsMetaDocument = gql`
    query getAllArchivedStudentsMeta($searchTerm: String, $schoolId: Int!, $schoolYear: Int) {
  allArchivedStudentsMeta(
    name: $searchTerm
    schoolId: $schoolId
    schoolYear: $schoolYear
  ) {
    count
  }
}
    `;

/**
 * __useGetAllArchivedStudentsMetaQuery__
 *
 * To run a query within a React component, call `useGetAllArchivedStudentsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArchivedStudentsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArchivedStudentsMetaQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      schoolId: // value for 'schoolId'
 *      schoolYear: // value for 'schoolYear'
 *   },
 * });
 */
export function useGetAllArchivedStudentsMetaQuery(baseOptions: Apollo.QueryHookOptions<GetAllArchivedStudentsMetaQuery, GetAllArchivedStudentsMetaQueryVariables>) {
        return Apollo.useQuery<GetAllArchivedStudentsMetaQuery, GetAllArchivedStudentsMetaQueryVariables>(GetAllArchivedStudentsMetaDocument, baseOptions);
      }
export function useGetAllArchivedStudentsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArchivedStudentsMetaQuery, GetAllArchivedStudentsMetaQueryVariables>) {
          return Apollo.useLazyQuery<GetAllArchivedStudentsMetaQuery, GetAllArchivedStudentsMetaQueryVariables>(GetAllArchivedStudentsMetaDocument, baseOptions);
        }
export type GetAllArchivedStudentsMetaQueryHookResult = ReturnType<typeof useGetAllArchivedStudentsMetaQuery>;
export type GetAllArchivedStudentsMetaLazyQueryHookResult = ReturnType<typeof useGetAllArchivedStudentsMetaLazyQuery>;
export type GetAllArchivedStudentsMetaQueryResult = Apollo.QueryResult<GetAllArchivedStudentsMetaQuery, GetAllArchivedStudentsMetaQueryVariables>;
export const GetArchivedStudentsYearsDocument = gql`
    query getArchivedStudentsYears($schoolId: Int!) {
  allArchivedStudentsYears(schoolId: $schoolId)
}
    `;

/**
 * __useGetArchivedStudentsYearsQuery__
 *
 * To run a query within a React component, call `useGetArchivedStudentsYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedStudentsYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedStudentsYearsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetArchivedStudentsYearsQuery(baseOptions: Apollo.QueryHookOptions<GetArchivedStudentsYearsQuery, GetArchivedStudentsYearsQueryVariables>) {
        return Apollo.useQuery<GetArchivedStudentsYearsQuery, GetArchivedStudentsYearsQueryVariables>(GetArchivedStudentsYearsDocument, baseOptions);
      }
export function useGetArchivedStudentsYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedStudentsYearsQuery, GetArchivedStudentsYearsQueryVariables>) {
          return Apollo.useLazyQuery<GetArchivedStudentsYearsQuery, GetArchivedStudentsYearsQueryVariables>(GetArchivedStudentsYearsDocument, baseOptions);
        }
export type GetArchivedStudentsYearsQueryHookResult = ReturnType<typeof useGetArchivedStudentsYearsQuery>;
export type GetArchivedStudentsYearsLazyQueryHookResult = ReturnType<typeof useGetArchivedStudentsYearsLazyQuery>;
export type GetArchivedStudentsYearsQueryResult = Apollo.QueryResult<GetArchivedStudentsYearsQuery, GetArchivedStudentsYearsQueryVariables>;
export const GetAllStudentsDocument = gql`
    query getAllStudents($searchTerm: String!, $schoolId: ID!, $take: Int, $skip: Int, $status: String, $isArchived: Boolean) {
  students(
    where: {AND: [{user: {OR: [{lastName: {contains: $searchTerm, mode: insensitive}}, {firstName: {contains: $searchTerm, mode: insensitive}}]}}, {school: {id: {equals: $schoolId}}}, {status: {equals: $status}}, {isArchived: {equals: $isArchived}}]}
    take: $take
    skip: $skip
  ) {
    status
    id
    testAttempt {
      id
      reportName
    }
    user {
      id
      firstName
      lastName
      email
      isConfirmed
    }
  }
}
    `;

/**
 * __useGetAllStudentsQuery__
 *
 * To run a query within a React component, call `useGetAllStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      schoolId: // value for 'schoolId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      status: // value for 'status'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useGetAllStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetAllStudentsQuery, GetAllStudentsQueryVariables>) {
        return Apollo.useQuery<GetAllStudentsQuery, GetAllStudentsQueryVariables>(GetAllStudentsDocument, baseOptions);
      }
export function useGetAllStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentsQuery, GetAllStudentsQueryVariables>) {
          return Apollo.useLazyQuery<GetAllStudentsQuery, GetAllStudentsQueryVariables>(GetAllStudentsDocument, baseOptions);
        }
export type GetAllStudentsQueryHookResult = ReturnType<typeof useGetAllStudentsQuery>;
export type GetAllStudentsLazyQueryHookResult = ReturnType<typeof useGetAllStudentsLazyQuery>;
export type GetAllStudentsQueryResult = Apollo.QueryResult<GetAllStudentsQuery, GetAllStudentsQueryVariables>;
export const GetAllStudentsMetaDocument = gql`
    query getAllStudentsMeta($where: StudentWhereInput!) {
  studentsCount(where: $where)
}
    `;

/**
 * __useGetAllStudentsMetaQuery__
 *
 * To run a query within a React component, call `useGetAllStudentsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentsMetaQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllStudentsMetaQuery(baseOptions: Apollo.QueryHookOptions<GetAllStudentsMetaQuery, GetAllStudentsMetaQueryVariables>) {
        return Apollo.useQuery<GetAllStudentsMetaQuery, GetAllStudentsMetaQueryVariables>(GetAllStudentsMetaDocument, baseOptions);
      }
export function useGetAllStudentsMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentsMetaQuery, GetAllStudentsMetaQueryVariables>) {
          return Apollo.useLazyQuery<GetAllStudentsMetaQuery, GetAllStudentsMetaQueryVariables>(GetAllStudentsMetaDocument, baseOptions);
        }
export type GetAllStudentsMetaQueryHookResult = ReturnType<typeof useGetAllStudentsMetaQuery>;
export type GetAllStudentsMetaLazyQueryHookResult = ReturnType<typeof useGetAllStudentsMetaLazyQuery>;
export type GetAllStudentsMetaQueryResult = Apollo.QueryResult<GetAllStudentsMetaQuery, GetAllStudentsMetaQueryVariables>;
export const GetAllStudentsMetaStatusDocument = gql`
    query getAllStudentsMetaStatus($whereCompleted: StudentWhereInput!, $whereNotStarted: StudentWhereInput!, $whereInProgress: StudentWhereInput!, $schoolId: ID!) {
  completed: studentsCount(where: $whereCompleted)
  notStarted: studentsCount(where: $whereNotStarted)
  inProgress: studentsCount(where: $whereInProgress)
  reportNotSent: allReportNotSentStudentsMeta(schoolId: $schoolId) {
    count
  }
}
    `;

/**
 * __useGetAllStudentsMetaStatusQuery__
 *
 * To run a query within a React component, call `useGetAllStudentsMetaStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentsMetaStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentsMetaStatusQuery({
 *   variables: {
 *      whereCompleted: // value for 'whereCompleted'
 *      whereNotStarted: // value for 'whereNotStarted'
 *      whereInProgress: // value for 'whereInProgress'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetAllStudentsMetaStatusQuery(baseOptions: Apollo.QueryHookOptions<GetAllStudentsMetaStatusQuery, GetAllStudentsMetaStatusQueryVariables>) {
        return Apollo.useQuery<GetAllStudentsMetaStatusQuery, GetAllStudentsMetaStatusQueryVariables>(GetAllStudentsMetaStatusDocument, baseOptions);
      }
export function useGetAllStudentsMetaStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentsMetaStatusQuery, GetAllStudentsMetaStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetAllStudentsMetaStatusQuery, GetAllStudentsMetaStatusQueryVariables>(GetAllStudentsMetaStatusDocument, baseOptions);
        }
export type GetAllStudentsMetaStatusQueryHookResult = ReturnType<typeof useGetAllStudentsMetaStatusQuery>;
export type GetAllStudentsMetaStatusLazyQueryHookResult = ReturnType<typeof useGetAllStudentsMetaStatusLazyQuery>;
export type GetAllStudentsMetaStatusQueryResult = Apollo.QueryResult<GetAllStudentsMetaStatusQuery, GetAllStudentsMetaStatusQueryVariables>;
export const GetAllStudentsSortedDocument = gql`
    query getAllStudentsSorted($searchTerm: String, $schoolId: Int!, $first: Int!, $skip: Int!, $status: StudentStatusType, $isArchived: Boolean, $isNotSentReport: Boolean, $folderIds: [Int], $isUnassignedToFolder: Boolean) {
  allStudentsSorted(
    searchTerm: $searchTerm
    schoolId: $schoolId
    status: $status
    first: $first
    skip: $skip
    isArchived: $isArchived
    isNotSentReport: $isNotSentReport
    folderIds: $folderIds
    isUnassignedToFolder: $isUnassignedToFolder
  ) {
    status
    id
    testAttempt {
      id
      reportName
    }
    folder {
      name
      id
      countryModel {
        flag
      }
    }
    user {
      id
      firstName
      lastName
      email
      isConfirmed
    }
  }
}
    `;

/**
 * __useGetAllStudentsSortedQuery__
 *
 * To run a query within a React component, call `useGetAllStudentsSortedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentsSortedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentsSortedQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      schoolId: // value for 'schoolId'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      status: // value for 'status'
 *      isArchived: // value for 'isArchived'
 *      isNotSentReport: // value for 'isNotSentReport'
 *      folderIds: // value for 'folderIds'
 *      isUnassignedToFolder: // value for 'isUnassignedToFolder'
 *   },
 * });
 */
export function useGetAllStudentsSortedQuery(baseOptions: Apollo.QueryHookOptions<GetAllStudentsSortedQuery, GetAllStudentsSortedQueryVariables>) {
        return Apollo.useQuery<GetAllStudentsSortedQuery, GetAllStudentsSortedQueryVariables>(GetAllStudentsSortedDocument, baseOptions);
      }
export function useGetAllStudentsSortedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentsSortedQuery, GetAllStudentsSortedQueryVariables>) {
          return Apollo.useLazyQuery<GetAllStudentsSortedQuery, GetAllStudentsSortedQueryVariables>(GetAllStudentsSortedDocument, baseOptions);
        }
export type GetAllStudentsSortedQueryHookResult = ReturnType<typeof useGetAllStudentsSortedQuery>;
export type GetAllStudentsSortedLazyQueryHookResult = ReturnType<typeof useGetAllStudentsSortedLazyQuery>;
export type GetAllStudentsSortedQueryResult = Apollo.QueryResult<GetAllStudentsSortedQuery, GetAllStudentsSortedQueryVariables>;
export const GetAnswersForInterestTestDocument = gql`
    query getAnswersForInterestTest($id: ID!) {
  interestAnswers(where: {testAttempt: {id: {equals: $id}}}) {
    interestQuestion {
      id
      parent {
        id
      }
    }
    answer
  }
}
    `;

/**
 * __useGetAnswersForInterestTestQuery__
 *
 * To run a query within a React component, call `useGetAnswersForInterestTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersForInterestTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersForInterestTestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnswersForInterestTestQuery(baseOptions: Apollo.QueryHookOptions<GetAnswersForInterestTestQuery, GetAnswersForInterestTestQueryVariables>) {
        return Apollo.useQuery<GetAnswersForInterestTestQuery, GetAnswersForInterestTestQueryVariables>(GetAnswersForInterestTestDocument, baseOptions);
      }
export function useGetAnswersForInterestTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnswersForInterestTestQuery, GetAnswersForInterestTestQueryVariables>) {
          return Apollo.useLazyQuery<GetAnswersForInterestTestQuery, GetAnswersForInterestTestQueryVariables>(GetAnswersForInterestTestDocument, baseOptions);
        }
export type GetAnswersForInterestTestQueryHookResult = ReturnType<typeof useGetAnswersForInterestTestQuery>;
export type GetAnswersForInterestTestLazyQueryHookResult = ReturnType<typeof useGetAnswersForInterestTestLazyQuery>;
export type GetAnswersForInterestTestQueryResult = Apollo.QueryResult<GetAnswersForInterestTestQuery, GetAnswersForInterestTestQueryVariables>;
export const GetAllInterestQuestionsDocument = gql`
    query getAllInterestQuestions {
  interestQuestions {
    id
    name
    interestId
    parent {
      id
    }
    subquestions {
      id
    }
  }
}
    `;

/**
 * __useGetAllInterestQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllInterestQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInterestQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInterestQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInterestQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInterestQuestionsQuery, GetAllInterestQuestionsQueryVariables>) {
        return Apollo.useQuery<GetAllInterestQuestionsQuery, GetAllInterestQuestionsQueryVariables>(GetAllInterestQuestionsDocument, baseOptions);
      }
export function useGetAllInterestQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInterestQuestionsQuery, GetAllInterestQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<GetAllInterestQuestionsQuery, GetAllInterestQuestionsQueryVariables>(GetAllInterestQuestionsDocument, baseOptions);
        }
export type GetAllInterestQuestionsQueryHookResult = ReturnType<typeof useGetAllInterestQuestionsQuery>;
export type GetAllInterestQuestionsLazyQueryHookResult = ReturnType<typeof useGetAllInterestQuestionsLazyQuery>;
export type GetAllInterestQuestionsQueryResult = Apollo.QueryResult<GetAllInterestQuestionsQuery, GetAllInterestQuestionsQueryVariables>;
export const FindUserDocument = gql`
    query findUser($id: ID!) {
  user(where: {id: $id}) {
    lastName
    firstName
    email
    id
    isConfirmed
  }
}
    `;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserQuery(baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
        return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, baseOptions);
      }
export function useFindUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
          return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, baseOptions);
        }
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<typeof useFindUserLazyQuery>;
export type FindUserQueryResult = Apollo.QueryResult<FindUserQuery, FindUserQueryVariables>;
export const GetVideoWithViewsDocument = gql`
    query getVideoWithViews($videoId: Int!) {
  findVideoWithViews(videoId: $videoId) {
    id
    title
    url
    isTutorial
    description
    views {
      id
      isCompleted
    }
  }
}
    `;

/**
 * __useGetVideoWithViewsQuery__
 *
 * To run a query within a React component, call `useGetVideoWithViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoWithViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoWithViewsQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useGetVideoWithViewsQuery(baseOptions: Apollo.QueryHookOptions<GetVideoWithViewsQuery, GetVideoWithViewsQueryVariables>) {
        return Apollo.useQuery<GetVideoWithViewsQuery, GetVideoWithViewsQueryVariables>(GetVideoWithViewsDocument, baseOptions);
      }
export function useGetVideoWithViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoWithViewsQuery, GetVideoWithViewsQueryVariables>) {
          return Apollo.useLazyQuery<GetVideoWithViewsQuery, GetVideoWithViewsQueryVariables>(GetVideoWithViewsDocument, baseOptions);
        }
export type GetVideoWithViewsQueryHookResult = ReturnType<typeof useGetVideoWithViewsQuery>;
export type GetVideoWithViewsLazyQueryHookResult = ReturnType<typeof useGetVideoWithViewsLazyQuery>;
export type GetVideoWithViewsQueryResult = Apollo.QueryResult<GetVideoWithViewsQuery, GetVideoWithViewsQueryVariables>;
export const GetVideosWithViewsDocument = gql`
    query getVideosWithViews($isTutorial: Boolean!) {
  findVideosWithViews(isTutorial: $isTutorial) {
    id
    title
    url
    isTutorial
    views {
      id
      isCompleted
    }
  }
}
    `;

/**
 * __useGetVideosWithViewsQuery__
 *
 * To run a query within a React component, call `useGetVideosWithViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosWithViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosWithViewsQuery({
 *   variables: {
 *      isTutorial: // value for 'isTutorial'
 *   },
 * });
 */
export function useGetVideosWithViewsQuery(baseOptions: Apollo.QueryHookOptions<GetVideosWithViewsQuery, GetVideosWithViewsQueryVariables>) {
        return Apollo.useQuery<GetVideosWithViewsQuery, GetVideosWithViewsQueryVariables>(GetVideosWithViewsDocument, baseOptions);
      }
export function useGetVideosWithViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosWithViewsQuery, GetVideosWithViewsQueryVariables>) {
          return Apollo.useLazyQuery<GetVideosWithViewsQuery, GetVideosWithViewsQueryVariables>(GetVideosWithViewsDocument, baseOptions);
        }
export type GetVideosWithViewsQueryHookResult = ReturnType<typeof useGetVideosWithViewsQuery>;
export type GetVideosWithViewsLazyQueryHookResult = ReturnType<typeof useGetVideosWithViewsLazyQuery>;
export type GetVideosWithViewsQueryResult = Apollo.QueryResult<GetVideosWithViewsQuery, GetVideosWithViewsQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $password: String!, $passwordToken: String!) {
  resetPassword(
    email: $email
    password: $password
    passwordConfirmation: $password
    resetPasswordToken: $passwordToken
  ) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      passwordToken: // value for 'passwordToken'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;