import React, { useEffect } from "react";

import Button from "components/atoms/Button";
import {
  useEnableIndividualTestMutation,
  useGetAuthenticatedUserQuery,
} from "generated/graphql";
import { useHistory } from "react-router-dom";
import { Wrapper, Content, Heading, Details, ButtonWrapper } from "./styles";

const PaymentSuccess = () => {
  const [enableIndividualTest] = useEnableIndividualTestMutation();
  const { data, loading } = useGetAuthenticatedUserQuery();
  const history = useHistory();

  useEffect(() => {
    function enableTest() {
      enableIndividualTest({
        variables: {
          schoolId:
            Number(data?.authenticatedItem?.adminOfSchools![0].id) || -1,
          userId: Number(data?.authenticatedItem?.id) || -1,
        },
      });
    }

    if (!loading && data) {
      enableTest();
    }
  }, [loading, data, enableIndividualTest]);

  const moveToDashboard = () => history.push("/student");

  return (
    <Wrapper>
      <Content type="success">
        <Heading>Success!</Heading>
        <Details>
          Your payment was successful. You will be now forwarded to the
          assessment dashboard.
        </Details>
        <ButtonWrapper>
          <Button variant="primary" onClick={moveToDashboard}>
            OK
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default PaymentSuccess;
