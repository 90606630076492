import React, { FC } from "react";

const Faq: FC = () => {
  return (
    <div>
      <h1>Faq</h1>
    </div>
  );
};

export default Faq;
