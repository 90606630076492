import styled from "styled-components";
import Button from "components/atoms/Button";

export const Container = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
`;

export const ButtonsSection = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  width: 50%;
  ${({ fullWidth }) => fullWidth && "width: 100%"}
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  min-width: min-content;
  height: min-content;
  padding: 0 2rem;
`;
