import { FormikProps, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import Input from "components/molecules/Input";

import { firstName, lastName, email } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import { currentSchoolIdVar } from "store/school";

import {
  InviteStudentMutation,
  InviteStudentMutationVariables,
  useFindUserQuery,
  useInviteStudentMutation,
  useResendStudentInvitationMutation,
  useUpdateUserMutation,
} from "generated/graphql";
import moment from "moment";
import StyledSelect from "components/molecules/Select";
import { Container, ButtonsSection, ButtonGroup } from "./styles";
import { InviteUserProps, DelayedEmailType } from "./types";

const InviteStudentModal: FC<InviteUserProps> = ({
  isOpen,
  onClose,
  title,
  studentId,
}) => {
  const { t } = useTranslation();
  const [inviteStudent] = useInviteStudentMutation();
  const [editUser] = useUpdateUserMutation();
  const [resendStudentInvitaion] = useResendStudentInvitationMutation();
  const [sendDate, setSendDate] = useState(moment());
  const [daysCount, setDaysCount] = useState(31);

  const { data: studentData } = useFindUserQuery({
    variables: {
      id: studentId,
    },
  });
  const currentSchoolId = currentSchoolIdVar();

  const addStudentInitialValues: InviteStudentMutationVariables &
    DelayedEmailType = {
    firstName: studentData?.user?.firstName || "",
    lastName: studentData?.user?.lastName || "",
    email: studentData?.user?.email?.toLowerCase() || "",
    schoolId: Number(currentSchoolId),
    invitationDate: null,
    isScheduled: false,
    schedule: "immediately",
    year: moment().year(),
    month: moment().month(),
    day: moment().date(),
    hour: moment().hour(),
    minute: "00",
  };

  const Toast = useToast();

  const inviteStudentForm: FormikProps<
    InviteStudentMutationVariables & DelayedEmailType
  > = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: addStudentInitialValues,
    validationSchema: Yup.object({
      firstName,
      lastName,
      email,
    }),

    onSubmit: async (values) =>
      studentId === ""
        ? inviteStudent({
            variables: {
              email: values.email.toLowerCase(),
              firstName: values.firstName,
              lastName: values.lastName,
              schoolId: Number(currentSchoolId),
              isScheduled: values.schedule === "scheduled",
              invitationDate: sendDate.utc().valueOf(),
            },
          })
        : editUser({
            variables: {
              id: studentId,
              email: values.email.toLowerCase(),
              firstName: values.firstName,
              lastName: values.lastName,
            },
          }),
  });

  const closeModal = () => {
    inviteStudentForm.resetForm();
    onClose();
  };

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // INFO: Formik resolves submitForm() Promise even on unsuccessful submitions
  // https://github.com/formium/formik/issues/1580
  const submitFormFix = async (andClose: boolean) =>
    inviteStudentForm
      .submitForm()
      .then((data: { data: InviteStudentMutation } | void) => {
        const isNew =
          data && data.data.inviteStudent?.studentsForNewUsers?.length;

        inviteStudentForm.resetForm();

        const addedMsg = isNew
          ? t("successAction.studentAdded")
          : t("warningAction.studentExists");

        Toast(
          isNew ? "success" : "warning",
          studentId === "" ? addedMsg : t("successAction.studentEdited")
        );
        if (andClose) closeModal();
      })
      .catch((err) =>
        Toast("error", err?.message || t("errorAction.generalError"))
      );

  const submitForm = (andClose: boolean) => {
    inviteStudentForm.validateForm().then((res) => {
      inviteStudentForm.setErrors(res);
      inviteStudentForm.setTouched({
        email: true,
        firstName: true,
        lastName: true,
      });

      if (Object.keys(res).length === 0) submitFormFix(andClose);
    });
  };

  const resendInvitation = () => {
    resendStudentInvitaion({
      variables: {
        userId: Number(studentId),
        schoolId: Number(currentSchoolId),
      },
    })
      .then(({ data }) => {
        if (data?.resendStudentInvitation?.success) {
          Toast("success", t("successAction.invitationResent"));
          closeModal();
        } else {
          Toast("error", t("errorAction.generalError"));
        }
      })
      .catch((err) => {
        Toast("error", err?.message || t("errorAction.generalError"));
      });
  };

  useEffect(() => {
    setDaysCount(
      moment(
        `${inviteStudentForm?.values?.year}-${(
          inviteStudentForm?.values?.month + 1
        )
          .toString()
          .padStart(2, "0")}`,
        "YYYY-MM"
      ).daysInMonth() || 31
    );
    setSendDate(
      moment(
        `${inviteStudentForm?.values?.year}-${
          inviteStudentForm?.values?.month + 1
        }-${inviteStudentForm?.values?.day} ${
          inviteStudentForm?.values?.hour
        }:${inviteStudentForm?.values?.minute}`,
        "Y-M-D h:m"
      )
    );
  }, [inviteStudentForm.values]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={title}
      size="medium"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <form
          onSubmit={inviteStudentForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="email"
            label={t("global.emailAddressLabel")}
            placeholder={t("global.emailAddressPlaceholder")}
            handleChange={inviteStudentForm.handleChange}
            handleBlur={inviteStudentForm.handleBlur}
            value={inviteStudentForm.values.email}
            error={
              inviteStudentForm.touched.email && inviteStudentForm.errors.email
            }
          />
          <Input
            name="firstName"
            label={t("global.firstNameLabel")}
            placeholder={t("global.firstNamePlaceholder")}
            handleChange={inviteStudentForm.handleChange}
            handleBlur={inviteStudentForm.handleBlur}
            value={inviteStudentForm.values.firstName}
            error={
              inviteStudentForm.touched.firstName &&
              inviteStudentForm.errors.firstName
            }
          />
          <Input
            name="lastName"
            label={t("global.lastNameLabel")}
            placeholder={t("global.lastNamePlaceholder")}
            handleChange={inviteStudentForm.handleChange}
            handleBlur={inviteStudentForm.handleBlur}
            value={inviteStudentForm.values.lastName}
            error={
              inviteStudentForm.touched.lastName &&
              inviteStudentForm.errors.lastName
            }
          />
          {!studentId && (
            <>
              <ButtonsSection>
                <label htmlFor="immediately">
                  <input
                    type="radio"
                    id="immediately"
                    name="schedule"
                    value="immediately"
                    onChange={inviteStudentForm.handleChange}
                    onBlur={inviteStudentForm.handleBlur}
                    checked={
                      inviteStudentForm.values.schedule === "immediately"
                    }
                  />
                  send immediately
                </label>
                <label htmlFor="schedule">
                  <input
                    type="radio"
                    id="schedule"
                    name="schedule"
                    value="scheduled"
                    onChange={inviteStudentForm.handleChange}
                    onBlur={inviteStudentForm.handleBlur}
                  />
                  schedule email
                </label>
              </ButtonsSection>
              {inviteStudentForm.values.schedule === "scheduled" && (
                <div style={{ marginTop: "2rem" }}>
                  <ButtonsSection
                    fullWidth
                    style={{ marginTop: "0rem", alignItems: "flex-end" }}
                  >
                    <div style={{ width: "200px" }}>
                      <StyledSelect
                        name="year"
                        label="Date"
                        menuPlacement="top"
                        handleChange={(
                          name: string,
                          value: { value: string }
                        ) => inviteStudentForm.setFieldValue(name, value.value)}
                        handleBlur={inviteStudentForm.handleBlur}
                        value={{
                          label: inviteStudentForm.values.year.toString() || "",
                          value: inviteStudentForm.values.year.toString() || "",
                        }}
                        options={[
                          {
                            value: moment().year().toString(),
                            label: moment().year().toString(),
                          },
                          {
                            value: (moment().year() + 1).toString(),
                            label: (moment().year() + 1).toString(),
                          },
                        ]}
                      />
                    </div>
                    <div
                      style={{
                        width: "200px",
                      }}
                    >
                      <StyledSelect
                        name="month"
                        menuPlacement="top"
                        handleChange={(
                          name: string,
                          value: { value: string }
                        ) =>
                          inviteStudentForm.setFieldValue(
                            name,
                            parseInt(value.value, 10)
                          )
                        }
                        handleBlur={inviteStudentForm.handleBlur}
                        value={{
                          label: monthList[inviteStudentForm.values.month],
                          value: inviteStudentForm.values.month.toString(),
                        }}
                        options={monthList.map((month, idx) => ({
                          label: month,
                          value: idx.toString(),
                        }))}
                      />
                    </div>

                    <div style={{ width: "200px" }}>
                      <StyledSelect
                        name="day"
                        menuPlacement="top"
                        handleChange={(
                          name: string,
                          value: { value: string }
                        ) => inviteStudentForm.setFieldValue(name, value.value)}
                        handleBlur={inviteStudentForm.handleBlur}
                        value={{
                          label: inviteStudentForm.values.day.toString(),
                          value: inviteStudentForm.values.day.toString(),
                        }}
                        options={Array.from(Array(daysCount).keys()).map(
                          (day) => ({
                            label: (day + 1).toString(),
                            value: (day + 1).toString(),
                          })
                        )}
                      />
                    </div>
                  </ButtonsSection>
                  <ButtonsSection
                    style={{ marginTop: "2rem", justifyContent: "flex-start" }}
                    fullWidth
                  >
                    <div style={{ width: "200px", marginRight: "1px" }}>
                      <StyledSelect
                        name="hour"
                        label="Hour"
                        menuPlacement="top"
                        handleChange={(
                          name: string,
                          value: { value: string }
                        ) => inviteStudentForm.setFieldValue(name, value.value)}
                        handleBlur={inviteStudentForm.handleBlur}
                        value={{
                          label: inviteStudentForm.values.hour.toString(),
                          value: inviteStudentForm.values.hour.toString(),
                        }}
                        options={Array.from(Array(24).keys()).map((hour) => ({
                          label: hour.toString(),
                          value: hour.toString(),
                        }))}
                      />
                    </div>

                    <div style={{ width: "200px" }}>
                      <StyledSelect
                        name="minute"
                        label="Minutes"
                        menuPlacement="top"
                        handleChange={(
                          name: string,
                          value: { value: string }
                        ) => inviteStudentForm.setFieldValue(name, value.value)}
                        handleBlur={inviteStudentForm.handleBlur}
                        value={{
                          label: inviteStudentForm.values.minute.toString(),
                          value: inviteStudentForm.values.minute.toString(),
                        }}
                        options={["00", "15", "30", "45"].map((minute) => ({
                          label: minute,
                          value: minute,
                        }))}
                      />
                    </div>
                  </ButtonsSection>
                </div>
              )}
            </>
          )}
          <ButtonsSection fullWidth>
            <div>
              <Button
                variant="primary"
                type="button"
                onClick={() => submitForm(true)}
              >
                {t("actions.save")}
              </Button>
            </div>
            <ButtonGroup>
              {studentId ? (
                <>
                  <Button
                    variant="link"
                    type="button"
                    onClick={resendInvitation}
                    width="auto"
                  >
                    {t("actions.resendInvitaion")}
                  </Button>
                  <Button
                    width="auto"
                    variant="link"
                    type="button"
                    onClick={closeModal}
                  >
                    {t("actions.cancel")}
                  </Button>
                </>
              ) : (
                <Button
                  variant="secondary"
                  width="auto"
                  type="button"
                  onClick={() => submitForm(false)}
                >
                  {t("teacher.saveAndAdd")}
                </Button>
              )}
            </ButtonGroup>
          </ButtonsSection>
        </form>
      </Container>
    </Modal>
  );
};

export default InviteStudentModal;
