import styled, { css } from "styled-components";

import { ReactComponent as Eye } from "assets/images/password-eye.svg";
import { ReactComponent as CrossedEye } from "assets/images/password-eye-cross.svg";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2.2rem;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Input = styled.input<{ hasError: boolean }>`
  border: none;
  border-radius: 0.4rem;
  padding: 1rem;

  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: normal;
  border: 1px solid ${({ theme }) => theme.colors.mishka};
  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.newYorkPink}};
    `}
  color: ${({ theme }) => theme.colors.sharade};
  width: 100%;
  letter-spacing: 0.02rem;
  cursor: text;

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.athensGray};
    background-color: ${({ theme }) => theme.colors.whiteLilac};
    cursor: not-allowed;
  }
`;

export const Error = styled.span`
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.01rem;
  color: ${({ theme }) => theme.colors.newYorkPink};
  margin-top: 0.8rem;
`;

export const StyledEye = styled(Eye)`
  position: absolute;
  right: 1.6rem;
  top: 3.1rem;
  width: 24px;
  height: 24px;
`;

export const StyledCrossedEye = styled(CrossedEye)`
  position: absolute;
  right: 1.6rem;
  top: 3.1rem;
  width: 24px;
  height: 24px;
`;
