import { useToasts, AppearanceTypes } from "react-toast-notifications";

export function useToast() {
  const { addToast } = useToasts();

  const Toast = (
    type: AppearanceTypes,
    message: string | JSX.Element[],
    autoDismiss = true
  ) => addToast(message, { appearance: type, autoDismiss });

  return Toast;
}
