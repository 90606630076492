import { useState, useEffect } from "react";

export default function useDebounce(value: string, delay = 500, minChars = 0) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler =
      value.length >= minChars || value.length === 0
        ? setTimeout(() => {
            setDebouncedValue(value);
          }, delay)
        : null;

    return () => {
      if (handler) clearTimeout(handler);
    };
  }, [value, delay, minChars]);

  return debouncedValue;
}
