import React, { FC } from "react";
import { ThemeProvider } from "styled-components";
import { useParams } from "react-router-dom";

import Colors from "constants/colors";
import { AssessmentType } from "types";

import { StyledMain } from "./styles";

const themes = {
  interest: {
    colors: {
      ...Colors,
      defaultColor: Colors.denim,
      secondaryColor: Colors.lightPurple,
    },
  },
};

const TestIntro: FC = ({ children }) => {
  const { type } = useParams<{ type: AssessmentType }>();

  return (
    <ThemeProvider theme={themes[type]}>
      <StyledMain>{children}</StyledMain>
    </ThemeProvider>
  );
};

export default TestIntro;
