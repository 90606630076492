import styled from "styled-components";
import Bulb from "assets/images/bulb.svg";
import { ReactComponent as AnswerCorrect } from "assets/images/answer-correct.svg";
import { ReactComponent as AnswerWrong } from "assets/images/answer-wrong.svg";

import Card from "components/atoms/Card";
import media from "helpers/media";

export const Container = styled.div`
  display: flex;
  width: 100%;

  ${media.SM`
    width: 96%;
    margin-left: 2%;
  `};
`;

export const SideMenuWrapper = styled.div`
  width: 20%;
  margin-right: 7.4rem;

  ${media.SM`
    display: none;
  `};
`;

export const CardWrapper = styled.div`
  width: 73rem;

  ${media.SM`
    width: 100%;
  `};
`;

export const Title = styled.h1`
  font-size: 3.2rem;
  line-height: 2.4rem;
  font-weight: 500;
`;

export const SubTitle = styled.h4`
  color: ${({ theme }) => theme.colors.defaultColor};
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
`;

export const CorrectAnswer = styled.div<{ icon?: "correct" | "wrong" }>`
  background-color: ${({ theme }) => theme.colors.secondaryColor};
  border-radius: 8px;
  font-size: 1.6rem;
  letter-spacing: 0.02rem;
  line-height: 2rem;
  text-align: center;
  padding: 3rem;
  margin: auto;
  position: relative;

  color: ${({ theme }) => theme.colors.defaultColor};
  max-width: 22rem;
  justify-self: center;

  ${media.SM`
    width: 100%;
    max-width: 100%;
    font-size: 1.4rem;
  `};
`;

export const CorrectAnswerIcon = styled(AnswerCorrect)`
  position: absolute;
  width: 100%;
  height: 8rem;
  top: -5rem;
  left: 0;
  right: 0;
  z-index: 999;
  #back {
    fill: ${({ theme }) => theme.colors.secondaryColor};
  }
  #front {
    fill: ${({ theme }) => theme.colors.defaultColor};
  }

  ${media.SM`
        margin-top: 5rem;
  `};
`;

export const WrongAnswerIcon = styled(AnswerWrong)`
  position: absolute;
  width: 100%;
  height: 8rem;
  top: -5rem;
  left: 0;
  right: 0;
  z-index: 999;
  #back {
    fill: ${({ theme }) => theme.colors.secondaryColor};
  }
  #front {
    fill: ${({ theme }) => theme.colors.defaultColor};
  }

  ${media.SM`
        margin-top: 5rem;
  `};
`;

export const CorrectAnswerHeader = styled.strong`
  margin-bottom: 0.6rem;
  display: block;
`;

export const YourTurn = styled.div`
  padding: 4.2rem 0;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
  border: 1.5px solid ${({ theme }) => theme.colors.spindle};
  background-color: ${({ theme }) => theme.colors.linkWater};
  color: ${({ theme }) => theme.colors.denim};
  background-image: url(${Bulb});
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8.5rem;
  padding-left: 17rem;
  border-radius: 8px;
  margin-bottom: 1.6rem;

  ${media.SM`
    width: 100%;
    padding: 1.2rem 0;
    padding-left: 9.4rem;
    background-position-x: 1.6rem;
    font-size: 1.4rem;
  `};
`;

export const StyledCard = styled(Card)`
  margin-bottom: 1.6rem;

  ${media.SM`
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  ${media.SM`
    width: 100%;
    display: flex;
    justify-content: center;
  `};
`;

export const TryItOut = styled.div`
  font-family: Roboto Slab;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;

  letter-spacing: 0.05rem;
  margin-bottom: 2rem;
  margin-left: -5.2rem;

  color: ${({ theme }) => theme.colors.defaultColor};

  ${media.SM`
    font-size: 1.6rem;
  `};
`;

export const InterestQuestionContent = styled.div`
  margin-bottom: 2.4rem;

  margin-left: -4.2rem;
  p {
    margin: 0;
    padding: 0;
  }

  strong {
    color: ${({ theme }) => theme.colors.defaultColor};
  }

  ${media.SM`
    padding-top: 3.8rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  `};
`;

export const InterestAnswersWrapper = styled.div`
  margin-left: -4.2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
`;
