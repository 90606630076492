import styled from "styled-components";

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  margin-bottom: 2.4rem;
`;

export const Muted = styled.span`
  color: ${({ theme }) => theme.colors.manatee};
  font-weight: 400;
`;
