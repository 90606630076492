import React from "react";

import Auth from "components/atoms/Auth";
import { RouteParams } from "routes/types";
import RouteWrapper from "routes/RouteWrapper";

const generateRoutes = (routes: RouteParams[]) => {
  return routes.map((route) => {
    const { exact, path, component, secure = true } = route;
    return (
      <RouteWrapper key={path + exact?.toString()} exact={exact} path={path}>
        {secure ? <Auth path={path}>{component}</Auth> : component}
      </RouteWrapper>
    );
  });
};

export default generateRoutes;
