import { useEffect, useState, useRef, RefObject } from "react";

function useClickOutside() {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        visible &&
        ref &&
        ref.current &&
        ref.current === localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        setVisible(false);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, visible]);
  return { visible, setVisible, ref };
}

export default useClickOutside;
