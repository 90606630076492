import { useFormik } from "formik";
import React, { FC } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import Input from "components/molecules/Input";
import PasswordChecklist from "components/molecules/PasswordChecklist";

import { password, passwordConfirmation } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import {
  useGetAuthenticatedUserQuery,
  useUpdateUserMutation,
} from "generated/graphql";

import { ButtonSection } from "./styles";

type ChangePasswordProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const ChangePasswordModal: FC<ChangePasswordProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [editUser] = useUpdateUserMutation();
  const { data: userData } = useGetAuthenticatedUserQuery();
  const Toast = useToast();

  const changePasswordForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password,
      passwordConfirmation,
    }),

    onSubmit: async (values, actions) => {
      if (userData && userData.authenticatedItem) {
        editUser({
          variables: {
            id: userData.authenticatedItem.id,
            password: values.password,
          },
        })
          .then(() => {
            onClose();
            Toast("success", t("successAction.passwordChanged"));
          })
          .catch((e) => {
            Toast("error", e.message || t("errorAction.generalError"));
          })
          .finally(() => {
            onClose();
            actions.resetForm();
          });
      }
    },
  });

  const handleClose = () => {
    onClose();
    changePasswordForm.resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("user.changePassword")}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <form
        onSubmit={changePasswordForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        <Input
          name="password"
          label={t("global.password")}
          handleChange={changePasswordForm.handleChange}
          handleBlur={changePasswordForm.handleBlur}
          placeholder={t("global.password")}
          type="password"
          value={changePasswordForm.values.password}
          error={
            changePasswordForm.touched.password &&
            changePasswordForm.errors.password
          }
          password
        />
        <PasswordChecklist password={changePasswordForm.values.password} />

        <Input
          name="passwordConfirmation"
          label={t("user.repeatPassword")}
          handleChange={changePasswordForm.handleChange}
          handleBlur={changePasswordForm.handleBlur}
          placeholder={t("user.repeatPassword")}
          type="password"
          value={changePasswordForm.values.passwordConfirmation}
          error={
            changePasswordForm.touched.passwordConfirmation &&
            changePasswordForm.errors.passwordConfirmation
          }
          password
        />

        <ButtonSection>
          <Button
            variant="primary"
            type="button"
            onClick={() => changePasswordForm.submitForm()}
          >
            {t("actions.save")}
          </Button>
          <Button variant="link" type="button" onClick={handleClose}>
            {t("actions.cancel")}
          </Button>
        </ButtonSection>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
