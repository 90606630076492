import React, { FC } from "react";

import Button from "../Button";

import { Props } from "./types";
import { CurrentPageBtn, Container, NextPagesWrapper } from "./styles";

const PageNumbers: FC<Props> = ({ currentPage, lastPage, handlePagePress }) => {
  const currNotFirst = currentPage !== 1;
  const isLastSelected = currentPage === lastPage;
  const nextNotLast = currentPage + 1 !== lastPage;
  const prevPageExist = currentPage - 1;

  return (
    <Container>
      {currNotFirst && !isLastSelected && (
        <Button variant="text" onClick={() => handlePagePress(currentPage - 1)}>
          {currentPage - 1}
        </Button>
      )}
      {isLastSelected ? (
        Boolean(prevPageExist) && (
          <Button
            onClick={() => handlePagePress(currentPage - 1)}
            variant="text"
          >
            {currentPage - 1}
          </Button>
        )
      ) : (
        <CurrentPageBtn>{currentPage}</CurrentPageBtn>
      )}

      <NextPagesWrapper>
        {nextNotLast && !isLastSelected && (
          <Button
            variant="text"
            onClick={() => handlePagePress(currentPage + 1)}
          >
            {currentPage + 1}
          </Button>
        )}
        <Container>
          {(Boolean(prevPageExist) || !isLastSelected) && (
            <div style={{ margin: "0 0.4rem" }}>...</div>
          )}
          {isLastSelected ? (
            <CurrentPageBtn>{currentPage}</CurrentPageBtn>
          ) : (
            <Button onClick={() => handlePagePress(lastPage)} variant="text">
              {lastPage}
            </Button>
          )}
        </Container>
      </NextPagesWrapper>
    </Container>
  );
};

export default PageNumbers;
