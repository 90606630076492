import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SideMenu from "components/molecules/SideMenu";
import paths from "routes/paths";

import { AssessmentType } from "types";

import {
  Answer,
  AnswersContent,
  Question,
  QuestionContent,
  QuestionNumber,
  StyledButton,
  AnswersWrapper,
  DangerousInner,
} from "pages/Student/Tests/styles";

import MobileHeader from "components/atoms/MobileHeader";

import { getTestName } from "helpers/getTestName";

import InterestAnswerBar from "components/molecules/InterestAnswerBar";
import {
  Container,
  SideMenuWrapper,
  CardWrapper,
  SubTitle,
  Title,
  CorrectAnswer,
  YourTurn,
  CorrectAnswerHeader,
  StyledCard,
  ButtonWrapper,
  TryItOut,
  InterestQuestionContent,
  InterestAnswersWrapper,
  WrongAnswerIcon,
  CorrectAnswerIcon,
} from "./styles";

import { QuestionsListType } from "./types";

const Training = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: AssessmentType }>();
  const history = useHistory();

  const sideMenuValues = [
    { name: "Dashboard", path: paths.studentDashboard },
    {
      name: t("student.instructions"),
      path: `/test-instruction/${type}`,
    },
  ];

  const [answers, setAnswers] = useState<{ [key: string]: number }>({});

  const finishTraining = () => history.push(`/training-finished/${type}`);
  const scrollRef = useRef<HTMLDivElement>(null);

  const selectAnswer = (question: string, idx: number) => {
    if (answers[question] === undefined || question === "test") {
      setAnswers((oldAnswers) => ({ ...oldAnswers, [question]: idx }));
    }
  };

  const questionsList: QuestionsListType = {
    interest: [
      {
        number: "",
        question: "",
        answers: [],
        correct: 0,
        hint: "",
      },
    ],
  };

  const questions = questionsList[type];

  const aptitudeAnswers = [
    t("test.examples.aptitudeAnswers.0"),
    t("test.examples.aptitudeAnswers.1"),
    t("test.examples.aptitudeAnswers.2"),
    t("test.examples.aptitudeAnswers.3"),
    t("test.examples.aptitudeAnswers.4"),
    t("test.examples.aptitudeAnswers.5"),
    t("test.examples.aptitudeAnswers.6"),
  ];

  const getHtmlQuestion = (question: string) => {
    return {
      __html: question,
    };
  };

  return (
    <div>
      <MobileHeader
        title={sideMenuValues[sideMenuValues.length - 1].name}
        onClick={() =>
          history.push(sideMenuValues[sideMenuValues.length - 1].path)
        }
      />
      <Container>
        <SideMenuWrapper>
          <SideMenu values={sideMenuValues} />
        </SideMenuWrapper>
        <CardWrapper>
          <StyledCard>
            <SubTitle>{getTestName(type)}</SubTitle>
            <Title>{t("student.training")}</Title>
          </StyledCard>
          {type === "interest" ? (
            <>
              <Question>
                <TryItOut>{t("student.tryItOut")}</TryItOut>
                <InterestQuestionContent>
                  {t("test.examples.interest.1.question")}
                </InterestQuestionContent>
                <InterestAnswersWrapper>
                  <InterestAnswerBar
                    question="test"
                    clickHandler={selectAnswer}
                    answers={answers}
                    parentQuestion=""
                  />
                </InterestAnswersWrapper>
                {typeof answers.test !== "undefined" && (
                  <InterestAnswersWrapper>
                    <AnswersWrapper>
                      <CorrectAnswer
                        dangerouslySetInnerHTML={getHtmlQuestion(
                          aptitudeAnswers[answers.test]
                        )}
                      />
                    </AnswersWrapper>
                  </InterestAnswersWrapper>
                )}
              </Question>
              {typeof answers.test !== "undefined" && (
                <ButtonWrapper ref={scrollRef}>
                  <StyledButton variant="primary" onClick={finishTraining}>
                    {t("student.finishTraining")}
                  </StyledButton>
                </ButtonWrapper>
              )}
            </>
          ) : (
            <>
              <Question key={questions[0].number}>
                <QuestionNumber>{questions[0].number}</QuestionNumber>
                <QuestionContent
                  dangerouslySetInnerHTML={getHtmlQuestion(
                    questions[0].question
                  )}
                />
                <AnswersWrapper>
                  <AnswersContent>
                    {questions[0].answers.map((answer, idx) => (
                      <Answer
                        key={idx}
                        selected={idx === questions[0].correct}
                        notInteractive
                        dangerouslySetInnerHTML={getHtmlQuestion(answer)}
                      />
                    ))}
                  </AnswersContent>
                  <AnswersWrapper>
                    <CorrectAnswer
                      dangerouslySetInnerHTML={getHtmlQuestion(
                        questions[0].hint
                      )}
                    />
                  </AnswersWrapper>
                </AnswersWrapper>
              </Question>
              <YourTurn>
                <strong>{t("student.yourTurn")}</strong>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={getHtmlQuestion(
                    t("student.readQuestions")
                  )}
                />
              </YourTurn>
              <Question key={questions[1].number}>
                <QuestionNumber>{questions[1].number}</QuestionNumber>
                <QuestionContent
                  dangerouslySetInnerHTML={getHtmlQuestion(
                    questions[1].question
                  )}
                />
                <AnswersWrapper>
                  <AnswersContent>
                    {questions[1].answers.map((answer, idx) => (
                      <Answer
                        key={idx}
                        selected={
                          answers[questions[1].number] !== undefined &&
                          questions[1].correct === idx
                        }
                        wrong={
                          answers[questions[1].number] !== undefined &&
                          questions[1].correct !== idx &&
                          answers[questions[1].number] === idx
                        }
                        onClick={() => selectAnswer(questions[1].number, idx)}
                        notInteractive={
                          answers[questions[1].number] !== undefined
                        }
                        dangerouslySetInnerHTML={getHtmlQuestion(answer)}
                      />
                    ))}
                  </AnswersContent>
                  {answers[questions[1].number] !== undefined && (
                    <AnswersWrapper>
                      <CorrectAnswer>
                        {answers[questions[1].number] ===
                        questions[1].correct ? (
                          <CorrectAnswerIcon />
                        ) : (
                          <WrongAnswerIcon />
                        )}
                        <CorrectAnswerHeader>
                          {answers[questions[1].number] === questions[1].correct
                            ? t("student.thatsCorrect")
                            : t("student.thatsWrong")}
                        </CorrectAnswerHeader>
                        <DangerousInner
                          dangerouslySetInnerHTML={getHtmlQuestion(
                            questions[1].hint
                          )}
                        />
                      </CorrectAnswer>
                    </AnswersWrapper>
                  )}
                </AnswersWrapper>
              </Question>
              {answers[questions[1].number] !== undefined && (
                <>
                  <YourTurn ref={scrollRef}>
                    <strong>{t("student.howAbout")}</strong>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={getHtmlQuestion(
                        t("student.readQuestions")
                      )}
                    />
                  </YourTurn>
                  <Question key={questions[2].number}>
                    <QuestionNumber>{questions[2].number}</QuestionNumber>
                    <QuestionContent
                      dangerouslySetInnerHTML={getHtmlQuestion(
                        questions[2].question
                      )}
                    />
                    <AnswersWrapper>
                      <AnswersContent>
                        {questions[2].answers.map((answer, idx) => (
                          <Answer
                            key={idx}
                            selected={
                              answers[questions[2].number] !== undefined &&
                              questions[2].correct === idx
                            }
                            wrong={
                              answers[questions[2].number] !== undefined &&
                              questions[2].correct !== idx &&
                              answers[questions[2].number] === idx
                            }
                            onClick={() =>
                              selectAnswer(questions[2].number, idx)
                            }
                            notInteractive={
                              answers[questions[2].number] !== undefined
                            }
                            dangerouslySetInnerHTML={getHtmlQuestion(answer)}
                          />
                        ))}
                      </AnswersContent>
                      {answers[questions[2].number] !== undefined && (
                        <AnswersWrapper>
                          <CorrectAnswer
                            icon={
                              answers[questions[2].number] ===
                              questions[2].correct
                                ? "correct"
                                : "wrong"
                            }
                          >
                            <CorrectAnswerHeader>
                              {answers[questions[2].number] ===
                              questions[2].correct
                                ? t("student.thatsCorrect")
                                : t("student.thatsWrong")}
                            </CorrectAnswerHeader>
                            <DangerousInner
                              dangerouslySetInnerHTML={getHtmlQuestion(
                                questions[2].hint
                              )}
                            />
                          </CorrectAnswer>
                        </AnswersWrapper>
                      )}
                    </AnswersWrapper>
                  </Question>
                </>
              )}
              {answers[questions[2].number] !== undefined && questions[3] && (
                <>
                  <YourTurn ref={scrollRef}>
                    <strong>{t("student.lastOne")}</strong>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={getHtmlQuestion(
                        t("student.readQuestions")
                      )}
                    />
                  </YourTurn>
                  <Question key={questions[3].number}>
                    <QuestionNumber>{questions[3].number}</QuestionNumber>
                    <QuestionContent
                      dangerouslySetInnerHTML={getHtmlQuestion(
                        questions[3].question
                      )}
                    />
                    <AnswersWrapper>
                      <AnswersContent>
                        {questions[3].answers.map((answer, idx) => (
                          <Answer
                            key={idx}
                            selected={
                              answers[questions[3].number] !== undefined &&
                              questions[3].correct === idx
                            }
                            wrong={
                              answers[questions[3].number] !== undefined &&
                              questions[3].correct !== idx &&
                              answers[questions[3].number] === idx
                            }
                            onClick={() =>
                              selectAnswer(questions[3].number, idx)
                            }
                            notInteractive={
                              answers[questions[3].number] !== undefined
                            }
                            dangerouslySetInnerHTML={getHtmlQuestion(answer)}
                          />
                        ))}
                      </AnswersContent>
                      {answers[questions[3].number] !== undefined && (
                        <AnswersWrapper>
                          <CorrectAnswer
                            icon={
                              answers[questions[3].number] ===
                              questions[3].correct
                                ? "correct"
                                : "wrong"
                            }
                          >
                            <CorrectAnswerHeader>
                              {answers[questions[3].number] ===
                              questions[3].correct
                                ? t("student.thatsCorrect")
                                : t("student.thatsWrong")}
                            </CorrectAnswerHeader>

                            <DangerousInner
                              dangerouslySetInnerHTML={getHtmlQuestion(
                                questions[3].hint
                              )}
                            />
                          </CorrectAnswer>
                        </AnswersWrapper>
                      )}
                    </AnswersWrapper>
                  </Question>
                </>
              )}
              {answers[questions[questions.length - 1].number] !==
                undefined && (
                <ButtonWrapper ref={scrollRef}>
                  <StyledButton variant="primary" onClick={finishTraining}>
                    {t("student.finishTraining")}
                  </StyledButton>
                </ButtonWrapper>
              )}
            </>
          )}
        </CardWrapper>
      </Container>
    </div>
  );
};

export default Training;
