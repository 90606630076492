import React, { FC } from "react";

import Modal from "components/atoms/Modal";

import { ConfirmWrapper, ButtonSection } from "./styles";
import { Props } from "./types";

const ConfirmationModal: FC<Props> = ({
  isOpen,
  onClose,
  cancelButton,
  confirmButton,
  contentText,
  title,
  zIndex,
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    size="narrow"
    onClose={onClose}
    zIndex={zIndex}
  >
    <ConfirmWrapper>
      {contentText}
      <ButtonSection>
        {confirmButton}
        {cancelButton}
      </ButtonSection>
    </ConfirmWrapper>
  </Modal>
);

export default ConfirmationModal;
