import React from "react";

import Button from "components/atoms/Button";

import { useHistory } from "react-router-dom";
import { Wrapper, Content, Heading, Details, ButtonWrapper } from "./styles";

const PaymentFailed = () => {
  const history = useHistory();

  const moveToDashboard = () => history.push("/student");

  return (
    <Wrapper>
      <Content type="error">
        <Heading>Warning!</Heading>
        <Details>
          The payment cannot be confirmed. You will be now moved to the
          assessment dashboard from where you can try again
        </Details>
        <ButtonWrapper>
          <Button variant="primary" onClick={moveToDashboard}>
            OK
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default PaymentFailed;
