import useClickOutside from "hooks/clickOutSide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import DropdownMenu from "../DropdownMenu";
import { Container, StyledCaretDown, TextWrapper, Wrapper } from "./styles";
import { Props } from "./types";

const ArchiveYearsDropdown: FC<Props> = ({ archiveYears, setSelectedYear }) => {
  const { t } = useTranslation();

  const {
    visible: dropdownVisible,
    setVisible: setDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const dropdownElements = [
    {
      name: "-",
      onClick: () => setSelectedYear(undefined),
    },
    ...archiveYears.map((year) => ({
      name: `${year - 1}-${year}`,
      onClick: () => setSelectedYear(year),
    })),
  ];

  return (
    <Container onClick={toggleDropdown} ref={containerRef}>
      <Wrapper>
        <StyledCaretDown />
        <DropdownMenu
          elements={dropdownElements}
          visible={dropdownVisible}
          offsetTop="2.5rem"
          style={{ width: "140px" }}
        />
        <TextWrapper>{t("teacher.archiveYears")}</TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default ArchiveYearsDropdown;
