import { FormikProps, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";

import Input from "components/molecules/Input";

import { firstName, lastName, email } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import { currentSchoolIdVar } from "store/school";

import {
  UpdateUserMutationVariables,
  useResendTeacherOrSchoolAdminInvitationMutation,
  useUpdateUserMutation,
} from "generated/graphql";
import ConfirmationModal from "components/molecules/ConfirmationModal";
import { ActionItem } from "components/molecules/StudentRow/styles";
import { Container, ButtonsSection, ButtonGroup, StyledButton } from "./styles";
import { Props } from "./types";

const TeacherDetailsModal: FC<Props> = ({
  isOpen,
  onClose,
  teacher,
  currentSchool,
  isAdmin,
  handleDeleteUser,
}) => {
  const { t } = useTranslation();
  const [editUser] = useUpdateUserMutation();
  const [
    resendTeacherOrSchoolAdminInvitation,
  ] = useResendTeacherOrSchoolAdminInvitationMutation();
  const [isTeacherConfirmed, setIsTeacherConfirmed] = useState(false);
  const [isUserSchoolAdmin, setIsUserSchoolAdmin] = useState(false);
  const [isTeacherSchoolAdmin, setIsTeacherSchoolAdmin] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [removeUserData, setRemoveUserData] = useState<null | {
    id: string;
    schoolId: string | null;
  }>(null);

  useEffect(() => {
    if (teacher?.isConfirmed !== undefined) {
      const { isConfirmed } = teacher;
      setIsTeacherConfirmed(isConfirmed || false);
    }
  }, [teacher]);

  useEffect(() => {
    if (currentSchool && teacher) {
      const teacherIsSchoolAdmin = currentSchool?.school?.schoolAdmins?.some(
        (admin) => {
          return admin.id === teacher.id;
        }
      );
      setIsTeacherSchoolAdmin(Boolean(teacherIsSchoolAdmin));
    }
  }, [currentSchool, teacher]);

  useEffect(() => {
    if (currentSchool) {
      const isSchoolAdmin = currentSchool?.school?.schoolAdmins?.some(
        (admin) => {
          return admin.id === currentSchool.authenticatedItem?.id;
        }
      );
      setIsUserSchoolAdmin(Boolean(isSchoolAdmin));
    }
  }, [currentSchool]);
  const currentSchoolId = currentSchoolIdVar();

  const isUserSelf = (id: string) => {
    return id === currentSchool?.authenticatedItem?.id;
  };

  const title = isTeacherSchoolAdmin
    ? t("teacher.schoolAdminDetails")
    : t("teacher.teacherDetails");

  const editTeacherInitialValues: UpdateUserMutationVariables = {
    firstName: teacher?.firstName || "",
    lastName: teacher?.lastName || "",
    email: teacher?.email?.toLowerCase() || "",
    id: teacher?.id || "",
  };

  const Toast = useToast();

  const updateTeacherForm: FormikProps<UpdateUserMutationVariables> = useFormik(
    {
      enableReinitialize: true,
      validateOnChange: false,
      initialValues: editTeacherInitialValues,
      validationSchema: Yup.object({
        firstName,
        lastName,
        email,
      }),

      onSubmit: async (values) =>
        !isTeacherConfirmed && teacher
          ? editUser({
              variables: {
                id: teacher.id,
                email: values.email?.toLowerCase(),
                firstName: values.firstName,
                lastName: values.lastName,
              },
            })
          : Toast("error", t("errors.teacherConfirmed")),
    }
  );

  const closeModal = () => {
    updateTeacherForm.resetForm();
    onClose();
  };

  const submitFormFix = async (andClose: boolean) =>
    updateTeacherForm
      .submitForm()
      .then(() => {
        updateTeacherForm.resetForm();

        Toast("success", t("successAction.teacherUpdated"));
        if (andClose) closeModal();
      })
      .catch((err) =>
        Toast("error", err?.message || t("errorAction.generalError"))
      );

  const submitForm = (andClose: boolean) => {
    updateTeacherForm.validateForm().then((res) => {
      updateTeacherForm.setErrors(res);
      updateTeacherForm.setTouched({
        email: true,
        firstName: true,
        lastName: true,
      });

      if (Object.keys(res).length === 0) submitFormFix(andClose);
    });
  };

  const resendInvitation = () => {
    if (teacher) {
      resendTeacherOrSchoolAdminInvitation({
        variables: {
          userId: Number(teacher?.id),
          schoolId: Number(currentSchoolId),
        },
      })
        .then(({ data }) => {
          if (data?.resendTeacherOrSchoolAdminInvitation?.success) {
            Toast("success", t("successAction.invitationResent"));
            closeModal();
          } else {
            Toast("error", t("errorAction.generalError"));
          }
        })
        .catch((err) => {
          Toast("error", err?.message || t("errorAction.generalError"));
        });
    }
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpened(true);
  };
  const closeConfirmModal = () => {
    setIsConfirmModalOpened(false);
  };

  const handleSetUserData = (id: string, schoolId: string | null) => {
    if (isUserSelf(id) && !isAdmin) {
      return Toast("error", t("errors.cannotDeleteSelf"));
    }

    return setRemoveUserData({
      id,
      schoolId,
    });
  };

  const inputDisabled = isTeacherConfirmed || (!isUserSchoolAdmin && !isAdmin);

  return (
    <>
      {removeUserData && (
        <ConfirmationModal
          title={t("teacher.deleteAccount")}
          isOpen={isConfirmModalOpened}
          onClose={closeConfirmModal}
          contentText={`${t("teacher.sureAboutRemove")} ${
            removeUserData.schoolId
              ? t("global.schoolAdmin")
              : t("global.teacher")
          }? ${t("teacher.cantUndone")}.`}
          confirmButton={
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteUser(removeUserData.id, removeUserData.schoolId);
                closeConfirmModal();
                onClose();
              }}
            >
              {t("teacher.yesRemove")}{" "}
              {removeUserData.schoolId
                ? t("global.schoolAdmin")
                : t("global.teacher")}
            </Button>
          }
          cancelButton={
            <Button
              variant="link"
              onClick={closeConfirmModal}
              style={{ width: "fit-content" }}
            >
              {t("actions.cancel")}
            </Button>
          }
          zIndex={2000}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={title}
        size="medium"
        headerMargin="0 0 4.8rem 0"
      >
        <Container>
          <form
            onSubmit={updateTeacherForm.handleSubmit}
            method="post"
            autoComplete="off"
          >
            <Input
              name="email"
              label={t("global.emailAddressLabel")}
              placeholder={t("global.emailAddressPlaceholder")}
              handleChange={updateTeacherForm.handleChange}
              handleBlur={updateTeacherForm.handleBlur}
              value={updateTeacherForm.values.email || ""}
              disabled={inputDisabled}
              error={
                updateTeacherForm.touched.email &&
                updateTeacherForm.errors.email
              }
            />
            <Input
              name="firstName"
              label={t("global.firstNameLabel")}
              placeholder={t("global.firstNamePlaceholder")}
              handleChange={updateTeacherForm.handleChange}
              handleBlur={updateTeacherForm.handleBlur}
              value={updateTeacherForm.values.firstName || ""}
              error={
                updateTeacherForm.touched.firstName &&
                updateTeacherForm.errors.firstName
              }
              disabled={inputDisabled}
            />
            <Input
              name="lastName"
              label={t("global.lastNameLabel")}
              placeholder={t("global.lastNamePlaceholder")}
              handleChange={updateTeacherForm.handleChange}
              handleBlur={updateTeacherForm.handleBlur}
              value={updateTeacherForm.values.lastName || ""}
              error={
                updateTeacherForm.touched.lastName &&
                updateTeacherForm.errors.lastName
              }
              disabled={inputDisabled}
            />

            {(isUserSchoolAdmin || isAdmin) && (
              <ButtonsSection fullWidth>
                <div>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => submitForm(true)}
                    disabled={isTeacherConfirmed}
                    width="100%"
                  >
                    {t("actions.save")}
                  </Button>
                </div>
                <ButtonGroup>
                  {!isTeacherConfirmed && (
                    <ActionItem>
                      <StyledButton variant="link" onClick={resendInvitation}>
                        {t("actions.resendInvitaion")}
                      </StyledButton>
                    </ActionItem>
                  )}
                  <ActionItem danger>
                    <StyledButton
                      variant="link"
                      type="button"
                      onClick={() => {
                        handleSetUserData(
                          isTeacherSchoolAdmin && teacher
                            ? teacher.id
                            : teacher?.teacherId || "-1",
                          isTeacherSchoolAdmin && currentSchool?.school
                            ? currentSchool?.school?.id
                            : null
                        );
                        openConfirmModal();
                      }}
                    >
                      {t("actions.remove")}
                    </StyledButton>
                  </ActionItem>
                  <ActionItem>
                    <StyledButton variant="link" onClick={closeModal}>
                      {t("actions.cancel")}
                    </StyledButton>
                  </ActionItem>
                </ButtonGroup>
              </ButtonsSection>
            )}
          </form>
        </Container>
      </Modal>
    </>
  );
};

export default TeacherDetailsModal;
