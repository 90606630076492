import { AssessmentType } from "types";

export const getTestDuration = (type: AssessmentType) => {
  let duration = 0;

  switch (type) {
    default:
      duration = 0;
  }

  return duration;
};
