import styled from "styled-components";

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.stormGray};
  margin-bottom: 0.6rem;
  font-size: 1.4rem;
  letter-spacing: 0.03rem;
`;

export default Label;
