import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.geyser};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;

  background: ${({ theme }) => theme.colors.aquaHaze};
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.denim};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  gap: 1.2rem;
`;

export const FolderItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid #e7ecee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: ${({ theme }) => theme.colors.denim};
  }
  &:last-child {
    border: 0;
  }

  ${({ selected }) => selected && `background-color: #E8F4FA`}
`;

export const FolderList = styled.div`
  height: 150px;
  overflow: auto;
`;

export const ClearAll = styled.div`
  color: ${({ theme }) => theme.colors.denim};
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding-left: 1.2rem;
  cursor: pointer;
  margin-bottom: 1.2rem;
`;
