import styled from "styled-components";

import { ReactComponent as CoinsIcon } from "assets/images/coins.svg";
import { ReactComponent as CaretDown } from "assets/images/caret-down.svg";

export const Container = styled.div`
  position: relative;
  flex-basis: 16%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  cursor: pointer;
`;

export const StyledCoinsIcon = styled(CoinsIcon)`
  margin-right: 0.8rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CreditsNumber = styled.div`
  font-family: "Roboto Slab";
  font-weight: bold;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.denim};
  margin-right: 0.6rem;
`;

export const CoinsText = styled.div`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.6rem;
  margin-right: 0.6rem;
`;

export const StyledCaretDown = styled(CaretDown)`
  width: 1.2rem;
  height: 0.6rem;
`;
