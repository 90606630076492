import React, { FC } from "react";

import { Container, StyledLoader } from "./styles";
import { Props } from "./types";

const Loader: FC<Props> = ({ color }) => {
  return (
    <Container>
      <StyledLoader color={color} />
    </Container>
  );
};

export default Loader;
