import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import translation from "utils/i18n/locales/en/translation.json";

export const resources = {
  en: {
    translation,
  },
} as const;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    lng: "en",
    resources,
  });

export default i18n;
