import { FormikProps, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckCircle } from "assets/images/check-circle-blue.svg";

import Button from "components/atoms/Button";

import Input from "components/molecules/Input";

import { firstName, lastName, email } from "helpers/formValidation";

import { useToast } from "hooks/Toast";

import { currentSchoolIdVar } from "store/school";

import {
  InviteStudentMutation,
  InviteStudentMutationVariables,
  OrderDirection,
  useAssignStudentsToFolderMutation,
  useGetAllFoldersQuery,
  useInviteStudentMutation,
} from "generated/graphql";
import moment from "moment";
import StyledSelect from "components/molecules/Select";
import { useHistory } from "react-router-dom";
import paths from "routes/paths";
import AddFolderModal from "pages/Teacher/Folders/Modals/AddFolderModal";
import {
  Container,
  ButtonsSection,
  ButtonGroup,
  SectionHeader,
  FolderRow,
  SearchInput,
  FolderWrapper,
  SectionContent,
  StyledRadio,
  StyledLabel,
  ManageFolderSectionWrapper,
} from "../styles";
import { InviteUserProps, DelayedEmailType } from "../types";

const InviteStudentModal: FC<InviteUserProps> = ({
  isCSV,
  setFolder,
  setSchedule,
}) => {
  const { t } = useTranslation();
  const [inviteStudent] = useInviteStudentMutation();
  const [sendDate, setSendDate] = useState(moment());
  const [daysCount, setDaysCount] = useState(31);
  const [isAddFolderModalOpen, setAddFolderModalOpen] = useState(false);
  const [filterFoldersValue, setFilterFoldersValue] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [assignStudentMutation] = useAssignStudentsToFolderMutation();
  const history = useHistory();

  const currentSchoolId = currentSchoolIdVar();

  const addStudentInitialValues: InviteStudentMutationVariables &
    DelayedEmailType = {
    firstName: "",
    lastName: "",
    email: "",
    schoolId: Number(currentSchoolId),
    invitationDate: null,
    isScheduled: false,
    schedule: "immediately",
    year: moment().year(),
    month: moment().month(),
    day: moment().date(),
    hour: moment().hour(),
    minute: "00",
  };

  const Toast = useToast();

  const inviteStudentForm: FormikProps<
    InviteStudentMutationVariables & DelayedEmailType
  > = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: addStudentInitialValues,
    validationSchema: Yup.object({
      firstName,
      lastName,
      email,
    }),

    onSubmit: async (values) =>
      inviteStudent({
        variables: {
          email: values.email.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          schoolId: Number(currentSchoolId),
          isScheduled: values.schedule === "scheduled",
          invitationDate: sendDate.utc().valueOf(),
        },
      }),
  });

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // INFO: Formik resolves submitForm() Promise even on unsuccessful submitions
  // https://github.com/formium/formik/issues/1580
  const submitFormFix = async (andClose: boolean) =>
    inviteStudentForm
      .submitForm()
      .then((data: { data: InviteStudentMutation } | void) => {
        const isNew =
          data && data.data.inviteStudent?.studentsForNewUsers?.length;

        if (selectedFolderId && data) {
          assignStudentMutation({
            variables: {
              folderId: Number(selectedFolderId),
              schoolId: Number(currentSchoolId),
              studentIds: [
                Number(
                  data.data?.inviteStudent?.studentsForNewUsers?.length
                    ? data.data?.inviteStudent?.studentsForNewUsers[0]?.id
                    : 0
                ),
              ],
            },
          });
        }

        if (andClose) {
          history.push(paths.studentsEmptyFolder);
        }

        inviteStudentForm.resetForm();

        const addedMsg = isNew
          ? t("successAction.studentAdded")
          : t("warningAction.studentExists");

        Toast(isNew ? "success" : "warning", addedMsg);
      })
      .catch((err) =>
        Toast("error", err?.message || t("errorAction.generalError"))
      );

  const submitForm = (andClose: boolean) => {
    inviteStudentForm.validateForm().then((res) => {
      inviteStudentForm.setErrors(res);
      inviteStudentForm.setTouched({
        email: true,
        firstName: true,
        lastName: true,
      });

      if (Object.keys(res).length === 0) submitFormFix(andClose);
    });
  };

  const { data: allFolders, refetch: refetchFolders } = useGetAllFoldersQuery({
    variables: {
      where: { school: { id: { equals: currentSchoolId } } },
      orderBy: [{ name: OrderDirection.Asc }],
    },
  });

  const filterFolders = (e: any) => {
    setFilterFoldersValue(e.target.value);
  };

  const setSelectedFolder = (folderId: string) => {
    setSelectedFolderId(selectedFolderId === folderId ? null : folderId);
  };

  useEffect(() => {
    setDaysCount(
      moment(
        `${inviteStudentForm?.values?.year}-${(
          inviteStudentForm?.values?.month + 1
        )
          .toString()
          .padStart(2, "0")}`,
        "YYYY-MM"
      ).daysInMonth() || 31
    );
    setSendDate(
      moment(
        `${inviteStudentForm?.values?.year}-${
          inviteStudentForm?.values?.month + 1
        }-${inviteStudentForm?.values?.day} ${
          inviteStudentForm?.values?.hour
        }:${inviteStudentForm?.values?.minute}`,
        "Y-M-D h:m"
      )
    );
  }, [inviteStudentForm.values]);

  useEffect(() => setFolder(selectedFolderId), [selectedFolderId, setFolder]);
  useEffect(
    () =>
      setSchedule(
        inviteStudentForm.values.schedule === "immediately"
          ? "immediately"
          : sendDate.utc().valueOf()
      ),
    [sendDate, setSchedule, inviteStudentForm.values.schedule]
  );

  const handleCloseAddFolderModal = (folderName?: string) => {
    if (folderName) {
      refetchFolders().then((data) => {
        const newFolder = data.data?.folders?.find(
          (folder) => folder.name === folderName
        );

        if (newFolder) {
          setSelectedFolder(newFolder.id);
        }
      });
    }
    setAddFolderModalOpen(false);
  };

  return (
    <Container>
      <AddFolderModal
        isOpen={isAddFolderModalOpen}
        title={t("global.createNewFolder")}
        onClose={handleCloseAddFolderModal}
      />
      {!isCSV && <SectionHeader>1. Basic details</SectionHeader>}
      <form
        onSubmit={inviteStudentForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        {!isCSV && (
          <SectionContent>
            <Input
              name="email"
              label={t("global.emailAddressLabel")}
              placeholder={t("global.emailAddressPlaceholder")}
              handleChange={inviteStudentForm.handleChange}
              handleBlur={inviteStudentForm.handleBlur}
              value={inviteStudentForm.values.email}
              error={
                inviteStudentForm.touched.email &&
                inviteStudentForm.errors.email
              }
            />
            <Input
              name="firstName"
              label={t("global.firstNameLabel")}
              placeholder={t("global.firstNamePlaceholder")}
              handleChange={inviteStudentForm.handleChange}
              handleBlur={inviteStudentForm.handleBlur}
              value={inviteStudentForm.values.firstName}
              error={
                inviteStudentForm.touched.firstName &&
                inviteStudentForm.errors.firstName
              }
            />
            <Input
              name="lastName"
              label={t("global.lastNameLabel")}
              placeholder={t("global.lastNamePlaceholder")}
              handleChange={inviteStudentForm.handleChange}
              handleBlur={inviteStudentForm.handleBlur}
              value={inviteStudentForm.values.lastName}
              error={
                inviteStudentForm.touched.lastName &&
                inviteStudentForm.errors.lastName
              }
            />
          </SectionContent>
        )}

        <>
          <SectionHeader>
            {isCSV ? "1" : "2"}. Select a folder to assign
          </SectionHeader>

          <ManageFolderSectionWrapper>
            <SearchInput
              onChange={filterFolders}
              placeholder="type to start searching"
            />
            <Button
              onClick={() => {
                setAddFolderModalOpen(true);
              }}
              height="40px"
              width="35%"
              variant="secondary"
            >
              {t("global.createNewFolder")}
            </Button>
          </ManageFolderSectionWrapper>

          <SectionContent>
            <FolderWrapper>
              {allFolders?.folders
                ?.filter((folder) =>
                  folder.name?.toLowerCase().includes(filterFoldersValue)
                )
                .sort((a, b) => (a.createdAt_utc > b.createdAt_utc ? -1 : 1))
                .map((folder) => (
                  <FolderRow
                    onClick={() => setSelectedFolder(folder.id)}
                    selected={selectedFolderId === folder.id}
                  >
                    <span>
                      {folder.countryModel?.flag} {folder.name}
                    </span>
                    {selectedFolderId === folder.id && <CheckCircle />}
                  </FolderRow>
                ))}
            </FolderWrapper>
          </SectionContent>
        </>

        <>
          <SectionHeader>{isCSV ? "2" : "3"}. Sending options</SectionHeader>
          <ButtonsSection>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <StyledLabel htmlFor="immediately">
              <StyledRadio
                type="radio"
                id="immediately"
                name="schedule"
                value="immediately"
                onChange={inviteStudentForm.handleChange}
                onBlur={inviteStudentForm.handleBlur}
                checked={inviteStudentForm.values.schedule === "immediately"}
              />
              send immediately
            </StyledLabel>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <StyledLabel htmlFor="schedule">
              <StyledRadio
                type="radio"
                id="schedule"
                name="schedule"
                value="scheduled"
                onChange={inviteStudentForm.handleChange}
                onBlur={inviteStudentForm.handleBlur}
              />
              schedule email
            </StyledLabel>
          </ButtonsSection>
          {inviteStudentForm.values.schedule === "scheduled" && (
            <div style={{ marginTop: "2rem" }}>
              <ButtonsSection
                fullWidth
                style={{ marginTop: "0rem", alignItems: "flex-end" }}
              >
                <div style={{ width: "200px" }}>
                  <StyledSelect
                    name="year"
                    label="Date"
                    menuPlacement="top"
                    handleChange={(name: string, value: { value: string }) =>
                      inviteStudentForm.setFieldValue(name, value.value)
                    }
                    handleBlur={inviteStudentForm.handleBlur}
                    value={{
                      label: inviteStudentForm.values.year.toString() || "",
                      value: inviteStudentForm.values.year.toString() || "",
                    }}
                    options={[
                      {
                        value: moment().year().toString(),
                        label: moment().year().toString(),
                      },
                      {
                        value: (moment().year() + 1).toString(),
                        label: (moment().year() + 1).toString(),
                      },
                    ]}
                  />
                </div>
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  <StyledSelect
                    name="month"
                    menuPlacement="top"
                    handleChange={(name: string, value: { value: string }) =>
                      inviteStudentForm.setFieldValue(
                        name,
                        parseInt(value.value, 10)
                      )
                    }
                    handleBlur={inviteStudentForm.handleBlur}
                    value={{
                      label: monthList[inviteStudentForm.values.month],
                      value: inviteStudentForm.values.month.toString(),
                    }}
                    options={monthList.map((month, idx) => ({
                      label: month,
                      value: idx.toString(),
                    }))}
                  />
                </div>

                <div style={{ width: "200px" }}>
                  <StyledSelect
                    name="day"
                    menuPlacement="top"
                    handleChange={(name: string, value: { value: string }) =>
                      inviteStudentForm.setFieldValue(name, value.value)
                    }
                    handleBlur={inviteStudentForm.handleBlur}
                    value={{
                      label: inviteStudentForm.values.day.toString(),
                      value: inviteStudentForm.values.day.toString(),
                    }}
                    options={Array.from(Array(daysCount).keys()).map((day) => ({
                      label: (day + 1).toString(),
                      value: (day + 1).toString(),
                    }))}
                  />
                </div>
              </ButtonsSection>
              <ButtonsSection
                style={{ marginTop: "2rem", justifyContent: "flex-start" }}
                fullWidth
              >
                <div style={{ width: "200px", marginRight: "1px" }}>
                  <StyledSelect
                    name="hour"
                    label="Hour"
                    menuPlacement="top"
                    handleChange={(name: string, value: { value: string }) =>
                      inviteStudentForm.setFieldValue(name, value.value)
                    }
                    handleBlur={inviteStudentForm.handleBlur}
                    value={{
                      label: inviteStudentForm.values.hour.toString(),
                      value: inviteStudentForm.values.hour.toString(),
                    }}
                    options={Array.from(Array(24).keys()).map((hour) => ({
                      label: hour.toString(),
                      value: hour.toString(),
                    }))}
                  />
                </div>

                <div style={{ width: "200px" }}>
                  <StyledSelect
                    name="minute"
                    label="Minutes"
                    menuPlacement="top"
                    handleChange={(name: string, value: { value: string }) =>
                      inviteStudentForm.setFieldValue(name, value.value)
                    }
                    handleBlur={inviteStudentForm.handleBlur}
                    value={{
                      label: inviteStudentForm.values.minute.toString(),
                      value: inviteStudentForm.values.minute.toString(),
                    }}
                    options={["00", "15", "30", "45"].map((minute) => ({
                      label: minute,
                      value: minute,
                    }))}
                  />
                </div>
              </ButtonsSection>
            </div>
          )}
        </>

        {!isCSV && (
          <ButtonsSection fullWidth>
            <div>
              <Button
                variant="primary"
                type="button"
                onClick={() => submitForm(true)}
              >
                {t("actions.save")}
              </Button>
            </div>
            <ButtonGroup>
              <Button
                variant="secondary"
                width="auto"
                type="button"
                onClick={() => submitForm(false)}
              >
                {t("teacher.saveAndAdd")}
              </Button>
            </ButtonGroup>
          </ButtonsSection>
        )}
      </form>
    </Container>
  );
};

export default InviteStudentModal;
