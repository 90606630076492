import React, { FC } from "react";
import { ReactComponent as ArrowLeftIcon } from "assets/images/arrow-left.svg";

import { Container, MobileHeaderButton } from "./styles";
import { Props } from "./types";

const MobileHeader: FC<Props> = ({ title, onClick }) => {
  return (
    <Container>
      <MobileHeaderButton variant="link" onClick={onClick}>
        <ArrowLeftIcon /> {title}
      </MobileHeaderButton>
    </Container>
  );
};

export default MobileHeader;
