/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect } from "react";

import { ReactComponent as CloseIcon } from "assets/images/circle-close.svg";

import {
  Background,
  Container,
  Header,
  HeaderTitle,
  SubTitle,
  CloseButton,
  ContentWrapper,
  StyledCard,
} from "./styles";
import { Props } from "./types";

const Modal: FC<Props> = ({
  cardStyles,
  isOpen,
  onClose,
  title,
  children,
  size = "wide",
  subTitle,
  heading,
  closeIcon = true,
  rightHeaderContent,
  headerMargin,
  zIndex,
}) => {
  // INFO: It might be omitted with React Portal
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  const handleClose = () => {
    document.body.style.overflow = "auto";
    onClose();
  };

  return isOpen ? (
    <Background isOpen={isOpen} onClick={handleClose} zIndex={zIndex}>
      <Container size={size}>
        <ContentWrapper onClick={(event) => event.stopPropagation()}>
          <StyledCard styles={cardStyles}>
            {heading && heading}
            <Header headerMargin={headerMargin}>
              <div>
                <HeaderTitle>{title}</HeaderTitle>
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
              </div>
              {closeIcon && (
                <CloseButton type="button" onClick={handleClose}>
                  <CloseIcon />
                </CloseButton>
              )}
              {rightHeaderContent && rightHeaderContent}
            </Header>
            {children}
          </StyledCard>
        </ContentWrapper>
      </Container>
    </Background>
  ) : (
    <Background isOpen={isOpen} onClick={handleClose} />
  );
};

export default Modal;
