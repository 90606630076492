import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  font-family: Roboto;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  padding: 2rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
