import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";
import DropdownMenu from "components/molecules/DropdownMenu";
import PageNumbers from "components/atoms/PageNumbers";

import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  AddStudentsWrapper,
  StyledButton,
  AddStudentsDropdown,
  BottomActionsSection,
  DownloadCSVButton,
  PageOptionsDropdown,
  PageNumberWrapper,
  GreyChevronDown,
} from "./styles";
import { Props } from "./types";

const STUDENTS_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50];

const StudentsListActions: FC<Props> = ({
  redirectToAddStudent,
  addStudentOptions,
  addStudentDropdownVisible,
  isAddStudentButtonEnabled,
  currentPage,
  handlePagePress,
  lastPage,
  openPageOptionsDropdown,
  studentsPerPage,
  handleStudentPageChange,
  pageOptionsDropdownVisible,
  studentsNumber,
  isArchivedStudentsList = false,
}) => {
  const { t } = useTranslation();
  return (
    <BottomActionsSection spaceBetween={studentsNumber !== 0}>
      {!isArchivedStudentsList && addStudentOptions && (
        <AddStudentsWrapper>
          <StyledButton
            onClick={(e) => {
              e.stopPropagation();
              redirectToAddStudent();
            }}
            variant="primary"
            disabled={!isAddStudentButtonEnabled}
          >
            {t("teacher.addStudents")}
          </StyledButton>

          <AddStudentsDropdown>
            <DropdownMenu
              elements={addStudentOptions}
              offsetTop="5rem"
              visible={addStudentDropdownVisible}
            />
          </AddStudentsDropdown>
        </AddStudentsWrapper>
      )}

      {studentsNumber !== 0 && (
        <>
          <PageNumberWrapper>
            <Button
              variant="text"
              disabled={currentPage === 1}
              onClick={() => handlePagePress(currentPage - 1)}
            >
              {"<"} {t("global.prev")}
            </Button>
            <PageNumbers
              currentPage={currentPage}
              lastPage={lastPage}
              handlePagePress={handlePagePress}
            />
            <Button
              variant="text"
              disabled={currentPage === lastPage}
              onClick={() => handlePagePress(currentPage + 1)}
            >
              {t("global.next")} {">"}
            </Button>
          </PageNumberWrapper>

          <PageOptionsDropdown>
            <StyledButton
              variant="text"
              onClick={(e) => {
                e.stopPropagation();
                openPageOptionsDropdown();
              }}
            >
              {t("teacher.studentsPerPage")}: {studentsPerPage}{" "}
              <GreyChevronDown />
            </StyledButton>
            <DropdownMenu
              position="right"
              elements={STUDENTS_PER_PAGE_OPTIONS.map((pageItemsNum) => ({
                name: String(pageItemsNum),
                onClick: () => handleStudentPageChange(pageItemsNum),
              }))}
              offsetTop="5rem"
              visible={pageOptionsDropdownVisible}
            />
          </PageOptionsDropdown>
        </>
      )}
    </BottomActionsSection>
  );
};

export default StudentsListActions;
