import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { answeredQuestionsCountVar, questionsCountVar } from "store/test";
import { useToast } from "hooks/Toast";
import { AssessmentType } from "types";
import {
  useCompleteInterestInventoryTestMutation,
  useGetAuthenticatedUserQuery,
} from "generated/graphql";

import paths from "routes/paths";
import { getTestName } from "helpers/getTestName";

import {
  Wrapper,
  HeaderWrapper,
  Header,
  FixedWrapper,
  MainWrapper,
  Border,
  HeaderProgressBar,
  ProgressBarText,
  ProgressBarPercentage,
  Title,
} from "./styles";

import FinishedTestModal from "../Test/Modals/FinishedTestModals/FinishedTestModal";
import TimesUpModal from "../Test/Modals/FinishedTestModals/TimesUpModal";
import Interest from "./Interest";
import ConfirmEndOfTestModal from "../Test/Modals/FinishedTestModals/ConfirmEndOfTestModal";

const Tests = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: AssessmentType }>();
  const Toast = useToast();
  const history = useHistory();

  const [
    finishInterestInventoryTest,
  ] = useCompleteInterestInventoryTestMutation();

  const { data: userData } = useGetAuthenticatedUserQuery();

  const [testFinishedModal, setTestFinishedModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const questionsCount = useReactiveVar(questionsCountVar);
  const answeredQuestionsCount = useReactiveVar(answeredQuestionsCountVar);
  const [expired, setExpired] = useState(false);
  const [isClockRunning, setIsClockRunning] = useState(true);
  const [
    isInterestAnswersHighlighted,
    setIsInterestAnswersHighlighted,
  ] = useState(false);
  const [isTooFewInterestAnswers, setIsTooFewInterestAnswers] = useState(false);

  const handleUncheckedQuestions = () => {
    if (isTooFewInterestAnswers) {
      setIsInterestAnswersHighlighted(true);
    }
  };

  const showConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
    handleUncheckedQuestions();
    const firstWithoutAnswer = document.querySelector(".unanswered");
    firstWithoutAnswer?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const finishTest = () => {
    setTestFinishedModal(true);
    setIsClockRunning(false);
    finishInterestInventoryTest({
      variables: {
        id: Number(
          userData?.authenticatedItem?.students![0].testAttempt?.id || -1
        ),
      },
    }).catch((err) => Toast("error", err.message));
  };

  const finishAssessment = () => {
    history.push(paths.studentDashboard);
  };

  const completedPercent = Math.round(
    (answeredQuestionsCount * 100) / questionsCount
  );

  return (
    <Wrapper>
      {/* MODALS */}
      <ConfirmEndOfTestModal
        isOpen={confirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={finishTest}
        isInterestInventoryBlocked={isTooFewInterestAnswers}
      />
      <FinishedTestModal
        isOpen={testFinishedModal}
        onClose={finishAssessment}
        testType={getTestName(type)}
      />
      {expired && <TimesUpModal isOpen={expired} onClose={() => {}} />}

      <FixedWrapper>
        <HeaderWrapper>
          <Border />
          <Header>
            <Title>{getTestName(type)}</Title>
            <HeaderProgressBar>
              <ProgressBarText>
                {answeredQuestionsCount}/{questionsCount}{" "}
                {t("student.completed").toLowerCase()}
              </ProgressBarText>
              <ProgressBarPercentage>
                {" "}
                ({completedPercent}%)
              </ProgressBarPercentage>
            </HeaderProgressBar>
          </Header>
        </HeaderWrapper>
      </FixedWrapper>
      <MainWrapper>
        <Interest
          id={userData?.authenticatedItem?.students![0].testAttempt?.id || "-1"}
          finishTest={showConfirmationModal}
          isUncheckedHighlighted={isInterestAnswersHighlighted}
          setIsTooFewInterestAnswers={setIsTooFewInterestAnswers}
        />
      </MainWrapper>
    </Wrapper>
  );
};

export default Tests;
