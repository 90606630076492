import React from "react";
import { Redirect } from "react-router-dom";

import SignIn from "pages/SignIn";
import NotFound from "pages/NotFound/NotFound";
import { SetupPassword, VerifyData, Payment } from "pages/SetupPassword";
import Students from "pages/Teacher/Students";
import Main from "layouts/Main";
import School from "pages/Teacher/School";
import Decider from "routes/Decider";
import Faq from "pages/Teacher/Faq";
import Student from "layouts/Student";
import Dashboard from "pages/Student/Dashboard";
import AcademyDashboard from "pages/Student/Academy/AcademyDashboard/AcademyDashboard";
import Player from "pages/Student/Academy/Player/Player";
import Instructions from "pages/Student/Test/Instructions";
import { PaymentSuccess, PaymentFailed } from "pages/Student/Payment";
import TestIntro from "layouts/TestIntro";

import TrainingFinished from "pages/Student/Test/TrainingFinished";

import Training from "pages/Student/Test/Training";
import Tests from "pages/Student/Tests";
import ForgotPassword from "pages/ForgotPassword";
import Folders from "pages/Teacher/Folders";
import AddStudents from "pages/Teacher/AddStudents";
import Feedback from "pages/Feedback/Feedback";
import paths from "./paths";
import { RouteParams } from "./types";

const routes: RouteParams[] = [
  {
    path: paths.root,
    exact: true,
    component: <Redirect to={paths.decider} />,
  },
  {
    path: paths.signIn,
    exact: true,
    secure: false,
    component: <SignIn />,
  },
  {
    path: paths.decider,
    exact: true,
    secure: true,
    component: <Decider />,
  },
  {
    path: paths.setupPassword,
    exact: true,
    secure: false,
    component: <SetupPassword />,
  },
  {
    path: paths.forgotPassword,
    exact: true,
    secure: false,
    component: <ForgotPassword />,
  },
  {
    path: paths.verifyData,
    exact: true,
    component: <VerifyData />,
  },
  {
    path: paths.selfRegistration,
    exact: true,
    secure: false,
    component: <VerifyData isSelfRegistration />,
  },
  {
    path: paths.selfRegistrationPayment,
    exact: true,
    secure: true,
    component: <Payment />,
  },
  {
    path: paths.selfRegistrationPaymentSuccess,
    exact: true,
    secure: true,
    component: <PaymentSuccess />,
  },
  {
    path: paths.selfRegistrationPaymentFailed,
    exact: true,
    secure: true,
    component: <PaymentFailed />,
  },

  {
    path: paths.studentsAdd,
    exact: true,
    component: (
      <Main>
        <AddStudents />
      </Main>
    ),
  },
  {
    path: paths.students,
    exact: false,
    component: (
      <Main>
        <Students />
      </Main>
    ),
  },

  {
    path: paths.tests,
    exact: true,
    component: (
      <TestIntro>
        <Tests />
      </TestIntro>
    ),
  },
  {
    path: paths.school,
    exact: true,
    component: (
      <Main>
        <School />
      </Main>
    ),
  },
  {
    path: paths.folders,
    exact: true,
    component: (
      <Main>
        <Folders />
      </Main>
    ),
  },
  {
    path: paths.paymentSuccess,
    exact: true,
    component: (
      <Main>
        <School />
      </Main>
    ),
  },
  {
    path: paths.paymentFailed,
    exact: true,
    component: (
      <Main>
        <School />
      </Main>
    ),
  },
  {
    path: paths.faq,
    exact: true,
    component: (
      <Main>
        <Faq />
      </Main>
    ),
  },
  {
    path: paths.studentDashboard,
    exact: true,
    component: (
      <Student>
        <Dashboard />
      </Student>
    ),
  },
  {
    path: paths.academyDashboard,
    exact: true,
    component: (
      <Student>
        <AcademyDashboard />
      </Student>
    ),
  },
  {
    path: paths.tutorialPlayer,
    exact: true,
    component: (
      <Student>
        <Player isTutorial />
      </Student>
    ),
  },
  {
    path: paths.videoPlayer,
    exact: true,
    component: (
      <Student>
        <Player isTutorial={false} />
      </Student>
    ),
  },
  {
    path: paths.testInstruction,
    exact: true,
    component: (
      <TestIntro>
        <Instructions />
      </TestIntro>
    ),
  },
  {
    path: paths.trainingFinished,
    exact: true,
    component: (
      <TestIntro>
        <TrainingFinished />
      </TestIntro>
    ),
  },
  {
    path: paths.training,
    exact: true,
    component: (
      <TestIntro>
        <Training />
      </TestIntro>
    ),
  },
  {
    path: paths.feedback,
    exact: true,
    secure: false,
    component: <Feedback />,
  },
  {
    path: paths.notFound,
    component: (
      <Main>
        <NotFound />
      </Main>
    ),
  },
];

export default routes;
