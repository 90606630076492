import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProps } from "formik";

import Header from "components/atoms/Header";

import { email } from "helpers/formValidation";
import { useHistory } from "react-router-dom";

import LoginTheme from "themes/LoginTheme";

import Input from "components/molecules/Input";
import {
  useAuthenticateUserWithPasswordMutation,
  UserAuthenticationWithPasswordSuccess,
} from "generated/graphql";
import { useToast } from "hooks/Toast";

import paths from "routes/paths";
import {
  FormWrapper,
  ButtonsWrapper,
  HeaderWrapper,
  PullRight,
  MobileOnly,
  SpaceMiddle,
} from "./styles";

import { SignInInitialValues } from "./types";

const signInInitialValues = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

const SignIn = () => {
  const { t } = useTranslation();
  const [login, { loading }] = useAuthenticateUserWithPasswordMutation();

  const Toast = useToast();

  const history = useHistory();

  const tryLogin = async (values: SignInInitialValues) => {
    login({
      variables: {
        email: values.email.toLowerCase(),
        password: values.password,
      },
    })
      .then((res) => {
        const data = res.data
          ?.authenticateUserWithPassword as UserAuthenticationWithPasswordSuccess;

        if (
          // eslint-disable-next-line prettier/prettier, no-underscore-dangle
          res.data?.authenticateUserWithPassword?.__typename !==
          "UserAuthenticationWithPasswordFailure"
        ) {
          localStorage.setItem("EFC_token", data?.sessionToken || "");
          localStorage.removeItem("EFC_FeedbackForm_Closed");
          history.push("/decider");
        } else {
          Toast("error", t("errorAction.wrongPassword"));
        }
      })
      .catch(() => {
        Toast("error", t("errorAction.unableToLogin"));
      });
  };

  useEffect(() => localStorage.removeItem("EFC_token"), []);

  const signInForm: FormikProps<SignInInitialValues> = useFormik({
    initialValues: signInInitialValues,
    validationSchema: Yup.object({
      email,
    }),
    onSubmit: (values) => tryLogin(values),
  });

  const handleForgotPassword = () => {
    history.push(paths.forgotPassword);
  };

  return (
    <LoginTheme>
      <FormWrapper
        onSubmit={signInForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        <HeaderWrapper>
          <Header>{t("user.logIn")}</Header>
        </HeaderWrapper>

        <Input
          name="email"
          label={t("user.yourLogin")}
          handleChange={signInForm.handleChange}
          handleBlur={signInForm.handleBlur}
          placeholder={t("user.yourEmail")}
          value={signInForm.values.email}
          error={signInForm.touched.email && signInForm.errors.email}
        />
        <Input
          name="password"
          label={t("global.password")}
          handleChange={signInForm.handleChange}
          handleBlur={signInForm.handleBlur}
          placeholder={t("global.password")}
          type="password"
          value={signInForm.values.password}
          error={signInForm.touched.password && signInForm.errors.password}
          password
        />
        <ButtonsWrapper>
          <SpaceMiddle type="submit" variant="primary" disabled={loading}>
            {t("user.logIn")}
          </SpaceMiddle>
          <PullRight
            type="button"
            variant="link"
            onClick={handleForgotPassword}
          >
            {t("user.forgotPassword")}
            <MobileOnly>?</MobileOnly>
          </PullRight>
        </ButtonsWrapper>
      </FormWrapper>
    </LoginTheme>
  );
};

export default SignIn;
