import styled from "styled-components";

export const Container = styled.div``;

export const StudentsListWrapper = styled.div`
  height: 82%;
  overflow-y: auto;
`;

export const ActionDrawerWrapper = styled.div`
  width: 69rem;
  margin: 1rem auto;
`;
