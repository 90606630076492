import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/atoms/Card";

import {
  Label,
  CompletedBar,
  InProgressBar,
  ChartInfo,
  ChartInfoText,
  ProgressBarWrapper,
  CompletedInfoText,
  Container,
} from "./styles";
import { Props } from "./types";

const TestProgressBar: FC<Props> = ({ completed, allQuestions }) => {
  const { t } = useTranslation();
  const completedValue = Math.round((completed * 100) / allQuestions);
  const [animatedCompletedValue, setAnimatedCompletedValue] = useState(
    completedValue
  );
  const inProgressValue = 100 - completedValue;

  useEffect(() => {
    let animation: number;
    if (animatedCompletedValue < completedValue)
      animation = setTimeout(
        () => setAnimatedCompletedValue((oldValue) => oldValue + 1),
        20
      );
    return () => clearTimeout(animation);
  }, [animatedCompletedValue, completedValue]);

  return (
    <Container>
      <Card styles={{ padding: "2.4rem 2.4rem 1.6rem 2.4rem" }}>
        <Label>{t("student.progress")}</Label>
        <div>
          <ChartInfo>
            <div>{animatedCompletedValue}%</div>
            {completed < 100 && (
              <ChartInfoText>{t("student.keepGoing")}</ChartInfoText>
            )}
          </ChartInfo>
          <ProgressBarWrapper>
            <CompletedBar width={completedValue} />
            <InProgressBar width={inProgressValue} />
          </ProgressBarWrapper>
          <CompletedInfoText>
            {completed} / {allQuestions} {t("student.completed").toLowerCase()}
          </CompletedInfoText>
        </div>
      </Card>
    </Container>
  );
};

export default TestProgressBar;
