import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Confetti } from "assets/images/confetti.svg";
import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";
import MobileModal from "components/molecules/MobileModal";

import {
  Text,
  Wrapper,
  ModalWrapper,
  ButtonSection,
  FinishButton,
  SmallCircle,
  RightIconWrapper,
} from "./styles";
import { FinishModalProps } from "./types";

const ConfirmEndOfTestModal: FC<FinishModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isInterestInventoryBlocked,
}) => {
  const { t } = useTranslation();

  const textComponent = isInterestInventoryBlocked ? (
    <Text style={{ marginBottom: "3rem" }}>
      {t("student.interestInventoryRequired")}
    </Text>
  ) : (
    <Text>{t("student.sureAboutFinish")}</Text>
  );

  return (
    <>
      <ModalWrapper>
        <Modal
          isOpen={isOpen}
          size="narrow"
          onClose={onClose}
          title={t("student.finishAssessment")}
          headerMargin=" 0 0 2rem 0"
          closeIcon={false}
          rightHeaderContent={
            <SmallCircle>
              <Confetti />
            </SmallCircle>
          }
        >
          <Wrapper>
            {textComponent}
            <ButtonSection>
              {!isInterestInventoryBlocked && (
                <FinishButton variant="primary" onClick={onConfirm}>
                  {t("student.confirmFinish")}
                </FinishButton>
              )}
              {isInterestInventoryBlocked ? (
                <Button variant="primary" onClick={onClose}>
                  {t("actions.goBack")}
                </Button>
              ) : (
                <Button
                  variant="link"
                  onClick={onClose}
                  style={{ maxWidth: "fit-content", minWidth: "auto" }}
                >
                  {t("actions.cancel")}
                </Button>
              )}
            </ButtonSection>
          </Wrapper>
        </Modal>
      </ModalWrapper>

      {/* Displayed only on mobile */}
      <MobileModal
        onClose={onClose}
        title={t("student.finishAssessment")}
        isOpen={isOpen}
        headerIcon={
          <RightIconWrapper>
            <SmallCircle
              style={{
                top: "-3rem",
                right: 0,
                position: "absolute",
              }}
            >
              <Confetti />
            </SmallCircle>
          </RightIconWrapper>
        }
      >
        <Wrapper>
          {textComponent}
          <ButtonSection>
            {!isInterestInventoryBlocked && (
              <Button
                variant="primary"
                onClick={onConfirm}
                style={{ marginBottom: "2rem" }}
              >
                {t("student.confirmFinish")}
              </Button>
            )}
            <Button variant="secondary" onClick={onClose}>
              {isInterestInventoryBlocked
                ? t("actions.goBack")
                : t("actions.cancel")}
            </Button>
          </ButtonSection>
        </Wrapper>
      </MobileModal>
    </>
  );
};

export default ConfirmEndOfTestModal;
