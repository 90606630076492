import React, { FC } from "react";

import { Checklist, ChecklistItem, Circle } from "./styles";
import { PasswordChecklistType } from "./types";

const PasswordChecklist: FC<PasswordChecklistType> = ({ password }) => (
  <Checklist>
    <div>The password must contain at least one:</div>
    <ChecklistItem>
      <Circle isset={password.length >= 8} />8 characters
    </ChecklistItem>
    <ChecklistItem>
      <Circle isset={password.match(/[A-Z]/g) !== null} />
      uppercase letter
    </ChecklistItem>
    <ChecklistItem>
      <Circle isset={password.match(/[0-9]/g) !== null} />
      number digit
    </ChecklistItem>
    <ChecklistItem>
      <Circle isset={password.match(/\W+/g) !== null} />
      special character
    </ChecklistItem>
  </Checklist>
);

export default PasswordChecklist;
