import styled from "styled-components";

export const RadioButtonsWrapper = styled.div`
  margin-top: 1.8rem;
  display: flex;
`;

export const RadioButtonWrapper = styled.div`
  margin-right: 3.2rem;
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
`;

export const ModalContentWrapper = styled.div`
  margin-top: 3.2rem;
`;
