import i18n from "utils/i18n/i18n";

import { Content } from "./types";

export const trainingFinishedContent: Content = {
  numerical: {
    header: i18n.t("test.trainingFinished.numerical.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.numerical.content1") },
      { content: i18n.t("test.trainingFinished.numerical.content2") },
      { content: i18n.t("test.trainingFinished.numerical.content3") },
      { content: i18n.t("test.trainingFinished.numerical.content4") },
      { content: i18n.t("test.trainingFinished.numerical.content5") },
      {
        content: i18n.t("test.trainingFinished.numerical.content6"),
        styles: { fontWeight: 500 },
      },
      { content: i18n.t("test.numerical.content6"), isBanner: true },
    ],
  },
  abstract: {
    header: i18n.t("test.trainingFinished.abstract.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.abstract.content1") },
      { content: i18n.t("test.trainingFinished.abstract.content2") },
      { content: i18n.t("test.trainingFinished.abstract.content3") },
      { content: i18n.t("test.trainingFinished.abstract.content4") },
      { content: i18n.t("test.trainingFinished.abstract.content5") },
      {
        content: i18n.t("test.trainingFinished.abstract.content6"),
        styles: { fontWeight: 500 },
      },
    ],
  },
  interest: {
    header: i18n.t("test.trainingFinished.interest.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.interest.content1") },
      { content: i18n.t("test.trainingFinished.interest.content2") },
      { content: i18n.t("test.trainingFinished.interest.content3") },
      { content: i18n.t("test.trainingFinished.interest.content4") },
      { content: i18n.t("test.trainingFinished.interest.content5") },
      {
        content: i18n.t("test.trainingFinished.interest.content6"),
        styles: { fontWeight: 500 },
      },
    ],
  },
  verbal1: {
    header: i18n.t("test.trainingFinished.verbal1.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.verbal1.content1") },
      { content: i18n.t("test.trainingFinished.verbal1.content2") },
      { content: i18n.t("test.trainingFinished.verbal1.content3") },
      { content: i18n.t("test.trainingFinished.verbal1.content4") },
      { content: i18n.t("test.trainingFinished.verbal1.content5") },
      {
        content: i18n.t("test.trainingFinished.verbal1.content6"),
        styles: { fontWeight: 500 },
      },
      { content: i18n.t("test.vocabulary.content7"), isBanner: true },
    ],
  },
  verbal2: {
    header: i18n.t("test.trainingFinished.verbal2.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.verbal2.content1") },
      { content: i18n.t("test.trainingFinished.verbal2.content2") },
      { content: i18n.t("test.trainingFinished.verbal2.content3") },
      { content: i18n.t("test.trainingFinished.verbal2.content4") },
      { content: i18n.t("test.trainingFinished.verbal2.content5") },
      {
        content: i18n.t("test.trainingFinished.verbal2.content6"),
        styles: { fontWeight: 500 },
      },
    ],
  },
  verbal3: {
    header: i18n.t("test.trainingFinished.verbal3.header"),
    contentData: [
      { content: i18n.t("test.trainingFinished.verbal3.content1") },
      { content: i18n.t("test.trainingFinished.verbal3.content2") },
      { content: i18n.t("test.trainingFinished.verbal3.content3") },
      { content: i18n.t("test.trainingFinished.verbal3.content4") },
      { content: i18n.t("test.trainingFinished.verbal3.content5") },
      {
        content: i18n.t("test.trainingFinished.verbal3.content6"),
        styles: { fontWeight: 500 },
      },
    ],
  },
};
