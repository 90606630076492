import React, { FC } from "react";

import Modal from "components/atoms/Modal";

import { useDeleteFolderMutation } from "generated/graphql";

import { ConfirmMessage, Container, StyledButton } from "./styles";
import { EditFolderProps } from "./types";

const DeleteFolderModal: FC<EditFolderProps> = ({
  isOpen,
  onClose,
  title,
  selectedFolder,
}) => {
  const [deleteFolderMutation] = useDeleteFolderMutation();

  const deleteFolder = async () => {
    await deleteFolderMutation({
      variables: { where: { id: selectedFolder?.id } },
    }).then(() => onClose());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        {selectedFolder?.studentsCount === 0 ? (
          <ConfirmMessage>
            Are you sure you want to delete the &lsquo;
            <strong>{selectedFolder?.name}</strong>&rsquo; folder?
          </ConfirmMessage>
        ) : (
          <ConfirmMessage wide>
            Deleting the &lsquo;
            <strong>{selectedFolder?.name}</strong>&rsquo; folder will leave{" "}
            {selectedFolder?.studentsCount} student
            {selectedFolder?.studentsCount === 1 ? "" : "s"} unassigned. <br />
            <br />
            <strong>Are you sure you want to proceed?</strong>
          </ConfirmMessage>
        )}

        <StyledButton
          variant="primary"
          width="50%"
          type="button"
          onClick={deleteFolder}
        >
          Yes, delete this folder
        </StyledButton>
      </Container>
    </Modal>
  );
};

export default DeleteFolderModal;
