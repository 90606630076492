import styled from "styled-components";

import { ReactComponent as CaretDownIcon } from "assets/images/caret-down.svg";

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 1.6rem;
  flex: 1;
  position: relative;
  word-break: break-word;
`;

export const CaretDown = styled(CaretDownIcon)`
  max-width: 1.2rem;
  margin-left: 0.6rem;
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  font-size: 1.4rem;
`;
