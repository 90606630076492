import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/images/chevron-down-new.svg";
import { ReactComponent as ReportIcon } from "assets/images/report-icon.svg";

import { useTranslation } from "react-i18next";
import {
  Label,
  LabelWrapper,
  DropdownContent,
  Content,
  FolderItem,
  Wrapper,
} from "./styles";

import { Props } from "./types";

const FolderDropdown = ({ isSelectedNotSent, setIsSelectedNotSent }: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((visible) => !visible);
  };

  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        ref &&
        ref.current &&
        localRef === ref &&
        localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, setDropdownVisible]);

  const setReports = (value: boolean) => {
    if (setIsSelectedNotSent) {
      setIsSelectedNotSent();
    }
    setDropdownVisible(false);
  };

  return (
    <Wrapper ref={ref}>
      <LabelWrapper onClick={toggleDropdown}>
        <Label>
          <ReportIcon />
          {isSelectedNotSent ? "Unsent" : "Report"}
        </Label>
        <ChevronDownIcon
          style={{
            rotate: isDropdownVisible ? "180deg" : "0deg",
            transition: "all 0.2s ease-in",
          }}
        />
      </LabelWrapper>
      <DropdownContent visible={isDropdownVisible}>
        <Content>
          <FolderItem>Show only sent reports</FolderItem>
          <FolderItem onClick={() => setReports(false)}>
            Show only unsent reports
          </FolderItem>
        </Content>
      </DropdownContent>
    </Wrapper>
  );
};

export default FolderDropdown;
