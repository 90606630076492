import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  margin-top: 2rem;
`;

export const CheckboxText = styled.div`
  font-size: 1.2rem;
  text-align: start;
  margin-left: 1.4rem;
`;
