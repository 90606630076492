import styled from "styled-components";

import Button from "components/atoms/Button";

import { ReactComponent as DetailsIcon } from "assets/images/details-icon.svg";

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionLabel = styled.div`
  margin-left: 1rem;
  font-size: 1.4rem;
`;

export const TeacherActionButton = styled(Button)`
  width: auto;
`;

export const DetailsIconStyled = styled(DetailsIcon)`
  fill: ${({ theme }) => theme.colors.denim};
  cursor: pointer;
  &:hover {
    fill: ${({ theme }) => theme.colors.matisse};
  }
`;
