import React, { FC } from "react";

import { ReactComponent as CloseIcon } from "assets/images/x-icon.svg";

import { Container, CloseButton } from "./styles";
import { Props } from "./types";

const Chip: FC<Props> = ({ title, onClose, icon, styles }) => {
  return (
    <Container style={styles}>
      {icon && icon}
      <div>{title}</div>
      <CloseButton type="button" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </Container>
  );
};

export default Chip;
