import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 4rem;
  background: ${({ theme }) => theme.colors.aquaHaze};
  border-radius: 0.4rem;
  border: ${({ theme }) => `1px solid ${theme.colors.mishka}`};
`;

export const StyledInput = styled.input`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  height: 100%;
  padding: 0 3rem 0 1rem;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
  height: 100%;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-radius: 0.5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: ${({ theme }) => `6px solid ${theme.colors.denim}`};
  margin-bottom: 0.6rem;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-radius: 0.5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ theme }) => `6px solid ${theme.colors.denim}`};
`;
