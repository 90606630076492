import styled from "styled-components";

export const OuterBorder = styled.button<{ isSelected: boolean }>`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 100%;
  border: ${({ theme, isSelected }) =>
    `${isSelected ? 6 : 1}px solid ${
      isSelected ? theme.colors.denim : theme.colors.calypso
    }`};
  margin-right: 0.8rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NameText = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
`;
