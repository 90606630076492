import React, { FC, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import {
  useGetAuthenticatedUserQuery,
  useGetSchoolQuery,
} from "generated/graphql";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import DropdownMenu from "components/molecules/DropdownMenu";
import useClickOutside from "hooks/clickOutSide";
import { currentSchoolIdVar } from "store/school";

import {
  Container,
  StyledCoinsIcon,
  CreditsNumber,
  CoinsText,
  TextWrapper,
  Wrapper,
  StyledCaretDown,
} from "./styles";
import AddCreditsModal from "./Modal";

const stripeLoader = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY || "");

const CreditsDropdown: FC = () => {
  const { t } = useTranslation();
  const [isCreditModalOpen, setCreditModalOpen] = useState(false);

  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const { data: authenticatedUser } = useGetAuthenticatedUserQuery();
  //  const isAdmin = authenticatedUser?.authenticatedItem?.isAdmin || false;
  const isAdmin =
    authenticatedUser?.authenticatedItem?.adminOfSchools?.find(
      (school) => school.id === currentSchoolId
    ) || false;

  const { data: currentSchool } = useGetSchoolQuery({
    variables: {
      id: currentSchoolId,
    },
  });

  const credits = currentSchool?.school?.credit?.balance || 0;

  const {
    visible: dropdownVisible,
    setVisible: setDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);

  const handleOpenCreditsModal = () => {
    setCreditModalOpen(true);
  };

  const dropdownElements = [
    { name: t("teacher.addMoreCredits"), onClick: handleOpenCreditsModal },
  ];

  return (
    <>
      {isCreditModalOpen && (
        <Elements stripe={stripeLoader}>
          <AddCreditsModal
            isOpen={isCreditModalOpen}
            onClose={() => setCreditModalOpen(false)}
          />
        </Elements>
      )}
      <Container onClick={toggleDropdown} ref={containerRef}>
        <Wrapper>
          <StyledCoinsIcon />
          <TextWrapper>
            <CreditsNumber>{credits}</CreditsNumber>
            <CoinsText>{t("global.credits")}</CoinsText>
          </TextWrapper>
          {isAdmin && (
            <>
              <StyledCaretDown />
              <DropdownMenu
                elements={dropdownElements}
                offsetTop="4rem"
                visible={dropdownVisible}
              />
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default CreditsDropdown;
