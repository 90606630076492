import React from "react";
import { Redirect } from "react-router-dom";
import { useGetAuthenticatedUserQuery } from "generated/graphql";
import paths from "routes/paths";
import { userRoleVar } from "store/authenticatedUser";
import { UserRoles } from "../../types/user";
import { currentSchoolIdVar } from "../../store/school";

const Decider = () => {
  const { loading, data } = useGetAuthenticatedUserQuery({
    fetchPolicy: "network-only",
  });

  const setCurrentSchoolId = (id = "") => {
    localStorage.setItem("EFC_currentSchool", id);
    currentSchoolIdVar(id);
  };

  const userRole = userRoleVar();

  if (loading) return null;

  if (data?.authenticatedItem === null) {
    return <Redirect to={paths.signIn} />;
  }
  if (!data?.authenticatedItem?.isConfirmed) {
    return <Redirect to={paths.verifyData} />;
  }

  if (
    data?.authenticatedItem?.students?.length &&
    (data?.authenticatedItem?.teachers?.length ||
      data?.authenticatedItem?.adminOfSchools?.filter(
        (school) => !school.isIndividual
      ).length)
  ) {
    if (userRole === UserRoles.student) {
      return <Redirect to={paths.studentDashboard} />;
    }

    if (userRole === UserRoles.teacher) {
      const firstSchool = data.authenticatedItem.teachers?.length
        ? data.authenticatedItem.teachers[0].school?.id
        : "";
      setCurrentSchoolId(firstSchool);
      return <Redirect to={paths.studentsEmptyFolder} />;
    }
  }

  if (data?.authenticatedItem?.isAdmin) {
    return <Redirect to={paths.studentsEmptyFolder} />;
  }

  if (
    data?.authenticatedItem?.teachers?.some(
      (teacher) => teacher.school?.isIndividual
    ) &&
    data?.authenticatedItem?.students?.length === 0
  ) {
    return <Redirect to={paths.selfRegistrationPayment} />;
  }

  if (
    data?.authenticatedItem?.teachers?.some(
      (teacher) => teacher.school?.isIndividual
    ) &&
    data?.authenticatedItem?.students?.length
  ) {
    return <Redirect to={paths.studentDashboard} />;
  }

  if (data?.authenticatedItem?.teachers?.length) {
    return <Redirect to={paths.studentsEmptyFolder} />;
  }
  if (data?.authenticatedItem?.adminOfSchools?.length) {
    return <Redirect to={paths.studentsEmptyFolder} />;
  }
  if (data?.authenticatedItem?.students?.length) {
    return <Redirect to={paths.studentDashboard} />;
  }

  return <Redirect to={paths.signIn} />;
};

export default Decider;
