import React, { FC } from "react";

import { STATIC_LINKS } from "constants/staticLinks";
import { ReactComponent as SmallLogo } from "assets/images/smallLogo.svg";
import { FooterItem, StyledFooter, StyledLink, StyledUl } from "./styles";

const Footer = () => (
  <StyledFooter>
    <FooterItem>
      <div>Created by:</div>
      <SmallLogo />
      <StyledLink href="https://careerfit.com">CareerFit.com</StyledLink>
    </FooterItem>
    <FooterItem>
      <StyledUl>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${STATIC_LINKS.PRIVACY_POLICY}`}
          >
            Privacy Policy
          </a>
        </li>
        <li
          style={{
            fontSize: "24px",
            margin: 0,
            padding: 0,
          }}
        >
          &bull;
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href={`${STATIC_LINKS.FAQ}`}>
            FAQ
          </a>
        </li>
      </StyledUl>
    </FooterItem>
  </StyledFooter>
);

export default Footer;
