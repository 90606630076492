import styled from "styled-components";

import Card from "components/atoms/Card";
import Button from "components/atoms/Button";
import { ReactComponent as LinkIcon } from "assets/images/link.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
`;

export const WrapperNarrow = styled(Wrapper)`
  margin-bottom: 0;
`;

export const Label = styled.div`
  font-weight: normal;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.stormGray};
  margin-bottom: 0.8rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.8rem 0;
`;

export const IconWrapper = styled.div`
  margin-bottom: 1.2rem;
`;

export const CardButton = styled.button`
  width: 50%;

  &:first-child {
    margin-right: 2rem;
  }
`;

export const CreditsInfo = styled.div`
  font-weight: normal;
  font-size: 1.6rem;
  margin-left: 1.6rem;
  color: ${({ theme }) => `${theme.colors.sharade}70`};
`;

export const ButtonsSection = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  width: min-content;
  padding: 0 4rem;
`;

export const StyledOk = styled(StyledButton)`
  padding: 0;
  justify-content: center;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  margin-left: 0.8rem;
`;

export const ThankYou = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.mineShaft};
  font-weight: normal;
  margin: 2.4rem 0 4.8rem;
`;
