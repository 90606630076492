import { useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { useToast } from "hooks/Toast";
import { useRemindPasswordMutation } from "generated/graphql";
import Input from "components/molecules/Input";
import Header from "components/atoms/Header";
import { email } from "helpers/formValidation";
import LoginTheme from "themes/LoginTheme";
import paths from "routes/paths";

import {
  FormWrapper,
  HeaderWrapper,
  ButtonsWrapper,
  SpaceMiddle,
  PullRight,
} from "./styles";

const resetPasswordInitialValues = {
  email: "",
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const Toast = useToast();
  const [remindPassword, { loading }] = useRemindPasswordMutation();

  const signInForm = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: Yup.object({
      email,
    }),
    onSubmit: (values) => {
      remindPassword({
        variables: {
          email: values.email,
        },
      })
        .then((res) => {
          if (!res.data?.remindPassword?.success) {
            Toast("error", t("errorAction.wrongEmail"));
          } else {
            Toast("success", t("successAction.emailSent"));
          }
        })
        .catch((err) => {
          Toast("error", err.message || t("errorAction.generalError"));
        });
    },
  });

  const handleSignInNavigate = () => {
    history.push(paths.signIn);
  };

  return (
    <LoginTheme>
      <FormWrapper
        onSubmit={signInForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        <HeaderWrapper>
          <Header>{t("user.forgotPassword")}?</Header>
        </HeaderWrapper>

        <Input
          name="email"
          label={t("user.yourLogin")}
          handleChange={signInForm.handleChange}
          handleBlur={signInForm.handleBlur}
          placeholder={t("user.yourEmail")}
          value={signInForm.values.email}
          error={signInForm.touched.email && signInForm.errors.email}
        />
        <ButtonsWrapper>
          <SpaceMiddle type="submit" variant="primary" disabled={loading}>
            {t("user.resetPassword")}
          </SpaceMiddle>
          <PullRight
            type="button"
            variant="link"
            onClick={handleSignInNavigate}
          >
            {t("user.returnToLogin")}
          </PullRight>
        </ButtonsWrapper>
      </FormWrapper>
    </LoginTheme>
  );
};

export default ForgotPassword;
