import styled from "styled-components";

export const StyledCard = styled.div<{ active?: boolean }>`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ active, theme }) =>
    active ? `1.5px solid ${theme.colors.denim}` : "1.5px solid transparent"};
  color: ${({ active, theme }) => active && theme.colors.denim};
  padding: 3rem 4rem;
  width: 100%;
`;
