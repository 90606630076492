import styled from "styled-components";
import { NavLink } from "react-router-dom";

import media from "helpers/media";

const activeClassName = "active";

export const Container = styled.div`
  display: flex;
  width: 1170px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 500;
  height: 55px;
  margin: 0 1rem;
`;

export const NavigationList = styled.nav`
  display: flex;
  height: 100%;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-family: "Roboto Slab";
  justify-content: space-between;
  ${media.SM`
    flex: 1;
  `}
`;

export const BurgerMenu = styled.div`
  display: none;

  ${media.SM`
    display: block;
  `}
`;

export const NavigationItem = styled(NavLink).attrs({
  activeClassName,
})`
  padding: 0 1rem;
  margin: 0 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.${activeClassName} {
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${({ theme }) => theme.colors.treePoppy};

    &::after {
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 3px;
      background: ${({ theme }) => theme.colors.treePoppy};
    }
  }
`;

export const NavigationLink = styled.a.attrs({
  activeClassName,
})`
  padding: 0 1rem;
  margin: 0 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.${activeClassName} {
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${({ theme }) => theme.colors.treePoppy};

    &::after {
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 3px;
      background: ${({ theme }) => theme.colors.treePoppy};
    }
  }
`;
