import { useFormik, FormikProps } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Button from "components/atoms/Button";
import Header from "components/atoms/Header";
import Input from "components/molecules/Input";
import Select from "components/molecules/Select";

import { firstName, lastName, email, country } from "helpers/formValidation";
import LoginTheme from "themes/LoginTheme";

import { useToast } from "hooks/Toast";
import {
  RegisterIndividualSchoolMutationVariables,
  UpdateAuthenticatedUserMutationVariables,
  useGetAllCountriesQuery,
  useGetAuthenticatedUserQuery,
  useRegisterIndividualSchoolMutation,
  UserGenderType,
  useUpdateAuthenticatedUserMutation,
} from "generated/graphql";
import paths from "routes/paths";
import { MAINPAGE_URL } from "constants/misc";
import { SelectValueType } from "components/molecules/Select/Select";
import {
  CustomLabel,
  Optional,
  FormWrapper,
  ButtonsWrapper,
  HeaderWrapper,
  CheckEmail,
  OptionalWrapper,
} from "./styles";

type Props = {
  isSelfRegistration?: boolean;
};

const SetupPassword: FC<Props> = ({ isSelfRegistration = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const Toast = useToast();

  const [isEditable, setIsEditable] = useState(isSelfRegistration);
  const [selectedCountry, setSelectedCountry] = useState<
    SelectValueType | undefined
  >();

  const { loading, data } = useGetAuthenticatedUserQuery();

  const { data: countriesData } = useGetAllCountriesQuery();

  const [
    updateData,
    { loading: updateLoading },
  ] = useUpdateAuthenticatedUserMutation();

  const [
    selfRegistrationMutation,
    { loading: selfRegistrationLoading },
  ] = useRegisterIndividualSchoolMutation();

  const isMutationLoading = isSelfRegistration
    ? selfRegistrationLoading
    : updateLoading;

  const tryUpdateData = async (
    values: UpdateAuthenticatedUserMutationVariables
  ) => {
    updateData({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        gender:
          values.gender === "male" || values.gender === "female"
            ? values.gender
            : null,
        dateOfBirth: values.dateOfBirth || null,
      },
    })
      .then(() => {
        history.push("/decider");
      })
      .catch((err) =>
        Toast("error", err?.message || t("errorAction.generalError"))
      );
  };

  const tryCreateSchool = async (
    values: RegisterIndividualSchoolMutationVariables
  ) => {
    selfRegistrationMutation({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email.toLowerCase(),
        gender:
          values.gender === "male" || values.gender === "female"
            ? values.gender
            : null,
        dateOfBirth: values.dateOfBirth || null,
        country: String(values.country),
      },
    })
      .then(() => {
        setIsEditable(false);
      })
      .catch((err) => Toast("error", err?.message || "General error"));
  };

  const verifyDataInitialValues: UpdateAuthenticatedUserMutationVariables = {
    firstName: data?.authenticatedItem?.firstName || "",
    lastName: data?.authenticatedItem?.lastName || "",
    dateOfBirth: data?.authenticatedItem?.dateOfBirth || null,
    gender: (data?.authenticatedItem?.gender as UserGenderType) || undefined,
  };

  const selfRegistrationInitialValues: RegisterIndividualSchoolMutationVariables = {
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: null,
    country: "0",
  };

  const verifyDataForm: FormikProps<UpdateAuthenticatedUserMutationVariables> = useFormik(
    {
      enableReinitialize: true,
      initialValues: verifyDataInitialValues,
      validationSchema: Yup.object({
        firstName,
        lastName,
      }),
      onSubmit: (values) => tryUpdateData(values),
    }
  );

  const selfRegistrationDataForm: FormikProps<RegisterIndividualSchoolMutationVariables> = useFormik(
    {
      enableReinitialize: true,
      initialValues: selfRegistrationInitialValues,
      validationSchema: Yup.object({
        email,
        firstName,
        lastName,
        country,
      }),

      onSubmit: (values) => tryCreateSchool(values),
    }
  );

  const verifyForm = isSelfRegistration
    ? selfRegistrationDataForm
    : verifyDataForm;

  const unblockForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const blockForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (isSelfRegistration) {
      window.location.href = MAINPAGE_URL;
    } else {
      verifyForm.resetForm();
      setIsEditable(false);
    }
  };

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.authenticatedItem &&
      data.authenticatedItem.teachers
    ) {
      if (data.authenticatedItem?.teachers.length > 0)
        history.push(paths.students);
    }
  }, [data, loading, history]);

  const getCountryOptions = () => {
    const countries = countriesData?.countries;
    if (countries?.length) {
      return (countries as {
        name: string;
        id: string;
      }[]).map(({ name, id }) => ({ label: name, value: id }));
    }
    return [];
  };

  if (isSelfRegistration && !isEditable)
    return (
      <LoginTheme selfRegistration>
        <HeaderWrapper>
          <Header>{t("user.accountCreated")}</Header>
        </HeaderWrapper>
        <CheckEmail>
          {t("user.checkYourEmail_1")}
          <br />
          {t("user.checkYourEmail_2")}
        </CheckEmail>
      </LoginTheme>
    );

  return (
    <LoginTheme selfRegistration>
      <FormWrapper
        onSubmit={verifyForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        <HeaderWrapper>
          <Header>
            {isSelfRegistration
              ? t("user.tellUsAboutYourself")
              : t("user.verifyDetails")}
          </Header>
        </HeaderWrapper>
        {isSelfRegistration && (
          <Input
            name="email"
            label="Email address"
            handleChange={verifyForm.handleChange}
            handleBlur={verifyForm.handleBlur}
            value={
              ("email" in verifyForm.values && verifyForm.values.email) || ""
            }
            disabled={!isEditable}
            error={
              "email" in verifyForm.touched && "email" in verifyForm.errors
                ? verifyForm.touched.email && verifyForm.errors.email
                : undefined
            }
          />
        )}
        <Input
          name="firstName"
          label={t("global.firstNameLabel")}
          handleChange={verifyForm.handleChange}
          handleBlur={verifyForm.handleBlur}
          value={verifyForm.values.firstName}
          disabled={!isEditable}
          error={verifyForm.touched.firstName && verifyForm.errors.firstName}
        />
        <Input
          name="lastName"
          label={t("global.lastNameLabel")}
          handleChange={verifyForm.handleChange}
          handleBlur={verifyForm.handleBlur}
          value={verifyForm.values.lastName}
          disabled={!isEditable}
          error={verifyForm.touched.lastName && verifyForm.errors.lastName}
        />
        {isSelfRegistration && (
          <Select
            name="country"
            label={<CustomLabel>{t("user.country")}</CustomLabel>}
            handleChange={(name: string, val: SelectValueType) => {
              verifyForm.setFieldValue(name, val?.value);
              setSelectedCountry(val);
            }}
            handleBlur={verifyForm.handleBlur}
            disabled={!isEditable}
            value={{
              label: selectedCountry?.label || "",
              value: selectedCountry?.value || "",
            }}
            options={getCountryOptions()}
          />
        )}
        <Input
          name="dateOfBirth"
          maxDate={new Date()}
          label={
            <CustomLabel>
              {t("user.dateOfBirth")} <Optional>{t("user.optional")}</Optional>
            </CustomLabel>
          }
          handleChange={verifyForm.handleChange}
          handleBlur={verifyForm.handleBlur}
          setFieldValue={verifyForm.setFieldValue}
          value={verifyForm.values.dateOfBirth || ""}
          disabled={!isEditable}
          date={isEditable}
        />
        <Select
          name="gender"
          label={
            <CustomLabel>
              {t("user.gender")} <Optional>{t("user.optional")}</Optional>
            </CustomLabel>
          }
          handleChange={(name: string, val: SelectValueType) =>
            verifyForm.setFieldValue(name, val?.value)
          }
          handleBlur={verifyForm.handleBlur}
          disabled={!isEditable}
          value={{
            label: verifyForm.values.gender || "",
            value: verifyForm.values.gender || "",
          }}
          options={[
            { label: t("user.male"), value: "male" },
            { label: t("user.female"), value: "female" },
            { label: t("user.other"), value: "other" },
          ]}
        />
        <OptionalWrapper>
          <Optional>{t("user.optionalDisclaimer")}</Optional>
        </OptionalWrapper>
        {isEditable ? (
          <ButtonsWrapper>
            <Button
              type="submit"
              variant="primary"
              disabled={isMutationLoading}
            >
              {t("actions.save")}
            </Button>
            <Button variant="link" type="button" onClick={blockForm}>
              {t("actions.cancel")}
            </Button>
          </ButtonsWrapper>
        ) : (
          <ButtonsWrapper row>
            <Button type="button" variant="primary" onClick={unblockForm}>
              {t("user.editTheseDetails")}
            </Button>
            <Button
              type="submit"
              variant="secondary"
              disabled={isMutationLoading}
            >
              {t("user.everythingCorrect")}
            </Button>
          </ButtonsWrapper>
        )}
      </FormWrapper>
    </LoginTheme>
  );
};

export default SetupPassword;
