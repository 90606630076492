import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import InterestAnswerBar from "components/molecules/InterestAnswerBar";
import TestProgressBar from "components/molecules/TestProgressBar";
import { useToast } from "hooks/Toast";

import {
  useGetAllInterestQuestionsQuery,
  useGetAnswersForInterestTestQuery,
  useStoreInterestInventoryAnswerMutation,
} from "generated/graphql";

import Loader from "components/atoms/Loader";
import { answeredQuestionsCountVar, questionsCountVar } from "store/test";
import {
  QuestionsWrapper,
  QuestionNumber,
  Question,
  QuestionContent,
  ProgressBarWrapper,
  StyledButton,
  AnswersWrapper,
  SubquestionContent,
  Subquestion,
  SubquestionContentWrapper,
  ButtonWrapper,
} from "../styles";
import { Props } from "./types";

const Interest: FC<Props> = ({
  id,
  finishTest,
  isUncheckedHighlighted,
  setIsTooFewInterestAnswers,
}) => {
  const { t } = useTranslation();
  const Toast = useToast();

  const [answers, setAnswers] = useState<{ [key: string]: number }>({});
  const [pendingAnswers, setPendingAnswers] = useState<{
    [key: string]: boolean;
  }>({});
  const [parentAnswers, setParentAnswers] = useState<{
    [key: string]: number;
  }>({});

  const [storeAnswer] = useStoreInterestInventoryAnswerMutation();

  const {
    data: answersData,
    loading: answersLoading,
  } = useGetAnswersForInterestTestQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const selectAnswer = (
    question: string,
    answer: number,
    parentQuestion: string
  ) => {
    setPendingAnswers((pending) => ({ ...pending, [question]: true }));
    storeAnswer({
      variables: {
        testId: Number(id),
        interestQuestionId: Number(question),
        answer,
      },
    })
      .then(() => {
        setAnswers((oldAnswers) => ({ ...oldAnswers, [question]: answer }));
        setParentAnswers((oldAnswers) => ({
          ...oldAnswers,
          [parentQuestion]: answer,
        }));
      })
      .catch((err) => Toast("error", err.message))
      .finally(() => {
        setPendingAnswers((pending) => ({ ...pending, [question]: false }));
      });
  };

  const getHtmlVariable = (htmlVar: string) => {
    return {
      __html: htmlVar,
    };
  };

  const {
    data: questionData,
    loading: questionDataLoading,
  } = useGetAllInterestQuestionsQuery();

  useEffect(() => {
    if (
      answersData?.interestAnswers &&
      answersData?.interestAnswers?.length > 0
    ) {
      const newAnswers = Object.assign(
        {},
        ...answersData?.interestAnswers?.map((item) => ({
          [item?.interestQuestion?.id || ""]: item?.answer || 0,
        }))
      );

      const newParentAnswers = Object.assign(
        {},
        ...answersData?.interestAnswers?.map((item) => ({
          [item?.interestQuestion?.parent?.id ||
          item?.interestQuestion?.id ||
          ""]: item?.answer || 0,
        }))
      );

      setAnswers((oldAnswers) => ({ ...oldAnswers, ...newAnswers }));
      setParentAnswers((oldParentAnswers) => ({
        ...oldParentAnswers,
        ...newParentAnswers,
      }));
    }
  }, [answersData]);

  useEffect(() => {
    const completed = Object.keys(parentAnswers).length;
    const all =
      questionData?.interestQuestions?.filter(
        (question) => question?.parent === null
      ).length || 0;
    setIsTooFewInterestAnswers(completed < all);
  }, [parentAnswers, questionData, setIsTooFewInterestAnswers]);

  useEffect(() => {
    questionsCountVar(
      questionData?.interestQuestions?.filter(
        (question) => question?.parent === null
      ).length || 0
    );

    answeredQuestionsCountVar(Object.keys(parentAnswers).length);
  }, [questionData, parentAnswers]);

  if (questionDataLoading || answersLoading) return <Loader />;

  const renderSubanswers = (subanswerId: string, baseId: string) => (
    <>
      {questionData &&
        questionData.interestQuestions &&
        questionData.interestQuestions
          .filter((question) => question?.parent?.id === subanswerId)
          .slice()
          .sort((x, y) =>
            (x?.interestId?.replace(baseId, "") || "0") >
            (y?.interestId?.replace(baseId, "") || "0")
              ? 1
              : -1
          )
          .map((question) => (
            <SubquestionContentWrapper key={question?.id}>
              <Subquestion
                dangerouslySetInnerHTML={getHtmlVariable(question?.name || "")}
              />
              <AnswersWrapper>
                <InterestAnswerBar
                  question={question?.id || ""}
                  pending={pendingAnswers[question?.id || ""]}
                  clickHandler={selectAnswer}
                  answers={answers}
                  parentQuestion={question?.parent?.id || ""}
                  isUncheckedHighlighted={isUncheckedHighlighted}
                />
              </AnswersWrapper>
            </SubquestionContentWrapper>
          ))}
    </>
  );

  return (
    <>
      <QuestionsWrapper>
        {questionData &&
          questionData.interestQuestions &&
          questionData.interestQuestions
            .filter((question) => question?.parent === null)
            .slice()
            .sort((x, y) =>
              parseFloat(x?.interestId || "0") >
              parseFloat(y?.interestId || "0")
                ? 1
                : -1
            )
            .map((question, index) => (
              <Question
                key={question?.id}
                className={
                  question?.id &&
                  answers[question.id] === undefined &&
                  question?.subquestions?.every(
                    (subquestion) => answers[subquestion.id] === undefined
                  )
                    ? "unanswered"
                    : "answered"
                }
              >
                <QuestionNumber>
                  {t("student.questionShort")}
                  {index + 1}
                </QuestionNumber>
                <QuestionContent
                  dangerouslySetInnerHTML={getHtmlVariable(
                    question?.name || ""
                  )}
                />
                <AnswersWrapper pending={pendingAnswers[question?.id || ""]}>
                  {question?.subquestions?.length === 0 ? (
                    <InterestAnswerBar
                      question={question?.id || ""}
                      pending={pendingAnswers[question?.id || ""]}
                      clickHandler={selectAnswer}
                      answers={answers}
                      parentQuestion={question?.id || ""}
                      isUncheckedHighlighted={isUncheckedHighlighted}
                    />
                  ) : (
                    <SubquestionContent>
                      {renderSubanswers(
                        question?.id || "-1",
                        question?.interestId || "-1"
                      )}
                    </SubquestionContent>
                  )}
                </AnswersWrapper>
              </Question>
            ))}
        <ButtonWrapper>
          <StyledButton variant="primary" onClick={finishTest}>
            {t("student.finishAssessment")}
          </StyledButton>
        </ButtonWrapper>
      </QuestionsWrapper>
      <ProgressBarWrapper>
        <TestProgressBar
          completed={Object.keys(parentAnswers).length}
          allQuestions={
            questionData?.interestQuestions?.filter(
              (question) => question?.parent === null
            ).length || 0
          }
        />
      </ProgressBarWrapper>
    </>
  );
};

export default Interest;
