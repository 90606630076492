import styled, { css } from "styled-components";

import CheckboxChecked from "assets/images/checkbox-checked.svg";

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input<{ isChecked: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border: 1.5px solid ${({ theme }) => theme.colors.nepal};
  border-radius: 2px;
  appearance: none;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background-image: url(${CheckboxChecked});
      border: none;
      background-position: center;
    `}
`;
