import styled from "styled-components";

import { ReactComponent as ArrowRightIcon } from "assets/images/arrow-right.svg";
import { ReactComponent as VideoImageIcon } from "assets/images/video-image.svg";
import { ReactComponent as WatchAgainIcon } from "assets/images/watch-again-icon.svg";

const completedClassName = "completed";

export const VideoWrapper = styled.div`
  display: flex;
  width: 376px;
`;

export const VideoCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.geyser};
  border-radius: 8px;
  height: 141px;
  padding: 2.4rem;
  width: 100%;
  display: flex;
  cursor: pointer;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.denim};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }

  &.${completedClassName} {
    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.oceanGreen};
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.shuttleGray};
  display: flex;
  font-weight: 500;
  font-size: 2rem;
  font-family: "Roboto Slab";
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 2.8rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-left: 1rem;
`;

export const Play = styled.div`
  color: ${({ theme }) => theme.colors.denim};
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 700;
  font-family: "Roboto";
  letter-spacing: 1px;
`;

export const ArrowRight = styled(ArrowRightIcon)`
  stroke: ${({ theme }) => theme.colors.shuttleGray};
  margin-left: 1.2rem;
`;

export const WatchAgain = styled(WatchAgainIcon)`
  stroke: 1.5px solid #39a370;
`;

export const ImageContainer = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: start;
  justify-content: center;
  margin-top: 0.8rem;
  margin-left: 0.6rem;
`;

export const VideoImage = styled(VideoImageIcon)`
  stroke: ${({ theme }) => theme.colors.denim};

  &.${completedClassName} {
    stroke: ${({ theme }) => theme.colors.shuttleGray};
  }
`;
