import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, FlexBox, FlexItem, StyledInput } from "./styles";
import { Props } from "./types";

const items = Array.from(Array(7).keys());

type LengthsType = { [key: number]: number };

const lengths: LengthsType = {
  0: 0,
  1: 15,
  2: 33,
  3: 49,
  4: 65,
  5: 80,
  6: 96,
};

const InterestAnswerBar: FC<Props> = ({
  question,
  clickHandler,
  answers,
  pending,
  parentQuestion,
  isUncheckedHighlighted,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <FlexBox>
        {items.map((item) => (
          <FlexItem key={item}>{item}</FlexItem>
        ))}
      </FlexBox>

      <FlexBox showScale selectedLength={lengths[answers[question]]}>
        {items.map((item) => (
          <FlexItem key={item}>
            <StyledInput
              type="radio"
              value={item}
              isSelected={answers[question] >= item}
              isCurrent={answers[question] === item}
              isPending={pending}
              name={question}
              onClick={
                pending || answers[question] === item
                  ? undefined
                  : () => clickHandler(question, item, parentQuestion)
              }
              isUncheckedHighlighted={
                isUncheckedHighlighted && answers[question] === undefined
              }
            />
          </FlexItem>
        ))}
      </FlexBox>
      <FlexBox>
        <FlexItem>{t("student.hateIt")}</FlexItem>
        <FlexItem>{t("student.notSure")}</FlexItem>
        <FlexItem>{t("student.loveIt")}</FlexItem>
      </FlexBox>
    </Wrapper>
  );
};

export default InterestAnswerBar;
