import colorsConst from "constants/colors";

export const theme = {
  colors: {
    ...colorsConst,
    defaultColor: "",
    secondaryColor: "",
  },
};

export type MainTheme = typeof theme;
