import React, { FC } from "react";

import { StyledButton } from "./styles";
import { Props } from "./types";

const ToggleButton: FC<Props> = ({
  children,
  enabled,
  onClick,
  enabledStyles,
}) => {
  return (
    <StyledButton
      enabled={enabled}
      onClick={onClick}
      enabledStyles={enabledStyles}
      type="button"
    >
      {children}
    </StyledButton>
  );
};

export default ToggleButton;
