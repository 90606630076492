import React, { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ParseError } from "papaparse";
import moment from "moment";
import { useToast } from "hooks/Toast";
import { ReactComponent as FileIcon } from "assets/images/file.svg";
import Modal from "components/atoms/Modal";
import parseCSV from "utils/parseCSV";
import Button from "components/atoms/Button";
import { StudentCSVKeys } from "constants/students/studentCSV";

import { currentSchoolIdVar } from "store/school";
import { useInviteStudentsMutation } from "generated/graphql";
import StyledSelect from "components/molecules/Select";
import { FormikProps, useFormik } from "formik";
import {
  Container,
  DropContent,
  StudentPreviewItem,
  StudentPreviewList,
  DNDContainer,
  StudentsUploadButtons,
  CSVUploadWrapper,
  BoldText,
  DNDText,
  StudentName,
  PreviewWrapper,
  UploadStudentsButton,
  ErrorContainer,
  ErrorsList,
  DownloadCSVButton,
  ButtonsSection,
} from "./styles";
import {
  ParsedStudentsCSV,
  UploadUserProps,
  CSVErrorsState,
  DelayedEmailType,
  AddStudentsCSVType,
} from "./types";

const PREVIEW_SIZE = 5;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const INVALID_NAME_REGEX = /^[0-9]*$/;

const AddStudentsModal: FC<UploadUserProps> = ({ isOpen, onClose, title }) => {
  const { t } = useTranslation();
  const [inviteStudents] = useInviteStudentsMutation();
  const [CSVdata, setCSVData] = useState<ParsedStudentsCSV[]>([]);
  const [CSVErrors, setCSVErrors] = useState<CSVErrorsState>({
    headerErrors: [],
    parsingErrors: [],
    contentErrors: [],
    errorCount: 0,
  });
  const [header, setHeader] = useState("");
  const Toast = useToast();

  const [sendDate, setSendDate] = useState(moment());
  const [daysCount, setDaysCount] = useState(31);

  const currentSchoolId = currentSchoolIdVar();

  useEffect(() => {
    if (CSVdata.length > 0) {
      setHeader(t("teacher.listPreview"));
    } else {
      setHeader(title);
    }
  }, [CSVdata, title, t]);

  const retrieveCSVData = useCallback(
    (file: {
      data: ParsedStudentsCSV[];
      errors: ParseError[];
      meta: {
        fields: string[];
      };
      // eslint-disable-next-line consistent-return
    }) => {
      const headerErrors = Object.values(StudentCSVKeys).filter(
        (key) => !file.meta.fields.includes(key)
      );

      const parsingErrors = file.errors.map(
        (error: ParseError) => `${error.message} in row ${error.row + 1}`
      );

      const contentErrors = file.data
        .map((row, index, self) => {
          const errors: string[] = [];

          if (
            typeof row["First Name"] === "string" &&
            INVALID_NAME_REGEX.test(row["First Name"].trim())
          ) {
            errors.push(
              `Field First Name cannot be empty or cannot contain only numbers in row ${
                index + 2
              }`
            );
          }

          if (
            typeof row["Last Name"] === "string" &&
            INVALID_NAME_REGEX.test(row["Last Name"].trim())
          ) {
            errors.push(
              `Field Last Name cannot be empty or cannot contain only numbers in row ${
                index + 2
              }`
            );
          }

          if (typeof row.Email === "string" && !EMAIL_REGEX.test(row.Email)) {
            errors.push(`Incorrect email: '${row.Email}' in row ${index + 2}`);
          }

          const notUniqueEmailIndex = self
            .map(
              (selfRow) => typeof selfRow.Email === "string" && selfRow.Email
            )
            .indexOf(row.Email, index + 1);

          if (notUniqueEmailIndex !== -1) {
            errors.push(
              `Given email: '${row.Email}' is duplicated in rows: ${
                index + 2
              } and ${notUniqueEmailIndex + 2}`
            );
          }

          return errors;
        })
        .filter((notValidField) => notValidField.length > 0)
        .flat();

      if (
        headerErrors.length > 0 ||
        parsingErrors.length > 0 ||
        contentErrors.length > 0
      ) {
        setCSVErrors({
          headerErrors,
          parsingErrors,
          contentErrors,
          errorCount:
            headerErrors.length + parsingErrors.length + contentErrors.length,
        });
      } else {
        setCSVData(file.data);
        Toast("success", t("successAction.fileUploaded"));
      }
    },
    [Toast, setCSVData, setCSVErrors, t]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      setCSVErrors({
        headerErrors: [],
        parsingErrors: [],
        contentErrors: [],
        errorCount: 0,
      });
      if (acceptedFiles[0]) {
        parseCSV(acceptedFiles[0], retrieveCSVData);
      } else {
        Toast("error", t("errorAction.importCsv"));
      }
    },
    [Toast, t, retrieveCSVData]
  );

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const uploadStudents = (isScheduled = false, invitationDate?: number) => {
    const studentToInvite = CSVdata.map((el) => {
      return {
        firstName: el[StudentCSVKeys.firstName],
        lastName: el[StudentCSVKeys.lastName],
        email: el[StudentCSVKeys.email],
      };
    });
    inviteStudents({
      variables: {
        data: studentToInvite,
        schoolId: Number(currentSchoolId),
        isScheduled,
        invitationDate,
      },
    })
      .then((res) => {
        if (
          res.data?.inviteStudents?.existingStudents &&
          res.data?.inviteStudents?.existingStudents.length > 0
        ) {
          const existingStudentsCount =
            res.data?.inviteStudents?.existingStudents.length;
          Toast(
            "warning",
            `${res.data?.inviteStudents?.existingStudents.length} ${
              existingStudentsCount > 1 || existingStudentsCount === 0
                ? t("global.students")
                : t("teacher.student")
            } ${t("warningAction.emailExists")}`
          );
        }
        if (
          res.data?.inviteStudents?.studentsForNewUsers &&
          res.data?.inviteStudents?.studentsForNewUsers.length > 0
        ) {
          const newStudentsCount =
            res.data?.inviteStudents?.studentsForNewUsers.length;

          Toast(
            "success",
            `${res.data?.inviteStudents?.studentsForNewUsers.length} ${
              newStudentsCount > 1 || newStudentsCount === 0
                ? t("global.students")
                : t("teacher.student")
            } ${t("successAction.wasAdded")}`
          );
          onClose();
        }
      })
      .catch((e) => {
        Toast("error", e.message || t("errorAction.generalError"));
      });
  };

  const initialValues: AddStudentsCSVType & DelayedEmailType = {
    year: moment().year(),
    month: moment().month(),
    day: moment().date(),
    hour: moment().hour(),
    minute: "00",
    schedule: "immediately",
    invitationDate: moment().date().valueOf(),
    isScheduled: false,
  };

  const inviteStudentForm: FormikProps<
    AddStudentsCSVType & DelayedEmailType
  > = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues,

    onSubmit: (values) =>
      uploadStudents(values.schedule === "scheduled", sendDate.utc().valueOf()),
  });

  useEffect(() => {
    setDaysCount(
      moment(
        `${inviteStudentForm?.values?.year}-${(
          inviteStudentForm?.values?.month + 1
        )
          .toString()
          .padStart(2, "0")}`,
        "YYYY-MM"
      ).daysInMonth() || 31
    );
    setSendDate(
      moment(
        `${inviteStudentForm?.values?.year}-${
          inviteStudentForm?.values?.month + 1
        }-${inviteStudentForm?.values?.day} ${
          inviteStudentForm?.values?.hour
        }:${inviteStudentForm?.values?.minute}`,
        "Y-M-D h:m"
      )
    );
  }, [inviteStudentForm.values]);

  const renderPreview = () => {
    return (
      <PreviewWrapper>
        <StudentPreviewList>
          {CSVdata.slice(0, PREVIEW_SIZE).map((row, i) => {
            return (
              <StudentPreviewItem
                key={
                  row[StudentCSVKeys.firstName] +
                  row[StudentCSVKeys.lastName] +
                  i
                }
              >
                <StudentName>
                  {`${row[StudentCSVKeys.firstName]}, ${
                    row[StudentCSVKeys.lastName]
                  }`}
                </StudentName>
                <div>{row[StudentCSVKeys.email]}</div>
              </StudentPreviewItem>
            );
          })}
        </StudentPreviewList>
        <ButtonsSection fullWidth>
          <label htmlFor="immediately">
            <input
              type="radio"
              id="immediately"
              name="schedule"
              value="immediately"
              checked={inviteStudentForm.values.schedule === "immediately"}
              onChange={inviteStudentForm.handleChange}
              onBlur={inviteStudentForm.handleBlur}
            />
            send immediately
          </label>
          <label htmlFor="schedule">
            <input
              type="radio"
              id="schedule"
              name="schedule"
              value="scheduled"
              onChange={inviteStudentForm.handleChange}
              onBlur={inviteStudentForm.handleBlur}
            />
            schedule email
          </label>
        </ButtonsSection>
        {inviteStudentForm.values.schedule === "scheduled" && (
          <div style={{ marginTop: "2rem" }}>
            <ButtonsSection
              fullWidth
              style={{ marginTop: "0rem", alignItems: "flex-end" }}
            >
              <div style={{ width: "200px" }}>
                <StyledSelect
                  name="year"
                  label="Date"
                  menuPlacement="top"
                  handleChange={(name: string, value: { value: string }) =>
                    inviteStudentForm.setFieldValue(name, value.value)
                  }
                  handleBlur={inviteStudentForm.handleBlur}
                  value={{
                    label: inviteStudentForm.values.year.toString() || "",
                    value: inviteStudentForm.values.year.toString() || "",
                  }}
                  options={[
                    {
                      value: moment().year().toString(),
                      label: moment().year().toString(),
                    },
                    {
                      value: (moment().year() + 1).toString(),
                      label: (moment().year() + 1).toString(),
                    },
                  ]}
                />
              </div>
              <div
                style={{
                  width: "200px",
                }}
              >
                <StyledSelect
                  name="month"
                  menuPlacement="top"
                  handleChange={(name: string, value: { value: string }) =>
                    inviteStudentForm.setFieldValue(
                      name,
                      parseInt(value.value, 10)
                    )
                  }
                  handleBlur={inviteStudentForm.handleBlur}
                  value={{
                    label: monthList[inviteStudentForm.values.month],
                    value: inviteStudentForm.values.month.toString(),
                  }}
                  options={monthList.map((month, idx) => ({
                    label: month,
                    value: idx.toString(),
                  }))}
                />
              </div>

              <div style={{ width: "200px" }}>
                <StyledSelect
                  name="day"
                  menuPlacement="top"
                  handleChange={(name: string, value: { value: string }) =>
                    inviteStudentForm.setFieldValue(name, value.value)
                  }
                  handleBlur={inviteStudentForm.handleBlur}
                  value={{
                    label: inviteStudentForm.values.day.toString(),
                    value: inviteStudentForm.values.day.toString(),
                  }}
                  options={Array.from(Array(daysCount).keys()).map((day) => ({
                    label: (day + 1).toString(),
                    value: (day + 1).toString(),
                  }))}
                />
              </div>
            </ButtonsSection>
            <ButtonsSection
              style={{ marginTop: "2rem", justifyContent: "flex-start" }}
              fullWidth
            >
              <div style={{ width: "200px", marginRight: "1px" }}>
                <StyledSelect
                  name="hour"
                  label="Hour"
                  menuPlacement="top"
                  handleChange={(name: string, value: { value: string }) =>
                    inviteStudentForm.setFieldValue(name, value.value)
                  }
                  handleBlur={inviteStudentForm.handleBlur}
                  value={{
                    label: inviteStudentForm.values.hour.toString(),
                    value: inviteStudentForm.values.hour.toString(),
                  }}
                  options={Array.from(Array(24).keys()).map((hour) => ({
                    label: hour.toString(),
                    value: hour.toString(),
                  }))}
                />
              </div>

              <div style={{ width: "200px" }}>
                <StyledSelect
                  name="minute"
                  label="Minutes"
                  menuPlacement="top"
                  handleChange={(name: string, value: { value: string }) =>
                    inviteStudentForm.setFieldValue(name, value.value)
                  }
                  handleBlur={inviteStudentForm.handleBlur}
                  value={{
                    label: inviteStudentForm.values.minute.toString(),
                    value: inviteStudentForm.values.minute.toString(),
                  }}
                  options={["00", "15", "30", "45"].map((minute) => ({
                    label: minute,
                    value: minute,
                  }))}
                />
              </div>
            </ButtonsSection>
          </div>
        )}
        <StudentsUploadButtons>
          <UploadStudentsButton
            variant="primary"
            onClick={inviteStudentForm.submitForm}
          >
            {t("teacher.uploadStudents")} ({CSVdata.length})
          </UploadStudentsButton>
          <Button variant="link" onClick={onClose}>
            {t("actions.cancel")}
          </Button>
        </StudentsUploadButtons>
      </PreviewWrapper>
    );
  };

  const renderDNDContent = () => {
    return (
      <DNDContainer>
        <DropContent {...getRootProps()}>
          <input {...getInputProps()} />
          <FileIcon />
          <DNDText>
            <BoldText>{t("teacher.drag&drop")}</BoldText>
            <div> {t("teacher.yourCsv")}</div>
          </DNDText>
          <div>{t("global.or")}</div>
          <Button variant="secondary" style={{ width: "auto" }}>
            {t("teacher.chooseFile")}
          </Button>
        </DropContent>
        {CSVErrors.errorCount > 0 && (
          <ErrorContainer>
            {CSVErrors.headerErrors.length > 0 && (
              <>
                {t("teacher.missingCSVheaders")}
                <ErrorsList>
                  {CSVErrors.headerErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}
            {CSVErrors.parsingErrors.length > 0 && (
              <>
                {t("teacher.parsingCSVErrors")}
                <ErrorsList>
                  {CSVErrors.parsingErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}
            {CSVErrors.contentErrors.length > 0 && (
              <>
                {t("teacher.incorrectCSVData")}
                <ErrorsList>
                  {CSVErrors.contentErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}

            {t("teacher.fixCSVfile")}
          </ErrorContainer>
        )}
        <DownloadCSVButton
          as="a"
          href="assets/template.csv"
          target="_blank"
          variant="link"
        >
          {t("teacher.downloadTemplate")}
        </DownloadCSVButton>
      </DNDContainer>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={header}
      subTitle={CSVdata.length > 0 ? t("teacher.makeSure") : undefined}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <CSVUploadWrapper>
          {CSVdata.length > 0 ? renderPreview() : renderDNDContent()}
        </CSVUploadWrapper>
      </Container>
    </Modal>
  );
};

export default AddStudentsModal;
