import React, { FC } from "react";
import { ToastProps } from "react-toast-notifications";
import { ReactComponent as ToastError } from "assets/images/toast-error.svg";
import { ReactComponent as ToastSuccess } from "assets/images/toast-success.svg";
import { ReactComponent as ToastWarning } from "assets/images/toast-warning.svg";

import styled from "styled-components";
import { ToastWrapper, ToastText, CloseIcon } from "./styles";

export const ToastContainer = styled.div`
  z-index: 10000;
  position: fixed;
  top: 10px;
  right: 10px;
`;

const Toast: FC<ToastProps> = ({ appearance, children, onDismiss }) => {
  const ToastIcon = () => {
    switch (appearance) {
      case "success":
        return <ToastSuccess />;
      case "error":
        return <ToastError />;
      case "warning":
        return <ToastWarning />;
      default:
        return <ToastSuccess />;
    }
  };

  const dismissModal = () => onDismiss();

  return (
    <ToastWrapper>
      <ToastIcon />
      <ToastText>{children}</ToastText>
      <CloseIcon onClick={dismissModal} />
    </ToastWrapper>
  );
};

export default Toast;
