import React, { useState, useRef, MutableRefObject } from "react";
import ReactPlayer from "react-player";
import Loader from "components/atoms/Loader";
import { useTranslation } from "react-i18next";
import {
  Container,
  Title,
  PlayerContainer,
  ControlsWrapper,
  DescriptionContainer,
} from "./styles";
import { ExtendedDocument, ExtendedHTMLElement, PlayerProps } from "./types";
import Controls from "./Controls";
import { Progress } from "../../../pages/Student/Academy/Player/types";

const VideoPlayer = ({
  video,
  handleOnStart,
  handleOnEnded,
  handleOnProgress,
  maxWidth,
  maxHeight,
  isTutorial,
}: PlayerProps) => {
  const { t } = useTranslation();
  const player: MutableRefObject<ReactPlayer | null> = useRef(null);
  const hideBarTimeoutRef: MutableRefObject<number | null> = useRef(null);
  const [videoIsReady, setVideoIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentSeek, setCurrentSeek] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [previousVolume, setPreviousVolume] = useState<number>(1);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [isMuted, setMuted] = useState<boolean>(false);
  const [isBarShowed, setIsBarshowed] = useState(true);
  const [isSeeking, setIsSeeking] = useState(false);

  const handleVideoIsReady = () => {
    setVideoIsReady(true);
  };

  const hideBar = (timeout: number) => {
    hideBarTimeoutRef.current = setTimeout(() => {
      setIsBarshowed(false);
      setIsSeeking(false);
    }, timeout);
  };

  const showBar = () => {
    setIsBarshowed(true);
    hideBar(3000);
  };

  const mouseMove = () => {
    if (hideBarTimeoutRef.current) {
      clearTimeout(hideBarTimeoutRef.current);
    }
    showBar();
  };
  const mouseLeave = () => {
    if (hideBarTimeoutRef.current) {
      clearTimeout(hideBarTimeoutRef.current);
    }
    hideBar(10);
  };

  const onSeekChangeMouseDown = () => {
    setIsSeeking(true);
    if (hideBarTimeoutRef.current) {
      clearTimeout(hideBarTimeoutRef.current);
    }
  };

  const handleSeekChange = (e: number) => {
    setCurrentSeek(e);
    player?.current?.seekTo(e);
  };

  const onSeekChangeMouseUp = (e: number) => {
    setIsSeeking(false);
    handleSeekChange(e);
  };

  const handleVolumeChange = (e: number) => {
    setVolume(e);
  };

  const handleProgress = ({ played, playedSeconds }: Progress) => {
    if (!isSeeking) {
      setCurrentSeek(playedSeconds);
      handleOnProgress(played);
    }
  };

  /* eslint-disable-next-line consistent-return */
  const handleFullscreen = () => {
    const PlayerContainerElement = document.getElementById(
      "player"
    ) as ExtendedHTMLElement;
    const documentElement = document as ExtendedDocument;

    /* eslint-disable-next-line consistent-return */
    const openFullScreen = () => {
      if (PlayerContainerElement.webkitRequestFullscreen) {
        return PlayerContainerElement.webkitRequestFullscreen();
      }
      if (PlayerContainerElement.msRequestFullscreen) {
        return PlayerContainerElement.msRequestFullscreen();
      }
      if (PlayerContainerElement.requestFullscreen) {
        return PlayerContainerElement.requestFullscreen();
      }
    };

    /* eslint-disable-next-line consistent-return */
    const closeFullScreen = () => {
      if (documentElement.webkitExitFullscreen) {
        return documentElement.webkitExitFullscreen();
      }
      if (documentElement.msExitFullscreen) {
        return documentElement.msExitFullscreen();
      }
      if (documentElement.exitFullscreen) {
        return documentElement.exitFullscreen();
      }
    };

    if (
      documentElement.webkitFullscreenElement ||
      documentElement.msFullscreenElement ||
      documentElement.fullscreenElement
    ) {
      closeFullScreen();
    } else {
      openFullScreen();
    }
  };

  const handleMuting = () => {
    if (isMuted) {
      setVolume(previousVolume);
    } else {
      setPreviousVolume(volume);
      setVolume(0);
    }
    setMuted(!isMuted);
  };

  const replaceImagePath = (htmlCode: string) => {
    return htmlCode.replaceAll(
      "../../storage/",
      "https://storage.googleapis.com/"
    );
  };

  return (
    <Container>
      <Title>{isTutorial ? t("student.welcome") : video.title}</Title>
      {!videoIsReady && <Loader />}
      <PlayerContainer id="player" maxWidth={maxWidth} maxHeight={maxHeight}>
        <ReactPlayer
          style={{
            backgroundColor: "black",
          }}
          ref={player}
          width="100%"
          height="100%"
          url={video.url}
          playing={isPlaying}
          volume={volume}
          muted={isMuted}
          onStart={handleOnStart}
          onEnded={() => {
            setIsPlaying(false);
            return handleOnEnded();
          }}
          onProgress={(progress) => handleProgress(progress)}
          onReady={handleVideoIsReady}
          onDuration={(duration) => setVideoDuration(duration - 1)}
          onError={() => setHasError(true)}
        />
        <ControlsWrapper onMouseMove={mouseMove} onMouseLeave={mouseLeave}>
          {isBarShowed && (
            <Controls
              currentSeek={currentSeek}
              playing={isPlaying}
              volume={volume}
              handleMute={handleMuting}
              handlePlay={() => setIsPlaying(!isPlaying)}
              handleSeekChange={handleSeekChange}
              handleSeekMouseDown={onSeekChangeMouseDown}
              handleSeekMouseUp={onSeekChangeMouseUp}
              handleVolumeChange={handleVolumeChange}
              handleFullscreen={handleFullscreen}
              totalVideoDuration={videoDuration}
            />
          )}
        </ControlsWrapper>
      </PlayerContainer>
      {video.description && (
        <DescriptionContainer maxWidth={maxWidth}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: replaceImagePath(video.description),
            }}
          />
        </DescriptionContainer>
      )}

      {hasError && (
        <h1>
          Sorry, some error occurred during video loading. Please try again
          later.
        </h1>
      )}
    </Container>
  );
};

export default VideoPlayer;
