import { useFormik, FormikProps } from "formik";
import React, { useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import jwt_decode, { JwtPayload } from "jwt-decode";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import Button from "components/atoms/Button";
import Header from "components/atoms/Header";
import Input from "components/molecules/Input";
import PasswordChecklist from "components/molecules/PasswordChecklist";

import { email, password, passwordConfirmation } from "helpers/formValidation";

import LoginTheme from "themes/LoginTheme";

import { MAINPAGE_URL } from "constants/misc";
import {
  useAuthenticateUserWithPasswordMutation,
  useGetAuthenticatedUserQuery,
  UserAuthenticationWithPasswordSuccess,
  useRegenerateInvitationLinkMutation,
  useUnauthenticateUserMutation,
  useUpdateAuthenticatedUserMutation,
} from "generated/graphql";
import { useToast } from "hooks/Toast";
import paths from "routes/paths";
import Loader from "components/atoms/Loader";
import {
  FormWrapper,
  ButtonsWrapper,
  HeaderWrapper,
  ExpiredInvitationWrapper,
  ExpiredInvitationLabel,
} from "./styles";

import { SignInInitialValues, ParamsType } from "./types";

const SetupPassword = () => {
  const { t } = useTranslation();
  const {
    loading: userLoading,
    data: userData,
    refetch: refetchUser,
  } = useGetAuthenticatedUserQuery();

  const [regenerateLinkMutation] = useRegenerateInvitationLinkMutation();

  const [logout, { client }] = useUnauthenticateUserMutation();

  const calledOnce = useRef<boolean>(false);
  const logoutRef = useRef<boolean>(true);
  const mailSentRef = useRef<boolean>(false);

  const [newToken, setNewToken] = useState<string | null | undefined>(null);

  const [updateUserData] = useUpdateAuthenticatedUserMutation();

  const INVALID_TOKEN_ERROR_CODE = "Invalid token";

  const RESET_PASSWORD = gql`
    mutation resetPassword(
      $email: String!
      $password: String!
      $passwordToken: String!
    ) {
      resetPassword(
        email: $email
        password: $password
        passwordConfirmation: $password
        resetPasswordToken: $passwordToken
      ) {
        success
      }
    }
  `;

  const { token, email: encodedEmail } = useParams<ParamsType>();
  const history = useHistory();

  const cancelRegistrationProcess = () => {
    window.location.href = MAINPAGE_URL;
  };

  const signInInitialValues = {
    email: atob(encodedEmail).replace("*", ""),
    password: "",
    passwordConfirmation: "",
  };

  const [passwordReset, { loading }] = useMutation(RESET_PASSWORD);
  const [login] = useAuthenticateUserWithPasswordMutation();

  useEffect(() => {
    if (userData?.authenticatedItem && logoutRef.current) {
      logout().then(() => client.resetStore());
      logoutRef.current = false;
      return;
    }
    if (userData?.authenticatedItem && !calledOnce.current) {
      if (
        userData?.authenticatedItem?.students &&
        userData?.authenticatedItem?.students?.length > 0
      ) {
        history.push(paths.verifyData);
      } else {
        updateUserData({
          variables: {
            firstName: userData?.authenticatedItem?.firstName || "",
            lastName: userData?.authenticatedItem?.lastName || "",
          },
        }).then(() => {
          history.push(paths.decider);
        });
      }
      if (userData?.authenticatedItem && !calledOnce.current) {
        calledOnce.current = true;
      }
    }
  }, [
    userData,
    logoutRef,
    calledOnce,
    logout,
    client,
    history,
    updateUserData,
  ]);

  const Toast = useToast();

  const tryLogin = async (values: SignInInitialValues) => {
    logoutRef.current = false;
    passwordReset({
      variables: {
        email: atob(encodedEmail).replace("*", "").toLowerCase(),
        password: values.password,
        passwordToken: atob(token),
      },
    })
      .then(() =>
        login({
          variables: {
            email: values.email.toLowerCase(),
            password: values.password,
          },
        })
          .then((res) => {
            const data = res.data
              ?.authenticateUserWithPassword as UserAuthenticationWithPasswordSuccess;
            localStorage.setItem("EFC_token", data.sessionToken || "");
          })
          .then(() => {
            refetchUser();
          })
      )
      .catch((err) => {
        Toast("error", err.message);

        if (err.message === INVALID_TOKEN_ERROR_CODE) {
          history.push(paths.signIn);
        }

        refetchUser();
      });
  };

  const signInForm: FormikProps<SignInInitialValues> = useFormik({
    initialValues: signInInitialValues,
    validationSchema: Yup.object({
      email,
      password,
      passwordConfirmation,
    }),
    onSubmit: (values) => tryLogin(values),
  });

  const decoded = jwt_decode<JwtPayload>(atob(token));

  const invitationExpired = Date.now() >= (decoded?.exp || 0) * 1000;

  const resendInvitation = (resendToken: string | null | undefined) => {
    if (resendToken) {
      regenerateLinkMutation({
        variables: {
          token: resendToken,
        },
      }).then(({ data }) => {
        if (data?.regenerateInvitationLink?.token) {
          setNewToken(data?.regenerateInvitationLink?.token);
          // If no user with that token -> redirect to login page (wrong token or account already confirmed)
        } else {
          history.push(paths.signIn);
        }
      });
      mailSentRef.current = true;
    }
  };

  if (invitationExpired && !mailSentRef.current) {
    resendInvitation(atob(token));
  }

  if (userLoading) {
    <Loader />;
  }

  return (
    <LoginTheme selfRegistration={atob(encodedEmail).indexOf("*") > -1}>
      <FormWrapper
        onSubmit={signInForm.handleSubmit}
        method="post"
        autoComplete="off"
      >
        {invitationExpired ? (
          <HeaderWrapper>
            <Header>{t("user.createPassword")}</Header>
            <ExpiredInvitationWrapper>
              <ExpiredInvitationLabel>
                {t("user.askNewInvitation")}
              </ExpiredInvitationLabel>
              <ExpiredInvitationLabel>
                {t("user.didnotReceived")}
              </ExpiredInvitationLabel>
              <Button
                variant="link"
                onClick={() => resendInvitation(newToken)}
                width="50%"
                style={{ marginTop: "2rem" }}
              >
                {t("user.resendEmail")}
              </Button>
            </ExpiredInvitationWrapper>
          </HeaderWrapper>
        ) : (
          <>
            <HeaderWrapper>
              <Header>{t("user.createPassword")}</Header>
            </HeaderWrapper>
            <Input
              name="email"
              label={t("user.yourLogin")}
              handleChange={signInForm.handleChange}
              handleBlur={signInForm.handleBlur}
              value={signInForm.values.email}
              disabled
            />

            <Input
              name="password"
              label={t("global.password")}
              handleChange={signInForm.handleChange}
              handleBlur={signInForm.handleBlur}
              placeholder="Provide your password"
              type="password"
              value={signInForm.values.password}
              error={signInForm.touched.password && signInForm.errors.password}
              password
            />

            <PasswordChecklist password={signInForm.values.password} />

            <Input
              name="passwordConfirmation"
              label={t("user.repeatPassword")}
              handleChange={signInForm.handleChange}
              handleBlur={signInForm.handleBlur}
              placeholder={t("user.repeatPassword")}
              type="password"
              value={signInForm.values.passwordConfirmation}
              error={
                signInForm.touched.passwordConfirmation &&
                signInForm.errors.passwordConfirmation
              }
              password
            />

            <ButtonsWrapper>
              <Button type="submit" variant="primary" disabled={loading}>
                {t("actions.save")}
              </Button>
              <Button
                variant="link"
                type="button"
                onClick={cancelRegistrationProcess}
              >
                {t("actions.cancel")}
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </FormWrapper>
    </LoginTheme>
  );
};

export default SetupPassword;
