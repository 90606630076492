import React, { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";
import Modal from "components/atoms/Modal";
import Input from "components/molecules/Input";
import { FormikProps, useFormik } from "formik";
import {
  OrderDirection,
  useCreateFolderInSchoolMutation,
  useGetAllFolderCountriesQuery,
} from "generated/graphql";
import { currentSchoolIdVar } from "store/school";
import { useReactiveVar } from "@apollo/client";
import StyledSelect from "components/molecules/Select";
import { Container, StyledButton } from "./styles";
import { AddFolderProps, AddFolderType } from "./types";

const AddFolderModal: FC<AddFolderProps> = ({
  isOpen,
  onClose,
  title,
  userId,
}) => {
  const { t } = useTranslation();
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const [createFolder] = useCreateFolderInSchoolMutation();

  const addFolderForm: FormikProps<AddFolderType> = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { folderName: "", countryId: 0 },

    onSubmit: async ({ folderName, countryId }, { setErrors, resetForm }) => {
      let variables;
      if (userId) {
        variables = {
          name: folderName,
          schoolId: Number(currentSchoolId),
          studentIds: [userId],
          countryId: countryId || 0,
        };
      } else {
        variables = {
          name: folderName,
          schoolId: Number(currentSchoolId),
          countryId: countryId || 0,
        };
      }

      createFolder({
        variables,
      })
        .then(async () => {
          resetForm();
          onClose(folderName);
        })
        .catch((e) => setErrors({ folderName: e.message }));
    },
  });

  const { data: countries } = useGetAllFolderCountriesQuery({
    variables: { orderBy: [{ name: OrderDirection.Asc }] },
  });

  const countriesMemo = useMemo(() => {
    const countriesCopy = countries?.countries?.slice();
    return countriesCopy
      ?.sort((a, b) => (a.code === "EU" ? -1 : 1))
      ?.map((country) => ({
        label: `${country.flag} ${country.name}`,
        value: country.id,
      }));
  }, [countries]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <form
          onSubmit={addFolderForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="folderName"
            label={t("global.folderName")}
            placeholder={t("global.folderNamePlaceholder")}
            handleChange={addFolderForm.handleChange}
            handleBlur={addFolderForm.handleBlur}
            value={addFolderForm.values.folderName}
            error={
              addFolderForm.touched.folderName &&
              addFolderForm.errors.folderName
            }
          />
          <StyledSelect
            name="countryId"
            label="Language model"
            isOptional
            handleChange={(name: string, value: { value: string }) =>
              addFolderForm.setFieldValue(name, Number(value.value))
            }
            handleBlur={addFolderForm.handleBlur}
            value={countriesMemo?.find(
              (country) =>
                country.value === addFolderForm.values.countryId.toString()
            )}
            options={countriesMemo || []}
          />
          <StyledButton variant="primary" width="50%" type="submit">
            {t("global.create")} {userId && "and assign"}
          </StyledButton>
        </form>
      </Container>
    </Modal>
  );
};

export default AddFolderModal;
