import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as Clock } from "assets/images/clock.svg";
import { ReactComponent as Monitor } from "assets/images/monitor.svg";
import { ReactComponent as CircleCheckedGreen } from "assets/images/circle-checked-green.svg";
import Colors from "constants/colors";

import {
  Card,
  CardWrapper,
  BottomContainer,
  Title,
  VerticalLine,
  TitleContainer,
  LeftBottom,
  ContentWrapper,
  Strong,
  InnerDiv,
  Button,
  RightColumn,
  LeftBottomCompleted,
} from "./styles";
import { Props } from "./types";

const Assessment = ({ status, name, type }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const assessmentName = name;

  const handleNavigate = () => {
    history.push(`/test-instruction/${type}`);
  };

  return (
    <CardWrapper
      onClick={status === "available" ? handleNavigate : undefined}
      flexOrder={status === "not-available" ? 2 : 1}
    >
      <Card status={status} type={type}>
        <TitleContainer>
          <VerticalLine type={type} />
          <Title>{assessmentName}</Title>
        </TitleContainer>
        <ContentWrapper>
          <InnerDiv>
            {status === "finished" ? (
              <>
                <div>
                  Congratulations on completing the assessment and exploring
                  various career families!
                </div>
                <Strong>
                  Explore detailed results for different groups of jobs by
                  downloading your report.
                </Strong>
              </>
            ) : (
              <>
                <div>
                  Taking this assessment will introduce you to various career
                  families, which are large groups of jobs sharing similar
                  activities or goals.
                </div>
                <Strong>
                  You have numerous choices for your future — enjoy the
                  beginning of your journey!
                </Strong>
              </>
            )}
          </InnerDiv>

          <BottomContainer>
            {status === "finished" ? (
              <LeftBottomCompleted>
                <CircleCheckedGreen style={{ marginRight: "0.6rem" }} />
                <span style={{ color: Colors.oceanGreen }}>
                  {t("student.completed")}
                </span>
              </LeftBottomCompleted>
            ) : (
              <RightColumn>
                <Button variant="primary">Let&apos;s begin!</Button>
                <LeftBottom>
                  <Clock style={{ marginRight: "0.6rem" }} />
                  <span style={{ color: Colors.shuttleGray }}>
                    {t("student.noLimit")}
                  </span>
                </LeftBottom>
              </RightColumn>
            )}
          </BottomContainer>
        </ContentWrapper>
      </Card>
    </CardWrapper>
  );
};

export default memo(Assessment);
