const paths = {
  root: "/",
  signIn: "/login",
  decider: "/decider",
  setupPassword: "/create-password/:email/:token",
  verifyData: "/create-password/verify",
  selfRegistration: "/individual",
  selfRegistrationPayment: "/individual/payment",
  selfRegistrationPaymentSuccess: "/individual/payment/success",
  selfRegistrationPaymentFailed: "/individual/payment/failed",
  students: "/students/:folderIds?",
  studentsAdd: "/students/add",
  studentsEmptyFolder: "/students",
  school: "/school/",
  folders: "/folders",
  faq: "/faq",
  userProfile: "/user-profile",
  studentDashboard: "/student",
  tutorialPlayer: "/tutorial/watch/:videoId",
  academyDashboard: "/academy",
  videoPlayer: "/academy/watch/:videoId",
  tests: "/student/test/:type",
  testInstruction: "/test-instruction/:type",
  training: "/training/:type",
  trainingFinished: "/training-finished/:type",
  paymentSuccess: "/payments/success",
  paymentFailed: "/payments/fail",
  forgotPassword: "/forgot-password",
  feedback: "/feedback/:token/:testAttemptId",
  notFound: "*",
};

export default paths;
