import media from "helpers/media";
import styled from "styled-components";

import Button from "components/atoms/Button";

export const ButtonsWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  ${media.SM`
    margin-top: 0;
    flex-direction: column-reverse;
    margin: 0;
  `}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 2.4rem;

  ${media.SM`;
  margin-bottom: 1rem;
  `}
`;

export const PullRight = styled(Button)`
  ${media.SM`;
    align-self: right;
    border-radius: 0; padding-right: 0;
    text-align: right;
  `}
`;

export const SpaceMiddle = styled(Button)`
  ${media.SM`
    width: 90%; 
    margin: auto;
    margin-top: 4rem;`}
`;
