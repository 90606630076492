import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";

import { AssessmentType } from "types";

import { getTestDuration } from "helpers/getTestDuration";
import { getTestName } from "helpers/getTestName";
import MobileModal from "components/molecules/MobileModal";
import {
  StyledButton,
  Heading,
  Content,
  ActionSectionTitle,
  ButtonsSection,
  Wrapper,
  PrimaryBtnWrapper,
  Circle,
  CircleText,
  ModalWrapper,
  StyledClock,
  CircleContent,
} from "./styles";

import data from "./data";

import { Props } from "./types";

const ImportantInfoModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  onTestStart,
}) => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: AssessmentType }>();

  return (
    <>
      <ModalWrapper>
        <Modal
          cardStyles={{ border: "none" }}
          isOpen={isOpen}
          onClose={onClose}
          title={title}
          heading={<Heading>{getTestName(type)}</Heading>}
          closeIcon={false}
          rightHeaderContent={
            <Circle>
              <StyledClock />
              <CircleText>{getTestDuration(type)}:00</CircleText>
            </Circle>
          }
        >
          <Wrapper>
            <Content>
              {t("student.importantInfoContent")}{" "}
              <b>{t("student.importantInfoContent2")}</b>{" "}
              {t("student.importantInfoContent3")}{" "}
              <b>
                {getTestDuration(type)}:00 {t("student.minutes")}
              </b>
              . {t("student.importantInfoContent4")}
            </Content>
            <ActionSectionTitle
              dangerouslySetInnerHTML={{ __html: data.assessmentQuestion }}
            />
            <ButtonsSection>
              <PrimaryBtnWrapper>
                <StyledButton
                  variant="primary"
                  onClick={onTestStart}
                  dangerouslySetInnerHTML={{ __html: data.confirmButton }}
                />
              </PrimaryBtnWrapper>
              <StyledButton
                variant="secondary"
                onClick={onClose}
                dangerouslySetInnerHTML={{ __html: data.rejectButton }}
              />
            </ButtonsSection>
          </Wrapper>
        </Modal>
      </ModalWrapper>

      {/* Displayed only on mobile */}
      <MobileModal
        onClose={onClose}
        isOpen={isOpen}
        title={title}
        heading={<Heading>{getTestName(type)}</Heading>}
        headerIcon={
          <Circle>
            <CircleContent>
              <StyledClock />
              <CircleText>{getTestDuration(type)}:00</CircleText>
            </CircleContent>
          </Circle>
        }
      >
        <Wrapper>
          <Content>
            {t("student.importantInfoContent")}{" "}
            <b>{t("student.importantInfoContent2")}</b>{" "}
            {t("student.importantInfoContent3")}{" "}
            <b>
              {getTestDuration(type)}:00 {t("student.minutes")}
            </b>
            . {t("student.importantInfoContent4")}
          </Content>
          <ActionSectionTitle
            dangerouslySetInnerHTML={{ __html: data.assessmentQuestion }}
          />
          <ButtonsSection>
            <PrimaryBtnWrapper>
              <StyledButton
                variant="primary"
                onClick={onTestStart}
                dangerouslySetInnerHTML={{ __html: data.confirmButton }}
              />
            </PrimaryBtnWrapper>
            <StyledButton
              variant="secondary"
              onClick={onClose}
              dangerouslySetInnerHTML={{ __html: data.rejectButton }}
            />
          </ButtonsSection>
        </Wrapper>
      </MobileModal>
    </>
  );
};

export default ImportantInfoModal;
