import Papa from "papaparse";

const parseCSV = (
  file: string | File | NodeJS.ReadableStream,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (result: any) => void,
  { hasHeader = true, preview = 0 } = {}
) => {
  Papa.parse(file, {
    header: hasHeader,
    preview,
    skipEmptyLines: true,
    transformHeader: (h) => h.trim(),
    complete: (result) => {
      callback(result);
    },
  });
};

export default parseCSV;
