import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";

import Label from "components/atoms/Label";

import "react-datepicker/dist/react-datepicker.css";

import {
  InputWrapper,
  Input,
  Error,
  StyledEye,
  StyledCrossedEye,
} from "./styles";

import { Props } from "./types";

const StyledInput: FC<Props> = ({
  maxDate,
  label,
  name,
  value,
  handleChange,
  handleBlur,
  error,
  type = "text",
  placeholder,
  password = false,
  disabled = false,
  date = false,
  setFieldValue,
}) => {
  const [fieldType, setFieldType] = useState(type);

  const toggleFieldType = () =>
    setFieldType(fieldType === "password" ? "text" : "password");

  return (
    <InputWrapper>
      {label && <Label htmlFor={name}>{label}</Label>}
      {date ? (
        <DatePicker
          maxDate={maxDate}
          selected={value ? new Date(value) : null}
          onChange={(localDate) =>
            setFieldValue &&
            setFieldValue(
              name,
              localDate instanceof Date
                ? localDate.toISOString().substring(0, 10)
                : ""
            )
          }
          dateFormat="dd-MM-yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          locale="en"
        />
      ) : (
        <Input
          name={name}
          type={fieldType}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          disabled={disabled}
          hasError={error !== undefined}
        />
      )}
      {password &&
        (fieldType === "password" ? (
          <StyledEye onClick={toggleFieldType} />
        ) : (
          <StyledCrossedEye onClick={toggleFieldType} />
        ))}

      {error && <Error>{error}</Error>}
    </InputWrapper>
  );
};

export default StyledInput;
