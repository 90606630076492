import React, { FC } from "react";

import { StyledTitle } from "./styles";
import { Props } from "./types";

const Title: FC<Props> = ({ children, style }) => (
  <StyledTitle style={style}>{children}</StyledTitle>
);

export default Title;
