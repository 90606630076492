import React, { FC } from "react";

import { Props } from "./types";
import { Title, Card } from "./styles";

const TitledPanel: FC<Props> = ({ children, title }) => (
  <Card>
    <Title>{title}</Title>
    {children}
  </Card>
);

export default TitledPanel;
