import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAuthenticatedUserQuery,
  useGetVideosWithViewsQuery,
} from "generated/graphql";
import { ReactComponent as SmallLogo } from "assets/images/smallLogo.svg";

import NavigationBar from "components/molecules/NavigationBar";

import paths from "routes/paths";
import { STATIC_LINKS } from "constants/staticLinks";

import {
  StyledMain,
  StyledHeader,
  StyledFooter,
  Footer,
  FooterItem,
  StyledUl,
  StyledLink,
} from "./styles";
import { VideoModel } from "../../pages/Student/Academy/AcademyDashboard/types";

const Student: FC = ({ children }) => {
  const { t } = useTranslation();

  const [videos, setVideos] = useState<VideoModel[]>([]);

  const { data: userData } = useGetAuthenticatedUserQuery();

  const { data: videoData } = useGetVideosWithViewsQuery({
    variables: { isTutorial: false },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data = videoData?.findVideosWithViews as VideoModel[];
    if (data?.length) {
      setVideos(data);
    }
  }, [videoData]);

  const studentNav =
    userData?.authenticatedItem?.students &&
    userData?.authenticatedItem?.students[0].testAttempt?.isCompleted &&
    videos.length
      ? [
          { to: paths.studentDashboard, title: t("student.dashboard") },
          { to: paths.academyDashboard, title: t("academy.academy") },
        ]
      : [];

  return (
    <>
      <StyledHeader>
        <NavigationBar navigationItems={studentNav} />
      </StyledHeader>
      <StyledMain>{children}</StyledMain>
      <StyledFooter>
        <Footer>
          <FooterItem>
            <SmallLogo />
            <StyledLink href="https://careerfit.com">CareerFit.com</StyledLink>
          </FooterItem>
          <FooterItem>
            <StyledUl>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${STATIC_LINKS.PRIVACY_POLICY}`}
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${STATIC_LINKS.FAQ}`}
                >
                  FAQ
                </a>
              </li>
            </StyledUl>
          </FooterItem>
        </Footer>
      </StyledFooter>
    </>
  );
};

export default Student;
