export enum ZIndex {
  Default = 1,
  Card,
  Chart,
  DataCard,
  Dropdown,
  Modal,
}

export const size = {
  containerWidth: "1170px",
};
