import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import paths from "routes/paths";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as Burger } from "assets/images/burger.svg";
import UserInfo from "components/molecules/UserInfo";
import CreditsDropdown from "components/molecules/CreditsDropdown";

import {
  Container,
  NavigationList,
  NavigationWrapper,
  NavigationItem,
  NavigationLink,
  BurgerMenu,
} from "./styles";
import { Props } from "./types";

const NavigationBar: FC<Props> = ({ navigationItems }) => {
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMenuOpen(true);
  const closeMobileMenu = () => setIsMenuOpen(false);

  const showCredits =
    location.pathname === paths.school ||
    location.pathname === paths.paymentSuccess ||
    location.pathname === paths.paymentFailed;

  return (
    <Container>
      <NavigationWrapper>
        <BurgerMenu>
          <Burger onClick={openMobileMenu} />
        </BurgerMenu>
        <Logo />
        <NavigationList>
          {navigationItems &&
            navigationItems.map((navItem) => {
              if (navItem.to.indexOf("https") > -1) {
                return (
                  <NavigationLink
                    target="_blank"
                    rel="noreferrer"
                    href={navItem.to}
                    key={navItem.title}
                  >
                    {navItem.title}
                  </NavigationLink>
                );
              }
              return (
                <NavigationItem to={navItem.to} key={navItem.title}>
                  {navItem.title}
                </NavigationItem>
              );
            })}
        </NavigationList>
      </NavigationWrapper>
      {showCredits && <CreditsDropdown />}
      <UserInfo isMenuOpen={isMenuOpen} onMenuClose={closeMobileMenu} />
    </Container>
  );
};

export default NavigationBar;
