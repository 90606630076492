import styled from "styled-components";
import media from "helpers/media";

export const Container = styled.div`
  display: flex;
  width: 100%;

  ${media.SM`
    flex-direction: column;
  `};
`;

export const SideMenuWrapper = styled.div`
  width: 20%;
  margin-right: 7.4rem;

  ${media.SM`
    display: none;
  `};
`;
