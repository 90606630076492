import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as TimesUp } from "assets/images/time-clock-fire.svg";
import Modal from "components/atoms/Modal";
import Button from "components/atoms/Button";
import MobileModal from "components/molecules/MobileModal";
import paths from "routes/paths";

import {
  SmallCircle,
  Text,
  AdditionalInfo,
  Wrapper,
  ModalWrapper,
  IconWrapper,
} from "./styles";
import { Props } from "./types";

const TimesUpModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNavigate = () => {
    history.push(paths.studentDashboard);
  };

  return (
    <>
      <ModalWrapper>
        <Modal
          isOpen={isOpen}
          size="narrow"
          onClose={onClose}
          title={t("student.yourTimesUp")}
          closeIcon={false}
          rightHeaderContent={
            <SmallCircle>
              <TimesUp />
            </SmallCircle>
          }
        >
          <Wrapper>
            <Text>
              {t("student.outOfTime")}{" "}
              <strong>{t("student.answersRecorded")}</strong>.
              <AdditionalInfo>{t("student.tryOther")}</AdditionalInfo>
            </Text>
            <Button variant="primary" onClick={handleNavigate}>
              {t("student.returnToDashboard")}
            </Button>
          </Wrapper>
        </Modal>
      </ModalWrapper>

      {/* Displayed only on mobile */}
      <MobileModal
        onClose={onClose}
        isOpen={isOpen}
        title={t("student.yourTimesUp")}
        headerIcon={
          <IconWrapper>
            <SmallCircle>
              <TimesUp />
            </SmallCircle>
          </IconWrapper>
        }
      >
        <Wrapper>
          <Text>
            {t("student.outOfTime")}{" "}
            <strong>{t("student.answersRecorded")}</strong>.
            <AdditionalInfo>{t("student.tryOther")}</AdditionalInfo>
          </Text>
          <Button variant="primary" onClick={handleNavigate}>
            {t("student.returnToDashboard")}
          </Button>
        </Wrapper>
      </MobileModal>
    </>
  );
};

export default TimesUpModal;
