import React, { FC, useState } from "react";

import {
  Wrapper,
  MenuItem,
  Button,
  StyledLink,
  StyledInput,
  Spacer,
  InputWrapper,
} from "./styles";
import { Props } from "./types";

const DropdownMenu: FC<Props> = ({
  className,
  style,
  visible,
  elements,
  offsetTop,
  position,
  filterable = false,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <Wrapper
      className={className}
      style={style}
      top={offsetTop}
      visible={visible}
      position={position}
    >
      {filterable && (
        <InputWrapper>
          <StyledInput
            placeholder="Search..."
            type="text"
            onClick={(e) => e.stopPropagation()}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Spacer />
        </InputWrapper>
      )}
      <ul>
        {elements
          .filter((element) => {
            return (
              element.name
                ?.toLocaleLowerCase()
                .includes(searchValue.toLowerCase()) ||
              element.content?.props
                .join("")
                .includes(searchValue.toLowerCase())
            );
          })
          .map((element, i) => {
            return (
              <MenuItem key={i}>
                {typeof element.link !== "undefined" && element.link.to ? (
                  <StyledLink {...element.link} />
                ) : (
                  <Button
                    onClick={element.onClick}
                    color={element?.name?.split("###")[1]}
                  >
                    {element?.name?.split("###")[0]}
                  </Button>
                )}
              </MenuItem>
            );
          })}
      </ul>
    </Wrapper>
  );
};

export default DropdownMenu;
