/* eslint-disable @typescript-eslint/ban-types */
import React, { FC } from "react";

import ToggleButton from "components/atoms/ToggleButton";

import Colors from "constants/colors";

import { ButtonsWrapper } from "./styles";
import { Props } from "./types";
import { Circle } from "../StudentRow/styles";

const colors = [
  Colors.oceanGreen,
  Colors.tulipTree,
  Colors.mandy,
  Colors.denim,
];

const ButtonGroup: FC<Props> = (props) => {
  const { buttonsData, selectedButtonId, onButtonClick, styles } = props;

  const handleButtonClick = (id: number | string, onClick?: Function) => {
    onButtonClick(id);
    if (onClick) onClick();
  };

  return (
    <ButtonsWrapper style={styles}>
      {buttonsData?.length &&
        buttonsData.map((buttonData, i) => (
          <ToggleButton
            key={buttonData.title}
            enabled={buttonData.id === selectedButtonId}
            onClick={() => handleButtonClick(buttonData.id, buttonData.onClick)}
            enabledStyles={{
              color: colors[i],
              borderColor: colors[i],
            }}
          >
            {buttonData.circleColor && (
              <Circle circleColor={buttonData.circleColor} />
            )}
            {buttonData.title}
          </ToggleButton>
        ))}
    </ButtonsWrapper>
  );
};

export default ButtonGroup;
