import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { ItemBtn, ArrowIcon, ButtonContent, Container } from "./styles";
import { Props } from "./types";

const SideMenu: FC<Props> = ({ values }) => {
  const history = useHistory();

  const renderItems = () => {
    return values.map((el) => (
      <ItemBtn
        key={el.name}
        type="button"
        onClick={() => history.push(el.path)}
      >
        <ButtonContent>
          <ArrowIcon /> {el.name}
        </ButtonContent>
      </ItemBtn>
    ));
  };

  return <Container>{renderItems()}</Container>;
};

export default SideMenu;
