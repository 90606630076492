import styled from "styled-components";

import Button from "components/atoms/Button";

import { ReactComponent as ChevronDownIcon } from "assets/images/chevron-down.svg";

export const AddStudentsWrapper = styled.div`
  position: relative;
  min-width: 21rem;
`;

export const AddStudentsDropdown = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const BottomActionsSection = styled.div<{ spaceBetween: boolean }>`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "initials"};
  align-items: center;
`;

export const PageNumberWrapper = styled.div`
  display: flex;
`;

export const PageOptionsDropdown = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  font-size: 1.4rem;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ChevronDown = styled(ChevronDownIcon)`
  margin-left: 0.6rem;
  stroke: ${({ theme }) => theme.colors.white}; ;
`;

export const GreyChevronDown = styled(ChevronDownIcon)`
  margin-left: 0.6rem;
  stroke: ${({ theme }) => theme.colors.mineShaft};
`;

export const DownloadCSVButton = styled(Button)`
  align-items: center;
  display: flex;
  margin-left: 0.8rem;
  min-width: initial;
  width: initial;

  &:hover {
    text-decoration: none;
  }
`;
