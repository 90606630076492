import React, { FC, useState } from "react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { ReactComponent as PaperPlane } from "assets/images/paper-plane.svg";
import { ReactComponent as Pencil } from "assets/images/pencil.svg";
import { ReactComponent as Download } from "assets/images/download.svg";
import { ReactComponent as Folder } from "assets/images/folder.svg";
import { ReactComponent as Unarchive } from "assets/images/unarchive.svg";

import { useToast } from "hooks/Toast";

import Checkbox from "components/atoms/Checkbox";

import Colors from "constants/colors";
import { StudentStatusType } from "generated/graphql";

import { downloadStudentReport } from "api/downloadReport";
import { Muted } from "components/atoms/Title";
import {
  Circle,
  TableRow,
  TableCell,
  ActionItem,
  ActionLabel,
  StyledButton,
  Tick,
} from "./styles";
import { Props } from "./types";
import FolderSelector from "../FolderSelector";

const StudentRow: FC<Props> = (props) => {
  const {
    student,
    isSelected,
    setSelectedStudents,
    onRemove,
    onEdit,
    onToggle,
    onSendMessage,
    onUnarchive,
    isArchived = false,
    studentsRefetch,
  } = props;

  const { t } = useTranslation();
  const Toast = useToast();

  const [folderAssignUser, setFolderAssignUser] = useState<number | null>(null);

  const [localFolder, setLocalFolder] = useState<Record<number, string>>({});

  const addLocalFolder = (id: number, value: string) => {
    setLocalFolder((prevData) => ({
      ...prevData,
      [id]: value, // Directly set the value for the given ID
    }));
  };

  if (!student) return null;

  const openFolderSelection = (userId: number) => setFolderAssignUser(userId);

  const closeFolderSelection = (
    localUserId: number | null,
    localFolderName?: string
  ) => {
    if (localUserId && localFolderName) {
      addLocalFolder(localUserId, localFolderName);
      if (setSelectedStudents) {
        setSelectedStudents([]);
      }
    }
    if (studentsRefetch) {
      studentsRefetch();
    }
    setFolderAssignUser(null);
  };

  const userStatus = () => {
    if (!student?.user?.isConfirmed)
      return <Circle circleColor={Colors.geyser} />;

    switch (student?.status) {
      case StudentStatusType.NotStarted:
        return <Circle circleColor={Colors.mandy} />;
      case StudentStatusType.InProgress:
        return <Circle circleColor={Colors.tulipTree} />;
      case StudentStatusType.Completed:
        return <Circle circleColor={Colors.oceanGreen} />;
      default:
        return <Circle circleColor={Colors.geyser} />;
    }
  };

  const handleDownloadReport = (id: string, reportName: string) => {
    downloadStudentReport(
      id,
      (res) => {
        fileDownload(res.data, reportName);
        Toast("success", t("successAction.reportDownloading"));
      },
      () => {
        Toast("error", t("errorAction.reportDoesntExist"));
      }
    );
  };

  const isReport = student.status === StudentStatusType.Completed;

  const isRemovable = student.status === StudentStatusType.NotStarted;
  const isCompleted = student.status === StudentStatusType.Completed;

  return (
    <TableRow key={student.user?.id}>
      <TableCell>
        <Checkbox isChecked={isSelected} onClick={() => onToggle(student)} />
      </TableCell>
      <TableCell>
        {student.user?.lastName}, {student.user?.firstName}
      </TableCell>
      {!isArchived && (
        <TableCell
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => openFolderSelection(Number(student.id))}
        >
          {student.folder || localFolder[Number(student.id)] ? (
            <>
              <Folder />{" "}
              {localFolder[Number(student.id)] ||
                `${student.folder?.countryModel?.flag} ${student.folder?.name}`}
            </>
          ) : (
            <Muted>{t("global.folderUnassigned")}</Muted>
          )}
          {Number(student.id) === folderAssignUser && (
            <FolderSelector
              userId={Number(student.id)}
              onClose={closeFolderSelection}
              currentFolderName={
                localFolder[Number(student.id)] ||
                `${student.folder?.countryModel?.flag} ${student.folder?.name}` ||
                ""
              }
            />
          )}
        </TableCell>
      )}
      <TableCell centered>{userStatus()}</TableCell>
      <TableCell>
        <ActionItem>
          {!isArchived && onEdit && (
            <ActionItem
              clickable={!student?.user?.isConfirmed}
              disabled={student?.user?.isConfirmed || false}
              data-tip={student?.user?.isConfirmed}
              data-for="onlyNotStarted"
              data-tip-disable={!student?.user?.isConfirmed}
            >
              <>
                <Pencil />
                <StyledButton
                  variant="link"
                  disabled={!!student?.user?.isConfirmed}
                  onClick={() => onEdit(student)}
                >
                  {t("actions.edit")}
                </StyledButton>
              </>
            </ActionItem>
          )}
          {isArchived && onUnarchive && (
            <ActionItem>
              <Unarchive />
              <StyledButton variant="link" onClick={() => onUnarchive(student)}>
                {t("actions.unarchive")}
              </StyledButton>
            </ActionItem>
          )}
          <ActionItem style={{ marginLeft: 0 }}>
            <PaperPlane />
            <StyledButton variant="link" onClick={() => onSendMessage(student)}>
              {t("teacher.message")}
            </StyledButton>
          </ActionItem>

          {/* {isRemovable && (
            <ActionItem danger>
              <StyledButton
                variant="link"
                onClick={() => onRemove(student)}
                style={{ paddingLeft: 0 }}
              >
                {t("actions.remove")}
              </StyledButton>
            </ActionItem>
          )} */}
        </ActionItem>
      </TableCell>
      <TableCell>
        {isReport && (
          <ActionItem>
            <Download />
            <StyledButton
              variant="link"
              onClick={() =>
                handleDownloadReport(
                  student.id || "",
                  student.testAttempt?.reportName || ""
                )
              }
              style={{ paddingLeft: "0.8rem" }}
            >
              <ActionLabel style={{ marginLeft: 0 }}>
                {t("teacher.pdf")}
              </ActionLabel>
            </StyledButton>
          </ActionItem>
        )}
      </TableCell>
    </TableRow>
  );
};

export default StudentRow;
