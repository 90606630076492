import styled from "styled-components";

import { ReactComponent as ArrowLeftIcon } from "assets/images/arrow-left.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
`;

export const ItemBtn = styled.button`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.denim};
  margin-bottom: 2.4rem;
  display: flex;
  align-self: flex-end;
`;

export const ArrowIcon = styled(ArrowLeftIcon)`
  margin-right: 1rem;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
