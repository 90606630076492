import styled from "styled-components";

import media from "helpers/media";
import Button from "components/atoms/Button";

export const StyledButton = styled(Button)`
  width: min-content;
  margin-bottom: 1.6rem;

  ${media.SM`
    width: 100%
  `}
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
