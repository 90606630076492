import React, { FC } from "react";

import { Props } from "./types";
import { MainBar, UsedBar, Wrapper } from "./styles";

const ProgressBar: FC<Props> = ({ allStudentsNumber, creditsLeft }) => (
  <Wrapper>
    <MainBar />
    <UsedBar
      width={100 - (creditsLeft / (allStudentsNumber + creditsLeft)) * 100}
    />
  </Wrapper>
);

export default ProgressBar;
