import i18n from "utils/i18n/i18n";

export const instructionsContent = {
  numerical: {
    header: i18n.t("test.numerical.numericalReasoning"),
    contentData: [
      { content: i18n.t("test.numerical.content") },
      { content: i18n.t("test.numerical.content2") },
      { content: i18n.t("test.numerical.content3") },
      { content: i18n.t("test.numerical.content4") },
      { content: i18n.t("test.numerical.content5") },
      { content: i18n.t("test.numerical.content6"), isBanner: true },
    ],
  },
  abstract: {
    header: i18n.t("test.abstract.abstractInventory"),
    contentData: [
      { content: i18n.t("test.abstract.content") },
      { content: i18n.t("test.abstract.content2") },
      { content: i18n.t("test.abstract.content3") },
      { content: i18n.t("test.abstract.content4") },
      { content: i18n.t("test.abstract.content5") },
    ],
  },
  interest: {
    header: i18n.t("test.interest.interestInventory"),
    contentData: [
      { content: i18n.t("test.interest.content") },
      { content: i18n.t("test.interest.content2") },
      { content: i18n.t("test.interest.content3") },
      { content: i18n.t("test.interest.content4") },
      { content: i18n.t("test.interest.content5") },
    ],
  },
  verbal3: {
    header: i18n.t("test.reasoning.verbalReasoning"),
    contentData: [
      { content: i18n.t("test.reasoning.content") },
      { content: i18n.t("test.reasoning.content2") },
      { content: i18n.t("test.reasoning.content3") },
      { content: i18n.t("test.reasoning.content4") },
      { content: i18n.t("test.reasoning.content5") },
      { content: i18n.t("test.reasoning.content6") },
    ],
  },
  verbal1: {
    header: i18n.t("test.vocabulary.verbalVocabulary"),
    contentData: [
      { content: i18n.t("test.vocabulary.content") },
      { content: i18n.t("test.vocabulary.content2") },
      { content: i18n.t("test.vocabulary.content3") },
      { content: i18n.t("test.vocabulary.content4") },
      { content: i18n.t("test.vocabulary.content5") },
      { content: i18n.t("test.vocabulary.content6") },
      { content: i18n.t("test.vocabulary.content7"), isBanner: true },
    ],
  },
  verbal2: {
    header: i18n.t("test.comprehension.verbalComprehension"),
    contentData: [
      { content: i18n.t("test.comprehension.content") },
      { content: i18n.t("test.comprehension.content2") },
      { content: i18n.t("test.comprehension.content3") },
      { content: i18n.t("test.comprehension.content4") },
      { content: i18n.t("test.comprehension.content5") },
      { content: i18n.t("test.comprehension.content6") },
    ],
  },
};
