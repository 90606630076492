import React, { FC } from "react";

import { Background, Wrapper, Title, Heading } from "./styles";
import { Props } from "./types";

const MobileModal: FC<Props> = ({
  onClose,
  children,
  title,
  heading,
  headerIcon,
  isOpen,
}) => {
  if (!isOpen) return null;
  return (
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        {headerIcon}
        <Heading>{heading}</Heading>
        <Title>{title}</Title>
        {children}
      </Wrapper>
    </Background>
  );
};

export default MobileModal;
