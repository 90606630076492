import styled from "styled-components";

import media from "helpers/media";

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  margin: 0 1.6rem;
  width: 100%;
`;

export const TopContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 4.2rem;
  justify-content: space-between;
  width: 100%;

  ${media.SM`
    display: none;
  `}
`;

export const Muted = styled.span`
  color: ${({ theme }) => theme.colors.manatee};
  font-weight: 400;
`;

export const SchoolLabel = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 1rem 1.4rem;
  max-width: 250px;
  position: relative;
  cursor: pointer;
  margin-right: 1.8rem;

  ${media.MD`
    margin-right: 2rem;
  `}
`;

export const AssessmentList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-end;
  margin-top: -1rem;
  margin-left: 2rem;
  flex: 1;
  ${media.MD`
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    margin: -2.8rem 3.8rem 0 3.8rem;
  `}
  ${media.SM`
    margin: 0;
  `}
`;

export const MobileHeader = styled.h1`
  display: none;
  font-size: 2.4rem;
  line-height: 2.8rem;
  max-width: 16rem;
  margin-bottom: 0.4rem;
  color: ${({ theme }) => theme.colors.mineShaft};

  ${media.SM`
    display: block;
  `};
`;

export const DesktopOnly = styled.div`
  display: block;
  ${media.SM`
    display: none;
  `};
`;

export const MobileOnly = styled.div`
  display: none;
  ${media.SM`
    display: block;
  `};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  ${media.MD`
    width: 100%;
  `}
`;
