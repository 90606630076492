import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import paths from "routes/paths";
import { AssessmentType } from "types";
import TestInformation from "components/molecules/TestInformation";
import { useToast } from "hooks/Toast";
import {
  useGetAuthenticatedUserQuery,
  useStartInterestInventoryTestMutation,
} from "generated/graphql";

import { instructionsContent } from "./data";

import { StyledButton, ButtonsSection } from "../TrainingFinished/styles";

const Instructions: FC = () => {
  const { t } = useTranslation();
  const { type, id } = useParams<{ type: AssessmentType; id: string }>();
  const history = useHistory();

  const Toast = useToast();

  const [startInterestInventoryTest] = useStartInterestInventoryTestMutation();
  const { data: userData } = useGetAuthenticatedUserQuery();

  const contentData = instructionsContent[type];

  const sideMenuValues = [{ name: "Dashboard", path: paths.studentDashboard }];

  const handleNavigate = () => {
    history.push(`/training/${type}`);
  };

  const handleStartTest = () => {
    startInterestInventoryTest({
      variables: {
        id: Number(
          userData?.authenticatedItem?.students![0].testAttempt?.id || -1
        ),
      },
    })
      .then(() => history.push(`/student/test/${type}`))
      .catch((err) => Toast("error", err.message));
  };

  return (
    <>
      <TestInformation
        subHeader={t("student.instructions")}
        contentType={contentData}
        assessmentType={type}
        sideMenuValues={sideMenuValues}
        buttonsSection={
          <ButtonsSection>
            <StyledButton variant="primary" onClick={handleNavigate}>
              {t("student.exampleQuestions")}
            </StyledButton>
            <StyledButton variant="secondary" onClick={handleStartTest}>
              {t("student.startAssessment")}
            </StyledButton>
          </ButtonsSection>
        }
      />
    </>
  );
};

export default Instructions;
