import styled from "styled-components";

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2.2rem;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Input = styled.input`
  border: none;
  border-radius: 0.4rem;
  padding: 1rem;

  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: normal;
  border: 1px solid ${({ theme }) => theme.colors.mishka};
  color: ${({ theme }) => theme.colors.sharade};
  width: 100%;
  letter-spacing: 0.02rem;
  cursor: text;

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.athensGray};
    background-color: ${({ theme }) => theme.colors.athensGray};
    cursor: not-allowed;
  }
`;

export const Error = styled.span`
  text-align: right;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.01rem;
  color: ${({ theme }) => theme.colors.newYorkPink};
  font-style: italic;
`;

export const LabelWrapper = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const Optional = styled.div`
  font-size: 1.4rem;
  color: #333;
  font-weight: 400;
  line-height: 1.4rem;
  opacity: 0.4;
`;
