import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as KebabButton } from "assets/images/kebabButton.svg";
import Modal from "components/atoms/Modal";
import ButtonGroup from "components/molecules/ButtonGroup";
import Textarea from "components/atoms/Textarea";
import Checkbox from "components/atoms/Checkbox";
import Button from "components/atoms/Button";
import { StudentStatusType } from "generated/graphql";

import EmailChipsGroup from "components/molecules/EmailChipsGroup";
import { SelectableStudent } from "types/student";
import {
  Container,
  InvitationSection,
  Separator,
  TemplateSection,
  TemplateLabel,
  EmailTextWrapper,
  EmailTitleWrapper,
  AttachSection,
  AttachSectionLabel,
  ButtonsSection,
  ChipsWrapper,
  TitleLabel,
  EmailTitle,
  ErrorMessage,
  SelectOptions,
  Option,
  VariablesSelectWrapper,
  TemplateWrapper,
  AttachStudentsSection,
  StyledExclamationIcon,
} from "./styles";
import { Props } from "./types";
import { TemplatesType } from "../types";

const StudentMessageModal: FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    selectedTemplateId,
    onTemplateClick,
    studentsAttached,
    setStudentsAttached,
    searchStudentValue,
    setSearchStudent,
    selectedStudents,
    handleToggleStudent,
    data,
    form,
  } = props;
  const { t } = useTranslation();

  const selectOptions = [
    {
      name: t("teacher.studentsName"),
      value: "{{name}}",
    },
    {
      name: t("teacher.studentsFirstName"),
      value: "{{firstName}}",
    },
    {
      name: t("teacher.studentsSurname"),
      value: "{{lastName}}",
    },
  ];

  const templateButtonsData: {
    id: TemplatesType;
    title: string;
  }[] = [
    { id: "completed", title: t("teacher.completed") },
    { id: "inProgress", title: t("teacher.inProgress") },
    { id: "notStarted", title: t("teacher.notStarted") },
    { id: "custom", title: t("teacher.custom") },
  ];

  const textareaRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [attachedStudents, setAttachedStudents] = useState<SelectableStudent[]>(
    []
  );
  const handleToggleSelect = () => {
    setSelectOpen(!isSelectOpen);
  };

  useEffect(() => {
    const attached = selectedStudents.filter(
      (student) => student.status === StudentStatusType.Completed
    );
    setAttachedStudents(attached);
  }, [selectedStudents]);

  const handleSelectVariable = (value: string) => {
    const cursorPosition2 = textareaRef.current.selectionStart;
    const firstPart = form.values.content.substring(0, cursorPosition2);
    const secondPart = form.values.content.substring(
      cursorPosition2,
      form.values.content.length
    );
    const updatedContent = `${firstPart}${value}${secondPart}`;

    form.setFieldValue("content", updatedContent);
  };

  const handleSelectOption = (value: string) => {
    handleSelectVariable(value);
    setSelectOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <Container>
        <form onSubmit={form.handleSubmit}>
          <InvitationSection>
            <div>{t("teacher.to")}:</div>
            <ChipsWrapper>
              <EmailChipsGroup
                inputValue={searchStudentValue}
                onInputSearch={setSearchStudent}
                handleToggle={handleToggleStudent}
                data={data}
                selectedData={selectedStudents}
              />
            </ChipsWrapper>
          </InvitationSection>
          <Separator />
          <TemplateSection>
            <TemplateWrapper>
              <TemplateLabel>{t("teacher.messageTemplate")}:</TemplateLabel>
              <ButtonGroup
                buttonsData={templateButtonsData}
                selectedButtonId={selectedTemplateId}
                onButtonClick={onTemplateClick}
                styles={{ flexGrow: 0 }}
              />
            </TemplateWrapper>
            <VariablesSelectWrapper>
              <button type="button" onClick={handleToggleSelect}>
                <KebabButton />
              </button>
              {isSelectOpen && (
                <SelectOptions style={{ top: "100%", right: 0 }}>
                  {selectOptions.map((el) => {
                    return (
                      <Option
                        key={el.value}
                        onClick={() => handleSelectOption(el.value)}
                      >
                        {el.name}
                      </Option>
                    );
                  })}
                </SelectOptions>
              )}
            </VariablesSelectWrapper>
          </TemplateSection>
          <EmailTitleWrapper>
            <TitleLabel>{t("teacher.topic")}:</TitleLabel>
            <EmailTitle
              name="title"
              value={form.values.title}
              onChange={form.handleChange}
            />
          </EmailTitleWrapper>
          <ErrorMessage>{form.touched.title && form.errors.title}</ErrorMessage>
          <EmailTextWrapper>
            <Textarea
              name="content"
              value={form.values.content}
              onChange={form.handleChange}
              reference={textareaRef}
            />
            <ErrorMessage>
              {form.touched.content && form.errors.content}
            </ErrorMessage>
          </EmailTextWrapper>
          <AttachSection>
            <Checkbox
              isChecked={studentsAttached}
              onClick={setStudentsAttached}
            >
              <AttachSectionLabel>
                {t("teacher.attachInfo")} ({attachedStudents.length})
              </AttachSectionLabel>
            </Checkbox>
            <AttachStudentsSection>
              <StyledExclamationIcon />
              <div>{t("teacher.availabilityInfo")}</div>
            </AttachStudentsSection>
          </AttachSection>
          <ButtonsSection>
            <Button variant="primary" type="submit">
              {t("teacher.sendMessages")} ({selectedStudents.length})
            </Button>
            <Button variant="link" onClick={onClose}>
              {t("actions.cancel")}
            </Button>
          </ButtonsSection>
        </form>
      </Container>
    </Modal>
  );
};

export default StudentMessageModal;
