import React, { useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useGetFeedbackResponseByTokenQuery,
  useFormSubmitByTokenMutation,
  useUnauthenticateUserMutation,
} from "generated/graphql";
import Loader from "components/atoms/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as CircleCheckedGreen } from "assets/images/circle-checked-green.svg";
import FeedbackFormModal from "../Student/Dashboard/Modals/FeedbackFormModal";
import { useToast } from "../../hooks/Toast";
import { FeedbackForm } from "../Student/Dashboard/Modals/FeedbackFormModal/types";
import Modal from "../../components/atoms/Modal";
import { Container, TextWrapper } from "./styles";
import Button from "../../components/atoms/Button";

const Feedback = () => {
  const Toast = useToast();
  const { t } = useTranslation();

  const [logout, { client }] = useUnauthenticateUserMutation();

  const logoutRef = useRef<boolean>(true);

  const { token, testAttemptId } = useParams<{
    token: string;
    testAttemptId: string;
  }>();
  const history = useHistory();
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [isCompletedModalVisible, setIsCompletedModalVisible] = useState(false);
  const [feedbackForm, setFeedbackForm] = useState<FeedbackForm | null>(null);
  const [testAttempt, setTestAttempt] = useState("");
  const [formSubmit] = useFormSubmitByTokenMutation();
  const [anotherFeedbackUrl, setAnotherFeedbackUrl] = useState<string | null>(
    null
  );
  const [anotherFormType, setAnotherFormType] = useState<string | null>(null);

  const {
    data: feedbackResponseData,
    loading: feedbackResponseDataLoading,
    error: feedbackResponseDataError,
  } = useGetFeedbackResponseByTokenQuery({
    variables: {
      token,
    },
    onError: (error) => Toast("error", error.message),
  });

  const onClose = () => {
    history.push("/login/");
  };

  const submitForm = (responseId: string) => {
    formSubmit({
      variables: {
        token,
        responseId,
      },
    })
      .then(() => {
        setIsCompletedModalVisible(true);
        setIsFeedbackModalVisible(false);
      })
      .catch((err) => Toast("error", err.message));
  };

  useEffect(() => {
    if (logoutRef.current) {
      logout().then(() => client.resetStore());
      logoutRef.current = false;
    }

    if (!feedbackResponseDataError) {
      const encodedTestAttemptId = atob(testAttemptId);
      setTestAttempt(encodedTestAttemptId);

      const feedbackResponse =
        feedbackResponseData?.findFeedbackResponseByToken;

      if (feedbackResponse) {
        if (feedbackResponse.url) {
          setAnotherFeedbackUrl(feedbackResponse.url);
        }
        if (feedbackResponse.anotherFeedbackFormType) {
          setAnotherFormType(feedbackResponse.anotherFeedbackFormType);
        }
        if (feedbackResponse.feedbackResponse.form)
          setFeedbackForm(
            feedbackResponse.feedbackResponse.form as FeedbackForm
          );
        if (feedbackResponse.isSubmitted) {
          setIsCompletedModalVisible(true);
        } else {
          setIsFeedbackModalVisible(true);
        }
      }
    }
  }, [
    logout,
    client,
    token,
    feedbackResponseData,
    testAttemptId,
    feedbackResponseDataError,
    Toast,
    history,
  ]);

  if (feedbackResponseDataLoading) {
    return <Loader />;
  }

  return (
    <div>
      <FeedbackFormModal
        isVisible={isFeedbackModalVisible}
        onClose={onClose}
        testAttemptId={testAttempt}
        formData={feedbackForm}
        onSubmit={submitForm}
      />
      <Modal
        isOpen={isCompletedModalVisible}
        onClose={onClose}
        title="Feedback"
        size="medium"
      >
        <Container>
          <TextWrapper>{t("student.feedbackThank")}</TextWrapper>
          <CircleCheckedGreen style={{ width: "5rem", height: "5rem" }} />
          {anotherFeedbackUrl && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextWrapper>
                {t("student.fillAnotherFeedback", {
                  formType: anotherFormType?.toLowerCase() || "assessment",
                })}
              </TextWrapper>
              <a href={anotherFeedbackUrl}>
                <Button variant="primary">
                  {t("student.feedbackModalFillButton")}
                </Button>
              </a>
            </div>
          )}
        </Container>
      </Modal>
    </div>
  );
};

export default Feedback;
