import { ReactiveVar, makeVar } from "@apollo/client";

import { GetAuthenticatedUserQuery } from "generated/graphql";
import { UserRoles } from "types/user";

export const authenticatedUserVar: ReactiveVar<GetAuthenticatedUserQuery> = makeVar<GetAuthenticatedUserQuery>(
  {}
);

export const userRoleVar: ReactiveVar<UserRoles | null> = makeVar<UserRoles | null>(
  null
);
