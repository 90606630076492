import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import media from "helpers/media";

export const Wrapper = styled.div<{
  top?: string;
  visible: boolean;
  position?: "left" | "right" | "full";
}>`
  background: ${({ theme }) => theme.colors.white};
  visibility: hidden;
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  top: 100%;

  max-height: 80vh;
  overflow: scroll;

  ${({ position }) =>
    position === "full"
      ? css`
          left: 0;
          right: 0;
        `
      : css`
          ${position}: 0;
        `}

  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
    `}

    ${media.SM`
    left: -115px;
  `};
`;

export const MenuItem = styled.li`
  text-align: center;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.linkWater};
    color: ${({ theme }) => theme.colors.denim};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Button = styled.button<{ color?: string }>`
  appearance: none;
  padding: 0.8rem 2.7rem;
  width: 100%;
  font-weight: 400;
  ${({ color }) => color && `color: ${color}`};
`;

export const StyledLink = styled(Link)`
  display: block;
  padding: 0.8rem 2.7rem;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.linkWater};
  width: 288px;
  cursor: text;

  padding: 0.8rem 0.8rem;
  position: fixed;

  background-color: ${({ theme }) => theme.colors.white};
`;

export const Spacer = styled.div`
  height: 3rem;
`;

export const InputWrapper = styled.div`
  position: relative;
`;
