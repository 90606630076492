import React, { FC } from "react";

import styled, { css } from "styled-components";

import media from "helpers/media";

import Footer from "components/atoms/Footer";

const MainColumn = styled.div`
  background-color: ${({ theme }) => theme.colors.efcGray};
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    ${media.SM`
      padding: 1rem; 
      background-color: ${theme.colors.white};
      height: auto;
  `}
  `}
`;

const ContentWrapper = styled.div<{ narrow: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 64.2rem;
  border-radius: 0.8rem;
  padding: 4.8rem 6rem;
  margin: 4.8rem 6rem;

  flex: 1;
  ${({ narrow }) =>
    narrow &&
    css`
      max-width: 45rem;
    `}
  ${media.SM`
    margin-top: 0;
    margin: 0;
    padding: 0 4rem;
    flex: 1;
    display: flex;
  `}
`;

const LogoWrapper = styled.div`
  ${media.SM`
    align-self: center;
    margin-top: 2.5rem;
  `}
  display: flex;
  margin-top: 5.6rem;
  justify-content: center;
  width: 300px;
`;

type Props = {
  selfRegistration?: boolean;
  narrow?: boolean;
};

const LoginTheme: FC<Props> = ({ children, narrow = false }) => (
  <>
    <MainColumn>
      <LogoWrapper>
        <img
          src="/images/efcLogo.png"
          alt="English for Careers"
          style={{ width: "300px" }}
        />
      </LogoWrapper>
      <ContentWrapper narrow={narrow}>{children}</ContentWrapper>
    </MainColumn>
    <Footer />
  </>
);

export default LoginTheme;
