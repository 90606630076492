import React from "react";
import { PopupButton } from "@typeform/embed-react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/atoms/Button";
import Modal from "../../../../../components/atoms/Modal";
import { ButtonSection, Container, TextWrapper } from "./styles";
import { Props } from "./types";

const FeedbackFormModal = ({
  isVisible,
  onClose,
  testAttemptId,
  formData,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {formData && (
        <Modal
          isOpen={isVisible}
          onClose={onClose}
          title={formData.modal.modalTitle}
          size="medium"
        >
          <Container>
            <TextWrapper>{formData.modal.modalContent}</TextWrapper>
            <ButtonSection>
              <PopupButton
                id={formData.formId}
                onSubmit={(response) => onSubmit(response.responseId)}
                hidden={{
                  testattemptid: testAttemptId,
                }}
              >
                <Button variant="primary">
                  {t("student.feedbackModalFillButton")}
                </Button>
              </PopupButton>
              <Button
                variant="primary"
                style={{ marginLeft: "2rem" }}
                onClick={onClose}
              >
                {t("student.feedbackModalLaterButton")}
              </Button>
            </ButtonSection>
          </Container>
        </Modal>
      )}
    </div>
  );
};

export default FeedbackFormModal;
