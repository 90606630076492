import Colors from "constants/colors";
import { createGlobalStyle } from "styled-components";

import Calendar from "assets/images/calendar.svg";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
    height: 100%;
  }
  body {
    font-family: 'Roboto', 'Roboto Slab', Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    min-height: 100%;
    color: ${Colors.mineShaft};
  }
  button, input {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  #root {
    height: inherit;
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  textarea  {  
    font-family: 'Roboto', 'Roboto Slab', Helvetica, Arial, sans-serif;
  }

  .react-datepicker-wrapper {
    font-size: 1.6rem;
    font-weight: normal;
    border: 1px solid #D4D8E1 !important;
    letter-spacing: 0.02rem;
    background: url(${Calendar});
    background-repeat: no-repeat;
    background-position: right 15px center;

    input {
      padding: 0.8rem 1.6rem !important;
      width: 100%;
    }
  

  
  }

  .react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}

.chartjs-render-monitor {
  z-index: 2
}

.react-datepicker__header__dropdown  {
  padding: 1rem;
}

.react-datepicker__month-select {
  margin-right: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
  cursor: pointer;
}

.react-datepicker__year-select {
  padding: 0.3rem;
  border-radius: 4px;
  cursor: pointer;
}

`;

export default GlobalStyle;
