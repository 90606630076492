import React, { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ParseError } from "papaparse";
import { useToast } from "hooks/Toast";
import { ReactComponent as FileIcon } from "assets/images/cloud-upload.svg";
import parseCSV from "utils/parseCSV";
import Button from "components/atoms/Button";
import { StudentCSVKeys } from "constants/students/studentCSV";

import {
  Container,
  DropContent,
  StudentPreviewItem,
  StudentPreviewList,
  DNDContainer,
  CSVUploadWrapper,
  BoldText,
  DNDText,
  StudentName,
  PreviewWrapper,
  ErrorContainer,
  ErrorsList,
  DownloadCSVButton,
  PreviewHeader,
} from "../styles";
import { ParsedStudentsCSV, UploadUserProps, CSVErrorsState } from "../types";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const INVALID_NAME_REGEX = /^[0-9]*$/;

const AddStudentsModal: FC<UploadUserProps> = ({ CSVdata, setCSVData }) => {
  const { t } = useTranslation();
  const Toast = useToast();

  const [CSVErrors, setCSVErrors] = useState<CSVErrorsState>({
    headerErrors: [],
    parsingErrors: [],
    contentErrors: [],
    errorCount: 0,
  });

  const retrieveCSVData = useCallback(
    (file: {
      data: ParsedStudentsCSV[];
      errors: ParseError[];
      meta: {
        fields: string[];
      };
      // eslint-disable-next-line consistent-return
    }) => {
      const headerErrors = Object.values(StudentCSVKeys).filter(
        (key) => !file.meta.fields.includes(key)
      );

      const parsingErrors = file.errors.map(
        (error: ParseError) => `${error.message} in row ${error.row + 1}`
      );

      const contentErrors = file.data
        .map((row, index, self) => {
          const errors: string[] = [];

          if (
            typeof row["First Name"] === "string" &&
            INVALID_NAME_REGEX.test(row["First Name"].trim())
          ) {
            errors.push(
              `Field First Name cannot be empty or cannot contain only numbers in row ${
                index + 2
              }`
            );
          }

          if (
            typeof row["Last Name"] === "string" &&
            INVALID_NAME_REGEX.test(row["Last Name"].trim())
          ) {
            errors.push(
              `Field Last Name cannot be empty or cannot contain only numbers in row ${
                index + 2
              }`
            );
          }

          if (typeof row.Email === "string" && !EMAIL_REGEX.test(row.Email)) {
            errors.push(`Incorrect email: '${row.Email}' in row ${index + 2}`);
          }

          const notUniqueEmailIndex = self
            .map(
              (selfRow) => typeof selfRow.Email === "string" && selfRow.Email
            )
            .indexOf(row.Email, index + 1);

          if (notUniqueEmailIndex !== -1) {
            errors.push(
              `Given email: '${row.Email}' is duplicated in rows: ${
                index + 2
              } and ${notUniqueEmailIndex + 2}`
            );
          }

          return errors;
        })
        .filter((notValidField) => notValidField.length > 0)
        .flat();

      if (
        headerErrors.length > 0 ||
        parsingErrors.length > 0 ||
        contentErrors.length > 0
      ) {
        setCSVErrors({
          headerErrors,
          parsingErrors,
          contentErrors,
          errorCount:
            headerErrors.length + parsingErrors.length + contentErrors.length,
        });
      } else {
        setCSVData(file.data);
        Toast("success", t("successAction.fileUploaded"));
      }
    },
    [Toast, setCSVData, setCSVErrors, t]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      setCSVErrors({
        headerErrors: [],
        parsingErrors: [],
        contentErrors: [],
        errorCount: 0,
      });
      if (acceptedFiles[0]) {
        parseCSV(acceptedFiles[0], retrieveCSVData);
      } else {
        Toast("error", t("errorAction.importCsv"));
      }
    },
    [Toast, t, retrieveCSVData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const renderPreview = () => {
    return (
      <PreviewWrapper>
        <PreviewHeader>Uploaded list — preview</PreviewHeader>
        <StudentPreviewList>
          {CSVdata.map((row, i) => {
            return (
              <StudentPreviewItem
                key={
                  row[StudentCSVKeys.firstName] +
                  row[StudentCSVKeys.lastName] +
                  i
                }
              >
                <StudentName>
                  {`${row[StudentCSVKeys.firstName]}, ${
                    row[StudentCSVKeys.lastName]
                  }`}
                </StudentName>
                <div>{row[StudentCSVKeys.email]}</div>
              </StudentPreviewItem>
            );
          })}
        </StudentPreviewList>
      </PreviewWrapper>
    );
  };

  const renderDNDContent = () => {
    return (
      <DNDContainer>
        <DropContent {...getRootProps()}>
          <input {...getInputProps()} />
          <FileIcon />
          <DNDText>
            <BoldText>{t("teacher.drag&drop")}</BoldText>
            <div> {t("teacher.yourCsv")}</div>
          </DNDText>
          <div>{t("global.or")}</div>
          <Button
            variant="primary"
            style={{ width: "220px", marginTop: "1.6rem" }}
          >
            {t("teacher.chooseFile")}
          </Button>
          <DownloadCSVButton
            as="a"
            href="/assets/template.csv"
            target="_blank"
            variant="tertiary"
            onClick={(e: any) => e.stopPropagation()}
          >
            {t("teacher.downloadTemplate")}
          </DownloadCSVButton>
        </DropContent>
        {CSVErrors.errorCount > 0 && (
          <ErrorContainer>
            {CSVErrors.headerErrors.length > 0 && (
              <>
                {t("teacher.missingCSVheaders")}
                <ErrorsList>
                  {CSVErrors.headerErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}
            {CSVErrors.parsingErrors.length > 0 && (
              <>
                {t("teacher.parsingCSVErrors")}
                <ErrorsList>
                  {CSVErrors.parsingErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}
            {CSVErrors.contentErrors.length > 0 && (
              <>
                {t("teacher.incorrectCSVData")}
                <ErrorsList>
                  {CSVErrors.contentErrors.map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                </ErrorsList>
              </>
            )}

            {t("teacher.fixCSVfile")}
          </ErrorContainer>
        )}
      </DNDContainer>
    );
  };

  return (
    <Container>
      <CSVUploadWrapper>
        {CSVdata.length > 0 ? renderPreview() : renderDNDContent()}
      </CSVUploadWrapper>
    </Container>
  );
};

export default AddStudentsModal;
