import styled from "styled-components";
import CheckCircle from "assets/images/check-circle.svg";
import CheckCircleError from "assets/images/check-circle-error.svg";

export const Wrapper = styled.div`
  background: linear-gradient(
    115.63deg,
    rgba(255, 255, 255, 0.77) 18.89%,
    rgba(217, 222, 246, 0.566512) 65.78%,
    rgba(178, 188, 236, 0.36) 83.92%
  );
  backdrop-filter: blur(32px);
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${({ theme }) => theme.colors.denim};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<{ type: "success" | "error" }>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 48.2rem;
  padding: 4.4rem 4.8rem 4rem 4.8rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 1.6rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.03rem;
  display: flex;
  flex-direction: column;
  background-image: ${({ type }) =>
    type === "success" ? `url(${CheckCircle})` : `url(${CheckCircleError})`};
  background-repeat: no-repeat;
  background-position: top 4rem right 4rem;
`;

export const Heading = styled.h1`
  font-size: 2.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.03rem;
  margin-bottom: 2.4rem;
`;

export const Details = styled.div`
  margin-bottom: 4.8rem;
  padding-right: 6rem;
`;

export const ButtonWrapper = styled.div`
  align-self: center;
`;
