import styled, { css } from "styled-components";
import media from "helpers/media";
import { ReactComponent as PlayImage } from "assets/images/play.svg";
import { ReactComponent as PauseImage } from "assets/images/pause.svg";
import { ReactComponent as FullscreenImage } from "assets/images/fullscreen-icon.svg";
import { ReactComponent as VolumeImage } from "assets/images/volume.svg";
import { ReactComponent as MutedImage } from "assets/images/muted.svg";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  ${media.SM`
    justify-content: center;
`};
`;

export const Title = styled.h1`
  font-family: "Roboto Slab";
  font-weight: normal;
  font-size: 3.2rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  width: 100%;

  ${media.SM`
    justify-content: center;
`};
`;

export const PlayerContainer = styled.div<{
  maxWidth: number;
  maxHeight: number;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3rem 0;
  ${({ maxWidth, maxHeight }) =>
    css`
      width: ${maxWidth}px;
      height: ${maxHeight}px;

      ${media.LG`
        width: ${maxWidth * 0.85}px;
        height: ${maxHeight * 0.85}px;
      `}

      ${media.MD`
        width: ${maxWidth * 0.65}px;
        height: ${maxHeight * 0.65}px;
      `}

      ${media.SM`
        width: ${maxWidth * 0.6}px;
        height: ${maxHeight * 0.6}px;
      `}

      ${media.XS`
        width: ${maxWidth * 0.5}px;
        height: ${maxHeight * 0.5}px;
      `}
    `}
  :-webkit-full-screen {
    width: 100%;
    height: 100%;
    background-color: black;
  }
`;

export const ControlsWrapper = styled.div`
  position: relative;
  bottom: 92%;
  height: 85%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1rem;
`;

export const ControlsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 8%;
  min-height: 3rem;
  max-height: 5rem;
  background-color: ${({ theme }) => theme.colors.shuttleGray};
  border-radius: 5px 5px;
`;

export const PlayIcon = styled(PlayImage)`
  fill: ${({ theme }) => theme.colors.white};
  width: 10%;
  height: 50%;
  align-self: center;
  &:hover {
    fill: ${({ theme }) => theme.colors.denim};
  }
  transition: 0.2s;
`;

export const PauseIcon = styled(PauseImage)`
  width: 10%;
  height: 50%;
  align-self: center;
  &:hover {
    fill: ${({ theme }) => theme.colors.denim};
  }
  transition: 0.2s;
`;

export const SeekProgress = styled.input`
  width: 60%;
`;

export const VolumeIcon = styled(VolumeImage)`
  padding-left: 3%;
  height: 50%;
  align-self: center;
  &:hover {
    fill: ${({ theme }) => theme.colors.denim};
  }
  transition: 0.2s;
`;

export const MutedIcon = styled(MutedImage)`
  padding-left: 3%;
  height: 50%;
  align-self: center;
  &:hover {
    fill: ${({ theme }) => theme.colors.denim};
  }
  transition: 0.2s;
`;

export const VolumeBar = styled.input`
  width: 15%;
`;

export const FullscreenIcon = styled(FullscreenImage)`
  width: 10%;
  height: 70%;
  align-self: center;
  &:hover {
    fill: ${({ theme }) => theme.colors.denim};
  }
  transition: 0.2s;
`;

export const DescriptionContainer = styled.div<{
  maxWidth: number;
}>`
  font-size: 1.6rem;

  ${({ maxWidth }) =>
    css`
      width: ${maxWidth}px;

      ${media.LG`
        width: ${maxWidth * 0.85}px;
      `}

      ${media.MD`
        width: ${maxWidth * 0.65}px;
      `}

      ${media.SM`
        width: ${maxWidth * 0.6}px;
      `}

      ${media.XS`
        width: ${maxWidth * 0.5}px;
      `}
    `}
  & * {
    max-width: 100%;
    height: auto;
  }
`;
