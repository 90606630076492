import React, { FC, useMemo, useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import Modal from "components/atoms/Modal";
import Input from "components/molecules/Input";
import { FormikProps, useFormik } from "formik";
import {
  OrderDirection,
  useGetAllFolderCountriesQuery,
  useUpdateFolderInSchoolMutation,
} from "generated/graphql";
import { currentSchoolIdVar } from "store/school";
import { useReactiveVar } from "@apollo/client";
import StyledSelect from "components/molecules/Select";
import { Container, StyledButton } from "./styles";
import { EditFolderProps, AddFolderType } from "./types";

const EditFolderModal: FC<EditFolderProps> = ({
  isOpen,
  onClose,
  title,
  selectedFolder,
}) => {
  const { t } = useTranslation();
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);
  const [updateFolder] = useUpdateFolderInSchoolMutation();

  const { data: countries } = useGetAllFolderCountriesQuery({
    variables: { orderBy: [{ name: OrderDirection.Asc }] },
  });

  const countriesMemo = useMemo(() => {
    const countriesCopy = countries?.countries?.slice();
    return countriesCopy
      ?.sort((a, b) => (a.code === "EU" ? -1 : 1))
      ?.map((country) => ({
        label: `${country.flag} ${country.name}`,
        value: country.id,
      }));
  }, [countries]);

  const editFolderForm: FormikProps<AddFolderType> = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      folderName: selectedFolder?.name || "",
      countryId: Number(selectedFolder?.countryModel?.id) || 0,
    },

    onSubmit: async ({ folderName, countryId }, { setErrors }) => {
      updateFolder({
        variables: {
          name: folderName,
          countryId,
          schoolId: Number(currentSchoolId),
          updateFolderInSchoolId: Number(selectedFolder?.id),
        },
      })
        .then(() => onClose())
        .catch((e) => setErrors({ folderName: e.message }))
        .finally(() => onClose());
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <form
          onSubmit={editFolderForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="folderName"
            label={t("global.folderName")}
            placeholder={t("global.folderNamePlaceholder")}
            handleChange={editFolderForm.handleChange}
            handleBlur={editFolderForm.handleBlur}
            value={editFolderForm.values.folderName}
            error={
              editFolderForm.touched.folderName &&
              editFolderForm.errors.folderName
            }
          />
          <StyledSelect
            name="countryId"
            label="Language model"
            isOptional
            handleChange={(name: string, value: { value: string }) =>
              editFolderForm.setFieldValue(name, Number(value.value))
            }
            handleBlur={editFolderForm.handleBlur}
            value={countriesMemo?.find(
              (country) =>
                country.value === editFolderForm.values.countryId.toString()
            )}
            options={countriesMemo || []}
          />
          <StyledButton variant="primary" width="50%" type="submit">
            Save changes
          </StyledButton>
        </form>
      </Container>
    </Modal>
  );
};

export default EditFolderModal;
