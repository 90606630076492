import { AxiosResponse } from "axios";
import axiosInstance from "utils/axios";

export const downloadStudentReport = (
  id: string,
  onSuccess: (
    res: AxiosResponse<string | ArrayBuffer | ArrayBufferView | Blob>
  ) => void,
  onError: (error: string) => void
) => {
  return axiosInstance
    .get(`download/student?studentId=${id}`)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err.message);
    });
};

interface DownloadRawScoresParams {
  id: string;
  isArchived?: boolean;
}

export const downloadRawScoresReport = (
  { id, isArchived }: DownloadRawScoresParams,
  onSuccess: (
    res: AxiosResponse<string | ArrayBuffer | ArrayBufferView | Blob>
  ) => void,
  onError: (error: string) => void
) => {
  const isArchivedParam = isArchived != null ? `&isArchived=${isArchived}` : "";
  return axiosInstance
    .get(`download/school?schoolId=${id}${isArchivedParam}`)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err.message);
    });
};
