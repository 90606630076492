import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/circle-close.svg";
import { ReactComponent as PaperPlane } from "assets/images/paper-plane.svg";
import { ReactComponent as Unarchive } from "assets/images/unarchive.svg";
import { ReactComponent as Archive } from "assets/images/archive.svg";
import { ReactComponent as ResetIcon } from "assets/images/reset-icon.svg";
import Button from "components/atoms/Button";

export const ActionDrawerContainer = styled.div`
  margin: auto;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  border: 1px solid ${({ theme }) => theme.colors.denim};
`;

export const DrawerSection = styled.div`
  display: flex;
  align-items: center;
`;

export const DrawerLeftText = styled.h2`
  font-size: 1.4rem;
  font-family: "Roboto slab";
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 1.6rem;
`;

export const StyledButton = styled(Button)`
  height: auto;
  padding: 0;
  font-size: inherit;
  min-width: min-content;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 2rem;
  height: 2rem;
`;

export const StyledPaperPlaneIcon = styled(PaperPlane)`
  margin-right: 0.6rem;
`;

export const StyledResetIcon = styled(ResetIcon)`
  margin-right: 0.6rem;
`;

export const StudentsBtnWrapper = styled.div`
  padding-left: 0.4rem;
`;

export const SendMsgBtnContent = styled.div`
  padding-right: 2.4rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
`;

export const DownloadBtnWrapper = styled.div`
  padding-right: 1.6rem;
  display: flex;
  margin-right: 2.8rem;
  font-weight: 500;
  font-size: 1.2rem;
`;

export const UnarchiveButtonWrapper = styled.div`
  padding-right: 1.6rem;
`;

export const UnarchiveButtonWrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledUnarchive = styled(Unarchive)`
  margin-right: 0.6rem;
`;

export const StyledArchive = styled(Archive)`
  margin-right: 0.6rem;
`;
