import styled from "styled-components";

const Header = styled.h1`
  font-size: 2.2rem;
  font-weight: bold;

  color: ${({ theme }) => theme.colors.mineShaft};
  letter-spacing: 0.03rem;
  align-self: flex-start;
`;

export default Header;
