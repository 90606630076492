import styled, { css } from "styled-components";
import { ReactComponent as TickIcon } from "assets/images/tick.svg";
import Button from "components/atoms/Button";

export const Circle = styled.div<{ circleColor?: string }>`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: ${({ theme }) => theme.colors.oceanGreen};
  ${({ circleColor }) =>
    circleColor &&
    css`
      background-color: ${circleColor};
    `}
`;

export const TableRow = styled.tr`
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
  width: 100%;
  margin-left: 2.4rem;

  &:nth-child(odd) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      #d8ddf2;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mystic};
    td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.mystic};
      &:first-child {
        border-bottom-left-radius: 0.3rem;
      }
      &:last-child {
        border-bottom-right-radius: 0.3rem;
      }
    }
  }

  &:first-child {
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.mystic};
      &:first-child {
        border-top-left-radius: 0.3rem;
        width: 5rem;
      }
      &:last-child {
        border-top-right-radius: 0.3rem;
      }
    }
  }
`;

export const TableCell = styled.td<{ centered?: boolean }>`
  padding: 1.8rem 0;

  ${({ centered }) =>
    centered &&
    css`
      div {
        margin-left: 2rem;
      }
    `};

  &:first-child {
    padding-left: 1.6rem;
    border-left: 1px solid ${({ theme }) => theme.colors.mystic};
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.colors.mystic};
  }
`;

export const ActionItem = styled.div<{
  clickable?: boolean;
  disabled?: boolean;
  danger?: boolean;
}>`
  display: flex;

  align-items: center;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  color: ${({ theme }) => theme.colors.denim};
  font-size: 1.2rem;

  &:nth-child(2) {
    margin-left: 2.4rem;
  }

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.mandy};
    `}
`;

export const ActionLabel = styled.div`
  margin-left: 1rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.sharade};
`;

export const StyledButton = styled(Button)`
  min-width: min-content;
  height: min-content;
  padding: 0 1rem;
  font-size: 1.4rem;
  width: initial;
`;

export const Tick = styled(TickIcon)`
  margin-left: 0.8rem;
`;
